import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  titleRoot: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  body: {
    position: 'relative',
    backgroundColor: theme.palette.bg.primary.default,
    border: theme.palette.border.secondary,
    borderRadius: theme.borderRadius.md,
    padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
  },
  title: {
    fontFamily: 'Inter',
    fontWeight: 500,
    padding: `${theme.spacing(1.5)} 0`,
    color: theme.palette.tx.primary,
  },
  stroke: {
    display: 'inline-block',
    width: `calc(100% + ${theme.spacing(6)})`,
    height: 2,
    backgroundColor: theme.palette.bg.primary.default,
    '&.outlined': {
      display: 'none',
    },
  },
}));
