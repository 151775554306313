import { PlanType } from '@api-zephyr/domains/subscription';
import { EnforceQuotaSettings } from 'ze-api-contract/organization-v1/post-organization-subscription-enforce-quota';

export const GET_ORGANIZATION_SUBSCRIPTION_PATH: string = '/organization/subscription';

export interface CurrentSubscription {
  isFirstSubscription: boolean;
  plan?: PlanType;
  quota: CurrentSubscriptionQuota;
  stats: CurrentSubscriptionQuota;
  subscriptionId: string;
  dueDate?: Date;
  enforceQuota: EnforceQuotaSettings;
  scheduled?: CurrentSubscriptionScheduled;
}

export interface CurrentSubscriptionQuota {
  users: number;
}

export interface CurrentSubscriptionScheduled {
  scheduleId: string;
  plan: PlanType;
  quota: CurrentSubscriptionQuota;
}
