import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  itemBox: {
    padding: '12px 16px',
    backgroundColor: theme.palette.bg.secondary.default,
    borderRadius: 8,
    maxWidth: 290,
    width: '30%',
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 700,
    border: theme.palette.border.secondary,

    '&.disabled': {
      cursor: 'not-allowed',
    },

    '&.active': {
      borderColor: theme.palette.border.primary,
    },
  },

  itemTitle: {
    fontSize: 18,
    fontWeight: 700,
    color: theme.palette.tx.primary,
  },

  defaultProviderSpan: {
    fontSize: 14,
    fontWeight: 700,
    color: theme.palette.tx.secondary.default,
  },

  providerBtn: {
    padding: 0,
    fontSize: 14,
    color: theme.palette.brand.purple[400],
    width: 'auto',
    height: 'auto',
    minWidth: 0,
    '&:hover': {
      color: theme.palette.tx.primary,
      backgroundColor: 'transparent',
    },
  },

  imgBox: {
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    backgroundColor: theme.palette.brand.gray[800],
    border: theme.palette.border.primary,
  },
}));
