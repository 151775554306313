import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  component: {
    width: '100%',
    color: theme.palette.tx.primary,
  },

  fullWidth: {
    width: '100%',
  },

  halfWidth: {
    width: '50%',
  },

  inputLabel: {
    marginTop: '24px',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '20px',
  },

  input: {
    marginTop: '2px',
    '& .MuiInputBase-root, input': {
      backgroundColor: theme.palette.bg.secondary.default,
      border: theme.palette.border.secondary,
    },
    '&:hover $notchedOutline': {
      border: theme.palette.border.primary,
    },
    '& svg': {
      color: theme.palette.tx.secondary.default,
    },
  },

  checkboxLabel: {
    marginTop: '24px',
    color: theme.palette.tx.secondary.default,
  },

  notchedOutline: {
    borderColor: theme.palette.border.tertiary,
  },
}));
