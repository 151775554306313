export enum DeploymentIntegrationType {
  WORKER = 'worker',
  PAGES = 'pages',
}
export enum DeploymentIntegrationPlatform {
  CLOUDFLARE = 'cloudflare',
  AWS = 'aws',
  NETLIFY = 'netlify',
  AZURE = 'azure',
  GCP = 'gcp',
  FASTLY = 'fastly',
}

export interface CloudflareIntegration {
  zone_id: string;
  zone_name?: string;
  account_id?: string;
  account_name?: string;
  projectName?: string;
}

export interface NetlifyIntegration {
  site_id: string;
  site_name?: string;
}

export interface CreateCloudflareIntegration {
  zone_id: string;
  api_token: string;
  projectName?: string;
}

export interface CreateNetlifyIntegration {
  site_id: string;
  api_token: string;
}

export interface CreateFastlyIntegration {
  api_token: string;
  domain: string;
}

export interface UpdateCloudflareIntegration {
  api_token: string;
  projectName?: string; // Will be ignored if set previously
}

export interface UpdateNetlifyIntegration {
  api_token: string;
}

export interface UpdateFastlyIntegration {
  api_token: string;
}

export type CreateIntegrationCfg = CreateCloudflareIntegration | CreateNetlifyIntegration | CreateFastlyIntegration;
export interface CreateDeploymentIntegration {
  name: string;
  displayName: string;
  type: DeploymentIntegrationType;
  platform: DeploymentIntegrationPlatform;
  integrationConfig: CreateIntegrationCfg;
  isDefault: boolean;
}

export type UpdateIntegrationCfg = UpdateCloudflareIntegration | UpdateNetlifyIntegration | UpdateFastlyIntegration;
export interface UpdateDeploymentIntegration {
  displayName: string | undefined;
  integrationConfig: UpdateIntegrationCfg | undefined;
  isDefault: boolean | undefined;
}

export interface CloudflareDeploymentConfig {
  zoneName: string;
  accountId: string;
  accountName: string;
  kvEnvsNamespaceId: string;
  kvSnapshotsNamespaceId: string;
  kvFilesNamespaceId: string;
  edgeUrl: string;
  staticUploadWorkerId: string;
  projectName?: string; // Cloudflare pages project name
}

export interface NetlifyDeploymentConfig {
  edgeUrl: string;
  domain: string;
  siteId: string;
  siteName?: string;
  accountId?: string;
  accountName?: string;
  accountSlug?: string;
}

export interface FastlyDeploymentConfig {
  edgeUrl: string;
  serviceId: string;
}

export interface FastlyIntegration {
  domain: string;
}

export type DeploymentConfig = CloudflareDeploymentConfig | NetlifyDeploymentConfig | FastlyDeploymentConfig;
export type DeploymentIntegrationConfigValue = CloudflareIntegration | NetlifyIntegration | FastlyIntegration;

export interface DeploymentIntegrationValue {
  id: string;
  name: string;
  displayName: string;
  type: DeploymentIntegrationType;
  platform: DeploymentIntegrationPlatform;
  integrationConfig?: DeploymentIntegrationConfigValue | undefined;
  deploymentConfig?: DeploymentConfig | undefined;
  organizationId: string;
  isDefault: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  isEnabled: boolean;
  workerVersionId: string | null;
}

export interface GetCloudflareZoneListParams {
  api_token: string;
}

export interface CloudflareZoneInfo {
  id: string;
  name: string;
  accountId: string;
  accountName: string;
  owner: string;
}

export interface GetNetlifySiteListParams {
  api_token: string;
}

export interface NetlifySiteInfo {
  id: string;
  name: string;
  customDomain?: string;
  defaultDomain?: string;
  url: string;
}
