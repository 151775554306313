import { envValue } from 'app-zephyr-environment';
import { Auth0Provider, AuthorizationParams } from '@auth0/auth0-react';
import { RouteNames } from 'app-zephyr-routes';
import React from 'react';

export const Auth0ProviderWithHistory = ({ children }) => {
  const {
    auth0: { domain, clientId, scope },
  } = envValue.value;
  // const [searchParams] = useSearchParams();
  // const organization = searchParams.get('organization');
  // const invitation = searchParams.get('invitation');

  const authorizationParams: AuthorizationParams = {
    scope,
    redirect_uri: window.location.origin.toString(),
  };
  const redirect_callback_url = new URL(window.location.origin);
  redirect_callback_url.pathname = RouteNames.INIT;
  // if (organization) authorizationParams.organization = organization;
  // if (invitation) authorizationParams.invitation = invitation;

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      onRedirectCallback={() => {
        window.location.assign(redirect_callback_url.toString());
      }}
      authorizationParams={authorizationParams}
      cacheLocation="localstorage"
      legacySameSiteCookie={false}
    >
      {children}
    </Auth0Provider>
  );
};
