import { useState, useRef, ChangeEvent, KeyboardEvent, MouseEvent, useEffect } from 'react';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import {
  UseFormRegister,
  FieldName,
  Path,
  FieldValues,
  PathValue,
  FieldErrors,
  Control,
  Controller,
  UseFormGetValues,
  UseFormSetValue,
  UseFormTrigger,
} from 'react-hook-form';
import { ErrorMessage, FieldValuesFromFieldErrors } from '@hookform/error-message';

import { ErrorMessage as FormErrorMessage, Label } from '../../_elements';
import { useStyles } from './styles';

export const KEYBOARD_KEY = {
  enter: 'Enter',
  backspace: 'Backspace',
};

export const KEYBOARD_KEYCODE = {
  ime: 229,
};

interface TextFieldChipsProps<T extends FieldValues> {
  id: string;
  control: Control<T, unknown>;
  disabled?: boolean;
  defaultValue?: string[];
  name: Path<T>;
  errors?: FieldErrors<T>;
  inputName: Path<T>;
  register: UseFormRegister<T>;
  getValues: UseFormGetValues<T>;
  setValue: UseFormSetValue<T>;
  trigger: UseFormTrigger<T>;
  label?: string;
  // fix me: workaround for reset event
  isReset?: boolean;
  hint?: string;
}

export const TextFieldChips = <T extends FieldValues>({
  inputName,
  name,
  register,
  errors,
  defaultValue,
  id,
  control,
  getValues,
  setValue,
  trigger,
  isReset,
  disabled,
  hint,
  label,
  ...props
}: TextFieldChipsProps<T>) => {
  const { classes } = useStyles();
  const [chips, setChips] = useState<string[]>(defaultValue ?? []);
  const [inputValue, setInputValue] = useState<string>('');
  const inputElRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setValue(name, chips as PathValue<T, Path<T>>, { shouldValidate: true, shouldDirty: true });
    void trigger(name);
  }, [chips, name, setValue, trigger]);

  useEffect(() => {
    if (isReset) setChips(defaultValue ?? []);
  }, [isReset, defaultValue]);

  const clearInputValue = () => {
    setInputValue('');
    setTimeout(() => {
      setValue(inputName, '' as PathValue<T, Path<T>>, { shouldValidate: true, shouldDirty: true });
    }, 1);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setInputValue(inputValue);
    setValue(inputName, inputValue as PathValue<T, Path<T>>, { shouldValidate: true, shouldDirty: true });
    void trigger(inputName);
  };

  const addChip = () => {
    if (!errors?.[inputName] && inputValue.trim()) {
      setChips((prev) => [...prev, inputValue.trim()]);
      clearInputValue();
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && inputValue.trim()) {
      event.preventDefault();
      addChip();
      clearInputValue();
    }
  };

  const handleDeleteChip = (chipIndex: number) => {
    setChips((prev) => prev.filter((_, index) => index !== chipIndex));
  };

  const handleClearAll = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setChips([]);
    clearInputValue();
  };

  const hasAtLeastOneChip = chips.length > 0;

  return (
    <Stack className={classes.container} spacing={1}>
      {label && (
        <Label onClick={() => inputElRef.current?.focus()} htmlFor={name}>
          {label}
        </Label>
      )}
      <ClickAwayListener
        onClickAway={() => {
          clearInputValue();
        }}
      >
        <Stack>
          <Controller
            name={name}
            control={control}
            disabled={disabled}
            render={() => (
              <TextField
                inputProps={{
                  id,
                  itemType: 'text',
                  onKeyDown: handleKeyDown,
                  enterKeyHint: 'done',
                  disabled: disabled,
                  onChange: handleChange,
                  ...props,
                }}
                InputProps={{
                  inputRef: inputElRef,
                  startAdornment: hasAtLeastOneChip
                    ? chips.map((chip, index) => {
                        const key = `chip-${index.toString()}`;

                        return (
                          <Chip
                            label={chip}
                            title={chip}
                            onDelete={() => {
                              handleDeleteChip(index);
                            }}
                            disabled={disabled}
                            key={key}
                          />
                        );
                      })
                    : null,
                  endAdornment: (
                    <div style={{ visibility: hasAtLeastOneChip ? 'visible' : 'hidden' }}>
                      <IconButton
                        aria-label="Clear"
                        title="Clear"
                        size="small"
                        onClick={handleClearAll}
                        className={classes.clearAllBtn}
                        disabled={disabled}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </div>
                  ),
                }}
                placeholder={hasAtLeastOneChip ? '' : 'Fill out domain and press enter'}
                fullWidth
                multiline
                {...register(inputName)}
                className={classes.textField}
              />
            )}
          />
          <p className={classes.helperText}>{hint}</p>
        </Stack>
      </ClickAwayListener>
      <ErrorMessage
        errors={errors}
        name={inputName as unknown as FieldName<FieldValuesFromFieldErrors<FieldErrors<T>>>}
        render={({ message }) => <FormErrorMessage className={'error-message'}>{message}</FormErrorMessage>}
      />
    </Stack>
  );
};
