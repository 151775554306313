export function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getAbbreviation(text = '', limit = 2): string {
  const textArray = text.match(/\b([A-Za-z0-9])/g) ?? [];
  if (textArray.length >= limit) {
    return textArray.slice(0, limit).join('').toUpperCase();
  }

  return text.slice(0, 2).toUpperCase();
}

export function replaceRange(text: string, start: number, end: number, substitute: string): string {
  return text.substring(0, start) + substitute + text.substring(end);
}
/**
 * Set copyString to the clipboard.
 * @param copyString string to copy.
 * @param onSuccess on success copy action.
 * @param onError on failed copy action.
 */
export const copyToClipboard = async (copyString: string, onSuccess?: () => void, onError?: (error: Error) => void) => {
  try {
    await navigator.clipboard.writeText(copyString);
    if (onSuccess) onSuccess();
  } catch (err) {
    if (onError && err instanceof Error) onError(err);
  }
};
