import { DetailedHTMLProps, InputHTMLAttributes, ReactNode, forwardRef } from 'react';
import { OutlinedInput, InputAdornment } from '@mui/material';
import classNames from 'classnames';
import { useStyles } from './styles';

// todo check for number type
export type InputType = 'text' | 'password' | 'email';

export interface InputProps
  extends Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'size'> {
  id: string;
  name?: string;
  label?: string;
  type?: InputType;
  className?: string;
  customLabel?: ReactNode;
  hasError?: boolean;
  startAdornment?: ReactNode;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id,
      name,
      label,
      type = 'text',
      className = '',
      placeholder,
      customLabel,
      hasError,
      disabled,
      startAdornment,
      ...props
    },
    ref,
  ) => {
    const { classes, cx } = useStyles();
    return (
      <OutlinedInput
        placeholder={placeholder}
        inputProps={{
          id,
          'aria-label': label,
          itemType: type,
          disabled,
          ...props,
        }}
        inputRef={ref}
        name={name}
        fullWidth
        label={customLabel ?? label}
        className={cx(classNames(className), classes.input, hasError ? 'error' : null, disabled ? 'disabled' : null)}
        startAdornment={startAdornment ? <InputAdornment position="start">{startAdornment}</InputAdornment> : null}
      />
    );
  },
);

export { Input };
