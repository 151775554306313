import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  textField: {
    '& .MuiInputBase-root': {
      background: theme.palette.bg.primary.default,
      flexWrap: 'wrap',
      gap: 5,
      position: 'relative',
      padding: '10px 16px',
      border: theme.palette.border.secondary,
      borderRadius: theme.spacing(0.5),
      fontSize: '16px',

      '& .MuiInputBase-input': {
        minWidth: 300,
        maxWidth: '40%',
      },
    },

    '& fieldset': {
      display: 'none !important',
    },
  },

  clearAllBtn: {
    position: 'absolute',
    top: '50%',
    right: '16px',
    transform: 'translateY(-50%)',
    padding: 0,
  },

  container: {
    '& .error-message': {
      color: theme.palette.tx.error.primary,
      fontSize: '12px',
      margin: 0,
    },
  },

  helperText: {
    color: theme.palette.tx.placeholder.default,
    fontSize: 14,
    margin: 0,
  },
}));
