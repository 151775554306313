import * as Joi from 'joi';

import {
  DEPLOY_INTEGRATION_NAME_VALIDATOR,
  DEPLOY_INTEGRATION_NAME_JOI_VALIDATOR_MESSAGES,
} from 'ze-api-contract/validation';

import {
  DeploymentIntegrationType,
  DeploymentIntegrationPlatform,
  CreateDeploymentIntegration,
  UpdateDeploymentIntegration,
  CreateCloudflareIntegration,
  CreateNetlifyIntegration,
  UpdateCloudflareIntegration,
  UpdateNetlifyIntegration,
  CreateFastlyIntegration,
  UpdateFastlyIntegration,
} from './interfaces';
import {
  PROVIDER_JOI_VALIDATOR,
  ORGANIZATION_DISPLAY_NAME_VALIDATOR,
  ORGANIZATION_DISPLAY_NAME_JOI_VALIDATOR_MESSAGES,
} from '../../validation';

export const integrationTypeValidator = Joi.string()
  .valid(...Object.values(DeploymentIntegrationType))
  .required();

export const integrationPlatformValidator = Joi.string()
  .valid(...Object.values(DeploymentIntegrationPlatform))
  .required();

export const integrationNameValidator = Joi.string()
  .pattern(DEPLOY_INTEGRATION_NAME_VALIDATOR.pattern.value)
  .min(DEPLOY_INTEGRATION_NAME_VALIDATOR.minLength.value)
  .max(DEPLOY_INTEGRATION_NAME_VALIDATOR.maxLength.value)
  .messages(DEPLOY_INTEGRATION_NAME_JOI_VALIDATOR_MESSAGES)
  .required();

export const integrationIsDefaultValidator = Joi.boolean().required();

export const createCloudflareIntegrationValidator = Joi.object<CreateCloudflareIntegration>({
  api_token: Joi.string().required(),
  zone_id: Joi.string().required(),
  projectName: Joi.string()
    .required()
    .max(58)
    .regex(/^[a-z]{1}[a-z-]*$/),
}).required();
export const createNetlifyIntegrationValidator = Joi.object<CreateNetlifyIntegration>({
  api_token: Joi.string().required(),
  site_id: Joi.string().required(),
}).required();
export const createFastlyIntegrationValidator = Joi.object<CreateFastlyIntegration>({
  api_token: Joi.string().required(),
  domain: Joi.string().required(),
}).required();

export const updateCloudflareIntegrationValidator = Joi.object<UpdateCloudflareIntegration>({
  api_token: Joi.string().optional().empty(''),
  projectName: Joi.string()
    .optional()
    .max(58)
    .regex(/^[a-z]{1}[a-z-]*$/),
}).required();

export const updateNetlifyIntegrationValidator = Joi.object<UpdateNetlifyIntegration>({
  api_token: Joi.string().optional().empty(''),
}).required();

export const updateFastlyIntegrationValidator = Joi.object<UpdateFastlyIntegration>({
  api_token: Joi.string().optional().empty(''),
}).required();

export const createIntegrationSchema = Joi.object<CreateDeploymentIntegration>({
  name: integrationNameValidator,
  displayName: Joi.string().required(),
  type: integrationTypeValidator,
  platform: integrationPlatformValidator,
  integrationConfig: Joi.object().when('type', {
    switch: [
      {
        is: DeploymentIntegrationPlatform.CLOUDFLARE,
        then: createCloudflareIntegrationValidator,
      },
      {
        is: DeploymentIntegrationPlatform.NETLIFY,
        then: createNetlifyIntegrationValidator,
      },
      {
        is: DeploymentIntegrationPlatform.FASTLY,
        then: createFastlyIntegrationValidator,
      },
    ],
  }),
  isDefault: integrationIsDefaultValidator,
}).required();

export const updateIntegrationSchema = Joi.object<UpdateDeploymentIntegration>({
  displayName: Joi.string().required(),
  integrationConfig: Joi.object().when('type', {
    switch: [
      {
        is: DeploymentIntegrationPlatform.CLOUDFLARE,
        then: updateCloudflareIntegrationValidator,
      },
      {
        is: DeploymentIntegrationPlatform.NETLIFY,
        then: updateNetlifyIntegrationValidator,
      },
      {
        is: DeploymentIntegrationPlatform.FASTLY,
        then: updateFastlyIntegrationValidator,
      },
    ],
  }),
  isDefault: integrationIsDefaultValidator,
}).required();

export const updateNameSchema = Joi.object({
  name: integrationNameValidator,
}).required();

export const updateVersionSchema = Joi.object({
  version: Joi.string().required(),
}).required();

const displayNameValidation = Joi.string()
  .max(ORGANIZATION_DISPLAY_NAME_VALIDATOR.maxLength.value)
  .min(ORGANIZATION_DISPLAY_NAME_VALIDATOR.minLength.value)
  .pattern(ORGANIZATION_DISPLAY_NAME_VALIDATOR.pattern.value)
  .required()
  .messages(ORGANIZATION_DISPLAY_NAME_JOI_VALIDATOR_MESSAGES);

const optionalCloudflareTokenId = Joi.string()
  .optional()
  .empty('')
  .min(PROVIDER_JOI_VALIDATOR.minLength.value)
  .messages({
    'string.max': PROVIDER_JOI_VALIDATOR.maxLength.message,
    'string.min': PROVIDER_JOI_VALIDATOR.minLength.message,
    'string.required': PROVIDER_JOI_VALIDATOR.required,
  });

const cloudflareTokenId = Joi.string().required().min(PROVIDER_JOI_VALIDATOR.minLength.value).messages({
  'string.min': PROVIDER_JOI_VALIDATOR.minLength.message,
  'string.max': PROVIDER_JOI_VALIDATOR.maxLength.message,
  'string.required': PROVIDER_JOI_VALIDATOR.required,
});

const cloudflareZoneId = Joi.string().required().min(PROVIDER_JOI_VALIDATOR.minLength.value).messages({
  'string.min': PROVIDER_JOI_VALIDATOR.minLength.message,
  'string.max': PROVIDER_JOI_VALIDATOR.maxLength.message,
  'string.required': PROVIDER_JOI_VALIDATOR.required,
});

export const integrationDisplayName = displayNameValidation.required();

export const addCloudflareIntegrationSchemaForm = Joi.object({
  apiToken: cloudflareTokenId,
  zoneId: cloudflareZoneId,
  name: integrationNameValidator,
  displayName: integrationDisplayName,
  projectName: Joi.string()
    .required()
    .max(58)
    .regex(/^[a-z]{1}[a-z-]*$/),
  isDefault: Joi.boolean().required(),
});

export const editCloudflareIntegrationSchemaForm = Joi.object({
  apiToken: optionalCloudflareTokenId,
  zoneId: cloudflareZoneId,
  name: integrationNameValidator,
  displayName: integrationDisplayName,
  projectName: Joi.string()
    .required()
    .max(58)
    .regex(/^[a-z]{1}[a-z-]*$/),
  isDefault: Joi.boolean().required(),
});

export const addNetlifyIntegrationSchemaForm = Joi.object({
  apiToken: cloudflareTokenId,
  siteId: cloudflareZoneId,
  name: integrationNameValidator,
  displayName: integrationDisplayName,
  isDefault: Joi.boolean().required(),
});

export const editNetlifyIntegrationSchemaForm = Joi.object({
  apiToken: optionalCloudflareTokenId,
  siteId: cloudflareZoneId,
  name: integrationNameValidator,
  displayName: integrationDisplayName,
  isDefault: Joi.boolean().required(),
});

export const addFastlyIntegrationSchemaForm = Joi.object({
  apiToken: Joi.string().required(),
  domain: Joi.string().required(),
  name: integrationNameValidator,
  displayName: integrationDisplayName,
  isDefault: Joi.boolean().required(),
});

export const editFastlyIntegrationSchemaForm = Joi.object({
  apiToken: Joi.string().optional().empty(''),
  domain: Joi.string().required(),
  name: integrationNameValidator,
  displayName: integrationDisplayName,
  isDefault: Joi.boolean().required(),
});

//#region Cloudflare
const cloudflareApiToken = Joi.string().required().min(40).messages({
  'string.min': 'Api token must be at least 40 character long',
  'string.required': 'Api token is required',
});
export const getCloudflareZoneListSchema = Joi.object({
  api_token: cloudflareApiToken,
});
//#endregion

//#region Netlify
const netlifyApiToken = Joi.string().required().min(40).messages({
  'string.min': 'Api token must be at least 40 character long',
  'string.required': 'Api token is required',
});
export const getNetlifySiteListSchema = Joi.object({
  api_token: netlifyApiToken,
});
//#endregion
