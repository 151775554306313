import { makeStyles } from 'tss-react/mui';
export const useStyles = makeStyles()((theme) => ({
  navbarTabs: {
    '& .MuiTabs-indicator': {
      height: '2px',
      background: theme.palette.brand.purple[400],
    },
  },
  tab: {
    color: theme.palette.tx.secondary.default,
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '140%',
    padding: '12px',
    marginRight: '5px',
    textTransform: 'capitalize',
    transitionProperty: 'all',
    transitionDuration: '300',
    transitionTimingFunction: 'ease-in-out',

    '&:hover': {
      color: theme.palette.tx.secondary.hover,
    },
    '&.Mui-selected': {
      color: theme.palette.tx.primary,
    },
  },
}));
