import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

import { axios } from 'app-zephyr-axios';
import { GET_EMAIL_OPTIONS, get_email_options_path } from 'ze-api-contract/user-v2/profile-settings/get-email-options';
import {
  ProfileSettingsEmailOptions,
  ProfileSettingsEmailOptionsBodyReq,
} from 'ze-api-contract/user-v2/profile-settings/interfaces';
import { resend_verification_email_path } from 'ze-api-contract/user-v2/profile-settings/resent-verification-email';
import { post_email_options_path } from 'ze-api-contract/user-v2/profile-settings/update-email-options';
import { post_default_email_path } from 'ze-api-contract/user-v2/profile-settings/set-default-email';

const emailsQuery = createQueryKeys('user-profile-settings-emails', {
  getEmailOptions: () => ({
    queryKey: [GET_EMAIL_OPTIONS],
    queryFn: async () => {
      return axios.get<{ value: ProfileSettingsEmailOptions }>(get_email_options_path()).then((res) => res.data.value);
    },
  }),
});

export function useEmailOptions() {
  const { data: emailOptions, isLoading, error } = useQuery(emailsQuery.getEmailOptions());

  return {
    emailOptions,
    isLoading,
    error,
  };
}

export function useSaveEmailsSettings() {
  const queryClient = useQueryClient();
  const { queryKey } = emailsQuery.getEmailOptions();

  return useMutation({
    mutationFn: (data: ProfileSettingsEmailOptionsBodyReq) =>
      axios.post<{ value: ProfileSettingsEmailOptionsBodyReq }>(post_email_options_path(), data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey });
    },
  });
}

export function useSendEmailVerification() {
  const queryClient = useQueryClient();
  const { queryKey } = emailsQuery.getEmailOptions();

  return useMutation({
    mutationFn: () => axios.post(resend_verification_email_path()),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey });
    },
  });
}

export function useMakeEmailDefault() {
  const queryClient = useQueryClient();
  const { queryKey } = emailsQuery.getEmailOptions();

  return useMutation({
    mutationFn: (email: string) => axios.post(post_default_email_path(), { email }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey });
    },
  });
}
