import { simplePath } from 'ze-api-contract/utils';
import { ApplicationTagUIdParams } from './interfaces';

export const DELETE_APPLICATION_TAG = '/v2/application/:organization/:project/:application/tag/:tag';

type DeleteApplicationTagParams = ApplicationTagUIdParams;

export function delete_application_tag_path(params: DeleteApplicationTagParams): string {
  return simplePath(DELETE_APPLICATION_TAG, params);
}
