import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useQuery } from '@tanstack/react-query';

import { axios } from 'app-zephyr-axios';
import { ApplicationListItem } from 'ze-api-contract/organization-v2/get-application-list/interfaces';
import {
  GET_USER_APPLICATION_LIST_PATH,
  GetUserApplicationListQueryParams,
  get_user_application_list_path,
} from 'ze-api-contract/user-v2/get-user-application-list';

interface UserApplicationListParams {
  userEmail?: string;
  queryParams?: GetUserApplicationListQueryParams;
}

export const userApplicationListQuery = createQueryKeys('user', {
  applicationList: ({ userEmail, queryParams }: UserApplicationListParams) => ({
    queryKey: [GET_USER_APPLICATION_LIST_PATH, userEmail, queryParams],
    queryFn: async () => {
      // TODO: change in other queries too because using undefined results in dev error
      if (!userEmail) return Promise.resolve(null);

      return axios
        .get<{ entities: ApplicationListItem[] }>(get_user_application_list_path({ userEmail }), {
          params: queryParams,
        })
        .then((res) => res.data.entities);
    },
  }),
});

export const useUserApplicationList = (params: UserApplicationListParams) => {
  const { data: applicationList, isLoading, error } = useQuery(userApplicationListQuery.applicationList(params));

  return { applicationList, isLoading, error };
};
