import * as Joi from 'joi';

import {
  APPLICATION_NAME_VALIDATOR,
  APPLICATION_NAME_JOI_VALIDATOR_MESSAGES,
  validationErrorMessagesDescriptionField,
} from '../../validation';
import { ApplicationNameBodyReq } from './interfaces';

export const name = Joi.string()
  .pattern(APPLICATION_NAME_VALIDATOR.pattern.value)
  .min(APPLICATION_NAME_VALIDATOR.minLength.value)
  .max(APPLICATION_NAME_VALIDATOR.maxLength.value)
  .required()
  .messages(APPLICATION_NAME_JOI_VALIDATOR_MESSAGES);
export const description = Joi.string().empty('').max(200).messages(validationErrorMessagesDescriptionField);
export const isPrivate = Joi.boolean().required();

export const baseSchema = Joi.object({
  description,
  isPrivate,
});

export const nameSchema = Joi.object<ApplicationNameBodyReq>({ name });
