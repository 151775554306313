import { defaultIntegrations, availableIntegrationList } from 'app-zephyr-constants';
import { DeploymentIntegrationValue } from 'ze-api-contract/deployment-integration/organization/interfaces';
import { FILTER_ITEMS } from 'app-zephyr-deployment-integrations';

export function sortProviders(
  integrations: DeploymentIntegrationValue[] | Partial<DeploymentIntegrationValue>[] | undefined,
  activeFilter: FILTER_ITEMS,
): DeploymentIntegrationValue[] | Partial<DeploymentIntegrationValue>[] {
  if (!integrations) return [];

  if (activeFilter === FILTER_ITEMS.ENABLED) return integrations;

  if (activeFilter === FILTER_ITEMS.AVAILABLE) {
    return defaultIntegrations.filter((item) => item.platform && availableIntegrationList.includes(item.platform));
  }

  return defaultIntegrations.filter((item) => item.platform && !availableIntegrationList.includes(item.platform));
}
