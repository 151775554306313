import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  button: {
    width: 'fit-content',
    fontSize: '16px',
    borderRadius: '20px',
    padding: '8px 16px',
    backgroundColor: 'transparent',
    color: theme.palette.tx.primary,
    border: `1px solid ${theme.palette.brand.gray[800] ?? ''}`,
    fontWeight: 700,
    lineHeight: 1.4,
    transition: 'all ease .2s',

    '&.active': {
      color: theme.palette.tx.primary,
      borderColor: theme.palette.brand.purple[400],
      backgroundColor: theme.palette.brand.gray[800],
    },
  },
}));
