import { makeStyles } from 'tss-react/mui';
export const useStyles = makeStyles()((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 6,

    '& p': {
      margin: 0,
    },

    '& .MuiFormControl-root': {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),

      '& .MuiFormLabel-root': {
        position: 'relative',
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '140%',
        color: theme.palette.tx.secondary.default,
        flexShrink: 0,
        minWidth: '220px',
        transform: 'unset',
        transformOrigin: 'unset',
        display: 'flex',
      },
    },

    '& .error-message': {
      color: theme.palette.tx.error.primary,
      fontSize: '12px',
    },
    '.MuiInputBase-root': {
      background: theme.palette.bg.secondary.default,
      color: theme.palette.tx.secondary.default,
      borderRadius: theme.borderRadius.md,

      '&:hover': {
        border: theme.palette.border.primary,
      },
      '&:focused': {
        color: theme.palette.tx.primary,
      },
    },

    '& .MuiInputBase-root.Mui-hover': {
      border: theme.palette.border.primary,
    },

    '& .MuiInputBase-root.Mui-focused': {
      border: theme.palette.border.primary,
      boxShadow: theme.boxShadow.default,
      color: theme.palette.tx.primary,
    },
  },

  hint: {
    color: theme.palette.tx.tertiary.default,
    fontSize: 14,
    fontWeight: 400,
  },

  label: {
    marginBottom: theme.spacing(4),
    color: theme.palette.tx.primary,
    fontWeight: 400,
    fontSize: 14,
  },
}));
