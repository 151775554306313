import * as Joi from 'joi';
import {
  PROFILE_NAME_VALIDATOR,
  PROFILE_NAME_JOI_VALIDATOR_MESSAGES,
  DESCRIPTION_VALIDATOR,
  DESCRIPTION_JOI_VALIDATOR_MESSAGES,
  EMAIL_JOI_VALIDATOR_MESSAGES,
  LINKS_JOI_VALIDATOR_MESSAGES,
  USERNAME_VALIDATOR,
} from '../../validation';
import { UrlLink, PrivacyOptions, LocationOptions } from './interfaces';

export const PASSWORD_VALIDATOR_REGEXP = {
  minLength: 8,
  maxLength: 100,
  pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
  message:
    'Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number and one special character',
};

export const nameValidation = Joi.string()
  .pattern(PROFILE_NAME_VALIDATOR.pattern.value)
  .min(PROFILE_NAME_VALIDATOR.minLength.value)
  .max(PROFILE_NAME_VALIDATOR.maxLength.value)
  .messages(PROFILE_NAME_JOI_VALIDATOR_MESSAGES)
  .required();

export const userPortraitValidation = Joi.string().optional();

export const descriptionValidation = Joi.string()
  .optional()
  .max(DESCRIPTION_VALIDATOR.maxLength.value)
  .pattern(DESCRIPTION_VALIDATOR.pattern.value)
  .messages(DESCRIPTION_JOI_VALIDATOR_MESSAGES)
  .allow(null, '');

export const userNameValidation = Joi.string()
  .optional()
  .max(USERNAME_VALIDATOR.maxLength.value)
  .pattern(USERNAME_VALIDATOR.pattern.value)
  .messages(PROFILE_NAME_JOI_VALIDATOR_MESSAGES)
  .allow(null, '');

export const socialAccountsValidation = Joi.array()
  .items(
    Joi.object<UrlLink>({
      link: Joi.string().required(),
      label: Joi.string().required(),
    }),
  )
  .optional();

export const socialAccountsFormValidation = Joi.array()
  .items(
    Joi.object<UrlLink>({
      link: Joi.string().uri().messages(LINKS_JOI_VALIDATOR_MESSAGES).optional().allow(null, ''),
      label: Joi.string().uri().messages(LINKS_JOI_VALIDATOR_MESSAGES).optional().allow(null, ''),
    }),
  )
  .optional();

export const privacyOptionsValidation = Joi.object<PrivacyOptions>({
  isPrivate: Joi.boolean().required(),
}).optional();

export const locationOptionsValidation = Joi.object<LocationOptions>({
  location: Joi.string().required(),
  locationVisible: Joi.boolean().required(),
}).optional();

export const emailValidation = Joi.string()
  .email({ tlds: { allow: false } })
  .messages(EMAIL_JOI_VALIDATOR_MESSAGES)
  .required();

export const baseSchema = Joi.object({
  name: nameValidation,
  username: userNameValidation,
  description: descriptionValidation,
  userPortrait: Joi.string().optional().allow(null, ''),
  socialAccounts: socialAccountsValidation,
  privacyOptions: privacyOptionsValidation,
  locationOptions: locationOptionsValidation,
});
