import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
// mui
import { Stack } from '@mui/material';
import Alert from '@mui/material/Alert';

// components
import { Button } from 'app-zephyr-components/Button';
// _elements
import { FormContainer } from '../../_elements';
// utils
import { FormInput, FormTextArea, FormCheckbox } from 'app-zephyr-forms';
import { joiResolver } from '@hookform/resolvers/joi';
// contracts
import { ApplicationSettings, ApplicationSettingsBodyReq } from 'ze-api-contract/application-v2/settings/interfaces';
import { formSchema } from 'ze-api-contract/application-v2/settings/update-application-settings';

export type ApplicationSettingsFormFields = ApplicationSettingsBodyReq & { name: string };

interface ApplicationSettingsFormProps {
  isSaveApplicationSettingsPending: boolean;
  updateApplicationSettingsError: Error | null;
  onSubmit: (value: ApplicationSettingsBodyReq) => Promise<void>;
  applicationSettings?: ApplicationSettings;
}

/**
 * Application settings form.
 * @returns {React.FC} react-hook-form form implementation.
 */
const ApplicationSettingsForm = ({
  updateApplicationSettingsError,
  isSaveApplicationSettingsPending,
  onSubmit,
  applicationSettings,
}: ApplicationSettingsFormProps) => {
  const defaultValues = useMemo(
    () => ({
      name: applicationSettings?.name ?? '',
      description: applicationSettings?.description ?? '',
      isPrivate: !!applicationSettings?.isPrivate,
    }),
    [applicationSettings],
  );

  const {
    register,
    handleSubmit,
    control,
    trigger,
    reset,
    formState: { isValid, errors, isDirty },
  } = useForm<ApplicationSettingsFormFields>({
    defaultValues,
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: joiResolver(formSchema),
  });

  const handleOnSubmit = handleSubmit(async (data) => {
    await onSubmit(data);
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <form onSubmit={(data) => void handleOnSubmit(data)} onChange={() => void trigger()}>
      <Stack marginBottom={2}>
        <FormContainer title={'Public information'} variant="outlined">
          <Stack spacing={3}>
            <FormInput<ApplicationSettingsFormFields>
              id="name"
              name="name"
              label="Application name"
              register={register}
              disabled={true}
              errors={errors}
            />
            <FormTextArea
              id="description"
              name="description"
              label="Application description"
              register={register}
              errors={errors}
              placeholder="Fill out a short description for this application."
            />
          </Stack>
        </FormContainer>
      </Stack>
      <FormContainer title={'Privacy options'} variant="outlined">
        <Stack>
          <FormCheckbox<ApplicationSettingsFormFields>
            id="isPrivate"
            name="isPrivate"
            label="Make application private"
            control={control}
            errors={errors}
          />
        </Stack>
      </FormContainer>
      {!!updateApplicationSettingsError && <Alert severity="error">Something went wrong, please try again</Alert>}
      <Stack display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} marginTop={2} gap={2}>
        <Button
          variant="outlined"
          onClick={() => {
            reset(defaultValues);
          }}
        >
          Reset
        </Button>
        <Button disabled={isSaveApplicationSettingsPending || !isValid || !isDirty} type="submit">
          Save changes
        </Button>
      </Stack>
    </form>
  );
};

export { ApplicationSettingsForm };
