import { ThemeOptions, CSSObject } from '@mui/material/styles';
import { tokens } from './tokens';
import { TypeBackground } from '@mui/material/styles/createPalette';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';

export type PaletteItem =
  | 'text'
  | 'border'
  /** Foreground */
  | 'fg'
  /** Background */
  | 'bg';

export type PaletteGrade =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'quarterary'
  | 'white'
  | 'disabled'
  | 'placeholder'
  | 'active'
  | 'overlay'
  | 'brand'
  | 'error'
  | 'warning'
  | 'success';

export type StateCategories =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'alt'
  | 'on_brand'
  | 'hover'
  | 'muted'
  | 'disabled'
  | 'placeholder'
  | 'brand'
  | 'error'
  | 'success'
  | 'subtle'
  | 'warning'
  | 'solid';

export type BorderKeys = 'primary' | 'secondary' | 'tertiary' | 'disabled' | 'brand' | 'error';

export type BrandKeys = 'primary' | 'secondary' | 'purple' | 'turquoise' | 'pink' | 'orange' | 'blue' | 'gray';

export interface TextType {
  primary: string;
  secondary: {
    default: string;
    alt: string;
    hover: string;
  };
  tertiary: {
    default: string;
    hover: string;
  };
  quarterary: string;
  white: string;
  disabled: string;
  placeholder: {
    default: string;
    subtle: string;
  };
  brand: {
    primary: string;
    secondary: string;
    tertiary: string;
  };
  error: {
    primary: string;
  };
  warning: {
    primary: string;
  };
  success: {
    primary: string;
  };
}

export interface BorderType {
  primary: string;
  secondary: string;
  tertiary: string;
  dashed: string;
  strong: string;
  none: string;
  /** Used for pagination button */
  turquoise: {
    300: string;
    600: string;
    800: string;
  };
  purple: {
    300: string;
    400: string;
    600: string;
    700: string;
    800: string;
  };
  gray: {
    600: string;
  };
  disabled: {
    default: string;
    subtle: string;
  };
  brand: {
    default: string;
    solid: string;
  };
  error: {
    default: string;
    solid: string;
  };
  warning: {
    default: string;
  };
}
export interface ForegroundType {
  primary: string;
  secondary: {
    default: string;
    hover: string;
  };
  tertiary: {
    default: string;
    hover: string;
  };
  white: string;
  disabled: {
    default: string;
    subtle: string;
  };
  brand: {
    default: string;
    alt: string;
  };
  error: {
    primary: string;
    secondary: string;
  };
  warning: {
    primary: string;
    secondary: string;
  };
  success: {
    primary: string;
    secondary: string;
  };
}

export interface BackgroundType {
  primary: {
    default: string;
    alt: string;
    hover: string;
    solid: string;
  };
  secondary: {
    default: string;
    alt: string;
    hover: string;
    subtle: string;
    solid: string;
  };
  tertiary: string;
  quarterary: string;
  active: string;
  disabled: {
    default: string;
    subtle: string;
  };
  overlay: string;
  accentTransparent: string;
}

export interface BorderRadiusType {
  sm: string;
  md: string;
  lg: string;
  xl: string;
  xxl: string;
  '3xl': string;
  '4xl': string;
  full: string;
}

export type BoxShadowKey = 'default' | 'checked' | 'transparent' | 'contained' | 'contained-focused';

export type ColorNumber =
  | '25'
  | '50'
  | '100'
  | '200'
  | '300'
  | '400'
  | '500'
  | '600'
  | '700'
  | '800'
  | '900'
  | '950'
  | '900-20%'
  | '900-30%';

export interface AdditionalThemeOptions {
  palette: {
    tx: TextType;
    border: BorderType;
    fg: ForegroundType;
    bg: BackgroundType;
    brand: Record<BrandKeys, string | Partial<Record<ColorNumber | StateCategories, string>>>;
    divider: string;
    white: string;
    background?: Partial<TypeBackground> & {
      info: string;
      success: string;
      error: string;
      warning: string;
      accentTransparent: string;
    };
  };
  borderRadius: BorderRadiusType;
  boxShadow: Record<BoxShadowKey, string>;
}

const themeOptionsCommon: Partial<ThemeOptions> = {
  typography: {
    fontSize: 14,
    fontFamily: 'Inter',
    fontWeightLight: 400,
    fontWeightBold: 700,
    h1: {
      fontWeight: 600,
    },
    h2: {
      fontWeight: 600,
    },
    h3: {
      fontWeight: 600,
      lineHeight: 1.3,
    },
    h4: {
      fontWeight: 600,
      lineHeight: 1.3,
    },
    subtitle1: {
      fontWeight: 600,
    },
    button: {
      fontWeight: 400,
    },
  },
  shape: {
    borderRadius: 8,
  },
};

export const darkThemeOptions: ThemeOptions & AdditionalThemeOptions = {
  typography: {
    labelBold: {
      fontFamily: 'Inter',
      fontSize: 16,
      fontWeight: 700,
      lineHeight: '140%',
    },
    labelBoldSmall: {
      fontFamily: 'Inter',
      fontSize: 14,
      fontWeight: 700,
      lineHeight: '140%',
    },
    regular2: {
      fontFamily: 'Inter',
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '140%',
    },
    regular3: {
      fontFamily: 'Inter',
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '140%',
    },
  },
  palette: {
    ...themeOptionsCommon,
    mode: 'dark',
    /** This is our text color, but naming it to `tx` to avoid collision with MUI default text. */
    tx: {
      primary: tokens.white,
      secondary: {
        default: tokens['gray-25'],
        alt: tokens['gray-100'],
        hover: tokens['gray-300'],
      },
      tertiary: {
        default: tokens['gray-200'],
        hover: tokens['gray-300'],
      },
      quarterary: tokens['gray-400'],
      white: tokens.white,
      disabled: tokens['gray-500'],
      placeholder: {
        default: tokens['gray-400'],
        subtle: tokens['gray-700'],
      },
      brand: {
        primary: tokens['purple-50'],
        secondary: tokens['purple-300'],
        tertiary: tokens['purple-400'],
      },
      error: {
        primary: tokens['error-400'],
      },
      warning: {
        primary: tokens['warning-400'],
      },
      success: {
        primary: tokens['success-400'],
      },
    },
    border: {
      primary: `1px solid ${tokens['gray-100']}`,
      secondary: `1px solid ${tokens['gray-700']}`,
      tertiary: `1px solid ${tokens['gray-800']}`,
      dashed: `1px dashed ${tokens['gray-400']}`,
      strong: `1px solid ${tokens['gray-25']}`,
      none: `1px solid transparent`,
      turquoise: {
        300: `1px solid ${tokens['turquoise-300']}`,
        600: `1px solid ${tokens['turquoise-600']}`,
        800: `1px solid ${tokens['turquoise-800']}`,
      },
      purple: {
        300: `1px solid ${tokens['purple-300']}`,
        400: `1px solid ${tokens['purple-400']}`,
        600: `1px solid ${tokens['purple-600']}`,
        700: `1px solid ${tokens['purple-700']}`,
        800: `1px solid ${tokens['purple-800']}`,
      },
      gray: {
        600: `1px solid ${tokens['gray-600']}`,
      },
      disabled: {
        default: `1px solid ${tokens['gray-700']}`,
        subtle: `1px solid ${tokens['gray-800']}`,
      },
      brand: {
        default: `1px solid ${tokens['brand-400']}`,
        solid: `1px solid ${tokens['brand-600']}`,
      },
      error: {
        default: `1px solid ${tokens['error-400']}`,
        solid: `1px solid ${tokens['error-600']}`,
      },
      warning: {
        default: `1px solid ${tokens['warning-400']}`,
      },
    },
    /** Foreground */
    fg: {
      primary: tokens.white,
      secondary: {
        default: tokens['gray-300'],
        hover: tokens['gray-200'],
      },
      tertiary: {
        default: tokens['gray-400'],
        hover: tokens['gray-300'],
      },
      white: tokens.white,
      disabled: {
        default: tokens['gray-500'],
        subtle: tokens['gray-600'],
      },
      brand: {
        default: tokens['brand-500'],
        alt: tokens['gray-300'],
      },
      error: {
        primary: tokens['error-600'],
        secondary: tokens['error-400'],
      },
      warning: {
        primary: tokens['warning-500'],
        secondary: tokens['warning-400'],
      },
      success: {
        primary: tokens['success-500'],
        secondary: tokens['success-400'],
      },
    },
    divider: '#2F3858',
    /** Background */
    bg: {
      primary: {
        default: tokens['gray-950'],
        alt: tokens['gray-900'],
        hover: tokens['gray-800'],
        solid: tokens['gray-900'],
      },
      secondary: {
        default: tokens['gray-900'],
        alt: tokens['gray-950'],
        hover: tokens['gray-800'],
        subtle: tokens['gray-900'],
        solid: tokens['gray-600'],
      },
      tertiary: tokens['gray-800'],
      quarterary: tokens['gray-700'],
      active: tokens['gray-800'],
      disabled: {
        default: tokens['gray-800'],
        subtle: tokens['gray-900'],
      },
      overlay: tokens['gray-800'],
      accentTransparent: tokens['gray-900'] + '10',
    },
    brand: {
      primary: {
        default: tokens['brand-500'],
        alt: tokens['gray-800'],
      },
      secondary: tokens['brand-600'],
      turquoise: {
        25: tokens['turquoise-25'],
        50: tokens['turquoise-50'],
        100: tokens['turquoise-100'],
        200: tokens['turquoise-200'],
        300: tokens['turquoise-300'],
        400: tokens['turquoise-400'],
        500: tokens['turquoise-500'],
        600: tokens['turquoise-600'],
        700: tokens['turquoise-700'],
        800: tokens['turquoise-800'],
        900: tokens['turquoise-900'],
        950: tokens['turquoise-950'],
      },
      purple: {
        25: tokens['purple-25'],
        50: tokens['purple-50'],
        100: tokens['purple-100'],
        200: tokens['purple-200'],
        300: tokens['purple-300'],
        400: tokens['purple-400'],
        500: tokens['purple-500'],
        600: tokens['purple-600'],
        700: tokens['purple-700'],
        800: tokens['purple-800'],
        900: tokens['purple-900'],
        950: tokens['purple-950'],
      },
      blue: {
        25: tokens['blue-25'],
        50: tokens['blue-50'],
        100: tokens['blue-100'],
        200: tokens['blue-200'],
        300: tokens['blue-300'],
        400: tokens['blue-400'],
        500: tokens['blue-500'],
        600: tokens['blue-600'],
        700: tokens['blue-700'],
        800: tokens['blue-800'],
        900: tokens['blue-900'],
        950: tokens['blue-950'],
      },
      pink: {
        25: tokens['pink-carmine-25'],
        50: tokens['pink-carmine-50'],
        100: tokens['pink-carmine-100'],
        200: tokens['pink-carmine-200'],
        300: tokens['pink-carmine-300'],
        400: tokens['pink-carmine-400'],
        500: tokens['pink-carmine-500'],
        600: tokens['pink-carmine-600'],
        700: tokens['pink-carmine-700'],
        800: tokens['pink-carmine-800'],
        900: tokens['pink-carmine-900'],
        950: tokens['pink-carmine-950'],
      },
      orange: {
        25: tokens['orange-25'],
        50: tokens['orange-50'],
        100: tokens['orange-100'],
        200: tokens['orange-200'],
        300: tokens['orange-300'],
        400: tokens['orange-400'],
        500: tokens['orange-500'],
        600: tokens['orange-600'],
        700: tokens['orange-700'],
        800: tokens['orange-800'],
        900: tokens['orange-900'],
        950: tokens['orange-950'],
      },
      gray: {
        600: tokens['gray-600'],
        800: tokens['gray-800'],
        900: tokens['gray-900'],
        '900-20%': tokens['gray-900-20'],
        '900-30%': tokens['gray-900-30'],
      },
    },
    white: tokens['gray-50'],
  },
  borderRadius: {
    sm: '0.125rem' /* 2px */,
    md: '0.375rem' /* 6px */,
    lg: '0.5rem' /* 8px */,
    xl: '0.75rem' /* 12px */,
    xxl: '1rem' /* 16px */,
    '3xl': '1.5rem' /* 24px */,
    '4xl': '2rem' /* 32px */,
    full: '9999px',
  },

  boxShadow: {
    /** Used widely in inputs */
    default: tokens['box-shadow-default'],
    /** Used for checked checkbox */
    checked: tokens['box-shadow-checked'],
    /** Used for primary button (as in "contained" style button) */
    contained: tokens['box-shadow-contained'],
    'contained-focused': tokens['box-shadow-contained-focused'],
    transparent: tokens['box-shadow-transparent'],
  },
  components: {
    MuiButtonBase: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          '--Button-brandOutlinedBorderColor': tokens['gray-100'],
          '--Button-brandContainedBgColor': tokens.white,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none' as CSSObject['textTransform'],
          fontWeight: 700,
          fontSize: 16,
          lineHeight: '150%',
        } as CSSObject,
      },
      variants: [
        {
          props: { variant: 'contained', color: 'error' },
          style: {
            backgroundColor: tokens['error-600'],
          },
        },
        {
          props: { size: 'large' },
          style: {
            paddingTop: 10,
            paddingBottom: 10,
          },
        },
      ],
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 8,
          backgroundColor: tokens['gray-600'],
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: 14,
          lineHeight: '140%',
        },
        arrow: {
          color: tokens['gray-600'],
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active': {
            transition: 'all 0s 9000000s;',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '--TextField-brandBorderColor': tokens['gray-700'],
          '--TextField-brandBorderHoverColor': tokens['gray-300'],
          '--TextField-brandBorderFocusedColor': tokens['gray-25'],
          '& label.Mui-focused': {
            color: 'var(--TextField-brandBorderFocusedColor) !important',
            paddingBottom: '8px',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: tokens['gray-700'],
        },
        root: {
          [`.${outlinedInputClasses.notchedOutline}`]: {
            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0), 0px 0px 0px 4px rgba(158, 119, 237, 0)',
          },
          [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: 'var(--TextField-brandBorderHoverColor)',
          },
          [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
            border: '1px solid var(--TextField-brandBorderFocusedColor) !important',
            color: 'var(--TextField-brandBorderFocusedColor) !important',
            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(158, 119, 237, 0.30)',
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          '&::before, &::after': {
            border: '1px solid var(--TextField-brandBorderColor)',
            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0), 0px 0px 0px 4px rgba(158, 119, 237, 0)',
          },
          '&:hover:not(.Mui-disabled, .Mui-error):before': {
            border: '1px solid var(--TextField-brandBorderHoverColor)',
          },
          '&.Mui-focused:after': {
            border: '1px solid var(--TextField-brandBorderFocusedColor) !important',
            color: 'var(--TextField-brandBorderFocusedColor) !important',
            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(158, 119, 237, 0.30)',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '&::before': {
            border: '1px solid var(--TextField-brandBorderColor)',
          },
          '&:hover:not(.Mui-disabled, .Mui-error):before': {
            border: '1px solid var(--TextField-brandBorderHoverColor)',
          },
          '&.Mui-focused:after': {
            border: '1px solid var(--TextField-brandBorderFocusedColor) !important',
            color: 'var(--TextField-brandBorderFocusedColor) !important',
            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(158, 119, 237, 0.30)',
          },
        },
      },
    },
  },
};
