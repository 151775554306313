import { useState } from 'react';
import Stack from '@mui/material/Stack';

import { DeploymentIntegrationValue } from 'ze-api-contract/deployment-integration/organization/interfaces';
import { useSetDefaultOrganizationCloudflareIntegration } from 'app-zephyr-domains/organization/set-default-deployment-integration';
import { useEnableDisableOrganizationIntegration } from 'app-zephyr-domains/organization/enable-disable-deployment-integration';
import { useSetDefaultProjectCloudflareIntegration } from 'app-zephyr-domains/project/set-default-deployment-integration';
import { useSetDefaultApplicationCloudflareIntegration } from 'app-zephyr-domains/application/set-default-deployment-integration';
import { ResponseError } from 'ze-api-contract/organization-v2/settings/interfaces';
import { Empty } from 'app-zephyr-components/AlertContent';
import { SnackBarAlertComponent } from 'app-zephyr-components/SnackBarAlert';
import { WorkerVersionResp } from 'ze-api-contract/worker-version';

import { DeploymentIntegrationItem } from '../../components/deployment-integration-item';
import { getWorkerVersion } from '../../utils';

interface DeploymentIntegrationListProps {
  deploymentIntegrationList: DeploymentIntegrationValue[] | Partial<DeploymentIntegrationValue>[];
  integrationNavigate: (name: DeploymentIntegrationValue | Partial<DeploymentIntegrationValue>) => void;
  integrationEditAddNavigateHandler: (name: DeploymentIntegrationValue | Partial<DeploymentIntegrationValue>) => void;
  organization: string;
  project?: string;
  application?: string;
  workerVersionList?: WorkerVersionResp;
}

export function DeploymentIntegrationList({
  deploymentIntegrationList,
  integrationNavigate,
  integrationEditAddNavigateHandler,
  organization,
  project,
  application,
  workerVersionList,
}: DeploymentIntegrationListProps) {
  const [snackBarMessage, setSnackBarMessage] = useState<string | null>(null);
  const [integrationInLoading, setIntegrationInLoading] = useState<string | null>(null);
  const { mutateAsync: setDefaultIntegration } = useSetDefaultOrganizationCloudflareIntegration(organization);
  const { mutateAsync: enableDisableIntegration, isPending: enablingDisablingIsPending } =
    useEnableDisableOrganizationIntegration(organization);
  const { mutateAsync: setDefaultProjectIntegration } = useSetDefaultProjectCloudflareIntegration(
    organization,
    project ?? '',
  );
  const { mutateAsync: setDefaultApplicationIntegration } = useSetDefaultApplicationCloudflareIntegration(
    organization,
    project ?? '',
    application ?? '',
  );

  if (!deploymentIntegrationList.length)
    return <Empty title="There is no available integration list" isRender={true} />;

  const setDefaultIntegrationHandler = (integration: string) => {
    if (!project) {
      setDefaultIntegration(integration).catch((error: unknown) => {
        setSnackBarMessage((error as ResponseError).response.data.errorMessage);
      });
      return;
    }

    if (!application) {
      setDefaultProjectIntegration(integration).catch((error: unknown) => {
        setSnackBarMessage((error as ResponseError).response.data.errorMessage);
      });
      return;
    }

    setDefaultApplicationIntegration(integration).catch((error: unknown) => {
      setSnackBarMessage((error as ResponseError).response.data.errorMessage);
    });
  };

  const enableDisableHandler = (integration: string | undefined) => {
    if (!integration || integrationInLoading || enablingDisablingIsPending) return;
    setIntegrationInLoading(integration);
    enableDisableIntegration(integration)
      .catch((error: unknown) => {
        setSnackBarMessage((error as ResponseError).response.data.errorMessage);
      })
      .finally(() => {
        setIntegrationInLoading(null);
      });
  };

  return (
    <>
      <Stack direction={'row'} spacing={2} useFlexGap flexWrap="wrap">
        {deploymentIntegrationList.map((item: DeploymentIntegrationValue | Partial<DeploymentIntegrationValue>) => (
          <DeploymentIntegrationItem
            key={item.id}
            integration={item}
            isOrgLevel={!project && !application}
            isLoading={integrationInLoading === item.name}
            setDefaultHandler={() => {
              if (!item.name) return;
              setDefaultIntegrationHandler(item.name);
            }}
            integrationNavigateHandler={() => {
              integrationNavigate(item);
            }}
            integrationEditAddNavigateHandler={() => {
              integrationEditAddNavigateHandler(item);
            }}
            enableDisableHandler={() => {
              enableDisableHandler(item.name);
            }}
            workerVersion={getWorkerVersion({ workerVersionList, integration: item })}
            isLastWorkerVersion={
              item.platform ? workerVersionList?.[item.platform]?.[0].id === item.workerVersionId : false
            }
          />
        ))}
      </Stack>
      <SnackBarAlertComponent
        open={!!snackBarMessage}
        onClose={() => {
          setSnackBarMessage(null);
        }}
        message={snackBarMessage ?? ''}
        alertColor="error"
      />
    </>
  );
}
