import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  profileContainer: {
    maxWidth: '150px',
    marginLeft: '10px',
    padding: '0',
  },
  profileItem: {
    maxWidth: '100%',
    cursor: 'pointer',
    padding: '0',
  },
  profileAvatarContainer: {
    minWidth: 'auto',
    marginRight: '12px',
  },
  menuContainer: {
    '.MuiPaper-root': {
      background: theme.palette.bg.primary.default,
      border: theme.palette.border.secondary,
      borderRadius: '8px',
      textAlign: 'right',
      // padding: '16px 0'
    },
    '.MuiMenuItem-root': {
      padding: '8px 16px',
      alignItems: 'center',
      justifyContent: 'space-between',
      '&:hover': {
        color: theme.palette.tx.secondary.hover,
        background: theme.palette.bg.primary.hover,
      },
      '& .iconContainer': {
        width: '24px',
        height: '24px',
        minWidth: 'auto',
        marginLeft: '10px',
      },

      'a, span': {
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '150%',
        color: theme.palette.tx.secondary.default,
        textDecoration: 'none',
        justifyContent: 'end',
        width: '100%',
        display: 'flex',
      },
      svg: {
        fill: theme.palette.tx.tertiary.default,
      },
      '&.Mui-focusVisible, &:hover': {
        background: theme.palette.bg.secondary.hover,
      },
    },
  },
  logout: {
    color: theme.palette.tx.primary + ' !important',

    '&.Mui-focusVisible, &:hover': {
      color: theme.palette.tx.warning.primary,
    },
  },
  transition: {
    transition: 'all ease 100',
  },
  divider: {
    border: theme.palette.border.tertiary,
    '&.MuiDivider-root': {
      border: theme.palette.border.tertiary,
    },
  },
}));
