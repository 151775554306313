export const NX_ENV = process.env.NX_ENV as 'local' | 'development' | 'staging' | 'production' | undefined;
export const {
  NX_PUBLIC_BASE_URL,
  NX_PUBLIC_CORE_API_BASE_URL,
  NX_PUBLIC_POST_LOGOUT_REDIRECT_URI,
  NX_CERBOS_HTTP_URL,
  NX_PUBLIC_USE_SENTRY,
  NX_PUBLIC_SENTRY_DSN,
  NX_PUBLIC_SENTRY_RELEASE,
} = process.env;
