import * as Joi from 'joi';
import { simplePath } from 'ze-api-contract/utils';
import { Condition, Channel } from './interfaces';
import {
  baseSchema,
  conditionTypeValueSchema,
  conditionValueSchema,
  formConditions,
  applicationVersionIdSchema,
} from './validation';

interface CreateApplicationTagParams {
  organization: string;
  project: string;
  application: string;
}

export const CREATE_APPLICATION_TAG = '/v2/application/:organization/:project/:application/tag';

export function create_application_tag_path(params: CreateApplicationTagParams): string {
  return simplePath(CREATE_APPLICATION_TAG, params);
}

export interface CreateApplicationTag {
  name: string;
  applicationVersionId?: string;
  conditions: Condition[];
}

export interface CreateApplicationTagForm {
  name: string;
  conditions: {
    type: {
      value: Channel;
      label: string;
    };
    value: {
      value: string;
      label: string;
    };
  }[];
}

export const schema = baseSchema.append<CreateApplicationTag>({
  applicationVersionId: applicationVersionIdSchema,
  conditions: Joi.array().items(
    Joi.object({
      type: conditionTypeValueSchema,
      value: conditionValueSchema,
    }),
  ),
});

export const formSchema = baseSchema.append<CreateApplicationTag>({
  conditions: formConditions,
});
