import { ReactNode, useCallback, useEffect } from 'react';

import { envValue } from 'app-zephyr-environment';

const { production, GTM_ID } = envValue.value;

declare global {
  interface Window {
    dataLayer?: unknown[];
  }
}

export interface GoogleTagManagerProps {
  children: ReactNode;
}
export const pageview = (url: string): void => {
  window.dataLayer?.push({
    event: `pageview`,
    page: url,
  });
};

export const fireCustomEvent = (eventName: string, url: string): void => {
  window.dataLayer?.push({
    event: eventName,
    page: url,
  });
};

export const GoogleTagManager = ({ children }: GoogleTagManagerProps) => {
  const location = window.location;

  const handleRouteChangeComplete = useCallback((url: string): void => {
    if (production) {
      pageview(url);
    }
  }, []);

  useEffect(() => {
    handleRouteChangeComplete(location.pathname);
  }, [location, handleRouteChangeComplete]);

  return children;
};

export { GTM_ID };
