import { CerbosApplicationActions, CerbosApplicationKind } from 'ze-api-contract/organization-v2/cerbos-actions';
import { OrganizationValue } from 'ze-api-contract/organization-v2/get-by-name';
import { User } from 'ze-api-contract/user-v2/get-current-user';
import { Role } from 'ze-api-contract/enums/role';
import { AttributeValue, getDecision } from '../decision';

const getOrgRole = (role: Role | undefined): string => `organization_${role ?? 'viewer'}`;

const checkActions = async (
  organization: OrganizationValue,
  user: User,
  actions: CerbosApplicationActions[],
): Promise<boolean[]> => {
  const decision = await getDecision({
    principal: {
      id: user.id,
      roles: [getOrgRole(organization.role)],
    },
    resource: {
      kind: CerbosApplicationKind,
      id: organization.id,
      attributes: {
        oldData: {},
        newData: {},
        organization: organization as unknown as AttributeValue,
      },
    },
    actions,
  });

  return actions.map((action) => decision.isAllowed(action) ?? false);
};

export const canManageConfigKey = [
  'canManageApplication',
  CerbosApplicationActions.UPDATE_APPLICATION,
  CerbosApplicationActions.UPDATE_APPLICATION_NAME,
  CerbosApplicationActions.UPDATE_APPLICATION_SETTINGS,
  CerbosApplicationActions.DELETE_APPLICATION,
].join('/');

export interface CanManageAppConfig {
  updateApplication: boolean;
  updateApplicationName: boolean;
  updateApplicationSettings: boolean;
  deleteApplication: boolean;
}

export const getCanManageAppConfig = async (
  organization: OrganizationValue,
  user: User,
): Promise<CanManageAppConfig> => {
  const [updateApplication, updateApplicationName, updateApplicationSettings, deleteApplication] = await checkActions(
    organization,
    user,
    [
      CerbosApplicationActions.UPDATE_APPLICATION,
      CerbosApplicationActions.UPDATE_APPLICATION_NAME,
      CerbosApplicationActions.UPDATE_APPLICATION_SETTINGS,
      CerbosApplicationActions.DELETE_APPLICATION,
    ],
  );
  return {
    updateApplication,
    updateApplicationName,
    updateApplicationSettings,
    deleteApplication,
  };
};
