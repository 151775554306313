import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useQuery } from '@tanstack/react-query';
import { User } from 'ze-api-contract/user-v2/get-current-user';
import { OrganizationValue } from 'ze-api-contract/organization-v2/get-by-name';
import {
  getCanManageUnmanagedAppConfig,
  canManageConfigKey,
  CanManageUnmanagedAppConfig,
} from 'app-zephyr-guards/unmanaged-application';
import { useCurrentUser } from './current-user';

export interface CanManageUnmanagedAppParams {
  organization: OrganizationValue | undefined;
  user: User | undefined;
}

export const queryKeys = createQueryKeys('user-can-manage-unmanaged-app', {
  can: ({ organization, user }: CanManageUnmanagedAppParams) => ({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [canManageConfigKey, organization?.name, user?.email],
    queryFn: async () => {
      if (!organization || !user)
        return {
          createUnmanagedApplication: false,
          updateUnmanagedApplication: false,
          deleteUnmanagedApplication: false,
        } as CanManageUnmanagedAppConfig;
      return getCanManageUnmanagedAppConfig(organization, user);
    },
  }),
});

export function useUserCanManageUnmanagedApp(params: Omit<CanManageUnmanagedAppParams, 'user'>) {
  const { user } = useCurrentUser();
  const { isLoading, data: can, error } = useQuery(queryKeys.can({ ...params, user }));

  return {
    can,
    isLoading,
    error,
  };
}
