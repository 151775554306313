import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  title: {
    fontSize: 17,
    fontWeight: 700,
    color: theme.palette.tx.secondary.default,
  },
  versionBox: {
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    border: theme.palette.border.secondary,
    backgroundColor: theme.palette.bg.primary.default,
    width: 'fit-content',

    '& p': {
      fontSize: '14px',
      color: theme.palette.tx.secondary.default,
      fontWeight: 400,
      margin: 0,
    },

    '&.edit': {
      minHeight: 65,
      alignContent: 'center',
    },
  },
  versionStr: {
    fontSize: theme.spacing(2),
    backgroundColor: theme.palette.tx.brand.primary,
    fontWeight: 700,
    paddingLeft: theme.spacing(0.5),
  },
  emptyVersion: {
    fontSize: theme.spacing(2),
    color: theme.palette.tx.warning.primary,
    fontWeight: 700,
    paddingLeft: theme.spacing(0.5),
  },
  confirmBtn: {
    marginLeft: theme.spacing(2),
  },
  displayFlex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'fit-content',
  },

  externalLink: {
    marginLeft: 10,
  },
}));
