import { QueryClient } from '@tanstack/react-query';
import {
  lazyRouteComponent,
  NotFoundRoute,
  Outlet,
  createRootRoute,
  createRoute,
  createRouter,
} from '@tanstack/react-router';
// error-pages
import NotFoundError from './pages/error-pages/404';
import NotAuthorizedError from './pages/error-pages/401';
import ForbiddenError from './pages/error-pages/403';
import InternalError from './pages/error-pages/500';
// routes
import { ProtectedRoute } from './components/private-routes';
import { RouteNames } from 'app-zephyr-routes';
import ForbiddenErrorNotValidEmail from './pages/error-pages/403-error-not-valid-email';
import CLILoginSuccess from './pages/notifications/cli-login-success';
import CLILoginError from './pages/notifications/cli-login-failure';
import SidePanelLoginSuccess from './pages/notifications/side-panel-login-success';
import SidePanelLoginError from './pages/notifications/side-panel-login-failure';
import { AppV2 } from './pages/_app_v2';
import { Layout } from 'app-zephyr-components/Layout';
// constants
import {
  navigationItemsAppLevel,
  navigationItemsOrgLevel,
  navigationItemsProjectLevel,
  navigationItemsProfileLevel,
} from 'app-zephyr-constants';
import { AuthenticatedUserProfileLayout, AuthenticatedUserDashboardLayout } from 'app-zephyr-layouts';

type Loader = (args: { params: any }) => void;

interface LoaderModule {
  queryLoader: (queryClient: QueryClient) => Loader;
}

export const router = (queryClient: QueryClient) => {
  function lazyLoader(importFn: () => Promise<LoaderModule>, key?: string) {
    return async function _lazyLoaderInt(...args: Parameters<Loader>) {
      const imported = await importFn();
      return imported[key || 'queryLoader'](queryClient)(...args);
    };
  }

  const rootRoute = createRootRoute({
    component: () => (
      <AppV2>
        <Outlet />
      </AppV2>
    ),
  });

  const authInitRoute = createRoute({
    id: 'init_route',
    getParentRoute: () => rootRoute,
    component: () => <Outlet />,
  });

  authInitRoute.addChildren(
    [
      {
        path: RouteNames.INIT,
        component: lazyRouteComponent(() => import('app-zephyr-pages/application-init'), 'Component'),
      },
      { path: RouteNames.NOT_AUTHORIZED, component: () => <NotAuthorizedError /> },
      { path: RouteNames.FORBIDDEN, component: () => <ForbiddenError /> },
      { path: RouteNames.INTERNAL_ERROR, component: () => <InternalError /> },
      { path: RouteNames.NEW_SUBSCRIBER, component: () => <ForbiddenErrorNotValidEmail /> },
      { path: RouteNames.CLI_LOGIN_SUCCESS, component: () => <CLILoginSuccess /> },
      { path: RouteNames.CLI_LOGIN_FAILURE, component: () => <CLILoginError /> },
      { path: RouteNames.SIDE_PANEL_LOGIN_SUCCESS, component: () => <SidePanelLoginSuccess /> },
      { path: RouteNames.SIDE_PANEL_LOGIN_FAILURE, component: () => <SidePanelLoginError /> },
    ].map((_route) => createRoute(Object.assign(_route, { getParentRoute: () => authInitRoute }))),
  );

  const protectedRoute = createRoute({
    id: 'protected_route',
    getParentRoute: () => rootRoute,
    component: () => <ProtectedRoute component={Outlet} />,
  });

  const orgLevel = createRoute({
    id: 'org_level_routing',
    getParentRoute: () => protectedRoute,
    component: () => (
      <Layout getNavigationItems={navigationItemsOrgLevel}>
        <Outlet />
      </Layout>
    ),
  });

  const orgRoutes = [
    {
      path: RouteNames.ORGANIZATION,
      component: lazyRouteComponent(() => import('app-zephyr-pages/org-root-overview'), 'Component'),
      loader: lazyLoader(() => import('app-zephyr-pages/org-root-overview'), 'queryLoader'),
    },
    {
      path: RouteNames.ORGANIZATION_MANAGED_APPLICATIONS,
      component: lazyRouteComponent(() => import('app-zephyr-pages/org-managed-application-list'), 'Component'),
      loader: lazyLoader(() => import('app-zephyr-pages/org-managed-application-list'), 'queryLoader'),
    },
    {
      path: RouteNames.ORGANIZATION_UNMANAGED_APPLICATIONS,
      component: lazyRouteComponent(() => import('app-zephyr-pages/org-unmanaged-application-list'), 'Component'),
      loader: lazyLoader(() => import('app-zephyr-pages/org-unmanaged-application-list'), 'queryLoader'),
    },
    {
      path: RouteNames.ORGANIZATION_UNMANAGED_APPLICATION_NEW,
      component: lazyRouteComponent(() => import('app-zephyr-pages/org-unmanaged-application-new'), 'Component'),
      loader: lazyLoader(() => import('app-zephyr-pages/org-unmanaged-application-new'), 'queryLoader'),
    },
    {
      path: RouteNames.ORGANIZATION_UNMANAGED_APPLICATION_EDIT,
      component: lazyRouteComponent(() => import('app-zephyr-pages/org-unmanaged-application-edit'), 'Component'),
      loader: lazyLoader(() => import('app-zephyr-pages/org-unmanaged-application-edit'), 'queryLoader'),
    },
    {
      path: RouteNames.ORGANIZATION_SETTINGS,
      component: lazyRouteComponent(() => import('app-zephyr-pages/org-settings'), 'Component'),
      loader: lazyLoader(() => import('app-zephyr-pages/org-settings'), 'queryLoader'),
    },
    {
      path: RouteNames.ORGANIZATION_SUBSCRIPTION,
      component: lazyRouteComponent(() => import('app-zephyr-pages/org-subscriptions'), 'Component'),
      loader: lazyLoader(() => import('app-zephyr-pages/org-subscriptions'), 'queryLoader'),
    },
    {
      path: RouteNames.ORGANIZATION_MEMBERSHIP,
      component: lazyRouteComponent(() => import('app-zephyr-pages/org-membership'), 'Component'),
      loader: lazyLoader(() => import('app-zephyr-pages/org-membership'), 'queryLoader'),
    },
    {
      path: RouteNames.ORGANIZATION_BILLING_HISTORY,
      component: lazyRouteComponent(() => import('app-zephyr-pages/org-billing-history'), 'Component'),
      loader: lazyLoader(() => import('app-zephyr-pages/org-billing-history'), 'queryLoader'),
    },
    {
      path: RouteNames.ORGANIZATION_PAYMENT_METHODS,
      component: lazyRouteComponent(() => import('app-zephyr-pages/org-payment-method-list'), 'Component'),
      loader: lazyLoader(() => import('app-zephyr-pages/org-payment-method-list'), 'queryLoader'),
    },
    {
      path: RouteNames.ORGANIZATION_SUBSCRIPTION_MANAGE,
      component: lazyRouteComponent(() => import('app-zephyr-pages/org-subscriptions-manage'), 'Component'),
      loader: lazyLoader(() => import('app-zephyr-pages/org-subscriptions-manage'), 'queryLoader'),
    },
    {
      path: RouteNames.ORGANIZATION_DEPLOYMENT_INTEGRATION_LIST,
      component: lazyRouteComponent(() => import('app-zephyr-pages/org-deployment-integration-list'), 'Component'),
      loader: lazyLoader(() => import('app-zephyr-pages/org-deployment-integration-list'), 'queryLoader'),
    },
    {
      path: RouteNames.ORGANIZATION_DEPLOYMENT_INTEGRATION_OVERVIEW,
      component: lazyRouteComponent(() => import('app-zephyr-pages/org-deployment-integration'), 'Component'),
      loader: lazyLoader(() => import('app-zephyr-pages/org-deployment-integration'), 'queryLoader'),
    },
    {
      path: RouteNames.ORGANIZATION_DEPLOYMENT_INTEGRATION,
      component: lazyRouteComponent(() => import('app-zephyr-pages/org-deployment-integration'), 'Component'),
      loader: lazyLoader(() => import('app-zephyr-pages/org-deployment-integration'), 'queryLoader'),
    },
    {
      path: RouteNames.ORGANIZATION_DEPLOYMENT_INTEGRATION_ADD,
      component: lazyRouteComponent(() => import('app-zephyr-pages/org-deployment-integration-new'), 'Component'),
      loader: lazyLoader(() => import('app-zephyr-pages/org-deployment-integration-new'), 'queryLoader'),
    },
    {
      path: RouteNames.ORGANIZATION_DEPLOYMENT_INTEGRATION_EDIT,
      component: lazyRouteComponent(() => import('app-zephyr-pages/org-deployment-integration-edit'), 'Component'),
      loader: lazyLoader(() => import('app-zephyr-pages/org-deployment-integration-edit'), 'queryLoader'),
    },
    {
      path: RouteNames.ORGANIZATION_PEOPLE,
      component: lazyRouteComponent(() => import('app-zephyr-pages/org-people-list'), 'Component'),
      loader: lazyLoader(() => import('app-zephyr-pages/org-people-list'), 'queryLoader'),
    },
    {
      path: RouteNames.ORGANIZATION_PROJECTS,
      component: lazyRouteComponent(() => import('app-zephyr-pages/project-list'), 'Component'),
      loader: lazyLoader(() => import('app-zephyr-pages/project-list'), 'queryLoader'),
    },
    {
      path: RouteNames.ORGANIZATION_TEAM_LIST,
      component: lazyRouteComponent(() => import('app-zephyr-pages/org-team-list'), 'Component'),
      loader: lazyLoader(() => import('app-zephyr-pages/org-team-list'), 'queryLoader'),
    },
    {
      path: RouteNames.ORGANIZATION_TEAM,
      component: lazyRouteComponent(() => import('app-zephyr-pages/org-team'), 'Component'),
      loader: lazyLoader(() => import('app-zephyr-pages/org-team'), 'queryLoader'),
    },
  ].map((_route) => createRoute(Object.assign(_route, { getParentRoute: () => orgLevel })));

  orgLevel.addChildren(orgRoutes);

  // project lvl
  const projectLevel = createRoute({
    id: 'project_level_routing',
    getParentRoute: () => protectedRoute,
    component: () => (
      <Layout getNavigationItems={navigationItemsProjectLevel}>
        <Outlet />
      </Layout>
    ),
  });

  const projectRoutes = [
    {
      path: RouteNames.PROJECT_OVERVIEW,
      component: lazyRouteComponent(() => import('app-zephyr-pages/project-overview'), 'Component'),
      loader: lazyLoader(() => import('app-zephyr-pages/project-overview'), 'queryLoader'),
    },
    {
      path: RouteNames.PROJECT_APPLICATION_LIST,
      component: lazyRouteComponent(() => import('app-zephyr-pages/project-application-list'), 'Component'),
      loader: lazyLoader(() => import('app-zephyr-pages/project-application-list'), 'queryLoader'),
    },
    {
      path: RouteNames.PROJECT_SETTINGS_GENERAL,
      component: lazyRouteComponent(() => import('app-zephyr-pages/project-settings-general'), 'Component'),
      loader: lazyLoader(() => import('app-zephyr-pages/project-settings-general'), 'queryLoader'),
    },
    {
      path: RouteNames.PROJECT_SETTINGS_DEPLOYMENT_INTEGRATION_LIST,
      component: lazyRouteComponent(() => import('app-zephyr-pages/project-deployment-integration-list'), 'Component'),
      loader: lazyLoader(() => import('app-zephyr-pages/project-deployment-integration-list'), 'queryLoader'),
    },
    {
      path: RouteNames.PROJECT_SETTINGS_COLLABORATORS_AND_TEAM,
      component: lazyRouteComponent(
        () => import('app-zephyr-pages/project-settings-collaborations-and-teams'),
        'Component',
      ),
      loader: lazyLoader(() => import('app-zephyr-pages/project-settings-collaborations-and-teams'), 'queryLoader'),
    },
  ].map((_route) => createRoute(Object.assign(_route, { getParentRoute: () => projectLevel })));

  projectLevel.addChildren([...projectRoutes]);

  // application lvl
  const appLevel = createRoute({
    id: 'app_level_routing',
    getParentRoute: () => protectedRoute,
    component: () => (
      <Layout getNavigationItems={navigationItemsAppLevel}>
        <Outlet />
      </Layout>
    ),
  });

  const appRoutes = [
    {
      path: RouteNames.APPLICATION_DEPENDENCIES,
      component: lazyRouteComponent(() => import('app-zephyr-pages/application-dependencies'), 'Component'),
      loader: lazyLoader(() => import('app-zephyr-pages/application-dependencies'), 'queryLoader'),
    },
    {
      path: RouteNames.APPLICATION_DEPENDENCY_GRAPH,
      component: lazyRouteComponent(() => import('app-zephyr-pages/application-dependency-graph'), 'Component'),
      loader: lazyLoader(() => import('app-zephyr-pages/application-dependency-graph'), 'queryLoader'),
    },
    {
      path: RouteNames.APPLICATION_NODE_DEPENDENCIES,
      component: lazyRouteComponent(() => import('app-zephyr-pages/application-relationship-graph'), 'Component'),
      loader: lazyLoader(() => import('app-zephyr-pages/application-relationship-graph'), 'queryLoader'),
    },
    {
      path: RouteNames.APPLICATION_NODE_GRAPH,
      component: lazyRouteComponent(() => import('app-zephyr-pages/application-node-graph'), 'Component'),
      loader: lazyLoader(() => import('app-zephyr-pages/application-node-graph'), 'queryLoader'),
    },
    {
      path: RouteNames.APPLICATION_OVERVIEW,
      component: lazyRouteComponent(() => import('app-zephyr-pages/application-overview'), 'Component'),
      loader: lazyLoader(() => import('app-zephyr-pages/application-overview'), 'queryLoader'),
    },
    {
      path: RouteNames.APPLICATION_VERSIONS,
      component: lazyRouteComponent(() => import('app-zephyr-pages/application-versions'), 'Component'),
      loader: lazyLoader(() => import('app-zephyr-pages/application-versions'), 'queryLoader'),
    },
    {
      path: RouteNames.APPLICATION_UML,
      component: lazyRouteComponent(() => import('app-zephyr-pages/application-uml'), 'Component'),
      loader: lazyLoader(() => import('app-zephyr-pages/application-uml'), 'queryLoader'),
    },
    {
      path: RouteNames.APPLICATION_REMOTES,
      component: lazyRouteComponent(() => import('app-zephyr-pages/application-remote-list'), 'Component'),
      loader: lazyLoader(() => import('app-zephyr-pages/application-remote-list'), 'queryLoader'),
    },
    {
      path: RouteNames.APPLICATION_MODULE,
      component: lazyRouteComponent(() => import('app-zephyr-pages/application-remote'), 'Component'),
      loader: lazyLoader(() => import('app-zephyr-pages/application-remote'), 'queryLoader'),
    },
    {
      path: RouteNames.APPLICATION_SETTINGS_GENERAL,
      component: lazyRouteComponent(() => import('app-zephyr-pages/application-settings-general'), 'Component'),
      loader: lazyLoader(() => import('app-zephyr-pages/application-settings-general'), 'queryLoader'),
    },
    {
      path: RouteNames.APPLICATION_SETTINGS_TAGS,
      component: lazyRouteComponent(() => import('app-zephyr-pages/org-application-settings-tag-list'), 'Component'),
      loader: lazyLoader(() => import('app-zephyr-pages/org-application-settings-tag-list'), 'queryLoader'),
    },
    {
      path: RouteNames.APPLICATION_SETTINGS_TAGS_ADD,
      component: lazyRouteComponent(() => import('app-zephyr-pages/application-settings-tag-add'), 'Component'),
      loader: lazyLoader(() => import('app-zephyr-pages/application-settings-tag-add'), 'queryLoader'),
    },
    {
      path: RouteNames.APPLICATION_SETTINGS_TAGS_EDIT,
      component: lazyRouteComponent(() => import('app-zephyr-pages/application-settings-tag-edit'), 'Component'),
      loader: lazyLoader(() => import('app-zephyr-pages/application-settings-tag-edit'), 'queryLoader'),
    },
    {
      path: RouteNames.APPLICATION_SETTINGS_ENVIRONMENT,
      component: lazyRouteComponent(
        () => import('app-zephyr-pages/org-application-settings-environment-list'),
        'Component',
      ),
      loader: lazyLoader(() => import('app-zephyr-pages/org-application-settings-environment-list'), 'queryLoader'),
    },
    {
      path: RouteNames.APPLICATION_SETTINGS_ENVIRONMENT_ADD,
      component: lazyRouteComponent(
        () => import('app-zephyr-pages/org-application-settings-environment-add'),
        'Component',
      ),
      loader: lazyLoader(() => import('app-zephyr-pages/org-application-settings-environment-add'), 'queryLoader'),
    },
    {
      path: RouteNames.APPLICATION_SETTINGS_DEPLOYMENT_INTEGRATION_LIST,
      component: lazyRouteComponent(
        () => import('app-zephyr-pages/application-deployment-integration-list'),
        'Component',
      ),
      loader: lazyLoader(() => import('app-zephyr-pages/application-deployment-integration-list'), 'queryLoader'),
    },
    {
      path: RouteNames.APPLICATION_SETTINGS_ENVIRONMENT_EDIT,
      component: lazyRouteComponent(
        () => import('app-zephyr-pages/org-application-settings-environment-edit'),
        'Component',
      ),
      loader: lazyLoader(() => import('app-zephyr-pages/org-application-settings-environment-edit'), 'queryLoader'),
    },
    {
      path: RouteNames.APPLICATION_SETTINGS_DEPLOYMENT_AND_ROLLBACK,
      component: lazyRouteComponent(
        () => import('app-zephyr-pages/application-settings-deployment-and-rollback'),
        'Component',
      ),
      loader: lazyLoader(() => import('app-zephyr-pages/application-settings-deployment-and-rollback'), 'queryLoader'),
    },
  ].map((_route) => createRoute(Object.assign(_route, { getParentRoute: () => appLevel })));

  appLevel.addChildren(appRoutes);

  // profile lvl
  const profileLevel = createRoute({
    id: 'profile_level_routing',
    getParentRoute: () => protectedRoute,
    component: () => (
      <Layout getNavigationItems={navigationItemsProfileLevel}>
        <Outlet />
      </Layout>
    ),
  });

  const profileRoutes = [
    {
      path: RouteNames.USER_PROFILE,
      component: lazyRouteComponent(() => import('app-zephyr-pages/user-profile'), 'UserProfile'),
      loader: lazyLoader(() => import('app-zephyr-pages/user-profile'), 'queryLoader'),
    },
  ].map((_route) => createRoute(Object.assign(_route, { getParentRoute: () => profileLevel })));
  profileLevel.addChildren(profileRoutes);

  const authenticatedUserDashboardLevel = createRoute({
    id: 'authenticated_user_dashboard_level_routing',
    getParentRoute: () => protectedRoute,
    component: () => <AuthenticatedUserDashboardLayout />,
    loader: lazyLoader(() => import('app-zephyr-pages/user-dashboard'), 'queryLoader'),
  });

  const profileDashboardRoutes = [
    {
      path: '/',
      component: lazyRouteComponent(() => import('app-zephyr-pages/user-dashboard'), 'UserDashboard'),
      loader: lazyLoader(() => import('app-zephyr-pages/user-dashboard'), 'queryLoader'),
    },
  ].map((_route) => createRoute(Object.assign(_route, { getParentRoute: () => authenticatedUserDashboardLevel })));
  authenticatedUserDashboardLevel.addChildren(profileDashboardRoutes);

  // authenticated user profile level
  const authenticatedUserProfileLevel = createRoute({
    id: 'authenticated_userprofile_level_routing',
    getParentRoute: () => protectedRoute,
    component: () => <AuthenticatedUserProfileLayout />,
    loader: lazyLoader(() => import('app-zephyr-layouts/authenticated-user-profile-layout'), 'queryLoader'),
  });

  const authenticatedUserProfileRoutes = [
    {
      path: RouteNames.AUTHENTICATED_USER_PROFILE,
      component: lazyRouteComponent(() => import('app-zephyr-pages/my-profile'), 'MyProfile'),
      loader: lazyLoader(() => import('app-zephyr-pages/my-profile'), 'queryLoader'),
    },
    {
      path: RouteNames.USER_PROFILE_SETTINGS,
      component: lazyRouteComponent(() => import('app-zephyr-pages/profile-public-settings'), 'Component'),
      loader: lazyLoader(() => import('app-zephyr-pages/profile-public-settings'), 'queryLoader'),
    },
    {
      path: RouteNames.USER_PROFILE_SETTINGS_EMAILS,
      component: lazyRouteComponent(() => import('app-zephyr-pages/profile-emails-settings'), 'Component'),
      loader: lazyLoader(() => import('app-zephyr-pages/profile-emails-settings'), 'queryLoader'),
    },
    {
      path: RouteNames.USER_PROFILE_SETTINGS_USER_TOKENS,
      component: lazyRouteComponent(() => import('app-zephyr-pages/user-tokens'), 'Component'),
      loader: lazyLoader(() => import('app-zephyr-pages/user-tokens'), 'queryLoader'),
    },
    {
      path: RouteNames.USER_PROFILE_SETTINGS_GENERATE_USER_TOKENS,
      component: lazyRouteComponent(() => import('app-zephyr-pages/user-token-add-edit'), 'Component'),
      loader: lazyLoader(() => import('app-zephyr-pages/user-token-add-edit'), 'queryLoader'),
    },
    {
      path: RouteNames.USER_PROFILE_SETTINGS_EDIT_USER_TOKEN,
      component: lazyRouteComponent(() => import('app-zephyr-pages/user-token-add-edit'), 'Component'),
      loader: lazyLoader(() => import('app-zephyr-pages/user-token-add-edit'), 'queryLoader'),
    },
  ].map((_route) => createRoute(Object.assign(_route, { getParentRoute: () => authenticatedUserProfileLevel })));
  authenticatedUserProfileLevel.addChildren(authenticatedUserProfileRoutes);

  const noNavbarRoute = createRoute({
    id: 'no_navbar_route',
    getParentRoute: () => protectedRoute,
    component: () => (
      <Layout>
        <Outlet />
      </Layout>
    ),
  });

  protectedRoute.addChildren([
    orgLevel,
    projectLevel,
    appLevel,
    noNavbarRoute,
    profileLevel,
    authenticatedUserProfileLevel,
    authenticatedUserDashboardLevel,
  ]);
  const routeTree = rootRoute.addChildren([authInitRoute, protectedRoute]);

  const notFoundRoute = new NotFoundRoute({
    getParentRoute: () => rootRoute,
    component: () => <NotFoundError />,
  });

  return createRouter({
    routeTree,
    notFoundRoute,
    defaultPreload: 'intent',
    defaultPreloadDelay: 400,
  });
};
