import { SVGProps } from 'react';

export type PersonIconProps = SVGProps<SVGSVGElement> & {
  color?: string;
};

export const PersonIcon = ({ color = '#7B84A3', ...props }: PersonIconProps) => (
  <svg
    width={props.width ?? 25}
    height={props.height ?? 25}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.1949 13.2096C17.1753 12.4383 17.8909 11.3806 18.2421 10.1836C18.5933 8.9866 18.5628 7.7099 18.1546 6.5311C17.7465 5.35231 16.9811 4.33003 15.9649 3.60649C14.9487 2.88296 13.7323 2.49414 12.4849 2.49414C11.2374 2.49414 10.021 2.88296 9.00479 3.60649C7.98861 4.33003 7.2232 5.35231 6.81507 6.5311C6.40694 7.7099 6.37637 8.9866 6.72762 10.1836C7.07887 11.3806 7.79447 12.4383 8.77486 13.2096C7.09494 13.8827 5.62915 14.999 4.53375 16.4396C3.43836 17.8801 2.75442 19.5909 2.55486 21.3896C2.54041 21.521 2.55198 21.6538 2.58889 21.7807C2.62579 21.9075 2.68733 22.0259 2.76997 22.129C2.93688 22.3371 3.17964 22.4705 3.44486 22.4996C3.71008 22.5288 3.97602 22.4514 4.18419 22.2845C4.39235 22.1176 4.52569 21.8749 4.55486 21.6096C4.77444 19.6548 5.70655 17.8494 7.17308 16.5384C8.63961 15.2274 10.5378 14.5027 12.5049 14.5027C14.472 14.5027 16.3701 15.2274 17.8366 16.5384C19.3032 17.8494 20.2353 19.6548 20.4549 21.6096C20.482 21.8554 20.5993 22.0823 20.784 22.2467C20.9686 22.411 21.2076 22.5011 21.4549 22.4996H21.5649C21.827 22.4695 22.0666 22.3369 22.2314 22.1309C22.3963 21.9248 22.473 21.662 22.4449 21.3996C22.2444 19.5958 21.5567 17.8806 20.4557 16.4378C19.3547 14.9951 17.8818 13.8791 16.1949 13.2096V13.2096ZM12.4849 12.4996C11.6937 12.4996 10.9204 12.265 10.2626 11.8255C9.60478 11.386 9.09209 10.7613 8.78934 10.0304C8.48659 9.29947 8.40738 8.4952 8.56172 7.71928C8.71606 6.94335 9.09702 6.23062 9.65643 5.67121C10.2158 5.1118 10.9286 4.73084 11.7045 4.5765C12.4804 4.42215 13.2847 4.50137 14.0156 4.80412C14.7465 5.10687 15.3712 5.61956 15.8107 6.27736C16.2503 6.93515 16.4849 7.70851 16.4849 8.49964C16.4849 9.5605 16.0634 10.5779 15.3133 11.3281C14.5631 12.0782 13.5457 12.4996 12.4849 12.4996Z"
      fill={color}
    />
  </svg>
);
