import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';

import { DeploymentIntegrationItemSkeleton } from './deployment-integration-item-skeleton';

export function DeploymentIntegrationListSkeleton() {
  return (
    <Stack spacing={2}>
      <Stack width={400}>
        <Skeleton height={40} />
      </Stack>
      <Stack direction={'row'} spacing={2} useFlexGap flexWrap="wrap">
        {Array.from({ length: 6 }).map((_, index) => (
          <DeploymentIntegrationItemSkeleton key={`skeleton-${index.toString()}`} />
        ))}
      </Stack>
    </Stack>
  );
}
