export const GET_CURRENT_USER_ORGANIZATION_LIST_PATH = 'v2/user/organization-list';

export interface AvailableOrganization {
  id: string;
  name: string;
  displayName: string;
  default: boolean;

  // TODO: implement on backend
  portrait?: string;
  avatarColor?: string;
}
