import { simplePath } from 'ze-api-contract/utils';

export const GET_APPLICATION_TAG_CONDITION_VERSION =
  '/v2/application/:organization/:project/:application/tag/condition/version';

interface GetApplicationEnvironmentConditionListParams {
  organization: string;
  project: string;
  application: string;
}

export function get_application_tag_condition_list_path(params: GetApplicationEnvironmentConditionListParams): string {
  return simplePath(GET_APPLICATION_TAG_CONDITION_VERSION, params);
}

export interface ConditionVersion {
  id: string;
  version: string;
  remote_host: string;
}
