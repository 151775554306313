import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useQuery } from '@tanstack/react-query';

import { axios } from 'app-zephyr-axios';
import { GET_CURRENT_USER_PATH, User } from 'ze-api-contract/user-v2/get-current-user';

export const currentUser = createQueryKeys('user', {
  current: {
    queryKey: [GET_CURRENT_USER_PATH],
    queryFn: () => axios.get<{ value: User }>(GET_CURRENT_USER_PATH).then((res) => res.data.value),
  },
});

export function useCurrentUser() {
  const { isLoading, data: user, error } = useQuery(currentUser.current);

  return {
    user,
    isLoading,
    error,
  };
}
