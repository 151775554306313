import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  breadcrumbContainer: {
    paddingBottom: theme.spacing(0.5),
  },
  breadcrumbContent: {
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center',
    justifyContent: 'space-between',
    minHeight: 44,
  },
  breadcrumbComponent: {
    '.MuiBreadcrumbs-li': {
      'a, p': {
        fontWeight: 550,
        letterSpacing: '0.04em',
        fontSize: '18px',
        lineHeight: '140%',
        color: theme.palette.tx.primary,
      },
      a: {
        color: theme.palette.tx.primary,
      },
      'p p.active': {
        color: theme.palette.tx.primary,
      },
      'p a.active': {
        color: theme.palette.brand.purple[500],
      },
      'a:hover': {
        color: theme.palette.tx.secondary.hover,
      },
    },
  },
}));
