import { axios } from 'app-zephyr-axios';
import { useQuery } from '@tanstack/react-query';
import { createQueryKeys } from '@lukemorales/query-key-factory';

import { GetOrgApplicationListQueryParams } from 'ze-api-contract/organization-v2/get-application-list/interfaces';
import {
  get_org_application_list_path,
  GET_ORG_APPLICATION_LIST_PATH,
  ApplicationListResponse,
} from 'ze-api-contract/organization-v2/get-application-list';

interface ApplicationListProps {
  organization?: string;
  queryParams?: GetOrgApplicationListQueryParams;
  /**
   * Initial page param to start the query from.
   * Returns the all data if not provided.
   */
  initialPageParam?: number;
}

export const orgAppList = createQueryKeys('organization', {
  applicationList: ({
    organization,
    perPage,
    page,
    queryParams,
  }: ApplicationListProps & { perPage?: number; page?: number }) => ({
    queryKey: [GET_ORG_APPLICATION_LIST_PATH, organization, page, perPage, queryParams],
    queryFn: async () => {
      if (!organization) return Promise.resolve(undefined);

      const params = {
        ...queryParams,
        perPage: Number.isInteger(page) ? perPage : undefined,
      };

      return axios
        .get<ApplicationListResponse>(get_org_application_list_path({ organization }), {
          params,
        })
        .then((res) => res.data);
    },
  }),
});

/**
 * Get specific organization application list with pagination.
 **/
export function useApplicationList({ organization, queryParams }: ApplicationListProps) {
  const perPage = queryParams?.perPage ?? 7;
  const page = queryParams?.page;

  const {
    data,
    isLoading,
    error,
    refetch: applicationListRefetch,
  } = useQuery(orgAppList.applicationList({ organization, perPage, page, queryParams }));
  const applicationList = data?.entities ?? [];
  const applicationListCount = data?.pageMetadata?.lastPage ?? 0;

  return {
    applicationList,
    applicationListCount,
    isLoading,
    error,
    applicationListRefetch,
  };
}
