import { createQueryKeys } from '@lukemorales/query-key-factory';
import { axios } from 'app-zephyr-axios';
import { useQuery } from '@tanstack/react-query';
import { OrganizationListItem } from 'ze-api-contract/organization-v2/get-my-organization-list';
import { GET_ORGANIZATION_AVAILABLE_PATH } from 'ze-api-contract/organization-v1/get-organization-available';

// todo::update to v2 API
const v1OrganizationListAvailable = createQueryKeys('organization', {
  listAvailable: () => ({
    queryKey: [GET_ORGANIZATION_AVAILABLE_PATH],
    queryFn: () => axios.get<OrganizationListItem[]>(GET_ORGANIZATION_AVAILABLE_PATH).then((res) => res.data),
    // staleTime: 1000 * 60 * 60,
  }),
});

/** @deprecated - use `my` organizations */
export function useOrganizationListAvailable() {
  const { data: availableOrganizations, isLoading, error } = useQuery(v1OrganizationListAvailable.listAvailable());

  return { availableOrganizations, isLoading, error };
}
