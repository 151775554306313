import { InputLabel, Autocomplete, TextField } from '@mui/material';
import {
  Path,
  FieldValues,
  Control,
  Controller,
  FieldErrors,
  FieldName,
  UseFormWatch,
  UseFormSetValue,
  UseFormTrigger,
  PathValue,
} from 'react-hook-form';
import { ErrorMessage, FieldValuesFromFieldErrors } from '@hookform/error-message';

import { InputProps, ErrorMessage as FormErrorMessage } from '../../_elements';
import { useStyles } from './styles';
import { useRef, useState } from 'react';

export interface AutocompleteValueItem {
  label: string;
  value: string;
}

export interface FormAutocompleteProps<FormValuesProps extends FieldValues> extends Omit<InputProps, 'name'> {
  name: Path<FormValuesProps>;
  control: Control<FormValuesProps>;
  options: AutocompleteValueItem[];
  errors?: FieldErrors<FormValuesProps>;
  watch?: UseFormWatch<FormValuesProps>;
  setValue: UseFormSetValue<FormValuesProps>;
  trigger: UseFormTrigger<FormValuesProps>;
}

const FormAutocomplete = <FormValuesProps extends Record<string, unknown>>({
  name,
  errors,
  className,
  setValue,
  trigger,
  label,
  control,
  options,
}: FormAutocompleteProps<FormValuesProps>) => {
  const { classes, cx } = useStyles();
  const handleSelectChange = (value: AutocompleteValueItem | null) => {
    const autoCompleteValue = value as PathValue<FormValuesProps, Path<FormValuesProps>>;
    setValue(name, autoCompleteValue, { shouldValidate: true, shouldDirty: true });
    void trigger(name);
  };

  const [focused, setFocused] = useState(false);
  const onFocus = () => {
    setFocused(true);
  };
  const onBlur = () => {
    setFocused(false);
  };

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleLabelClick = () => {
    inputRef.current?.focus();
  };

  return (
    <div className={cx(classes.container, className)} aria-live="polite" data-testid="form-autocomplete">
      {!!label && (
        <InputLabel onClick={handleLabelClick} className={classes.label}>
          {label}
        </InputLabel>
      )}
      <div className={cx(classes.selectContainer, focused && classes.focused)}>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              className={classes.searchSelect}
              options={options}
              getOptionLabel={(option) => {
                return option.label;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  inputRef={(element) => {
                    field.ref(element);
                    inputRef.current = element;
                  }}
                  label={!options.length ? 'No options available' : ''}
                  placeholder="Start typing the value or choose the valid one"
                />
              )}
              onChange={(e, value) => {
                handleSelectChange(value);
              }}
              disabled={!options.length}
              value={field.value as AutocompleteValueItem}
            />
          )}
        />
      </div>
      <ErrorMessage
        errors={errors}
        name={name as unknown as FieldName<FieldValuesFromFieldErrors<FieldErrors<FormValuesProps>>>}
        render={({ message }) => <FormErrorMessage className={'error-message'}>{message}</FormErrorMessage>}
      />
    </div>
  );
};

export { FormAutocomplete };
