import { organization_path, organization_deployment_integrations_list_path } from 'app-zephyr-routes';

export function getBreadCrumbList({
  organization,
  platform,
  name,
}: {
  organization: string;
  platform: string;
  name?: string;
}) {
  return [
    {
      title: organization,
      href: organization_path({ organization: { name: organization } }),
    },
    {
      title: 'Deployment Integrations',
      href: organization_deployment_integrations_list_path({ organization: { name: organization } }),
    },
    {
      title: name ?? platform,
    },
  ];
}
