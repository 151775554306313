import { simplePath } from 'ze-api-contract/utils';

export const GET_APPLICATION_ENVIRONMENT_CONDITION_VERSION =
  '/v2/application/:organization/:project/:application/environment/condition/version';

interface GetApplicationEnvironmentConditionVersionParams {
  organization: string;
  project: string;
  application: string;
}

export function get_application_environment_condition_version_path(
  params: GetApplicationEnvironmentConditionVersionParams,
): string {
  return simplePath(GET_APPLICATION_ENVIRONMENT_CONDITION_VERSION, params);
}

export interface ConditionVersionQueryParams {
  /**
   * condition version value
   */
  value: string;
  /**
   * condition version id
   */
  channel: string;
}

export interface ConditionVersion {
  id: string;
  version: string;
  remote_host: string;
}
