import React from 'react';

/**
 * Common Helmet page with a basic seo meta tags.
 * @param id google tag manager id.
 * @param fireCustomEvent function to push event.
 */

const GoogleScripts: React.FC<{ id: string; fireCustomEvent: (eventName: string, url: string) => void }> = ({
  id,
  fireCustomEvent,
}) => (
  <>
    <script src={`https://www.googletagmanager.com/gtag/js?id=${id}`} />
    <script id="google-analytics">
      {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){${fireCustomEvent.toString()};}
          gtag('js', new Date());

          gtag('config', '${id}');
        `}
    </script>
    <noscript>
      <iframe
        title="google-tag-manager-noscript-iframe"
        src={`https://www.googletagmanager.com/ns.html?id=${id}`}
        height="0"
        width="0"
        style={{ display: `none`, visibility: `hidden` }}
      />
    </noscript>
  </>
);

export { GoogleScripts };
