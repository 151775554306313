import { ReactNode } from 'react';
import { Link, Typography } from '@mui/material';

interface getBreadCrumbItemProps {
  title: string;
  href?: string;
}

export function getBreadCrumbList(breadCrumbList: getBreadCrumbItemProps[]): ReactNode[] {
  return breadCrumbList.map((item, index) => {
    if (!item.href) {
      return (
        <Typography key="3" color="text.primary">
          {item.title}
        </Typography>
      );
    }

    return (
      <Link underline="hover" key={`breadcrumb_${index.toString()}`} href={item.href}>
        {item.title}
      </Link>
    );
  });
}
