export enum Channel {
  Branch = 'branch',
  Version = 'version',
  User = 'user',
  isCI = 'isCI',
}

export const channelList = [Channel.Version, Channel.Branch, Channel.User, Channel.isCI];

export interface ConditionValue {
  value: string;
  label: string;
}

export interface Condition {
  type: Channel;
  value: ConditionValue;
}

export interface SearchCondition {
  type: Channel;
  value: string;
}

export interface ApplicationTag {
  id: string;
  name: string;
  applicationVersionId: string;
  version?: {
    id: string;
    name: string;
    remote_host: string | null;
    remote_entry_url: string | null;
  };
  conditions: Condition[];
  publishedAt?: string;
  downloads?: number;
  createdAt: string;
  updatedAt?: string;
  deletedAt?: string;
  remote_host: string;
  remote_entry_url: string;
}

export interface ApplicationTagUIdParams {
  organization: string;
  project: string;
  application: string;
  tag: string;
}

export interface Error {
  message: string;
  response: {
    data: {
      errorMessage: string;
    };
  };
}
