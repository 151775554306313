import {
  DeploymentIntegrationValue,
  DeploymentIntegrationType,
  DeploymentIntegrationPlatform,
} from 'ze-api-contract/deployment-integration/organization/interfaces';

export const integrationsOrder = [
  DeploymentIntegrationPlatform.CLOUDFLARE,
  DeploymentIntegrationPlatform.AWS,
  DeploymentIntegrationPlatform.NETLIFY,
  DeploymentIntegrationPlatform.AZURE,
  DeploymentIntegrationPlatform.GCP,
];

export const availableIntegrationList = [
  DeploymentIntegrationPlatform.CLOUDFLARE,
  DeploymentIntegrationPlatform.NETLIFY,
  DeploymentIntegrationPlatform.FASTLY,
];

export const defaultIntegrations: Partial<DeploymentIntegrationValue>[] = [
  {
    id: 'cloudflare_default_provider',
    type: DeploymentIntegrationType.WORKER,
    displayName: 'Cloudflare',
    platform: DeploymentIntegrationPlatform.CLOUDFLARE,
  },
  {
    id: 'fastly_default_provider',
    type: DeploymentIntegrationType.WORKER,
    displayName: 'Fastly',
    platform: DeploymentIntegrationPlatform.FASTLY,
  },
  {
    id: 'aws_default_provider',
    type: DeploymentIntegrationType.WORKER,
    displayName: 'AWS Cloud',
    platform: DeploymentIntegrationPlatform.AWS,
  },
  {
    id: 'netlify_default_provider',
    type: DeploymentIntegrationType.WORKER,
    displayName: 'Netlify',
    platform: DeploymentIntegrationPlatform.NETLIFY,
  },
  {
    id: 'azure_default_provider',
    type: DeploymentIntegrationType.WORKER,
    displayName: 'Azure Cloud',
    platform: DeploymentIntegrationPlatform.AZURE,
  },
  {
    id: 'gcp_default_provider',
    type: DeploymentIntegrationType.WORKER,
    displayName: 'Google Cloud',
    platform: DeploymentIntegrationPlatform.GCP,
  },
];
