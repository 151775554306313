import { simplePath } from 'ze-api-contract/utils';

export const GET_APPLICATION_ENVIRONMENT =
  '/v2/application/:organization/:project/:application/environment/:environment';

interface GetApplicationEnvironmentParams {
  organization: string;
  project: string;
  application: string;
  environment: string;
}

export function get_application_environment_path(params: GetApplicationEnvironmentParams): string {
  return simplePath(GET_APPLICATION_ENVIRONMENT, params);
}
