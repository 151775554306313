import { SVGProps } from 'react';

export type NetlifyIconProps = SVGProps<SVGSVGElement> & {
  isDisabled?: boolean;
};

export const DisabledNetlifyIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="28" viewBox="0 0 32 28" fill="none">
      <g clipPath="url(#clip0_4310_1583)">
        <path
          d="M8.64766 23.3027H8.34553L6.8374 21.8079V21.5085L9.14295 19.2234H10.7402L10.9532 19.4345V21.0176L8.64766 23.3027Z"
          fill="#5F6681"
        />
        <path
          d="M6.8374 6.39629V6.09684L8.34553 4.60205H8.64766L10.9532 6.88717V8.47031L10.7402 8.68141H9.14295L6.8374 6.39629Z"
          fill="#5F6681"
        />
        <path
          d="M20.1132 18.4848H17.9192L17.7359 18.3032V13.2126C17.7359 12.3069 17.3768 11.6049 16.2748 11.5803C15.7077 11.5656 15.0589 11.5803 14.3655 11.6073L14.2615 11.7129V18.3007L14.0783 18.4823H11.8842L11.7009 18.3007V9.60449L11.8842 9.42285H16.8221C18.7413 9.42285 20.2965 10.9643 20.2965 12.8665V18.3032L20.1132 18.4848Z"
          fill="#424960"
        />
        <path
          d="M8.9596 15.2252H0.183253L0 15.0436V12.864L0.183253 12.6824H8.9596L9.14286 12.864V15.0436L8.9596 15.2252Z"
          fill="#5F6681"
        />
        <path
          d="M31.8168 15.2252H23.0404L22.8572 15.0436V12.864L23.0404 12.6824H31.8168L32.0001 12.864V15.0436L31.8168 15.2252Z"
          fill="#5F6681"
        />
        <path
          d="M14.7346 6.70565V0.181631L14.9179 0H17.1169L17.3002 0.181631V6.70565L17.1169 6.88726H14.9179L14.7346 6.70565Z"
          fill="#5F6681"
        />
        <path
          d="M14.7346 27.7259V21.2019L14.9179 21.0203H17.1169L17.3002 21.2019V27.7259L17.1169 27.9075H14.9179L14.7346 27.7259Z"
          fill="#5F6681"
        />
      </g>
      <defs>
        <clipPath id="clip0_4310_1583">
          <rect width="32" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const ActiveNetlifyIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="28" viewBox="0 0 32 28" fill="none">
      <g clipPath="url(#clip0_4310_3828)">
        <path
          d="M8.64766 23.3027H8.34553L6.8374 21.8079V21.5085L9.14295 19.2234H10.7402L10.9532 19.4345V21.0176L8.64766 23.3027Z"
          fill="#05BDBA"
        />
        <path
          d="M6.8374 6.39629V6.09684L8.34553 4.60205H8.64766L10.9532 6.88717V8.47031L10.7402 8.68141H9.14295L6.8374 6.39629Z"
          fill="#05BDBA"
        />
        <path
          d="M20.1135 18.4848H17.9194L17.7361 18.3032V13.2126C17.7361 12.3069 17.3771 11.6049 16.2751 11.5803C15.708 11.5656 15.0592 11.5803 14.3658 11.6073L14.2618 11.7129V18.3007L14.0785 18.4823H11.8844L11.7012 18.3007V9.60449L11.8844 9.42285H16.8224C18.7416 9.42285 20.2968 10.9643 20.2968 12.8665V18.3032L20.1135 18.4848Z"
          fill="#014847"
        />
        <path
          d="M8.9596 15.2252H0.183253L0 15.0436V12.864L0.183253 12.6824H8.9596L9.14286 12.864V15.0436L8.9596 15.2252Z"
          fill="#05BDBA"
        />
        <path
          d="M31.8166 15.2252H23.0402L22.8569 15.0436V12.864L23.0402 12.6824H31.8166L31.9998 12.864V15.0436L31.8166 15.2252Z"
          fill="#05BDBA"
        />
        <path
          d="M14.7344 6.70565V0.181631L14.9176 0H17.1167L17.2999 0.181631V6.70565L17.1167 6.88726H14.9176L14.7344 6.70565Z"
          fill="#05BDBA"
        />
        <path
          d="M14.7344 27.7259V21.2019L14.9176 21.0203H17.1167L17.2999 21.2019V27.7259L17.1167 27.9075H14.9176L14.7344 27.7259Z"
          fill="#05BDBA"
        />
      </g>
      <defs>
        <clipPath id="clip0_4310_3828">
          <rect width="32" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export function NetlifyIcon({ isDisabled, ...props }: NetlifyIconProps) {
  return <>{isDisabled ? <DisabledNetlifyIcon /> : <ActiveNetlifyIcon />}</>;
}
