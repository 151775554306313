import { SVGProps } from 'react';

export type GoogleCloudIconProps = SVGProps<SVGSVGElement> & {
  isDisabled?: boolean;
};

export const DisabledGoogleCloudIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
      <g clipPath="url(#clip0_4310_4074)">
        <path
          d="M21.0305 10.2439L22.0555 10.2614L24.8405 7.47638L24.9755 6.29638C22.7665 4.32838 19.85 3.12988 16.663 3.12988C10.893 3.12988 6.02304 7.05588 4.58154 12.3739C4.88554 12.1619 5.53554 12.3209 5.53554 12.3209L11.1005 11.4059C11.1005 11.4059 11.3865 10.9324 11.5315 10.9559C12.1823 10.241 12.9752 9.6699 13.8594 9.27925C14.7437 8.88861 15.6998 8.68701 16.6665 8.68738C18.3165 8.69088 19.8365 9.27388 21.0305 10.2384V10.2439Z"
          fill="#282D3E"
        />
        <path
          d="M28.7516 12.3849C28.1051 9.99989 26.7726 7.89389 24.9741 6.29639L21.0306 10.2399C22.6106 11.5149 23.6241 13.4659 23.6241 15.6499V16.3459C25.5426 16.3459 27.1011 17.9079 27.1011 19.8229C27.1011 21.7414 25.5391 23.2999 23.6241 23.2999H16.6701L15.9741 23.9999V28.1729L16.6701 28.8654H23.6241C26.0212 28.8621 28.3192 27.9084 30.0143 26.2134C31.7094 24.5185 32.6632 22.2205 32.6666 19.8234C32.6631 16.7459 31.1166 14.0229 28.7516 12.3849Z"
          fill="#424960"
        />
        <path
          d="M9.70911 28.87H16.6591V23.3H9.70911C9.21533 23.3001 8.72731 23.194 8.27811 22.989L7.27461 23.2965L4.48961 26.0815L4.24561 27.0215C5.81505 28.2188 7.7346 28.8669 9.70861 28.866L9.70911 28.87Z"
          fill="#5F6681"
        />
        <path
          d="M9.709 10.7849C7.31188 10.7882 5.01389 11.7419 3.31882 13.4369C1.62375 15.1318 0.669943 17.4298 0.666504 19.8269C0.666504 22.7634 2.073 25.3744 4.2495 27.0284L8.2815 22.9964C7.67121 22.7212 7.15325 22.2758 6.78973 21.7137C6.42621 21.1515 6.23256 20.4964 6.232 19.8269C6.232 17.9084 7.794 16.3499 9.709 16.3499C11.119 16.3499 12.331 17.1999 12.879 18.3999L16.911 14.3679C15.2575 12.1914 12.646 10.7849 9.7095 10.7849H9.709Z"
          fill="#5F6681"
        />
      </g>
      <defs>
        <clipPath id="clip0_4310_4074">
          <rect width="32" height="32" fill="white" transform="translate(0.666504)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const ActiveGoogleCloudIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
      <g clipPath="url(#clip0_4310_3942)">
        <path
          d="M21.0305 10.2439L22.0555 10.2614L24.8405 7.47638L24.9755 6.29638C22.7665 4.32838 19.85 3.12988 16.663 3.12988C10.893 3.12988 6.02304 7.05588 4.58154 12.3739C4.88554 12.1619 5.53554 12.3209 5.53554 12.3209L11.1005 11.4059C11.1005 11.4059 11.3865 10.9324 11.5315 10.9559C12.1823 10.241 12.9752 9.6699 13.8594 9.27925C14.7437 8.88861 15.6998 8.68701 16.6665 8.68738C18.3165 8.69088 19.8365 9.27388 21.0305 10.2384V10.2439Z"
          fill="#EA4335"
        />
        <path
          d="M28.7516 12.3849C28.1051 9.99989 26.7726 7.89389 24.9741 6.29639L21.0306 10.2399C22.6106 11.5149 23.6241 13.4659 23.6241 15.6499V16.3459C25.5426 16.3459 27.1011 17.9079 27.1011 19.8229C27.1011 21.7414 25.5391 23.2999 23.6241 23.2999H16.6701L15.9741 23.9999V28.1729L16.6701 28.8654H23.6241C26.0212 28.8621 28.3192 27.9084 30.0143 26.2134C31.7094 24.5185 32.6632 22.2205 32.6666 19.8234C32.6631 16.7459 31.1166 14.0229 28.7516 12.3849Z"
          fill="#4285F4"
        />
        <path
          d="M9.70911 28.87H16.6591V23.3H9.70911C9.21533 23.3001 8.72731 23.194 8.27811 22.989L7.27461 23.2965L4.48961 26.0815L4.24561 27.0215C5.81505 28.2188 7.7346 28.8669 9.70861 28.866L9.70911 28.87Z"
          fill="#34A853"
        />
        <path
          d="M9.709 10.7849C7.31188 10.7882 5.01389 11.7419 3.31882 13.4369C1.62375 15.1318 0.669943 17.4298 0.666504 19.8269C0.666504 22.7634 2.073 25.3744 4.2495 27.0284L8.2815 22.9964C7.67121 22.7212 7.15325 22.2758 6.78973 21.7137C6.42621 21.1515 6.23256 20.4964 6.232 19.8269C6.232 17.9084 7.794 16.3499 9.709 16.3499C11.119 16.3499 12.331 17.1999 12.879 18.3999L16.911 14.3679C15.2575 12.1914 12.646 10.7849 9.7095 10.7849H9.709Z"
          fill="#FBBC05"
        />
      </g>
      <defs>
        <clipPath id="clip0_4310_3942">
          <rect width="32" height="32" fill="white" transform="translate(0.666504)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export function GoogleCloudIcon({ isDisabled, ...props }: GoogleCloudIconProps) {
  return <>{isDisabled ? <DisabledGoogleCloudIcon /> : <ActiveGoogleCloudIcon />}</>;
}
