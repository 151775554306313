import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { axios } from 'app-zephyr-axios';

import { application_settings_environment_path } from 'app-zephyr-routes';
import {
  get_application_environment_list_path,
  GET_APPLICATION_ENVIRONMENT_LIST,
} from 'ze-api-contract/application-v2/application-environment/list';
import {
  GET_APPLICATION_ENVIRONMENT,
  get_application_environment_path,
} from 'ze-api-contract/application-v2/application-environment/get';
import {
  UpdateApplicationEnvironment,
  update_application_environment_path,
} from 'ze-api-contract/application-v2/application-environment/update';
import { delete_application_environment_path } from 'ze-api-contract/application-v2/application-environment/delete';
import {
  GET_APPLICATION_ENVIRONMENT_CONDITIONS,
  get_application_environment_condition_list_path,
  GetApplicationEnvironmentConditionListParams,
  EnvironmentConditionOptions,
} from 'ze-api-contract/application-v2/application-environment/get-condition-list';
import {
  CreateApplicationEnvironment,
  create_application_environment_path,
} from 'ze-api-contract/application-v2/application-environment/create';
import {
  GET_APPLICATION_ENVIRONMENT_CONDITION_VERSION,
  get_application_environment_condition_version_path,
  ConditionVersion,
  ConditionVersionQueryParams,
} from 'ze-api-contract/application-v2/application-environment/get-condition-version';
import { ApplicationEnvironment, Channel } from 'ze-api-contract/application-v2/application-environment/interfaces';

export interface GetApplicationEnvironmentListParams {
  organization?: string;
  project?: string;
  application?: string;
}

export interface GetApplicationEnvironmentParams {
  organization?: string;
  project?: string;
  application?: string;
  environment?: string;
}

export interface GetApplicationEnvironmentConditionVersionParams {
  organization?: string;
  project?: string;
  application?: string;
  queryParams: ConditionVersionQueryParams | null;
}

export const TYPE_LABELS: Record<Channel, string> = {
  [Channel.Version]: 'Version',
  [Channel.Tag]: 'Tag',
};

export const applicationEnvironmentQuery = createQueryKeys('application-environment', {
  getApplicationEnvironmentList: ({ organization, project, application }: GetApplicationEnvironmentListParams) => ({
    queryKey: [GET_APPLICATION_ENVIRONMENT_LIST, organization, project, application],
    queryFn: async () => {
      if (!organization || !project || !application) return Promise.resolve(undefined);
      return axios
        .get<{
          entities: ApplicationEnvironment[];
        }>(get_application_environment_list_path({ organization, project, application }))
        .then((res) => res.data.entities);
    },
  }),
  getApplicationEnvironmentConditionList: ({
    organization,
    project,
    application,
  }: GetApplicationEnvironmentConditionListParams) => ({
    queryKey: [GET_APPLICATION_ENVIRONMENT_CONDITIONS, organization, project, application],
    queryFn: async () => {
      if (!organization || !project || !application) return Promise.resolve(undefined);
      return axios
        .get<{
          value: EnvironmentConditionOptions;
        }>(get_application_environment_condition_list_path({ organization, project, application }))
        .then((res) => res.data.value);
    },
  }),
  getApplicationEnvironmentConditionVersion: ({
    organization,
    project,
    application,
    queryParams,
  }: GetApplicationEnvironmentConditionVersionParams) => ({
    queryKey: [
      GET_APPLICATION_ENVIRONMENT_CONDITION_VERSION,
      organization,
      project,
      application,
      JSON.stringify(queryParams),
      queryParams?.channel,
      queryParams?.value,
    ],
    queryFn: async () => {
      if (!queryParams) return Promise.resolve(undefined);

      if (!organization || !project || !application || !(queryParams.channel && queryParams.value)) {
        return Promise.resolve(undefined);
      }

      return axios
        .get<{
          value: ConditionVersion;
        }>(get_application_environment_condition_version_path({ organization, project, application }), {
          params: queryParams,
        })
        .then((res) => res.data.value);
    },
  }),
  getApplicationEnvironment: ({
    organization,
    project,
    application,
    environment,
  }: GetApplicationEnvironmentParams) => ({
    queryKey: [GET_APPLICATION_ENVIRONMENT, organization, project, application, environment],
    queryFn: async () => {
      if (!organization || !project || !application || !environment) return Promise.resolve(undefined);

      return axios
        .get<{
          value: ApplicationEnvironment;
        }>(get_application_environment_path({ organization, project, application, environment }))
        .then((res) => res.data.value);
    },
  }),
});

export function useApplicationEnvironmentList(params: GetApplicationEnvironmentListParams) {
  const {
    isLoading,
    data: environmentList,
    error,
  } = useQuery(applicationEnvironmentQuery.getApplicationEnvironmentList(params));

  return { environmentList, isLoading, error };
}

export function useCreateEnvironment(params: Required<GetApplicationEnvironmentListParams>) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { queryKey } = applicationEnvironmentQuery.getApplicationEnvironmentList(params);

  return useMutation({
    mutationFn: (environment: CreateApplicationEnvironment) =>
      axios.post<{ value: CreateApplicationEnvironment }>(create_application_environment_path(params), environment),
    onSuccess: async () => {
      // todo: when api will be implemented onSettled should be changed on onSuccess
      await queryClient.invalidateQueries({ queryKey });
      void navigate({
        to: application_settings_environment_path({
          organization: { name: params.organization },
          project: { name: params.project },
          application: { name: params.application },
        }),
      });
    },
  });
}

export function useApplicationEnvironment(params: GetApplicationEnvironmentParams) {
  const {
    isLoading,
    data: environment,
    error,
  } = useQuery(applicationEnvironmentQuery.getApplicationEnvironment(params));
  return { environment, isLoading, error };
}

export function useEditEnvironment(params: Required<GetApplicationEnvironmentParams>) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { queryKey } = applicationEnvironmentQuery.getApplicationEnvironmentList(params);

  return useMutation({
    mutationFn: (environment: UpdateApplicationEnvironment) =>
      axios.post<{ value: UpdateApplicationEnvironment }>(update_application_environment_path(params), environment),
    onSuccess: async () => {
      // todo: when api will be implemented onSettled should be changed on onSuccess
      await queryClient.invalidateQueries({ queryKey });
      void navigate({
        to: application_settings_environment_path({
          organization: { name: params.organization },
          project: { name: params.project },
          application: { name: params.application },
        }),
      });
    },
  });
}

export function useRemoveEnvironment(params: Required<GetApplicationEnvironmentParams>) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { queryKey } = applicationEnvironmentQuery.getApplicationEnvironmentList(params);

  return useMutation({
    mutationFn: () => axios.delete(delete_application_environment_path(params)),
    onSuccess: async () => {
      // todo: when api will be implemented onSettled should be changed on onSuccess
      await queryClient.invalidateQueries({ queryKey });
      void navigate({
        to: application_settings_environment_path({
          organization: { name: params.organization },
          project: { name: params.project },
          application: { name: params.application },
        }),
      });
    },
  });
}

export function useApplicationEnvironmentConditionList(params: GetApplicationEnvironmentConditionListParams) {
  const {
    isLoading,
    data: conditionList,
    error,
  } = useQuery(applicationEnvironmentQuery.getApplicationEnvironmentConditionList(params));

  return { conditionList, isLoading, error };
}

export function useApplicationEnvironmentConditionVersion(params: GetApplicationEnvironmentConditionVersionParams) {
  const {
    isLoading,
    data: conditionVersion,
    error,
  } = useQuery(applicationEnvironmentQuery.getApplicationEnvironmentConditionVersion(params));

  return { conditionVersion, isLoading, error };
}
