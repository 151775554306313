import { makeStyles } from 'tss-react/mui';

export const useProviderPageStyles = makeStyles()((theme) => ({
  pageContent: {
    '& code': {
      padding: 4,
      backgroundColor: theme.palette.bg.secondary.default,
      borderRadius: 8,
      color: theme.palette.tx.secondary.default,
    },

    '& strong': {
      fontWeight: 700,
      color: theme.palette.white,
    },
  },

  breadCrumbBox: {
    '& a, & .MuiBreadcrumbs-separator': {
      color: theme.palette.tx.secondary.default,
    },

    '& p': {
      fontWeight: 700,
    },
  },

  introBox: {
    padding: '16px 24px',
    borderRadius: 16,
    backgroundColor: theme.palette.bg.secondary.default,
    width: '100%',

    '& th': {
      width: '33.33%',
      fontSize: 14,
    },
  },

  title_32: {
    fontSize: 32,
    fontWeight: 700,
    lineHeight: '130%',
    textTransform: 'capitalize',
  },

  title_24: {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: '130%',
    color: theme.palette.tx.secondary.default,
    width: '100%',
  },

  title_16: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '130%',
    color: theme.palette.tx.secondary.default,
    width: '100%',
  },

  description_14: {
    fontSize: 14,
    color: theme.palette.tx.secondary.alt,
    margin: '0 !important',
  },

  description_16: {
    fontSize: 16,
    color: theme.palette.tx.secondary.alt,
    margin: 0,
  },

  link: {
    fontSize: 16,
    color: theme.palette.tx.brand.secondary,
  },

  imgBox: {
    borderRadius: '50%',
    backgroundColor: theme.palette.bg.primary.default,
  },
  providerBtn: {
    color: theme.palette.brand.purple[400],
  },
}));
