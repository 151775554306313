import { Role } from '../enums';
import { OrganizationValue } from '../organization-v2/get-by-name';
import { TokenPayload } from './token-payload';

export * from './token-payload';

export const GET_CURRENT_USER_PATH = '/v2/user/me';

export interface User {
  id: string;
  email: string;
  name: string;
  avatarColor?: string;
  portrait: string | null;
  emails?: string[];
  createdAt: Date;
  updatedAt: Date | null;
  lastAccess: Date | null;
  deletedAt: Date | null;
  theme: string;
  role?: Role;

  // TODO: implement on backend
  username?: string;
  location?: {
    country: string;
    city: string;
  };
  company?: string;
  personalEmail?: string;
  webpage?: string;
  socialAccounts?: {
    label: string;
    link: string;
  }[];
}

export interface CurrentUserInfo {
  auth0TokenPayload: TokenPayload;
  auth0?: {
    id: string;
    orgId?: string;
    email: string;
    name: string;
  };
  profile?: User;
  user: User;
  organization?: OrganizationValue;
  cacheKey?: string;
}
