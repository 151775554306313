import { SVGProps } from 'react';

export type CloudFlareIconProps = SVGProps<SVGSVGElement> & {
  isDisabled?: boolean;
};

export const DisabledCloudFlareIcon = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="24" fill="#11121A" />
      <path
        d="M38.2782 21.7817L34.1991 20.8348L33.4707 20.5435L14.7508 20.6891V29.7213L38.2782 29.7942V21.7817Z"
        fill="#848DAA"
      />
      <path
        d="M30.6299 28.92C30.7471 28.6097 30.7884 28.276 30.7504 27.9465C30.7124 27.6171 30.5962 27.3015 30.4114 27.0261C30.2216 26.7986 29.9888 26.6107 29.7264 26.4732C29.4639 26.3357 29.1769 26.2513 28.8818 26.2249L16.2076 26.0792C16.1347 26.0792 16.0619 26.0064 15.989 26.0064C15.9721 25.9936 15.9583 25.9771 15.9488 25.9582C15.9393 25.9392 15.9344 25.9183 15.9344 25.8971C15.9344 25.8759 15.9393 25.855 15.9488 25.836C15.9583 25.8171 15.9721 25.8006 15.989 25.7878C16.0619 25.6422 16.1347 25.5693 16.2804 25.5693L29.0274 25.4236C29.8367 25.3338 30.6074 25.0301 31.2603 24.5436C31.9132 24.0571 32.4248 23.4055 32.7423 22.6557L33.4707 20.7619C33.4707 20.689 33.5435 20.6162 33.4707 20.5433C33.0747 18.7795 32.1137 17.1931 30.7338 16.0252C29.3538 14.8574 27.6304 14.1719 25.8253 14.073C24.0202 13.9741 22.2322 14.4671 20.7329 15.4772C19.2336 16.4873 18.105 17.9592 17.5187 19.6693C16.7575 19.1256 15.829 18.8677 14.8964 18.9409C14.0406 19.0363 13.2427 19.42 12.6338 20.0289C12.0249 20.6378 11.6412 21.4357 11.5458 22.2915C11.4973 22.7296 11.5219 23.1726 11.6186 23.6026C10.2339 23.6409 8.91873 24.2179 7.9529 25.211C6.98706 26.204 6.44678 27.5347 6.44697 28.92C6.43502 29.1891 6.45952 29.4586 6.51981 29.7212C6.52316 29.7781 6.54726 29.8317 6.58753 29.872C6.62781 29.9123 6.68147 29.9364 6.73834 29.9397H30.12C30.2657 29.9397 30.4114 29.8669 30.4114 29.7212L30.6299 28.92Z"
        fill="#282D3E"
      />
      <path
        d="M34.6362 20.762H34.272C34.1991 20.762 34.1263 20.8348 34.0534 20.9076L33.5435 22.6558C33.4264 22.966 33.385 23.2998 33.4231 23.6292C33.4611 23.9587 33.5773 24.2743 33.7621 24.5497C33.9519 24.7772 34.1846 24.9651 34.4471 25.1026C34.7096 25.2401 34.9966 25.3244 35.2917 25.3509L37.9868 25.4966C38.0596 25.4966 38.1325 25.5694 38.2053 25.5694C38.2223 25.5821 38.2361 25.5986 38.2455 25.6176C38.255 25.6366 38.26 25.6575 38.26 25.6787C38.26 25.6999 38.255 25.7208 38.2455 25.7398C38.2361 25.7587 38.2223 25.7752 38.2053 25.7879C38.1325 25.9336 38.0596 26.0065 37.914 26.0065L35.146 26.1521C34.3368 26.2419 33.5661 26.5457 32.9132 27.0322C32.2602 27.5187 31.7487 28.1703 31.4312 28.9201L31.2855 29.5756C31.2127 29.6485 31.2855 29.7942 31.4312 29.7942H41.0461C41.0758 29.7984 41.106 29.7957 41.1345 29.7862C41.1629 29.7767 41.1888 29.7607 41.21 29.7395C41.2312 29.7183 41.2472 29.6925 41.2566 29.664C41.2661 29.6356 41.2689 29.6053 41.2646 29.5756C41.438 28.9585 41.5359 28.3225 41.556 27.6818C41.5444 25.8501 40.8117 24.0967 39.5165 22.8015C38.2212 21.5063 36.4678 20.7735 34.6362 20.762Z"
        fill="#424960"
      />
    </svg>
  );
};

export const ActiveCloudFlareIcon = () => {
  return (
    <svg width="36" height="20" viewBox="0 0 36 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame 1000004304">
        <g id="Group 1">
          <path
            id="Vector"
            d="M32.2781 7.7815L28.1991 6.83457L27.4707 6.54321L8.75073 6.68889V15.7211L32.2781 15.7939V7.7815Z"
            fill="white"
          />
          <path
            id="Vector_2"
            d="M24.63 14.9198C24.7472 14.6096 24.7885 14.2759 24.7505 13.9464C24.7125 13.617 24.5962 13.3014 24.4115 13.026C24.2217 12.7985 23.9889 12.6106 23.7264 12.4731C23.4639 12.3356 23.1769 12.2512 22.8818 12.2248L10.2076 12.0791C10.1348 12.0791 10.0619 12.0062 9.9891 12.0062C9.97214 11.9935 9.95837 11.977 9.94889 11.9581C9.93941 11.9391 9.93447 11.9182 9.93447 11.897C9.93447 11.8758 9.93941 11.8549 9.94889 11.8359C9.95837 11.8169 9.97214 11.8004 9.9891 11.7877C10.0619 11.642 10.1348 11.5692 10.2805 11.5692L23.0275 11.4235C23.8368 11.3337 24.6075 11.0299 25.2604 10.5435C25.9133 10.057 26.4248 9.40534 26.7424 8.65558L27.4708 6.76174C27.4708 6.6889 27.5436 6.61606 27.4708 6.54322C27.0748 4.77933 26.1138 3.19293 24.7338 2.02508C23.3539 0.857231 21.6304 0.171772 19.8253 0.0728636C18.0203 -0.0260451 16.2322 0.467004 14.733 1.47709C13.2337 2.48717 12.1051 3.95908 11.5187 5.66913C10.7575 5.12548 9.82905 4.86756 8.8965 4.94073C8.04068 5.03616 7.24277 5.41984 6.63387 6.02875C6.02496 6.63765 5.64128 7.43556 5.54585 8.29138C5.49735 8.72944 5.52196 9.17252 5.61869 9.60251C4.23395 9.64079 2.91879 10.2178 1.95296 11.2109C0.987124 12.2039 0.446841 13.5346 0.447034 14.9198C0.435081 15.189 0.459582 15.4585 0.519874 15.7211C0.523218 15.7779 0.547316 15.8316 0.587594 15.8719C0.627873 15.9122 0.681533 15.9363 0.738396 15.9396H24.1201C24.2658 15.9396 24.4115 15.8668 24.4115 15.7211L24.63 14.9198Z"
            fill="#F48120"
          />
          <path
            id="Vector_3"
            d="M28.6363 6.76172H28.2721C28.1993 6.76172 28.1264 6.83456 28.0536 6.9074L27.5437 8.65557C27.4265 8.9658 27.3852 9.29955 27.4232 9.629C27.4612 9.95844 27.5775 10.274 27.7622 10.5494C27.952 10.777 28.1848 10.9648 28.4473 11.1023C28.7097 11.2398 28.9967 11.3242 29.2919 11.3507L31.987 11.4963C32.0598 11.4963 32.1326 11.5692 32.2055 11.5692C32.2224 11.5819 32.2362 11.5984 32.2457 11.6174C32.2552 11.6363 32.2601 11.6572 32.2601 11.6784C32.2601 11.6996 32.2552 11.7205 32.2457 11.7395C32.2362 11.7585 32.2224 11.775 32.2055 11.7877C32.1326 11.9334 32.0598 12.0062 31.9141 12.0062L29.1462 12.1519C28.3369 12.2417 27.5662 12.5455 26.9133 13.032C26.2604 13.5184 25.7489 14.1701 25.4313 14.9198L25.2857 15.5754C25.2128 15.6482 25.2857 15.7939 25.4313 15.7939H35.0462C35.0759 15.7981 35.1062 15.7954 35.1346 15.7859C35.1631 15.7765 35.1889 15.7605 35.2101 15.7393C35.2313 15.7181 35.2473 15.6922 35.2568 15.6638C35.2663 15.6353 35.269 15.6051 35.2648 15.5754C35.4382 14.9582 35.536 14.3223 35.5561 13.6815C35.5446 11.8499 34.8118 10.0965 33.5166 8.80125C32.2214 7.50602 30.468 6.77326 28.6363 6.76172Z"
            fill="#FAAD3F"
          />
        </g>
      </g>
    </svg>
  );
};

export function CloudFlareIcon({ isDisabled, ...props }: CloudFlareIconProps) {
  return <>{isDisabled ? <DisabledCloudFlareIcon /> : <ActiveCloudFlareIcon />}</>;
}
