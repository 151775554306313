import { FormEvent } from 'react';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { Stack } from '@mui/material';

import { FormInput, FormCheckbox } from 'app-zephyr-forms';
import { Button } from 'app-zephyr-components/Button';
import {
  addNetlifyIntegrationSchemaForm,
  editNetlifyIntegrationSchemaForm,
} from 'ze-api-contract/deployment-integration/organization/validations';
import {
  CreateDeploymentIntegration,
  DeploymentIntegrationPlatform,
  DeploymentIntegrationType,
  UpdateDeploymentIntegration,
} from 'ze-api-contract/deployment-integration/organization/interfaces';
import { FormBox } from 'app-zephyr-components/FormBox';
import { TooltipWrapper } from 'app-zephyr-components/Tooltip-wrapper';

export type AddNetlifyIntegrationFormFields = {
  apiToken: string;
  name: string;
  siteId: string;
  displayName: string;
  isDefault: boolean;
} & Record<string, string | boolean>;

const defaultValue = {
  name: '',
  displayName: '',
  apiToken: '',
  siteId: '',
  isDefault: true,
};

interface AddNetlifyIntegrationFormProps {
  submitHandler: (value: CreateDeploymentIntegration | UpdateDeploymentIntegration) => void;
  formValue?: AddNetlifyIntegrationFormFields;
  onReset: () => void;
}

const AddNetlifyIntegrationForm = ({ submitHandler, formValue, onReset }: AddNetlifyIntegrationFormProps) => {
  const {
    register,
    formState: { errors, isValid, isDirty },
    reset,
    control,
    getValues,
  } = useForm<AddNetlifyIntegrationFormFields>({
    defaultValues: formValue ?? defaultValue,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: joiResolver(formValue ? editNetlifyIntegrationSchemaForm : addNetlifyIntegrationSchemaForm),
  });

  const submit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { name, displayName, siteId, apiToken, isDefault } = getValues();
    if (!formValue) {
      submitHandler({
        name,
        displayName,
        integrationConfig: {
          site_id: siteId,
          api_token: apiToken,
        },
        type: DeploymentIntegrationType.WORKER,
        platform: DeploymentIntegrationPlatform.NETLIFY,
        isDefault,
      });

      return;
    }

    submitHandler({
      displayName,
      integrationConfig: {
        api_token: apiToken,
      },
      isDefault: formValue.isDefault,
    });
  };

  const onResetHandler = () => {
    reset();
    onReset();
  };

  return (
    <form onSubmit={submit}>
      <Stack spacing={2}>
        <FormBox title={formValue ? `Edit ${formValue.displayName} integration` : 'Add new Netlify integration'}>
          <Stack spacing={1}>
            <FormInput
              id="netlify-name-integration"
              name={'name'}
              register={register}
              errors={errors}
              label="Integration name"
              disabled={!!formValue}
              placeholder="Fill Integration name"
            />
            <FormInput
              id="netlify-displayName-integration"
              name={'displayName'}
              register={register}
              errors={errors}
              label="Integration display name"
              placeholder="Fill Integration display name"
            />
            <Stack direction={'row'} spacing={2}>
              <FormInput
                id="netlify-api-token"
                name={'apiToken'}
                register={register}
                errors={errors}
                label="API token"
                placeholder={formValue ? 'API token is hidden, it can be only updated' : 'Fill an API token'}
              />
              <FormInput
                id="netlify-zone-id"
                disabled={!!formValue}
                name={'siteId'}
                register={register}
                errors={errors}
                label="Site id"
                placeholder="Fill Integration Site id"
              />
            </Stack>
          </Stack>
        </FormBox>
        {!formValue && (
          <FormBox>
            <Stack spacing={2}>
              <FormCheckbox
                id="isDefault"
                name="isDefault"
                label="Set integration as default"
                control={control}
                errors={errors}
              />
            </Stack>
          </FormBox>
        )}
      </Stack>
      <Stack display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} marginTop={2}>
        <TooltipWrapper name={'Navigate back to existing integration list.'}>
          <Button
            variant="outlined"
            onClick={() => {
              onResetHandler();
            }}
          >
            Cancel
          </Button>
        </TooltipWrapper>
        <Button disabled={!isValid || !isDirty} type="submit">
          Save changes
        </Button>
      </Stack>
    </form>
  );
};

export { AddNetlifyIntegrationForm };
