import { ReactNode, useEffect } from 'react';
import { useNavigate } from '@tanstack/react-router';
// routes
import { RouteNames } from 'app-zephyr-routes';

interface Props {
  children: ReactNode;
  can: boolean;
  loading?: boolean;
}

/**
 * Container component that redirects to the forbidden page if the user doesn't have access to the page.
 */
const ProtectedContainer = ({ children, can, loading }: Props) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!can && !loading) {
      void navigate({ to: RouteNames.FORBIDDEN, search: (p: never) => p, params: (p: never) => p });
    }
  }, [can, navigate]);

  return children;
};
/**
 * Component that renders its children only if the user has access to the page.
 */
const ProtectedComponent = ({ children, can }: Props) => {
  if (!can) return null;

  return children;
};

export { ProtectedContainer, ProtectedComponent };
