import { useQuery } from '@tanstack/react-query';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { axios } from 'app-zephyr-axios';

import {
  get_application_deployment_integration_list_path,
  APPLICATION_DEPLOYMENT_INTEGRATION_LIST,
} from 'ze-api-contract/deployment-integration/application/path';
import { DeploymentIntegrationValue } from 'ze-api-contract/deployment-integration/organization/interfaces';

export const applicationDeploymentIntegrationQuery = createQueryKeys('cloudProvider', {
  list: (organization?: string, project?: string, application?: string) => ({
    queryKey: [APPLICATION_DEPLOYMENT_INTEGRATION_LIST, organization, project, application],
    queryFn: async () => {
      if (!organization || !project || !application) return Promise.resolve(undefined);

      return axios
        .get<{
          entities: DeploymentIntegrationValue[];
        }>(get_application_deployment_integration_list_path({ organization, project, application }))
        .then((res) => res.data.entities);
    },
  }),
});

export function useApplicationDeploymentIntegrationList(
  organization: string | undefined,
  project: string | undefined,
  application: string | undefined,
) {
  const {
    data: applicationDeploymentIntegrationList,
    isLoading,
    error,
  } = useQuery(applicationDeploymentIntegrationQuery.list(organization, project, application));

  return { applicationDeploymentIntegrationList, isLoading, error };
}
