import Stack from '@mui/material/Stack';

import { FormBox } from 'app-zephyr-components/FormBox';
import { Button } from 'app-zephyr-components/Button';

import { useProviderPageStyles as useStyles } from '../../styles';
interface updateVersionBoxProps {
  latestVersion: string;
  isDisabled: boolean;
  updateWorkerVersionHandler: () => Promise<void>;
}

export function UpdateVersionBox({ latestVersion, isDisabled, updateWorkerVersionHandler }: updateVersionBoxProps) {
  const { classes } = useStyles();

  return (
    <Stack marginTop={3}>
      <FormBox>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <div>
            <span>
              A new <strong>{latestVersion}</strong> worker version is available.
              <br />
            </span>
            <span className={classes.description_14}>
              Update it in your integration to ensure proper functionality.
            </span>
          </div>

          <Button
            color="warning"
            disabled={isDisabled}
            onClick={() => {
              void updateWorkerVersionHandler();
            }}
          >
            Update Version
          </Button>
        </Stack>
      </FormBox>
    </Stack>
  );
}
