import { Role } from 'ze-api-contract/enums';
import { simplePath } from 'ze-api-contract/utils';

export const GET_ORGANIZATION_BY_NAME_PATH: string = '/v2/organization/:organization';

export function get_organization_by_name_path(params: GetOrganizationByNameParams): string {
  return simplePath(GET_ORGANIZATION_BY_NAME_PATH, params);
}

export interface GetOrganizationByNameParams {
  organization: string;
}

// this is most likely obsolete
type SubPlan = 'free' | 'team';

interface LocationOptions {
  location: string;
  locationVisible: boolean;
}

interface PrivacyOptions {
  isPrivate: boolean;
}

interface UrlLink {
  link: string;
  label: string;
}

export interface OrganizationValue {
  id: string;
  name: string;
  displayName: string;
  email: string;
  description: string;
  socialAccounts: UrlLink[];
  privacyOptions: PrivacyOptions;
  locationOptions: LocationOptions;
  createdAt: Date;
  updatedAt: Date | null;
  deletedAt: Date | null;
  domains: string[];
  defaultRole: Role;
  openMembership: boolean;
  stripeCustomerId?: string | null;
  enforceQuota?: boolean;
  currentSubPlan: SubPlan;
  currentStripeSubscriptionId: string | null;
  nextBillingPeriodStartAt: Date | null;
  scheduledSubPlan: SubPlan | null;
  scheduledStripeSubScheduleId: string | null;
  location?: string;
  logoImg?: string;
  avatarColor?: string;
  role?: Role;
}
