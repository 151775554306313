import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<{ options: number }>()(({ palette }, { options }) => ({
  input: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    '> label': {
      fontSize: 14,
      fontWeight: 600,
      color: palette.tx.secondary.default,
    },
    '.MuiFormControl-root': {
      backgroundColor: palette.bg.primary.default,
      borderColor: palette.border.primary,
      '.MuiInputBase-root': { background: palette.bg.primary.default },
    },
    '.MuiFormHelperText-root': {
      fontSize: 14,
      fontWeight: 700,
      fontStyle: 'italic',
      color: palette.tx.tertiary.default,
      '&.warning': { color: palette.tx.warning.primary },
    },
  },
  checkboxContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    alignItems: 'start',

    '.labelPrefix': {
      color: palette.tx.primary,
    },
    maxHeight: 320,
    minHeight: options > 8 ? 320 : options * 40,
    overflowY: 'auto',

    '::-webkit-scrollbar': {
      width: '0.4em',
    },
    '::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,0.1)',
      border: '1px solid slategrey',
      borderRadius: '10px',
    },
    '.MuiCheckbox-root': {
      padding: '0px 2px',
      width: 18,
      height: 18,
    },
  },
  checkBoxLabel: {
    display: 'flex',
    gap: 10,
    paddingLeft: '11px',
  },
  checked: {
    width: 18,
    height: 18,
  },

  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 16,
    width: '100%',

    button: {
      width: '45%',
    },
  },
}));
