import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme, _, classes: Record<string, string>) => ({
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: 780,
    backgroundColor: theme.palette.bg.secondary.default,
    borderRadius: theme.spacing(2),
    padding: 40,
    '&.warning:not(.customIcon)': {
      '.icon svg path': {
        fill: theme.palette.tx.warning.primary,
      },
    },
    '&.error:not(.customIcon)': {
      '.icon svg path': {
        fill: theme.palette.tx.error.primary,
      },
    },
  },
  header: {
    textAlign: 'center',
    svg: {
      width: 55,
      height: 56,
    },
    h3: {
      margin: `${theme.spacing(3).toString()} 0 ${theme.spacing(3).toString()}`,
      fontWeight: 700,
      fontSize: 32,
      lineHeight: '120%',
      color: theme.palette.tx.primary,
    },
  },
  footer: {
    display: 'flex',
    marginTop: 24,
    gap: 16,
    justifyContent: 'space-between',
    '.MuiButtonBase-root': {
      width: '100%',
      padding: '14px 16px',
      fontWeight: 700,
    },
  },
  confirmBtnTitle: {
    marginLeft: theme.spacing(1),
  },
  close: {
    position: 'absolute',
    top: 22,
    right: 22,
    color: theme.palette.tx.secondary.default,
    transition: theme.transitions.create(['color'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '&:hover': {
      color: theme.palette.tx.primary,
    },
    svg: {
      width: 30,
      height: 30,
    },
  },
  description: {
    textAlign: 'center',
    fontWeight: 500,
    fontSize: 18,
    color: theme.palette.tx.secondary.default,
    width: '100%',
    maxWidth: 500,
    margin: `${theme.spacing(3).toString()} auto ${theme.spacing(3).toString()}`,
  },
  content: {
    marginTop: theme.spacing(3.5),
  },
}));
