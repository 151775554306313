import { Role } from 'ze-api-contract/enums';
import { simplePath } from 'ze-api-contract/utils';

import { PageMetadata } from './get-application-list/interfaces';

export const GET_ORG_PEOPLE_LIST_PATH: string = `/v2/organization/:organization/people-list`;

export interface GetOrgPeopleListPath {
  organization: string;
}

export function get_org_people_list_path(params: GetOrgPeopleListPath) {
  return simplePath(GET_ORG_PEOPLE_LIST_PATH, params);
}

export interface PeopleListItem {
  id: string;
  name: string;
  email: string;
  role: Role;
  portrait?: string;
  username?: string;
}

export interface OrganizationUser {
  user: {
    id: string;
    name: string;
    email: string;
    username: string;
  };
  role: Role;
  organization: { privacyOptions?: { isPrivate: boolean } };
}

export interface GetOrgPeopleListResponse {
  entities: PeopleListItem[];
  peopleLimitExceeded: boolean;
  pageMetadata?: PageMetadata;
}

export interface GetPeopleListParams {
  organization?: string;
  initialPageParam?: number;
  perPage?: number;
  page?: number;
  name?: string;
}
