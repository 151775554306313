import React from 'react';
// @mui
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Typography from '@mui/material/Typography';
// types
import { IError } from './interfaces';
// styles
import { useStyles } from './styles';

/**
 * Error display content.
 * @param error error instance, uses as a render flag.
 * @param icon optional custom alert icon.
 * @param className optional root className.
 */
const Error: React.FC<IError> = ({ error, icon, className }) => {
  // const _error = useAsyncError();
  const { classes, cx } = useStyles();

  if (!error) return null;

  return (
    <div className={cx([classes.alertContent, className])}>
      {icon ?? <ErrorOutlineIcon />}
      <Typography variant="body1">{error.message}</Typography>
    </div>
  );
};

export { Error, Error as DisplayError };
