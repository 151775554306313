import { RouteNames } from './route-names';
import { simplePath } from 'ze-api-contract/utils';

const project_cache = new Map<string, string>();

interface ProjectProps {
  organization?: { name?: string };
  project?: { name?: string };
  query?: string;
}

function cache_me(path: string, { organization, project, query }: ProjectProps): string {
  if (!organization?.name || !project?.name) return '/';
  const cache_key = `${path}:${organization.name}:${project.name}:${query ?? ''}`;

  if (project_cache.has(cache_key)) {
    return project_cache.get(cache_key) ?? '/';
  }

  const params = { organization: organization.name, project: project.name };
  const url = simplePath(path, params) + (query ?? '');

  project_cache.set(cache_key, url);

  return url;
}

export function project_overview_path(props: ProjectProps): string {
  return cache_me(RouteNames.PROJECT_OVERVIEW, props);
}

export function project_application_list_path(props: ProjectProps): string {
  return cache_me(RouteNames.PROJECT_APPLICATION_LIST, props);
}

export function project_settings_general_path(props: ProjectProps): string {
  return cache_me(RouteNames.PROJECT_SETTINGS_GENERAL, props);
}

export function project_settings_collaborators_and_team_path(props: ProjectProps): string {
  return cache_me(RouteNames.PROJECT_SETTINGS_COLLABORATORS_AND_TEAM, props);
}

export function project_settings_deployment_integrations_path(props: ProjectProps): string {
  return cache_me(RouteNames.PROJECT_SETTINGS_DEPLOYMENT_INTEGRATION_LIST, props);
}
