import { InputLabel, InputLabelProps, Typography, TypographyProps } from '@mui/material';

interface LabelProps extends Omit<InputLabelProps, 'color'> {
  color?: TypographyProps['color'];
}

export const Label = ({ children, color = 'grey.400', ...props }: LabelProps) => {
  return (
    <InputLabel {...props}>
      <Typography color={color} variant="labelBoldSmall">
        {children}
      </Typography>
    </InputLabel>
  );
};
