import { simplePath } from 'ze-api-contract/utils';

export const PROJECT_DEPLOYMENT_INTEGRATION = 'v2/project/:organization/:project/deployment-integration';
export const PROJECT_DEPLOYMENT_INTEGRATION_LIST =
  'v2/project/:organization/:project/deployment-integrations/integration-list';
export const DEFAULT_PROJECT_DEPLOYMENT_INTEGRATION =
  'v2/project/:organization/:project/deployment-integrations/:integration';

export interface ProjectParams {
  organization: string;
  project: string;
}

export interface ProjectIntegrationParams {
  organization: string;
  project: string;
  integration: string;
}

export function get_project_deployment_integration_path(params: ProjectParams): string {
  return simplePath(PROJECT_DEPLOYMENT_INTEGRATION, params);
}

export function get_project_deployment_integration_list_path(params: ProjectParams): string {
  return simplePath(PROJECT_DEPLOYMENT_INTEGRATION_LIST, params);
}

export function post_set_default_project_deployment_integrations_path(params: ProjectIntegrationParams): string {
  return simplePath(DEFAULT_PROJECT_DEPLOYMENT_INTEGRATION, params);
}

export function delete_project_deployment_integrations_path(params: ProjectIntegrationParams): string {
  return simplePath(DEFAULT_PROJECT_DEPLOYMENT_INTEGRATION, params);
}
