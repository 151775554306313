import { useMemo, useState } from 'react';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Controller, useForm } from 'react-hook-form';

import { Input } from 'app-zephyr-forms';
import { CheckboxCheckedIcon } from 'app-zephyr-icons';
import { UserPinOption } from 'ze-api-contract/user-v2/user-pins/get-user-pin-options';
import { UserPin } from 'ze-api-contract/user-v2/user-pins/get-user-pins';
import { Button } from 'app-zephyr-components/Button';
import { useSaveUserPins } from 'app-zephyr-domains/user';
import { SaveUserPin } from 'ze-api-contract/user-v2/user-pins/save-user-pins';

import { useStyles } from './styles';
import { PinOptionsFormSkeleton } from './pin-options-form-skeleton';
import { PinOptionLabel } from './pin-option-label';

interface PinOptionsFormProps {
  pins: UserPin[];
  pinOptions: UserPinOption[];
  onClose: () => void;
  isLoading?: boolean;
}

interface PinOptionForm extends UserPinOption {
  selected?: boolean;
  notSearchMatch?: boolean;
}

const PinOptionsForm = ({ pins, pinOptions, onClose, isLoading }: PinOptionsFormProps) => {
  const { classes, theme } = useStyles({ options: pinOptions.length });
  const [searchValue, setSearchValue] = useState('');
  const { mutateAsync } = useSaveUserPins();
  const { control, formState, handleSubmit, watch } = useForm<{ pinOptions: PinOptionForm[] }>({
    defaultValues: { pinOptions: getDefaultPinOptions(pinOptions, pins) },
  });

  const formPinOptions = watch('pinOptions');
  const selectedOptions = formPinOptions.filter(({ selected }) => selected);
  const limitReached = selectedOptions.length >= 6;

  const onSubmit = handleSubmit(async (data?: { pinOptions: PinOptionForm[] }) => {
    const userPins: SaveUserPin[] =
      data?.pinOptions
        .filter((option) => option.selected)
        .map((option) => {
          if (option.type === 'organization') {
            return { type: option.type, organizationId: option.organization?.id };
          }
          if (option.type === 'project') {
            return { type: option.type, projectId: option.project?.id };
          }
          return { type: option.type, applicationId: option.application?.id };
        }) ?? [];
    await mutateAsync({ userPins });
    onClose();
  });

  const searchedOptions: PinOptionForm[] = useMemo(() => {
    if (!searchValue.length) return formPinOptions;

    const words = searchValue.toLowerCase().split(' ').filter(Boolean);
    return formPinOptions.map(({ organization, project, application, ...rest }) => {
      const compareString = `${organization?.displayName ?? ''} ${project?.displayName ?? ''} ${
        application?.displayName ?? ''
      }`.toLowerCase();
      const notSearchMatch = !words.every((word) => compareString.includes(word));
      return { organization, project, application, notSearchMatch, ...rest };
    });
  }, [formPinOptions, searchValue]).sort((a, b) => Number(a.notSearchMatch) - Number(b.notSearchMatch));

  if (isLoading) return <PinOptionsFormSkeleton />;

  return (
    <>
      <div className={classes.input}>
        <label htmlFor="pinName">Find applications, projects or organizations</label>
        <Input
          id="pinName"
          name="pinName"
          value={searchValue}
          disabled={formState.isSubmitting}
          onChange={({ target: { value } }) => {
            setSearchValue(value);
          }}
        />
        <FormHelperText className={limitReached ? 'warning' : ''}>
          {limitReached
            ? 'You have selected the maximum quantity'
            : `${(6 - selectedOptions.length).toString()} remaining`}
        </FormHelperText>
      </div>
      <form
        onSubmit={() => {
          void onSubmit();
        }}
      >
        <div className={classes.checkboxContainer}>
          {searchedOptions.map((pinOption, index) => (
            <Controller
              key={index}
              /* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */
              name={`pinOptions.${index}.selected`}
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  className={classes.checkBoxLabel}
                  sx={{ ...(pinOption.notSearchMatch && { opacity: 0.5 }) }}
                  disabled={formState.isSubmitting || (!field.value && limitReached)}
                  control={
                    <Checkbox
                      {...field}
                      checkedIcon={
                        <div className={classes.checked}>
                          <CheckboxCheckedIcon color={theme.palette.brand.turquoise[300]} />{' '}
                        </div>
                      }
                      checked={field.value}
                      // disableRipple
                    />
                  }
                  label={<PinOptionLabel {...pinOption} />}
                />
              )}
            />
          ))}
        </div>
        <div className={classes.buttonGroup}>
          <Button fullWidth variant="outlined" onClick={onClose} disabled={formState.isSubmitting}>
            Cancel
          </Button>
          <Button fullWidth type="submit" disabled={formState.isSubmitting || !formState.isDirty}>
            Save pinned
          </Button>
        </div>
      </form>
    </>
  );
};

const getDefaultPinOptions = (pinOptions: UserPinOption[], pins: UserPin[]): PinOptionForm[] => {
  return pinOptions.map((pinOption) => ({
    ...pinOption,
    selected: isPinOptionSelected(pinOption, pins),
  }));
};

const isPinOptionSelected = (pinOption: UserPinOption, pins: UserPin[]) => {
  return pins.some((pin) => {
    if (pinOption.type !== pin.type) return false;
    if (pinOption.type === 'organization') return pinOption.organization?.id === pin.organization.id;
    if (pinOption.type === 'project') return pinOption.project?.id === pin.project?.id;
    return pinOption.application?.id === pin.application?.id;
  });
};

export { PinOptionsForm };
