import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

import { useProviderPageStyles as useStyles } from '../../styles';

export function DeploymentIntegrationSkeleton() {
  const { classes } = useStyles();

  return (
    <Stack spacing={2}>
      <Stack spacing={1} direction={'row'} alignItems={'center'}>
        <Skeleton width={60} height={15} /> &#10094;
        <Skeleton width={60} height={15} /> &#10094;
        <Skeleton width={60} height={15} />
      </Stack>
      <Stack className={classes.introBox} direction={'row'} spacing={2}>
        <Stack
          justifyContent={'center'}
          alignItems={'center'}
          padding={'8px'}
          width={48}
          height={48}
          className={classes.imgBox}
        >
          <Skeleton variant="circular" />
        </Stack>
        <Stack direction={'column'} spacing={1}>
          <Skeleton variant="rounded" height={32} width={200} />
          <Skeleton variant="rounded" height={14} width={200} />
        </Stack>
        <Stack alignItems={'flex-end'} flexGrow={1} justifyContent={'center'}>
          <Skeleton variant="rounded" height={50} width={100} />
        </Stack>
      </Stack>
      <Stack className={classes.introBox} spacing={2}>
        <Skeleton width={200} height={20} variant="rounded" />
        <Skeleton width={200} height={20} variant="rounded" />
        <Skeleton width={200} height={20} variant="rounded" />
        <Skeleton width={200} height={20} variant="rounded" />
        <Skeleton variant="rounded" height={50} width={100} />
      </Stack>
      <Stack spacing={1}>
        {Array.from({ length: 15 }).map((_, index) => (
          <Skeleton key={`skeleton_${index.toString()}`} width={'100%'} height={20} variant="rounded" />
        ))}
      </Stack>
    </Stack>
  );
}
