import { simplePath } from 'ze-api-contract/utils';
import * as Joi from 'joi';
import {
  NotificationsActivity,
  PrivacyOptions,
  ProfileSettingsEmailOptionsBodyReq,
  notificationUpdateList,
} from './interfaces';

export const POST_EMAIL_OPTIONS = 'v2/user-profile-settings/email-options';

export function post_email_options_path(): string {
  return simplePath(POST_EMAIL_OPTIONS, {});
}

export const schema = Joi.object<ProfileSettingsEmailOptionsBodyReq>({
  privacyOptions: Joi.object<PrivacyOptions>({
    isPrivate: Joi.boolean().required(),
  }),
  notificationsActivity: Joi.object<NotificationsActivity>({
    value: Joi.string()
      .valid(...notificationUpdateList)
      .required(),
    label: Joi.string().required(),
    receiveNotifications: Joi.boolean().required(),
  }),
  additionalEmails: Joi.array()
    .items(Joi.string().email({ tlds: false }))
    .required(),
}).required();
