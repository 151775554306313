import { FormControlLabel } from '@mui/material';
import { Path, FieldValues, Control, FieldErrors, FieldName } from 'react-hook-form';
import { ErrorMessage, FieldValuesFromFieldErrors } from '@hookform/error-message';
import { InputProps, ErrorMessage as FormErrorMessage, FormCheckboxElement } from '../../_elements';
import { useStyles } from './styles';

export interface FormCheckboxProps<FormValuesProps extends FieldValues> extends Omit<InputProps, 'name'> {
  /**
   * input and error name
   */
  name: Path<FormValuesProps>;
  /**
   * react-hook-form control
   */
  control: Control<FormValuesProps>;
  /**
   * errors object
   */
  errors?: FieldErrors<FormValuesProps>;
}

const FormCheckbox = <FormValuesProps extends Record<string, unknown>>({
  name,
  errors,
  className,
  label,
  control,
}: FormCheckboxProps<FormValuesProps>) => {
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.container, className)} aria-live="polite" data-testid="form-checkbox">
      <FormControlLabel label={label} control={<FormCheckboxElement name={name} control={control} />} />
      <ErrorMessage
        errors={errors}
        name={name as unknown as FieldName<FieldValuesFromFieldErrors<FieldErrors<FormValuesProps>>>}
        render={({ message }) => <FormErrorMessage className={'error-message'}>{message}</FormErrorMessage>}
      />
    </div>
  );
};

export { FormCheckbox };
