import { useMutation, useQueryClient } from '@tanstack/react-query';

import { axios } from 'app-zephyr-axios';

import { teamQuery } from './team';
import { teamMemberQuery } from './get-team-member-list';

interface AddMemberData {
  membersIds: string[];
}

interface AddMembersParams {
  teamId: string;
  data: AddMemberData;
  queryParams?: { name?: string };
}

export function useAddMembers() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ teamId, data, queryParams }: AddMembersParams) => axios.post(`/v2/team/${teamId}/member`, data),
    onSuccess: async (res, { teamId, queryParams }) => {
      await queryClient.invalidateQueries(teamQuery.getById(teamId));
      await queryClient.invalidateQueries(teamMemberQuery.list(teamId, queryParams));
    },
  });
}
