import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useQuery } from '@tanstack/react-query';
import { User } from 'ze-api-contract/user-v2/get-current-user';
import { OrganizationValue } from 'ze-api-contract/organization-v2/get-by-name';
import { getCanManageOrgConfig, canMangeConfigKey, CanManageOrgConfig } from 'app-zephyr-guards/org';
import { useCurrentUser } from './current-user';

export interface CanManageOrgParams {
  organization: OrganizationValue | undefined;
  user: User | undefined;
}

export const queryKeys = createQueryKeys('user-can-manage-org', {
  can: ({ organization, user }: CanManageOrgParams) => ({
    queryKey: [canMangeConfigKey, organization, user],
    queryFn: async () => {
      if (!organization || !user)
        return {
          updateOrganization: false,
          updateOrganizationName: false,
          updateOrganizationSettings: false,
          deleteOrganization: false,
        } as CanManageOrgConfig;
      return getCanManageOrgConfig(organization, user);
    },
  }),
});

export function useUserCanManageOrg(params: Omit<CanManageOrgParams, 'user'>) {
  const { user } = useCurrentUser();
  const { isLoading, data: can, error } = useQuery(queryKeys.can({ ...params, user }));

  return {
    can,
    isLoading,
    error,
  };
}
