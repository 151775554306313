import {
  NX_ENV,
  NX_PUBLIC_CORE_API_BASE_URL,
  NX_PUBLIC_USE_SENTRY,
  NX_PUBLIC_SENTRY_DSN,
  NX_PUBLIC_SENTRY_RELEASE,
} from './env-from-process';
import { envValue } from 'app-zephyr-environment';

envValue.value = {
  env: NX_ENV ?? 'production',
  production: true,
  coreApiBaseUrl: NX_PUBLIC_CORE_API_BASE_URL ?? 'https://api.zephyr-cloud.io/',
  GTM_ID: 'G-B7G266JZDH',
  auth0: {
    domain: 'https://auth.zephyr-cloud.io',
    clientId: 'IdueDw0bnBoCPfXSmYkMgawtDKXkkChN',
    scope: 'openid profile email',
  },
  stripePkKey:
    'pk_live_51NpB1QEI2sUkNL2sFUiQ8TuDTiAa6LKZArCUYI9fTANXSd6tljPD4XMwxUPFh6Jk6yYcmJaOKQQrYNoyQjOqj6wq00wrUo3aLl',
  stripePrices: {
    personal: '',
    standard: '',
    users: '',
  },
  cerbosHttpUrl: 'https://zephyr-cerbos-prod.zephyr-cloud.io',
  useSentry: NX_PUBLIC_USE_SENTRY != null ? Boolean(NX_PUBLIC_USE_SENTRY) : false,
  sentryDsn: NX_PUBLIC_SENTRY_DSN ?? '',
  sentryRelease: NX_PUBLIC_SENTRY_RELEASE ?? '',
};
