import { simplePath } from '../utils';

export const GET_USER_APPLICATION_LIST_PATH = '/v2/user/:userEmail/application-list';

export type UserApplicationListQueryType = 'all' | 'created_by_me' | 'public' | 'private' | 'removed';
export type UserApplicationListQuerySort = 'name_asc' | 'name_desc' | 'latest_updates';
export interface GetUserApplicationListQueryParams {
  type?: UserApplicationListQueryType;
  sort?: UserApplicationListQuerySort;
  name?: string;
  limit?: number;
  offset?: number;
  latestDays?: number;
}

export interface GetUserApplicationListParams {
  userEmail: string;
}

export function get_user_application_list_path(params: GetUserApplicationListParams): string {
  return simplePath(GET_USER_APPLICATION_LIST_PATH, params);
}

export interface UserApplicationListItem {
  id: string;
  name: string;
  description: string;
  contributors: {
    id: string;
    name: string;
    // todo: implement on backend
    avatarColor?: string;
    //todo: should be added type member | team to know which link should be generated on ui https://github.com/ZephyrCloudIO/zephyr-cloud-io/issues/557
  }[];
  organization: {
    id: string;
    name: string;
    displayName: string;
  };
  project: {
    id: string;
    name: string;
    displayName: string;
    visibility: 'public' | 'private';
    updatedAt: string;
    deletedAt: string;
    contributors: {
      id: string;
      name: string;
      avatarColor?: string;
      portrait?: string;
    }[];
  };
  tags: string[];
  deletedAt: string;
  updatedAt: string;
  counters: {
    remotes?: number;
  };
  //todo: should be implemented https://github.com/ZephyrCloudIO/zephyr-cloud-io/issues/555
  hostPlatform: string;
}
