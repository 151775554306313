import { SVGProps } from 'react';

export type CheckboxCheckedIconProps = SVGProps<SVGSVGElement> & {
  color?: string;
};

export const CheckboxCheckedIcon = ({ color = '#4172BD', ...props }: CheckboxCheckedIconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
    <rect width="18" height="18" rx="4" fill={color} />
    <path
      d="M3.61108 10.5327L7.77776 13.8447L15.8565 5.27344"
      stroke="#0B0A09"
      strokeWidth="2.08696"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
