import React from 'react';
// @mui
import { CircularProgress, Stack } from '@mui/material';
// styles
import { useStyles } from './styles';
/**
 * Spinner component (absolute centerize by the default).
 */
const Loading: React.FC<{ className?: string }> = ({ className }) => {
  const { classes, cx } = useStyles();
  return (
    <Stack alignItems="center" className={cx(classes.root, className)}>
      <CircularProgress />
    </Stack>
  );
};

export { Loading };
