import { CerbosProjectActions, CerbosProjectKind } from 'ze-api-contract/organization-v2/cerbos-actions';
import { OrganizationValue } from 'ze-api-contract/organization-v2/get-by-name';
import { User } from 'ze-api-contract/user-v2/get-current-user';
import { Role } from 'ze-api-contract/enums/role';
import { AttributeValue, getDecision } from '../decision';

const getOrgRole = (role: Role | undefined): string => `organization_${role ?? 'viewer'}`;

const checkActions = async (
  organization: OrganizationValue,
  user: User,
  actions: CerbosProjectActions[],
): Promise<boolean[]> => {
  const decision = await getDecision({
    principal: {
      id: user.id,
      roles: [getOrgRole(organization.role)],
    },
    resource: {
      kind: CerbosProjectKind,
      id: organization.id,
      attributes: {
        oldData: {},
        newData: {},
        organization: organization as unknown as AttributeValue,
      },
    },
    actions,
  });

  return actions.map((action) => decision.isAllowed(action) ?? false);
};

export const canMangeConfigKey = [
  'canManageProject',
  CerbosProjectActions.UPDATE_PROJECT,
  CerbosProjectActions.UPDATE_PROJECT_NAME,
  CerbosProjectActions.UPDATE_PROJECT_SETTINGS,
  CerbosProjectActions.DELETE_PROJECT,
  CerbosProjectActions.MANAGE_CONTRIBUTOR,
  CerbosProjectActions.MANAGE_ALL_CONTRIBUTOR,
].join('/');

export const canMangeContributorConfigKey = ['canManageProject', CerbosProjectActions.MANAGE_CONTRIBUTOR].join('/');
export const canMangeAllContributorConfigKey = ['canManageProject', CerbosProjectActions.MANAGE_ALL_CONTRIBUTOR].join(
  '/',
);

export interface CanManageProjConfig {
  updateProject: boolean;
  updateProjectName: boolean;
  updateProjectSettings: boolean;
  deleteProject: boolean;
  manageContributor: boolean;
  manageAllContributor: boolean;
}

export interface CanManageConfig {
  can: boolean;
}

export const getCanManageProjConfig = async (
  organization: OrganizationValue,
  user: User,
): Promise<CanManageProjConfig> => {
  const [
    updateProject,
    updateProjectName,
    updateProjectSettings,
    deleteProject,
    manageContributor,
    manageAllContributor,
  ] = await checkActions(organization, user, [
    CerbosProjectActions.CREATE_PROJECT,
    CerbosProjectActions.UPDATE_PROJECT,
    CerbosProjectActions.UPDATE_PROJECT_NAME,
    CerbosProjectActions.UPDATE_PROJECT_SETTINGS,
    CerbosProjectActions.DELETE_PROJECT,
    CerbosProjectActions.MANAGE_CONTRIBUTOR,
    CerbosProjectActions.MANAGE_ALL_CONTRIBUTOR,
  ]);
  return {
    updateProject,
    updateProjectName,
    updateProjectSettings,
    deleteProject,
    manageContributor,
    manageAllContributor,
  };
};

export const getCanManageContributorConfig = async (
  organization: OrganizationValue,
  user: User,
): Promise<CanManageConfig> => {
  const [can] = await checkActions(organization, user, [CerbosProjectActions.MANAGE_CONTRIBUTOR]);
  return {
    can,
  };
};

export const getCanManageAllContributorConfig = async (
  organization: OrganizationValue,
  user: User,
): Promise<CanManageConfig> => {
  const [can] = await checkActions(organization, user, [CerbosProjectActions.MANAGE_ALL_CONTRIBUTOR]);
  return {
    can,
  };
};
