import { WorkerVersionResp } from 'ze-api-contract/worker-version';
import { DeploymentIntegrationValue } from 'ze-api-contract/deployment-integration/organization/interfaces';

export const getWorkerVersion = ({
  workerVersionList,
  integration,
}: {
  workerVersionList?: WorkerVersionResp;
  integration: DeploymentIntegrationValue | Partial<DeploymentIntegrationValue>;
}): string => {
  if (!workerVersionList || !integration.platform) return '';
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const res = workerVersionList[integration.platform]?.find(
    (version) => integration.workerVersionId === version.id,
  )?.version;
  return res ?? '';
};
