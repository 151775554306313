import { useMutation, useQueryClient } from '@tanstack/react-query';
import { axios } from 'app-zephyr-axios';
// hooks
import { organizationQuery } from 'app-zephyr-domains/organization/organization';
import { get_org_rud_path, UpdateOrganizationValue } from 'ze-api-contract/organization-v2/crud-organization';

interface UpdateOrganizationSettings extends UpdateOrganizationValue {
  id: string;
}

/**
 * POST update organization.
 **/
export function useUpdateOrganizationSettings(organization: string | undefined) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, ...settings }: UpdateOrganizationSettings) =>
      axios.post<{ value: UpdateOrganizationValue }>(get_org_rud_path({ id }), settings).then((res) => res.data.value),
    onSuccess: async () => {
      await queryClient.invalidateQueries(organizationQuery.byName(organization));
    },
  });
}
