import { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';

import { PillBtn } from 'app-zephyr-components/Pill-button';

import { useStyles } from './styles';

interface FilterPillsProps {
  items: {
    label: string;
    value: string;
  }[];
  active: string;
  onActiveChangedHandler: (value: unknown) => void;
  title: string;
}

export const FilterPills = ({ items, active, onActiveChangedHandler, title }: FilterPillsProps) => {
  const { classes } = useStyles();
  const [activeItem, setActiveItem] = useState(active);
  // changing argument active doesn't trigger re-rendering
  useEffect(() => {
    setActiveItem(active);
  }, [active]);

  return (
    <Stack spacing={1}>
      <h3 className={classes.title}>{title}</h3>
      <Stack direction={'row'} spacing={1}>
        {items.map((item) => (
          <PillBtn
            key={`filter-btn-${item.value}`}
            onClick={() => {
              setActiveItem(item.value);
              onActiveChangedHandler(item.value);
            }}
            label={item.label}
            isActive={item.value === activeItem}
          />
        ))}
      </Stack>
    </Stack>
  );
};
