import { simplePath } from 'ze-api-contract/utils';
import { Channel, Condition, ConditionValue } from './interfaces';

export const GET_APPLICATION_ENVIRONMENT_CONDITIONS =
  '/v2/application/:organization/:project/:application/environment/condition/options';

export interface GetApplicationEnvironmentConditionListParams {
  organization: string;
  project: string;
  application: string;
}

export function get_application_environment_condition_list_path(
  params: GetApplicationEnvironmentConditionListParams,
): string {
  return simplePath(GET_APPLICATION_ENVIRONMENT_CONDITIONS, params);
}

export interface EnvironmentConditionOptions {
  [Channel.Tag]: ConditionValue[];
  [Channel.Version]: ConditionValue[];
}

export const defaultSelectedCondition: Condition = {
  channel: Channel.Tag,
  value: {
    value: '',
    label: '',
  },
};
