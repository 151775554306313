import { type ReactNode, Suspense } from 'react';
// components
import { Loading } from '../Loading';

interface ILazyLoadedComponent {
  /**
   * lazy loaded component
   */
  component?: ReactNode;
  children?: ReactNode;
}

/**
 * Lazy loaded component renderer with a loader fallback.
 * @note - each lazy component should have a skeleton loader and not generic loader if possible
 */
export function LazyLoadedComponent({ component, children }: ILazyLoadedComponent): ReactNode {
  if (!component && !children) return null;
  return <Suspense fallback={<Loading />}>{component ?? children}</Suspense>;
}
