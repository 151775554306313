import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useQuery } from '@tanstack/react-query';

import { axios } from 'app-zephyr-axios';
import {
  GET_USER_PROJECT_LIST_PATH,
  GetUserProjectListQueryParams,
  UserProjectListItem,
  get_user_project_list_path,
} from 'ze-api-contract/user-v2/get-user-project-list';

interface UserProjectListParams {
  userEmail?: string;
  queryParams?: GetUserProjectListQueryParams;
}

export const userProjectListQuery = createQueryKeys('user', {
  projectList: ({ userEmail, queryParams }: UserProjectListParams) => ({
    queryKey: [GET_USER_PROJECT_LIST_PATH, userEmail, queryParams],
    queryFn: async () => {
      // TODO: change in other queries too because using undefined results in dev error
      if (!userEmail) return Promise.resolve(null);

      return axios
        .get<{ entities: UserProjectListItem[] }>(get_user_project_list_path({ userEmail }), {
          params: queryParams,
        })
        .then((res) => res.data.entities);
    },
  }),
});

export const useUserProjectList = (params: UserProjectListParams) => {
  const { data: projectList, isLoading, error } = useQuery(userProjectListQuery.projectList(params));

  return { projectList, isLoading, error };
};
