import { get } from 'lodash';
import { UseFormRegister, Path, FieldValues, FieldName, FieldErrors } from 'react-hook-form';
import { ErrorMessage, FieldValuesFromFieldErrors } from '@hookform/error-message';
import { ErrorMessage as FormErrorMessage, TextField, TextFieldProps } from '../../_elements';
import { useStyles } from './styles';
import { Label } from '../../_elements/label';
import { useRef } from 'react';
import React from 'react';

export interface FormTextAreaProps<FormValuesProps extends FieldValues> extends Omit<TextFieldProps, 'name'> {
  /**
   * input and error name
   */
  name: Path<FormValuesProps>;
  /**
   * react-hook-form registering control
   */
  register: UseFormRegister<FormValuesProps>;
  /**
   * errors object
   */
  errors?: FieldErrors<FormValuesProps>;
}

const FormTextArea = <FormValuesProps extends Record<string, unknown>>({
  name,
  register,
  errors,
  className,
  label,
  ...props
}: FormTextAreaProps<FormValuesProps>): React.JSX.Element => {
  const { classes, cx } = useStyles();
  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);

  const { ref, ...rest } = register(name);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleLabelClick = () => {
    inputRef.current?.focus();
  };

  const setRefs = (element: HTMLInputElement | null) => {
    ref(element);
    inputRef.current = element;
  };

  return (
    <div className={cx([className, classes.container])} aria-live="polite" data-testid="form-input">
      <Label onClick={handleLabelClick}>
        <p className={classes.label}>{label}</p>
      </Label>
      <TextField aria-invalid={hasError} {...props} {...rest} ref={setRefs} />
      <ErrorMessage
        errors={errors}
        name={name as unknown as FieldName<FieldValuesFromFieldErrors<FieldErrors<FormValuesProps>>>}
        render={({ message }) => <FormErrorMessage className={'error-message'}>{message}</FormErrorMessage>}
      />
    </div>
  );
};

export { FormTextArea };
