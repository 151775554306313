import { SVGProps } from 'react';

export type CheckmarkIconProps = SVGProps<SVGSVGElement> & {
  color?: string;
};

export const CheckmarkIcon = ({ color = '#fff', ...props }: CheckmarkIconProps) => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M20.6177 5.83302C20.4986 5.71297 20.357 5.61769 20.2009 5.55266C20.0448 5.48764 19.8774 5.45416 19.7084 5.45416C19.5393 5.45416 19.3719 5.48764 19.2158 5.55266C19.0597 5.61769 18.9181 5.71297 18.799 5.83302L9.25712 15.3877L5.24826 11.366C5.12463 11.2466 4.9787 11.1527 4.81878 11.0897C4.65887 11.0267 4.48811 10.9958 4.31625 10.9987C4.1444 11.0017 3.97481 11.0385 3.81717 11.107C3.65953 11.1755 3.51693 11.2744 3.39752 11.398C3.2781 11.5217 3.1842 11.6676 3.12118 11.8275C3.05816 11.9874 3.02725 12.1582 3.03023 12.33C3.0332 12.5019 3.06999 12.6715 3.13851 12.8291C3.20702 12.9868 3.30591 13.1294 3.42954 13.2488L8.34776 18.167C8.46683 18.2871 8.60848 18.3823 8.76456 18.4474C8.92064 18.5124 9.08804 18.5459 9.25712 18.5459C9.4262 18.5459 9.59361 18.5124 9.74968 18.4474C9.90576 18.3823 10.0474 18.2871 10.1665 18.167L20.6177 7.71578C20.7477 7.59584 20.8515 7.45028 20.9224 7.28826C20.9934 7.12624 21.03 6.95128 21.03 6.7744C21.03 6.59752 20.9934 6.42256 20.9224 6.26054C20.8515 6.09852 20.7477 5.95295 20.6177 5.83302Z"
      fill={color}
    />
  </svg>
);
