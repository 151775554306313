import * as Joi from 'joi';
import { simplePath } from 'ze-api-contract/utils';
import { Condition, ConditionValue } from './interfaces';
import {
  baseSchema,
  baseApplicationVersionId,
  formApplicationVersionId,
  baseCustomDNSNames,
  channelTypeSchema,
  conditionValueSchema,
  formSchemaCondition,
  formCustomDnsNames,
} from './validation';

interface CreateApplicationEnvironmentParams {
  organization: string;
  project: string;
  application: string;
}

export const CREATE_APPLICATION_ENVIRONMENT = '/v2/application/:organization/:project/:application/environment';

export function create_application_environment_path(params: CreateApplicationEnvironmentParams): string {
  return simplePath(CREATE_APPLICATION_ENVIRONMENT, params);
}

interface BaseCreateApplicationEnvironment {
  name: string;
  applicationVersionId: string;
  description: string;
}

export interface CreateApplicationEnvironment extends BaseCreateApplicationEnvironment {
  condition: Condition;
  customDnsNames?: string[];
}

export interface CreateApplicationEnvironmentForm extends BaseCreateApplicationEnvironment {
  condition: {
    type: ConditionValue;
    value: ConditionValue;
  };
  customDnsNames?: string;
}

export const schema = baseSchema.append<CreateApplicationEnvironment>({
  applicationVersionId: baseApplicationVersionId,
  condition: Joi.object({
    channel: channelTypeSchema,
    value: conditionValueSchema,
  }),
  customDnsNames: baseCustomDNSNames,
});

export const formSchema = baseSchema.append<CreateApplicationEnvironmentForm>({
  applicationVersionId: formApplicationVersionId,
  condition: formSchemaCondition,
  customDnsNames: formCustomDnsNames,
});
