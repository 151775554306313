import { FilterPills } from 'app-zephyr-components/Filter-pills-buttons';

export enum FILTER_ITEMS {
  ENABLED = 'enabled',
  AVAILABLE = 'available',
  UPCOMING = 'upcoming',
}

const filterItems = [
  { label: 'Enabled', value: FILTER_ITEMS.ENABLED },
  { label: 'Available', value: FILTER_ITEMS.AVAILABLE },
  { label: 'Upcoming', value: FILTER_ITEMS.UPCOMING },
];

interface FilterDeploymentIntegrationListProps {
  onFilterIsChangedHandler: (value: FILTER_ITEMS) => void;
  active: FILTER_ITEMS;
}

export const FilterDeploymentIntegrationList = ({
  onFilterIsChangedHandler,
  active,
}: FilterDeploymentIntegrationListProps) => {
  return (
    <FilterPills
      title={'Integrations'}
      active={active}
      onActiveChangedHandler={onFilterIsChangedHandler}
      items={filterItems}
    />
  );
};
