import { SVGProps } from 'react';

export type CheckboxOutlinedIconProps = SVGProps<SVGSVGElement> & {
  color?: string;
};

export const CheckboxOutlinedIcon = ({ color = '#11121A', ...props }: CheckboxOutlinedIconProps) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="18" height="18" rx="3" fill={color} />
    <rect x="1" y="1" width="18" height="18" rx="3" stroke="#53576C" strokeWidth="2" />
  </svg>
);
