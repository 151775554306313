import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';

import { FormContainer } from '../../_elements';
import { useStyles } from './styles';

export const ApplicationEnvironmentFormSkeleton = ({ title }: { title: string }) => {
  const { classes } = useStyles();

  return (
    <>
      <FormContainer title={title}>
        <Stack spacing={3}>
          <Stack>
            <Skeleton width={80} height={20} />
            <Skeleton width="100%" height={45} />
          </Stack>
          <Stack>
            <span className={classes.title}>Environment conditions</span>
          </Stack>
          <Stack direction={'row'} spacing={3}>
            <Stack width="45%">
              <Skeleton width={80} height={20} />
              <Skeleton width="100%" height={45} />
            </Stack>
            <Stack width="45%">
              <Skeleton width={80} height={20} />
              <Skeleton width="100%" height={45} />
            </Stack>
          </Stack>
          <Stack>
            <Skeleton width={80} height={20} />
            <Skeleton width="100%" height={45} />
          </Stack>
          <Stack>
            <Skeleton width={80} height={20} />
            <Skeleton width="100%" height={150} />
          </Stack>
        </Stack>
      </FormContainer>
      <Stack spacing={2} direction={'row'} justifyContent={'flex-end'} marginTop={2}>
        <Skeleton width={130} height={40} />
        <Skeleton width={200} height={40} />
      </Stack>
    </>
  );
};
