import { useMemo } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { Stack, IconButton } from '@mui/material';
import { joiResolver } from '@hookform/resolvers/joi';

import { Button } from 'app-zephyr-components/Button';
import { FormInput, FormAutocomplete } from 'app-zephyr-forms';
import { FormBox } from 'app-zephyr-components/FormBox';
import { TrashIcon } from 'app-zephyr-icons/Trash';
import {
  CreateUnmanagedApplication,
  UpdateUnmanagedApplication,
} from 'ze-api-contract/unmanaged-application-v2/interfaces';
import { createUnmanagedApplicationFormSchema } from 'ze-api-contract/unmanaged-application-v2/validation-rules';
import { TooltipWrapper } from 'app-zephyr-components/Tooltip-wrapper';

import { useStyles } from './styles';

export interface ProjectItem {
  label: string;
  value: string;
}

export interface EnvironmentItem {
  name: string;
  remote_entry_url: string;
  remote_host: string;
}

export enum FormType {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

export interface UnmanagedAppForm {
  name: string;
  displayName: string;
  projectId: ProjectItem | null;
  environments: EnvironmentItem[];
}

export type UnmanagedAppFormFields = UnmanagedAppForm & Record<string, string>;

interface UnmanagedAppFormProps {
  onCreate?: (value: CreateUnmanagedApplication) => void;
  onEdit?: (value: UpdateUnmanagedApplication) => void;
  onReset: () => void;
  app: UnmanagedAppForm;
  projectList: ProjectItem[];
  type: FormType;
  canManage: boolean;
}

const UnmanagedAppFormBox = ({
  app,
  onCreate,
  onEdit,
  projectList,
  type,
  canManage,
  onReset,
}: UnmanagedAppFormProps) => {
  const {
    register,
    control,
    getValues,
    setValue,
    trigger,
    formState: { isValid, errors, isDirty },
    reset,
    watch,
  } = useForm<UnmanagedAppFormFields>({
    defaultValues: app as UnmanagedAppFormFields,
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: joiResolver(createUnmanagedApplicationFormSchema),
  });

  const { fields, append, remove } = useFieldArray<UnmanagedAppFormFields>({
    control,
    name: 'environments' as never,
  });

  const { classes } = useStyles();
  const environmentsWatch = watch();

  const isAddEnvDisabled = useMemo(() => {
    const environments = getValues().environments;
    return environments.some((item) => !item.name || !item.remote_entry_url || !item.remote_host);
  }, [environmentsWatch]);

  const onSubmitHandler = () => {
    type === FormType.EDIT ? onEditHandler() : onCreateHandler();
  };

  const onCreateHandler = () => {
    const data = getValues();
    if (!data.projectId || !onCreate) return;

    const req = {
      projectId: data.projectId.value,
      name: data.name,
      environments: data.environments,
      displayName: data.displayName,
    };

    onCreate(req);
  };

  const onEditHandler = () => {
    const data = getValues();
    if (!data.projectId || !onEdit) return;

    const req = {
      projectId: data.projectId.value,
      environments: data.environments,
      displayName: data.displayName,
    };

    onEdit(req);
  };

  const onCancel = () => {
    reset();
    onReset();
  };

  return (
    <form onSubmit={onSubmitHandler}>
      <Stack spacing={2}>
        <FormBox>
          <Stack spacing={2} alignItems={'flex-end'}>
            <FormAutocomplete
              id="form-autocomplete"
              trigger={trigger}
              setValue={setValue}
              control={control}
              name="projectId"
              options={projectList}
              errors={errors}
              label="Project"
            />
            <FormInput
              id="name"
              name="name"
              label="Name"
              register={register}
              errors={errors}
              placeholder="Fill the unmanaged application name"
              disabled={type === FormType.EDIT}
            />
            <FormInput
              id="displayName"
              name="displayName"
              label="Display name"
              register={register}
              errors={errors}
              placeholder="Fill the unmanaged application display name"
            />
            {fields.map((item, index) => {
              return (
                <Stack spacing={2} key={item.id} width={'100%'} className={classes.environmentBox}>
                  <Stack
                    spacing={2}
                    direction={'row'}
                    width={'100%'}
                    alignItems={errors.environments?.[index] ? 'center' : 'flex-end'}
                  >
                    <FormInput
                      id="name"
                      name={`environments.${index.toString()}.name`}
                      label="Environment"
                      register={register}
                      errors={errors}
                      placeholder="Fill the environment name"
                    />

                    {fields.length > 1 && (
                      <IconButton
                        color={'error'}
                        sx={{ width: 'fit-content', height: 'fit-content' }}
                        onClick={() => {
                          remove(index);
                        }}
                      >
                        <TrashIcon width={24} height={24} />
                      </IconButton>
                    )}
                  </Stack>

                  <Stack spacing={2} direction={'row'} width={'100%'}>
                    <FormInput
                      id="name"
                      name={`environments.${index.toString()}.remote_host`}
                      label="Remote host"
                      register={register}
                      errors={errors}
                      placeholder="Fill the remote host"
                    />
                    <FormInput
                      id="name"
                      name={`environments.${index.toString()}.remote_entry_url`}
                      label="Remote entry url"
                      register={register}
                      errors={errors}
                      placeholder="Fill the remote entry url"
                    />
                  </Stack>
                </Stack>
              );
            })}
            <Button
              color={'secondary'}
              sx={{ width: 'fit-content' }}
              disabled={isAddEnvDisabled}
              onClick={() => {
                append({
                  name: '',
                  remote_host: '',
                  remote_entry_url: '',
                });
              }}
            >
              Add environment
            </Button>
          </Stack>
        </FormBox>
      </Stack>
      <Stack display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} alignItems={'baseline'} spacing={1}>
        <TooltipWrapper name={'Navigate back to existing unmanaged application list.'}>
          <Button variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
        </TooltipWrapper>
        {/* todo: inline style bad approach here, but don't understand why sapcing doesn't work */}
        <Button style={{ marginLeft: '8px' }} disabled={canManage ? !isDirty || !isValid : true} type="submit">
          Save Changes
        </Button>
      </Stack>
    </form>
  );
};

export { UnmanagedAppFormBox };
