import { useEffect, FormEvent } from 'react';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { FormInput } from 'app-zephyr-forms';
import { createTeamValidation } from 'ze-api-contract/organization-v2/validation';

export type CreateTeamConfirmFormFields = { name: string } & Record<string, string>;

interface CreateTeamConfirmFormProps {
  isFormValid: (value: boolean) => void;
  onFormSubmit: (value: CreateTeamConfirmFormFields) => void;
  formValueIsChanged: (value: CreateTeamConfirmFormFields) => void;
}

const CreateTeamConfirmForm = ({ isFormValid, onFormSubmit, formValueIsChanged }: CreateTeamConfirmFormProps) => {
  const {
    getValues,
    register,
    formState: { errors, isValid, isDirty },
    watch,
  } = useForm<CreateTeamConfirmFormFields>({
    defaultValues: { name: '' },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: joiResolver(createTeamValidation),
  });

  const { name: nameWatch } = watch();

  useEffect(() => {
    const value = getValues();
    formValueIsChanged(value);
  }, [nameWatch]);

  useEffect(() => {
    isFormValid(isValid && isDirty);
  }, [isDirty, isFormValid, isValid]);

  const onSubmitHandler = (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    const value = getValues();
    onFormSubmit(value);
  };

  return (
    <form
      onSubmit={(ev) => {
        onSubmitHandler(ev);
      }}
    >
      <FormInput id="user-name" name={'name'} register={register} errors={errors} label="Team Name" />
    </form>
  );
};

export { CreateTeamConfirmForm };
