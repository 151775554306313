import { useMutation, useQueryClient } from '@tanstack/react-query';

import { axios } from 'app-zephyr-axios';
import { orgTeamList } from '../organization';

interface DeleteTeamParams {
  teamId: string;
  organization: string;
}

export function useDeleteTeam() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ teamId }: DeleteTeamParams) => axios.delete(`/v2/team/${teamId}`),
    onSuccess: async (_res, { organization }) => {
      return queryClient.invalidateQueries({
        queryKey: orgTeamList.teamList({ organization }).queryKey.slice(0, -1),
      });
    },
  });
}
