import { simplePath } from 'ze-api-contract/utils';

export const ORG_DEPLOYMENT_INTEGRATIONS = 'v2/organization/:organization/deployment-integration';
export const ORG_DEPLOYMENT_INTEGRATION_LIST = 'v2/organization/:organization/deployment-integration/list';
export const ORG_DEPLOYMENT_INTEGRATION = 'v2/organization/:organization/deployment-integration/:integration';
export const ORG_DEPLOYMENT_INTEGRATION_REDEPLOY =
  'v2/organization/:organization/deployment-integration/:integration/redeploy';
export const ORG_DEPLOYMENT_INTEGRATION_DEFAULT =
  'v2/organization/:organization/deployment-integration/:integration/default';
export const ORG_ENABLE_DISABLE_DEPLOYMENT_INTEGRATION =
  'v2/organization/:organization/deployment-integration/:integration/enabled';
export const ORG_DEPLOYMENT_INTEGRATION_CHANGE_WORKER_VERSION =
  'v2/organization/:organization/deployment-integration/:integration/worker';

export interface OrganizationIntegrationsParams {
  organization: string;
}

export interface OrganizationIntegrationParams {
  organization: string;
  integration: string;
}

export function create_org_integrations_path(params: OrganizationIntegrationsParams): string {
  return simplePath(ORG_DEPLOYMENT_INTEGRATIONS, params);
}

export function get_org_integration_list_path(params: OrganizationIntegrationsParams): string {
  return simplePath(ORG_DEPLOYMENT_INTEGRATION_LIST, params);
}

export function get_org_integration_path(params: OrganizationIntegrationParams): string {
  return simplePath(ORG_DEPLOYMENT_INTEGRATION, params);
}

export function update_org_integrations_path(params: OrganizationIntegrationParams): string {
  return simplePath(ORG_DEPLOYMENT_INTEGRATION, params);
}

export function redeploy_org_integrations_path(params: OrganizationIntegrationParams): string {
  return simplePath(ORG_DEPLOYMENT_INTEGRATION_REDEPLOY, params);
}

export function set_default_org_integrations_path(params: OrganizationIntegrationParams): string {
  return simplePath(ORG_DEPLOYMENT_INTEGRATION_DEFAULT, params);
}

export function delete_default_org_integrations_path(params: OrganizationIntegrationParams): string {
  return simplePath(ORG_DEPLOYMENT_INTEGRATION_DEFAULT, params);
}

export function enable_disable_org_integrations_path(params: OrganizationIntegrationParams): string {
  return simplePath(ORG_ENABLE_DISABLE_DEPLOYMENT_INTEGRATION, params);
}

export function delete_org_integrations_path(params: OrganizationIntegrationParams): string {
  return simplePath(ORG_DEPLOYMENT_INTEGRATION, params);
}

export const CLOUDFLARE_ZONE_LIST = 'v2/organization/deployment-integration/cloudflare/zone-list';

export interface CloudflareZoneListParams {
  api_token: string;
}

export function get_cloudflare_zone_list_path(params: CloudflareZoneListParams): string {
  return simplePath(CLOUDFLARE_ZONE_LIST, params);
}

export const NETLIFY_SITE_LIST = 'v2/organization/deployment-integration/netlify/site-list';

export interface NetlifySiteListParams {
  api_token: string;
}

export function get_netlify_site_list_path(params: NetlifySiteListParams): string {
  return simplePath(NETLIFY_SITE_LIST, params);
}

export const ENABLED_DEPLOYMENT_INTEGRATION_LIST = 'v2/organization/:organization/deployment-integration/enabled';

export function get_enabled_deployment_integration_list_path(params: OrganizationIntegrationsParams): string {
  return simplePath(ENABLED_DEPLOYMENT_INTEGRATION_LIST, params);
}

export function update_worker_version_path(params: { organization: string; integration: string }): string {
  return simplePath(ORG_DEPLOYMENT_INTEGRATION_CHANGE_WORKER_VERSION, params);
}
