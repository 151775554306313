import { ProjectVisibility, UserPinType } from 'ze-api-contract/enums';
import { simplePath } from 'ze-api-contract/utils';

export const GET_USER_PINS_PATH = '/v2/user-pins/:username';

export interface GetUserPinsParams {
  username: string | undefined;
}

export function get_user_pins_path(params: GetUserPinsParams) {
  return simplePath(GET_USER_PINS_PATH, params);
}
export interface UserPin {
  id: string;
  cloudProvider: string;
  organization: BasicInfo;
  project?: BasicInfo;
  application?: BasicInfo;
  type: UserPinType;
  description?: string;
  counters: {
    applications?: number;
    remotes: number;
  };
}

interface BasicInfo {
  id: string;
  name: string;
  displayName: string;
  visibility?: ProjectVisibility;
}
