import { ReactNode } from 'react';
import { Tooltip, Stack } from '@mui/material';

interface TooltipWrapperProps {
  name: string;
  children: ReactNode;
}

export const TooltipWrapper = ({ name, children }: TooltipWrapperProps) => {
  return (
    <Tooltip title={name} arrow placement="top">
      <Stack>{children}</Stack>
    </Tooltip>
  );
};
