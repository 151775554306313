import { Stack } from '@mui/material';

import {
  DeploymentIntegrationConfigValue,
  DeploymentIntegrationPlatform,
  CloudflareIntegration,
  NetlifyIntegration,
  NetlifyDeploymentConfig,
  DeploymentConfig,
  FastlyIntegration,
} from 'ze-api-contract/deployment-integration/organization/interfaces';

import { useStyles } from './styles';

export const ItemDetails = ({
  integrationConfig,
  deploymentConfig,
  type,
}: {
  integrationConfig: DeploymentIntegrationConfigValue | undefined;
  deploymentConfig: DeploymentConfig | undefined;
  type: DeploymentIntegrationPlatform;
}) => {
  if (type === DeploymentIntegrationPlatform.CLOUDFLARE) {
    return <CloudflareItemDetails config={integrationConfig as CloudflareIntegration} />;
  }

  if (type === DeploymentIntegrationPlatform.NETLIFY) {
    return (
      <NetlifyItemDetails
        integrationConfig={integrationConfig as NetlifyIntegration}
        deploymentConfig={deploymentConfig as NetlifyDeploymentConfig}
      />
    );
  }

  if (type === DeploymentIntegrationPlatform.FASTLY) {
    return <FastlyItemDetails integrationConfig={integrationConfig as FastlyIntegration} />;
  }

  return <></>;
};

const CloudflareItemDetails = ({ config }: { config: CloudflareIntegration }) => {
  const { classes } = useStyles();

  return (
    <Stack spacing={0.5}>
      {config.account_id && (
        <p className={classes.item}>
          Account id -{' '}
          <span className={classes.value}>
            XXXX...{config.account_id.slice(config.account_id.length - 6, config.account_id.length)}
          </span>
        </p>
      )}
      {config.zone_id && (
        <p className={classes.item}>
          Zone id -{' '}
          <span className={classes.value}>
            XXXX...{config.zone_id.slice(config.zone_id.length - 6, config.zone_id.length)}
          </span>
        </p>
      )}
      {config.zone_name && (
        <p className={classes.item}>
          Zone name - <span className={classes.value}>{config.zone_name}</span>
        </p>
      )}
      {config.projectName && (
        <p className={classes.item}>
          Project name - <span className={classes.value}>{config.projectName}</span>
        </p>
      )}
    </Stack>
  );
};

const NetlifyItemDetails = ({
  integrationConfig,
  deploymentConfig,
}: {
  integrationConfig: NetlifyIntegration | undefined;
  deploymentConfig: NetlifyDeploymentConfig | undefined;
}) => {
  const { classes } = useStyles();

  return (
    <Stack spacing={0.5}>
      {integrationConfig?.site_name && (
        <p className={classes.item}>
          Site name - <span className={classes.value}>{integrationConfig.site_name}</span>
        </p>
      )}
      {integrationConfig?.site_id && (
        <p className={classes.item}>
          Site id -{' '}
          <span className={classes.value}>
            XXXX...
            {integrationConfig.site_id.slice(integrationConfig.site_id.length - 6, integrationConfig.site_id.length)}
          </span>
        </p>
      )}
      {deploymentConfig?.domain && (
        <p className={classes.item}>
          Domain - <span className={classes.value}>{deploymentConfig.domain}</span>
        </p>
      )}
      {deploymentConfig?.accountName && (
        <p className={classes.item}>
          Account - <span className={classes.value}>{deploymentConfig.accountName}</span>
        </p>
      )}
    </Stack>
  );
};

const FastlyItemDetails = ({ integrationConfig }: { integrationConfig: FastlyIntegration | undefined }) => {
  const { classes } = useStyles();

  return (
    <>
      {integrationConfig?.domain && (
        <p className={classes.item}>
          Domain - <span className={classes.value}>{integrationConfig.domain}</span>
        </p>
      )}
    </>
  );
};
