import { joiResolver } from '@hookform/resolvers/joi';
import { useForm } from 'react-hook-form';

import { membershipFormSchema } from 'ze-api-contract/organization-v2/settings/update-org-settings';

import { FormSwitch } from '../../controls/form-switch';

export type EnableDisableIntegrationFormFields = { isEnabled: boolean } & Record<string, boolean>;

interface EnableDisableIntegrationFormProps {
  isEnabled: boolean;
  isDisabled: boolean;
  valueIsChanged: (value: boolean) => void | Promise<void>;
}

export const EnableDisableIntegrationForm = ({
  isEnabled,
  isDisabled,
  valueIsChanged,
}: EnableDisableIntegrationFormProps) => {
  const { control } = useForm<EnableDisableIntegrationFormFields>({
    defaultValues: { isEnabled },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: joiResolver(membershipFormSchema),
  });

  return (
    <form>
      <FormSwitch
        id={'isEnabled-control'}
        control={control}
        name="isEnabled"
        disabled={isDisabled}
        onChange={(event: boolean) => {
          void valueIsChanged(event);
        }}
      />
    </form>
  );
};
