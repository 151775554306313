import { useMutation, useQueryClient } from '@tanstack/react-query';
import { axios } from 'app-zephyr-axios';
// hooks
import { projectDeploymentIntegrationQuery } from './get-project-deployment-list';
import { post_set_default_project_deployment_integrations_path } from 'ze-api-contract/deployment-integration/project/path';

export function useSetDefaultProjectCloudflareIntegration(organization: string, project: string) {
  const queryClient = useQueryClient();
  const { queryKey } = projectDeploymentIntegrationQuery.list(organization, project);

  return useMutation({
    mutationFn: (integration: string) =>
      axios.post(post_set_default_project_deployment_integrations_path({ organization, project, integration })),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey });
    },
  });
}
