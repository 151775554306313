import { SVGProps } from 'react';

export type TrashIconProps = SVGProps<SVGSVGElement> & {
  color?: string;
};

export const TrashIcon = ({ color = '#FF7B7B', ...props }: TrashIconProps) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 4.5H12V3.75C12 3.15326 11.763 2.58096 11.341 2.159C10.919 1.73703 10.3467 1.5 9.75 1.5H8.25C7.65326 1.5 7.08096 1.73703 6.659 2.159C6.23703 2.58096 6 3.15326 6 3.75V4.5H3C2.8011 4.5 2.61031 4.57901 2.46968 4.71964C2.32901 4.86035 2.25 5.05106 2.25 5.25C2.25 5.44894 2.32901 5.63965 2.46968 5.78037C2.61031 5.92099 2.8011 6 3 6H3.75V14.25C3.75 14.8467 3.98708 15.419 4.409 15.841C4.83096 16.263 5.40326 16.5 6 16.5H12C12.5967 16.5 13.169 16.263 13.591 15.841C14.013 15.419 14.25 14.8467 14.25 14.25V6H15C15.1989 6 15.3897 5.92099 15.5303 5.78037C15.671 5.63965 15.75 5.44894 15.75 5.25C15.75 5.05106 15.671 4.86035 15.5303 4.71964C15.3897 4.57901 15.1989 4.5 15 4.5ZM7.5 3.75C7.5 3.55106 7.57901 3.36035 7.71968 3.21964C7.86031 3.07901 8.0511 3 8.25 3H9.75C9.9489 3 10.1397 3.07901 10.2803 3.21964C10.421 3.36035 10.5 3.55106 10.5 3.75V4.5H7.5V3.75ZM12.75 14.25C12.75 14.4489 12.671 14.6396 12.5303 14.7804C12.3897 14.921 12.1989 15 12 15H6C5.8011 15 5.61031 14.921 5.46968 14.7804C5.32901 14.6396 5.25 14.4489 5.25 14.25V6H12.75V14.25ZM6.75 8.25C6.75 7.83582 7.08577 7.5 7.5 7.5C7.91423 7.5 8.25 7.83582 8.25 8.25V12.75C8.25 13.1642 7.91423 13.5 7.5 13.5C7.08577 13.5 6.75 13.1642 6.75 12.75V8.25ZM10.5 7.5C10.0858 7.5 9.75 7.83582 9.75 8.25V12.75C9.75 13.1642 10.0858 13.5 10.5 13.5C10.9142 13.5 11.25 13.1642 11.25 12.75V8.25C11.25 7.83582 10.9142 7.5 10.5 7.5Z"
      fill={color}
    />
  </svg>
);
