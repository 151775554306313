import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { createQueryKeys } from '@lukemorales/query-key-factory';

import {
  get_organization_by_name_path,
  GET_ORGANIZATION_BY_NAME_PATH,
  OrganizationValue,
} from 'ze-api-contract/organization-v2/get-by-name';
import { axios } from 'app-zephyr-axios';
import { get_org_settings_path } from 'ze-api-contract/organization-v2/settings/get-org-settings';
import { get_org_name_path } from 'ze-api-contract/organization-v2/settings/change-org-name';
import { OrganizationPublicSettings, OrganizationCounters } from 'ze-api-contract/organization-v2/settings/interfaces';
import { organization_path } from 'app-zephyr-routes';
import { GET_ORG_COUNTERS, get_org_counters_path } from 'ze-api-contract/organization-v2/settings/get-counters';

export const organizationQuery = createQueryKeys('organization', {
  byName: (organization?: string) => ({
    queryKey: [GET_ORGANIZATION_BY_NAME_PATH, organization],
    queryFn: async () => {
      if (!organization) return Promise.resolve(undefined);

      return axios
        .get<{ value: OrganizationValue }>(get_organization_by_name_path({ organization }))
        .then((res) => res.data.value);
    },
  }),
  counters: (organization?: string) => ({
    queryKey: [GET_ORG_COUNTERS, organization],
    queryFn: async () => {
      if (!organization) return Promise.resolve(undefined);

      return axios
        .get<{ value: OrganizationCounters }>(get_org_counters_path({ organization }))
        .then((res) => res.data.value);
    },
  }),
});

/**
 * Get organization by organization name.
 **/
export function useOrganizationByName(orgName: string | undefined) {
  const { data: organization, isLoading, error } = useQuery(organizationQuery.byName(orgName));

  return { organization, isLoading, error };
}

export function useOrganizationCounters(orgName: string | undefined) {
  const { data: orgCounters, isLoading, error } = useQuery(organizationQuery.counters(orgName));

  return { orgCounters, isLoading, error };
}

export function useUpdateOrganizationPublicSettings({ organization }: { organization: string }) {
  const queryClient = useQueryClient();
  const { queryKey } = organizationQuery.byName(organization);

  return useMutation({
    mutationFn: (val: OrganizationPublicSettings) => axios.post(get_org_settings_path({ organization }), val),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey });
    },
  });
}

export function useDeleteOrganization({ organization }: { organization: string }) {
  const queryClient = useQueryClient();
  const { queryKey } = organizationQuery.byName(organization);
  const navigate = useNavigate();

  return useMutation({
    mutationFn: () => axios.delete(get_organization_by_name_path({ organization })),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey });
      void navigate({ to: '/', search: (p: never) => p, params: (p: never) => p });
    },
  });
}

export function useRenameOrganization({ organization }: { organization: string }) {
  const queryClient = useQueryClient();
  const { queryKey } = organizationQuery.byName(organization);
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (val: { name: string }) => axios.post(get_org_name_path({ organization }), val),
    onSuccess: async (res, { name }) => {
      await queryClient.invalidateQueries({ queryKey });
      void navigate({ to: organization_path({ organization: { name } }) });
    },
  });
}
