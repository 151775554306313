import { Stack, Breadcrumbs, TableCell, TableRow, Table, TableBody } from '@mui/material';

import { Button } from 'app-zephyr-components/Button';
import { CloudFlareIcon } from 'app-zephyr-icons/Cloudflare';
import { OpenExternal } from 'app-zephyr-icons/OpenExternal';
import { getBreadCrumbList } from 'app-zephyr-deployment-integrations';
import {
  DeploymentIntegrationPlatform,
  DeploymentIntegrationValue,
} from 'ze-api-contract/deployment-integration/organization/interfaces';

import { useProviderPageStyles as useStyles } from '../../styles';
import { ItemDetails } from '../item-details';

interface CloudflareContentProps {
  breadCrumbList: {
    title: string;
    href?: string;
  }[];
  addIntegrationHandler: () => void;
  editIntegrationHandler: () => void;
  removeIntegrationHandler: () => void;
  setAsDefault?: () => void;
  integration: DeploymentIntegrationValue | undefined;
}

export const CloudflareContent = ({
  breadCrumbList,
  addIntegrationHandler,
  editIntegrationHandler,
  removeIntegrationHandler,
  integration,
  setAsDefault,
}: CloudflareContentProps) => {
  const { classes } = useStyles();

  return (
    <Stack spacing={2} className={classes.pageContent}>
      <Breadcrumbs separator="&#10094;" aria-label="breadcrumb" className={classes.breadCrumbBox}>
        {getBreadCrumbList(breadCrumbList)}
      </Breadcrumbs>

      <Stack className={classes.introBox} direction={'row'} spacing={2}>
        <Stack
          justifyContent={'center'}
          alignItems={'center'}
          padding={'8px'}
          width={48}
          height={48}
          className={classes.imgBox}
        >
          <CloudFlareIcon />
        </Stack>
        <Stack direction={'column'} spacing={1}>
          <span className={classes.title_32}>{integration?.displayName ?? 'Cloudflare'}</span>
          <span className={classes.description_14}>
            Regain control while connecting and protecting your people, apps and data everywhere.
          </span>
        </Stack>
        <Stack alignItems={'flex-end'} flexGrow={1} justifyContent={'center'}>
          {!integration?.name && (
            <Button className={classes.providerBtn} onClick={addIntegrationHandler}>
              Add Integration
            </Button>
          )}
          {!!integration?.name && (
            <Stack direction={'row'} spacing={1}>
              <Button onClick={editIntegrationHandler} sx={{ width: 100 }}>
                Edit
              </Button>
              <Button color="error" onClick={removeIntegrationHandler} sx={{ width: 100 }}>
                Remove
              </Button>
            </Stack>
          )}
        </Stack>
      </Stack>
      {!!integration && (
        <Stack className={classes.introBox} spacing={2}>
          <ItemDetails
            integrationConfig={integration.integrationConfig}
            deploymentConfig={integration.deploymentConfig}
            type={DeploymentIntegrationPlatform.CLOUDFLARE}
          />
          {!integration.isDefault && (
            <Button onClick={setAsDefault} variant="outlined" sx={{ width: 'fit-content' }}>
              Set as default
            </Button>
          )}
        </Stack>
      )}

      <Stack spacing={3}>
        <Stack spacing={2}>
          <span className={classes.title_24}>About</span>
          <p className={classes.description_16}>
            Cloudflare integrates with content management systems, control panels, cloud providers, eCommerce platforms
            and more.
          </p>
        </Stack>

        <Stack spacing={2}>
          <span className={classes.title_24}>
            A connectivity cloud improves agility, reduces risk, and cuts IT costs
          </span>
          <p className={classes.description_16}>
            Better control of IT and security environments delivers more value to the business. Better control means
            quicker adoption of new tech requirements. It means establishing more effective security policies and
            responding faster when novel attacks strike. And it means less money spent on duplicating tools and the
            people required to manage them.
          </p>
        </Stack>
        <Stack spacing={1}>
          <p className={classes.title_24}> For integration You will need to provide next information</p>
          <ul>
            <li>API Token</li>
            <li>Zone ID</li>
          </ul>
          <p className={classes.description_14}>
            {' '}
            API token can be created here -{' '}
            <a
              className={classes.link}
              href="https://dash.cloudflare.com/profile/api-tokens"
              target="_blank"
              rel="noreferrer"
            >
              {' '}
              cloudflare.com <OpenExternal width={16} height={16} />
            </a>
            . More information here -{' '}
            <a
              className={classes.link}
              href="https://developers.cloudflare.com/learning-paths/get-started/account-setup/create-api-token"
              target="_blank"
              rel="noreferrer"
            >
              {' '}
              create-api-token.com <OpenExternal width={16} height={16} />
            </a>
          </p>
        </Stack>
        <Stack spacing={2}>
          <p className={classes.title_24}>API Token Permissions</p>
          <Stack spacing={2} className={classes.introBox}>
            <Table aria-label="custom pagination table">
              <TableBody>
                <TableRow key={'Account-table-id'}>
                  <TableCell component="th" scope="row">
                    Account
                  </TableCell>
                  <TableCell>Worker KV Storage</TableCell>
                  <TableCell>Read/Edit</TableCell>
                </TableRow>
                <TableRow key={'Account-worker-table-id'}>
                  <TableCell component="th" scope="row">
                    Account
                  </TableCell>
                  <TableCell>Worker Scripts</TableCell>
                  <TableCell>Read/Edit</TableCell>
                </TableRow>
                <TableRow key={'Account-pages-table-id'}>
                  <TableCell component="th" scope="row">
                    Account
                  </TableCell>
                  <TableCell>Cloudflare Pages</TableCell>
                  <TableCell>Read/Edit</TableCell>
                </TableRow>
                <TableRow key={'Zone-table-id'}>
                  <TableCell component="th" scope="row">
                    Zone
                  </TableCell>
                  <TableCell>Worker Routes</TableCell>
                  <TableCell>Read/Edit</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Stack>
        </Stack>
        <Stack spacing={1}>
          <p className={classes.title_24}>What will be created?</p>
          <p className={classes.description_16}>KV Namespaces</p>
          <ul>
            <li>ze_snapshots</li>
            <li>ze_files</li>
            <li>ze_envs</li>
          </ul>
          <p className={classes.description_16}>Workers</p>
          <ul>
            <li>
              <code>ze-worker-for-static-upload</code> (uses for upload and serve assets)
            </li>
          </ul>
          <p className={classes.description_16}>Worker routes</p>
          <ul>
            <li>
              <code>ze.&#60;ZONE_NAME&#62;/*</code> on worker <code>ze-worker-for-static-upload</code>
            </li>
            <li>
              <code>*-ze.&#60;ZONE_NAME&#62;/*</code> on worker <code>ze-worker-for-static-upload</code>
            </li>
          </ul>
          <p className={classes.description_16}>DNS Records</p>
          <p className={classes.description_14}>
            Should be <code>A</code> type for wildcard route <code>*-ze.@</code> and <code>ze.@</code> or simple
            wildcard can be <code>*.@</code> like
          </p>
          <Stack spacing={2} className={classes.introBox}>
            <Table aria-label="custom pagination table">
              <TableBody>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Content</TableCell>
                  <TableCell>Proxy Status</TableCell>
                  <TableCell>TTL</TableCell>
                </TableRow>
                <TableRow key={'Account-table-id'}>
                  <TableCell>A</TableCell>
                  <TableCell>\*</TableCell>
                  <TableCell>D.D.D.D</TableCell>
                  <TableCell>Proxied</TableCell>
                  <TableCell>Auto</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
