import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { StripeElementChangeEvent, loadStripe } from '@stripe/stripe-js';
import {
  ElementType,
  ForwardedRef,
  HTMLAttributes,
  Ref,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import clsx from 'clsx';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { CalendarIcon } from 'app-zephyr-icons/Calendar';
import { CheckmarkIcon } from 'app-zephyr-icons/Checkmark';
import { CreditCardIcon } from 'app-zephyr-icons/CreditCard';
import { InfoIcon } from 'app-zephyr-icons/Info';
import { AttachPaymentMethodMutation } from 'app-zephyr-domains/subscription';
import { envValue } from 'app-zephyr-environment';
import { SnackBarAlertComponent } from 'app-zephyr-components/SnackBarAlert';

import { useStyles } from './styles';

const stripePromise = loadStripe(envValue.value.stripePkKey);

export interface FormInputs {
  // cardholderName: string;
  cardNumber?: string;
  cardExpiry?: string;
  cardCvc?: string;
  setAsDefault: boolean;
}

type FormControlNames = 'cardNumber' | 'cardExpiry' | 'cardCvc' | 'setAsDefault';

export interface PaymentMethodFormData {
  // cardholderName: string;
  setAsDefault: boolean;
}

export type NormalizedFormInputs = PaymentMethodFormData;

export type PaymentMethodFormProps = HTMLAttributes<HTMLFormElement> & {
  onSubmitValidData: AttachPaymentMethodMutation['attachPaymentMethodAsync'];
  setAsDefaultLocked?: boolean;
  onSuccess?: () => void;
};

interface StripeInputProps {
  component: ElementType;
  inputRef: Ref<unknown>;
  [key: string]: unknown;
}

function StripeInputRender(props: StripeInputProps, ref: ForwardedRef<unknown>) {
  const { component: Component, inputRef, ...other } = props;
  const elementRef = useRef<HTMLElement>();

  useImperativeHandle(ref, () => ({
    focus() {
      return elementRef.current?.focus.bind(this);
    },
  }));

  return <Component onReady={(element: HTMLElement) => (elementRef.current = element)} {...other} />;
}

const StripeInput = forwardRef(StripeInputRender);

function StripeAddCardForm({
  onSubmitValidData,
  setAsDefaultLocked,
  onSuccess,
  className,
  children,
  ...rest
}: PaymentMethodFormProps) {
  const { classes } = useStyles();
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const stripe = useStripe();
  const elements = useElements();

  const {
    control,
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<FormInputs>({
    defaultValues: {
      setAsDefault: true,
    },
  });

  const onStripeElementChange = (evt: StripeElementChangeEvent) => {
    const { error, elementType } = evt;
    const name = elementType as FormControlNames;
    if (error) {
      setError(name, {
        type: 'value',
        message: error.message,
      });
    } else {
      setError(name, {});
    }
    setIsValid(Boolean(errors.cardCvc?.message ?? errors.cardExpiry?.message ?? errors.cardNumber?.message));
  };

  const clearFormData = useCallback(() => {
    if (elements) {
      elements.getElement('cardNumber')?.clear();
      elements.getElement('cardExpiry')?.clear();
      elements.getElement('cardCvc')?.clear();
    }
  }, [elements]);

  const normalizeFormData = useCallback(
    (data: FormInputs): NormalizedFormInputs => ({
      setAsDefault: setAsDefaultLocked ? true : Boolean(data.setAsDefault),
    }),
    [setAsDefaultLocked],
  );

  const handlerOnSubmit: SubmitHandler<FormInputs> = useCallback(
    async (data) => {
      const normalizedData = normalizeFormData(data);
      const cardNumberElement = elements?.getElement('cardNumber');

      if (!cardNumberElement || !stripe) {
        return;
      }

      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumberElement,
      });

      if (error) {
        setSnackbarOpen(true);
        return;
      }

      try {
        const response = await onSubmitValidData({
          paymentMethodId: paymentMethod.id,
          useAsDefault: normalizedData.setAsDefault,
        });

        if (response.status >= 200 && response.status < 300) {
          clearFormData();
          onSuccess?.();
        }
      } catch {
        setSnackbarOpen(true);
      }
    },
    [normalizeFormData, elements, stripe, onSubmitValidData, clearFormData, onSuccess],
  );

  const style = {
    base: {
      backgroundColor: '##2F385',
      color: 'white',
      fontWeight: 'normal',
      fontFamily: 'Inter',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#white',
      },
      '::placeholder': {
        color: '#7B84A3',
      },
    },
  };

  const options = {
    style,
  };
  const events: Partial<StripeInputProps> = {
    onChange: onStripeElementChange,
    onBlur: () => null,
  };

  return (
    <form
      className={clsx(classes.component, className)}
      onSubmit={(event) => {
        event.preventDefault();
        void handleSubmit(handlerOnSubmit)();
      }}
      {...rest}
    >
      <FormControl fullWidth>
        <FormLabel className={classes.inputLabel} component="legend">
          Card Number
        </FormLabel>
        <Controller
          name="cardNumber"
          control={control}
          render={(props) => (
            <TextField
              className={classes.input}
              error={!!errors.cardNumber?.message}
              helperText={errors.cardNumber?.message}
              InputProps={{
                inputComponent: StripeInput,
                inputProps: {
                  ...register('cardNumber'),
                  component: CardNumberElement,
                  options: {
                    ...options,
                    placeholder: '7777 - 7777 - 7777 - 7777',
                  },
                  ...events,
                },
                endAdornment: <CreditCardIcon color="currentColor" />,
              }}
            />
          )}
        />
      </FormControl>

      <Box display="flex" flexDirection="row">
        <FormControl fullWidth>
          <FormLabel className={classes.inputLabel} component="legend">
            Expiry Date
          </FormLabel>
          <Controller
            name="cardExpiry"
            control={control}
            render={(props) => (
              <TextField
                className={classes.input}
                error={!!errors.cardExpiry?.message}
                helperText={errors.cardExpiry?.message}
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    component: CardExpiryElement,
                    options,
                    ...events,
                  },
                  endAdornment: <CalendarIcon color="currentColor" />,
                }}
              />
            )}
          />
        </FormControl>

        <FormControl style={{ marginLeft: '16px' }} fullWidth>
          <FormLabel className={classes.inputLabel} component="legend">
            CVV Code
          </FormLabel>
          <Controller
            name="cardCvc"
            control={control}
            render={(props) => (
              <TextField
                className={classes.input}
                error={!!errors.cardCvc?.message}
                helperText={errors.cardCvc?.message}
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    component: CardCvcElement,
                    options,
                    ...events,
                  },
                  endAdornment: <InfoIcon color="currentColor" />,
                }}
              />
            )}
          />
        </FormControl>
      </Box>

      <FormControlLabel
        className={classes.checkboxLabel}
        control={
          <Checkbox
            color="primary"
            defaultChecked
            {...register('setAsDefault')}
            disabled={isSubmitting || setAsDefaultLocked}
          />
        }
        label="Set this method as Default"
        name="setAsDefault"
      />

      {/* TODO: make a class for this */}
      <Button
        variant="contained"
        color="secondary"
        fullWidth
        type="submit"
        disabled={isSubmitting || isValid}
        style={{
          color: '#CAD1EA',
          backgroundColor: '#465380',
          height: '44px',
          marginTop: '16px',
          fontSize: '18px',
          fontWeight: 700,
          lineHeight: '25px',
        }}
      >
        {isSubmitting ? (
          <CircularProgress size="16px" />
        ) : (
          <>
            <CheckmarkIcon style={{ marginRight: '8px' }} color="#54C752" /> Save New Payment Method
          </>
        )}
      </Button>
      <SnackBarAlertComponent
        open={snackbarOpen}
        onClose={() => {
          setSnackbarOpen(false);
        }}
        message="Error when adding payment data. Check the entered data again or enter another one"
        alertColor="error"
      />
    </form>
  );
}

function StripePaymentMethodForm(props: PaymentMethodFormProps) {
  return (
    <Elements stripe={stripePromise}>
      <StripeAddCardForm {...props} />
    </Elements>
  );
}

export { StripePaymentMethodForm };
