import { simplePath } from 'ze-api-contract/utils';

export const APPLICATION_DEPLOYMENT_INTEGRATION_LIST =
  'v2/application/:organization/:project/:application/deployment-integrations/integration-list';
export const DEFAULT_APPLICATION_DEPLOYMENT_INTEGRATION =
  'v2/application/:organization/:project/:application/deployment-integrations/:integration';

export interface ApplicationParams {
  organization: string;
  project: string;
  application: string;
}

export interface ApplicationIntegrationParams {
  organization: string;
  project: string;
  application: string;
  integration: string;
}

export function get_application_deployment_integration_list_path(params: ApplicationParams): string {
  return simplePath(APPLICATION_DEPLOYMENT_INTEGRATION_LIST, params);
}

export function post_set_default_application_deployment_integrations_path(
  params: ApplicationIntegrationParams,
): string {
  return simplePath(DEFAULT_APPLICATION_DEPLOYMENT_INTEGRATION, params);
}
