import { useForm } from 'react-hook-form';
import { FormInput } from 'app-zephyr-forms';
import { joiResolver } from '@hookform/resolvers/joi';
import { Stack } from '@mui/material';
import { accountDeleteModalForm } from 'ze-api-contract/user-v2/profile-settings/update-profile';
import { useStyles } from './styles';
import { useCallback, useEffect } from 'react';
export type DeleteProfileConfirmFormFields = { name: string; confirm: string } & Record<string, string>;

const CONFIRM_WORD = 'delete-account';

interface DeleteProfileConfirmFormProps {
  name: string;
  email: string;
  isFormValid: (value: boolean) => void;
}

const DeleteProfileConfirmForm = ({ name, email, isFormValid }: DeleteProfileConfirmFormProps) => {
  const { classes } = useStyles();

  const {
    register,
    getValues,
    watch,
    formState: { errors, isValid },
  } = useForm<DeleteProfileConfirmFormFields>({
    mode: 'all',
    reValidateMode: 'onSubmit',
    resolver: joiResolver(accountDeleteModalForm(name, email)),
  });

  const watchAllFields = watch();

  const onChangeHandler = useCallback(() => {
    const data = getValues();

    // todo: should be validated with Joi but i faced issue on this step
    const confirmIsValid = data.confirm === CONFIRM_WORD;
    isFormValid(isValid && confirmIsValid);
  }, [getValues, isValid, isFormValid]);

  useEffect(() => {
    onChangeHandler();
  }, [onChangeHandler, watchAllFields]);

  return (
    <Stack spacing={2}>
      <FormInput
        id="confirm-name"
        name={'name'}
        register={register}
        errors={errors}
        label="To verify: type your user name or e-mail address"
      />
      <FormInput
        id="confirm-word"
        name={'confirm'}
        register={register}
        errors={errors}
        customLabel={
          <>
            To verify: type <span className={classes.accentError}>&nbsp;{CONFIRM_WORD}&nbsp;</span> below
          </>
        }
      />
    </Stack>
  );
};

export { DeleteProfileConfirmForm };
