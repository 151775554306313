import { ReactNode } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios, { AxiosError, AxiosRequestHeaders, AxiosResponse } from 'axios';

import { envValue } from 'app-zephyr-environment';
import { RouteNames } from 'app-zephyr-routes';

export type AxiosResponseError = AxiosError<{
  message: string;
  errorMessage: string;
}>;

const axiosInstance = axios.create({
  baseURL: envValue.value.coreApiBaseUrl,
});

export function AxiosInterceptor({ children }: { children: ReactNode }) {
  const { getAccessTokenSilently } = useAuth0();

  const reqInterceptor = async (config: { headers: AxiosRequestHeaders }) => {
    if (!config.headers.Authorization) {
      const token = await getAccessTokenSilently();
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  };

  const reqErrInterceptor = (error: unknown) => Promise.reject(error as Error);
  const respInterceptor = (resp: AxiosResponse) => resp;
  const respErrInterceptor = (error: AxiosResponseError) => {
    if (error.response?.status === 403) {
      location.href = RouteNames.FORBIDDEN;
    }
    return Promise.reject(error);
  };

  axiosInstance.interceptors.request.use(reqInterceptor, reqErrInterceptor);
  axiosInstance.interceptors.response.use(respInterceptor, respErrInterceptor);

  return children;
}

export { axiosInstance as axios };
export default axiosInstance;
