export const tokens = {
  'midnight-950': '#090807',
  'midnight-900': '#141414',
  'midnight-800': '#242424',
  'midnight-700': '#1f1f1f',
  'midnight-600': '#343434',
  'midnight-500': '#424960',
  'midnight-400': '#73768F',
  'midnight-300': '#A4AAB9',
  'midnight-200': '#EBEFFF',

  'blue-25': '#F5F8FF',
  'blue-50': '#B5E4FF',
  'blue-100': '#80D1FF',
  'blue-200': '#00A3FF',
  'blue-300': '#3367B5',
  'blue-400': '#155CC7',
  'blue-500': '#1F41BD',
  'blue-600': '#0E3961',
  'blue-700': '#10215F',
  'blue-800': '#002140',
  'blue-900': '#060D26',
  'blue-950': '#001020',

  /** Brand color */
  'brand-400': '#9667ED',
  'brand-500': '#6B35D0',
  'brand-600': '#5221AE',

  // same as brand color scale
  'purple-25': '#F4EEFF',
  'purple-50': '#CDC6DB',
  'purple-100': '#9D8DB6',
  'purple-200': '#7B689E',
  'purple-300': '#AE87F6',
  'purple-400': '#9667ED',
  'purple-500': '#6B35D0',
  'purple-600': '#5221AE',
  'purple-700': '#421597',
  'purple-800': '#381678',
  'purple-900': '#211041',
  'purple-950': '#130c20',

  'turquoise-25': '#86FFD3',
  'turquoise-50': '#5CE7CA',
  'turquoise-100': '#4CBBA3',
  'turquoise-200': '#61E8B7',
  'turquoise-300': '#3ec594',
  'turquoise-400': '#09905F',
  'turquoise-500': '#27584D',
  'turquoise-600': '#1D3C35',
  'turquoise-700': '#162B27',
  'turquoise-800': '#101C19',
  'turquoise-900': '#0D1312',
  'turquoise-950': '#0B0E0E',

  white: '#FFFFFF',
  'gray-25': '#DDDDDD',
  'gray-50': '#A9A9A9',
  'gray-100': '#969696',
  'gray-200': '#888888',
  'gray-300': '#747474',
  'gray-400': '#646464',
  'gray-500': '#565656',
  'gray-600': '#4A4A4A',
  //'gray-650': '#343434',
  'gray-700': '#343434',
  //'gray-750': '#242424',
  'gray-800': '#242424',
  'gray-900': '#141414',
  // in design we have bg gray-900 with 20%
  'gray-900-20': '#14141433',
  // in design we have bg gray-900 with 30%
  'gray-900-30': '#1414144D',
  'gray-950': '#090807',

  'orange-25': '#FCD9BF',
  'orange-50': '#FBCBA8',
  'orange-100': '#FBBF92',
  'orange-200': '#FAB27D',
  'orange-300': '#F9A96E',
  'orange-400': '#C9895A',
  'orange-500': '#996946',
  'orange-600': '#815A3C',
  'orange-700': '#6A4A32',
  'orange-800': '#523A28',
  'orange-900': '#3A2A1E',
  'orange-950': '#221A14',

  'pink-carmine-25': '#FFD4D4',
  'pink-carmine-50': '#FFC4C4',
  'pink-carmine-100': '#FFB6B6',
  'pink-carmine-200': '#FFA8A8',
  'pink-carmine-300': '#FF9D9D',
  'pink-carmine-400': '#E57676',
  'pink-carmine-500': '#AE5B5B',
  'pink-carmine-600': '#934E4E',
  'pink-carmine-700': '#784040',
  'pink-carmine-800': '#5D3333',
  'pink-carmine-900': '#412525',
  'pink-carmine-950': '#261818',

  'success-400': '#47CD89',
  'success-500': '#17B26A',

  'warning-400': '#FDB022',
  'warning-500': '#F79009',

  'error-400': '#F97066',
  'error-600': '#F04438',

  'transparent-blue': 'rgba(6, 41, 95, 0.2)',

  /* Focus rings/ring-gray */
  'box-shadow-default': '0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(158, 119, 237, 0.30)',
  'box-shadow-checked': '0px 0px 0px 2px #3ec59440',
  'box-shadow-transparent': '0px 1px 2px 0px rgba(16, 24, 40, 0), 0px 0px 0px 4px rgba(158, 119, 237, 0)',
  'box-shadow-contained': '0px 1px 2px 0px rgba(16, 24, 40, 0.05);',
  'box-shadow-contained-focused': '0px 0px 0px 4px rgba(255, 255, 255, 0.20);',
  blueGradient: {
    light: '#6198EC',
    dark: '#2E62B1',
  },

  black: '#000000',
} as const;
