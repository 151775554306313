import { simplePath } from 'ze-api-contract/utils';

export const CREATE_ORG_PATH: string = `/v2/organization`;

export interface CreateOrganizationBody {
  name: string;
  displayName: string;
  acceptedBy: string;
}

export interface UpdateOrganizationValue {
  name?: string;
  displayName?: string;
  domains?: string[];
  openMembership?: boolean;
}

export const RUD_ORG_PATH = '/v2/organization/:id';

interface RUDOrganizationPathProps {
  id: string;
}
export function get_org_rud_path(params: RUDOrganizationPathProps): string {
  return simplePath(RUD_ORG_PATH, params);
}
