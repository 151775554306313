import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { FormBox } from 'app-zephyr-components/FormBox';
import { FormInput } from 'app-zephyr-forms';
import { createTeamValidation } from 'ze-api-contract/organization-v2/validation';

export interface TeamNameFormProps {
  canUpdateTeam?: boolean;
  name: string;
  nameIsChanged: (value: string) => void;
}

function TeamNameForm({ name, canUpdateTeam, nameIsChanged }: TeamNameFormProps) {
  const {
    register,
    formState: { errors, isValid, isDirty },
    getValues,
    watch,
  } = useForm<{ name: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: { name },
    resolver: joiResolver(createTeamValidation),
  });

  const nameWatch = watch();

  useEffect(() => {
    if (!isValid || !isDirty) {
      nameIsChanged('');
      return;
    }

    const value = getValues().name;
    nameIsChanged(value);
  }, [nameWatch]);

  return (
    <form>
      <FormBox>
        <FormInput
          id="team-name"
          name="name"
          label="Team Name"
          register={register}
          disabled={!canUpdateTeam}
          errors={errors}
        />
      </FormBox>
    </form>
  );
}

export { TeamNameForm };
