import { UserPinType } from 'ze-api-contract/enums';

export const GET_USER_PIN_OPTIONS_PATH = '/v2/user-pins/options';

export function get_user_pin_options_path() {
  return GET_USER_PIN_OPTIONS_PATH;
}

export interface UserPinOption {
  organization?: BasicInfo;
  project?: BasicInfo;
  application?: BasicInfo;
  type: UserPinType;
}

interface BasicInfo {
  id: string;
  name: string;
  displayName: string;
}
