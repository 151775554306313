import { simplePath } from 'ze-api-contract/utils';
import { ApplicationTag, ApplicationTagUIdParams } from './interfaces';

export const GET_APPLICATION_TAG = '/v2/application/:organization/:project/:application/tag/:tag';

type GetApplicationTagListParams = ApplicationTagUIdParams;

export function get_application_tag_path(params: GetApplicationTagListParams): string {
  return simplePath(GET_APPLICATION_TAG, params);
}

export type ApplicationTagValue = ApplicationTag;
