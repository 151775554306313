import { Link } from '@tanstack/react-router';
// @mui
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
// styles
import { useStyles } from './styles';

import { project_overview_path, useTypedParams } from 'app-zephyr-routes';
import { application_overview_path, organization_path, authenticated_user_profile_path } from 'app-zephyr-routes';

interface BreadcrumbProps {
  username: string | undefined;
  name: string | undefined;
}

export function BreadCrumbs({ username, name }: BreadcrumbProps) {
  const { classes, cx } = useStyles();
  const { organization, project, application } = useTypedParams();

  return (
    <div className={classes.breadcrumbContent}>
      <Breadcrumbs className={cx(classes.breadcrumbComponent)} aria-label="breadcrumb" separator={'/'}>
        {username && name && (
          <ItemCrumb
            key={username + 'breadcrumb_key'}
            title={name}
            last={!project}
            id={username + 'userId'}
            // FIXME: temporary workaround for issue https://github.com/ZephyrCloudIO/zephyr-cloud-io/issues/1439 to avoid 404 for user. Should be user_path after api fix
            path={authenticated_user_profile_path()}
          />
        )}
        {organization && (
          <ItemCrumb
            key={organization}
            title={organization}
            last={!project}
            id={organization}
            path={organization_path({ organization: { name: organization } })}
          />
        )}
        {organization && project && (
          <ItemCrumb
            key={project}
            title={project}
            last={!application}
            id={project}
            path={project_overview_path({ organization: { name: organization }, project: { name: project } })}
          />
        )}
        {organization && project && application && (
          <ItemCrumb
            key={application}
            title={application}
            last={true}
            id={application}
            path={application_overview_path({
              organization: { name: organization },
              project: { name: project },
              application: { name: application },
            })}
          />
        )}
      </Breadcrumbs>
    </div>
  );
}

export function ItemCrumb(props: Readonly<{ title: string; id: string; path?: string; last: boolean }>) {
  const { cx } = useStyles();
  if (!props.path) {
    return (
      <Typography className={cx([{ active: props.last }])} key={props.id + props.title}>
        {props.title}
      </Typography>
    );
  }

  return (
    <Link
      className={cx([{ active: props.last }])}
      key={props.id + props.title}
      color="inherit"
      to={props.path}
      data-e2e="LAYOUT_HEADER_MAIN"
    >
      {props.title}
    </Link>
  );
}
