import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  form: {
    '.MuiButtonBase-root.textBtn': {
      marginLeft: '30px !important',
    },
  },

  title: {
    fontWeight: 700,
    color: theme.palette.tx.primary,
    fontSize: 16,
    margin: 0,
  },

  hint: {
    color: theme.palette.tx.secondary.default,
    fontSize: 14,
    fontStyle: 'italic',
    fontWeight: 700,
    marginTop: 4,

    '&.warning': {
      color: theme.palette.tx.warning.primary,
    },
  },

  avatar: {
    fontSize: 30,
  },

  hintLink: {
    a: {
      color: theme.palette.brand.purple[400],
      textDecoration: 'underline',
    },
  },

  socialItem: {},
  link: {
    color: theme.palette.brand.purple[400],
    textDecoration: 'underline',
    textDecorationColor: theme.palette.brand.purple[400],
    textUnderlineOffset: 4,

    '& :hover': {
      color: theme.palette.tx.primary,
      textDecorationColor: theme.palette.tx.primary,
      textDecoration: 'underline',
    },
  },
  textBtn: {
    width: 'fit-content',
    marginLeft: '48px !important',
    padding: 0,
    fontSize: 14,
    color: theme.palette.brand.purple[400],
    fontWeight: 400,

    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.brand.purple[400],
    },

    '&.Mui-disabled': {
      backgroundColor: 'transparent !important',
      color: theme.palette.tx.secondary.default,
    },
  },
}));
