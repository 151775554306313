import 'highlight.js/styles/atom-one-dark.css';

import * as Sentry from '@sentry/browser';
import { useEffect } from 'react';
import { useNavigate, useRouterState } from '@tanstack/react-router';
import { useAuth0 } from '@auth0/auth0-react';
import { RouteNames } from 'app-zephyr-routes';

// providers
import { provider, ProviderComposer } from 'app-zephyr-components/ProviderCompose';
import { GoogleTagManager } from 'app-zephyr-components/GoogleTagManager';

// styles
import './global.css';

export function AppV2({ children }) {
  const router = useRouterState();
  const navigate = useNavigate();
  const { isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      void navigate({ to: RouteNames.INIT, search: (p: never) => p, params: (p: never) => p });
    }
  }, [isAuthenticated, isLoading, navigate]);

  useEffect(() => {
    const scope = Sentry.getCurrentScope();
    scope.setTransactionName(router.location.pathname);
  }, [router.location.pathname]);

  return <ProviderComposer providers={[provider(GoogleTagManager)]}>{children}</ProviderComposer>;
}
