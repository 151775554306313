import { RouteNames } from 'app-zephyr-routes';

const QUERY_MENU_ID = 'activeMenu';

enum navBarItemId {
  // organization lvl
  DASHBOARD = 'navbarItem-Dashboard',
  PROJECTS = 'navbarItem-Projects',
  APPLICATIONS = 'navbarItem-Apps',
  TEAMS = 'navbarItem-Teams',
  PEOPLE = 'navbarItem-People',
  SETTINGS = 'navbarItem-Settings',
  // Project lvl
  PROJECT_OVERVIEW = 'navbarItem-secondary-Overview',
  PROJECT_VERSIONS = 'navbarItem-secondary-Versions',
  PROJECT_UML = 'navbarItem-secondary-UML',
  PROJECT_APPLICATION_LIST = 'navbarItem-project-applications',
  // App lvl
  PROJECT_REMOTES = 'navbarItem-secondary-Remotes',
  PROJECT_ANALYTICS = 'navbarItem-secondary-Analytics',
  PROJECT_SETTINGS = 'navbarItem-secondary-Settings',
  APPLICATION_SETTINGS = 'navbarItem-application-settings',
  PROFILE_OVERVIEW = 'profile-overview',
  PROFILE_SETTINGS = 'profile-settings',
}

enum navTitle {
  All = 'All',
  ANALYTICS = 'Analytics',
  APPLICATIONS = 'Applications',
  BILLING_HISTORY = 'Billing History',
  COLLABORATIONS = 'Collaborators and Teams',
  CREATED_BY_ME = 'Created by me',
  DASHBOARD = 'Dashboard',
  DEPENDENCIES = 'Dependencies',
  DEPENDENCY_GRAPH = 'Dependency Graph',
  EMAILS = 'Emails',
  ENVIRONMENT = 'Environment',
  FAVORITE = 'Favorite',
  GENERAL = 'General',
  GENERAL_SETTINGS = 'General Settings',
  MANAGE_SUBSCRIPTIONS = 'Manage Subscriptions',
  MANAGED = 'Managed',
  MEMBERSHIP = 'Membership',
  MODULES = 'Modules',
  NODE_GRAPH = 'Node Graph',
  NPM_DEPENDENCIES = 'NPM Dependencies',
  ORGANIZATION = 'Organization',
  OVERVIEW = 'Overview',
  PAYMENT_METHODS = 'Payment Methods',
  PEOPLE = 'People',
  PROFILE = 'Profile',
  PROJECTS = 'Projects',
  PUBLIC_INFORMATION = 'Public Information',
  PUBLIC_PROFILE = 'Public Profile',
  QUERY_MENU_NAME = 'type',
  REMOTES = 'Remotes',
  REMOVED = 'Removed',
  SETTINGS = 'Settings',
  SUBSCRIPTIONS = 'Subscriptions',
  TAGS = 'Tags',
  TEAMS = 'Teams',
  TOKENS = 'Tokens',
  UML = 'UML',
  UNMANAGED = 'Unmanaged',
  USER_API_TOKENS = 'API Tokens',
  VERSIONS = 'Versions',
  DEPLOY_INTEGRATIONS = 'Deployment Integrations',
  DEFAULT_DEPLOYMENT_INTEGRATIONS = 'Default Deployment Integration',
  DEPLOYMENT_AND_ROLLBACK = 'Deployment and Rollback',
}

interface TGetItemsProps {
  organization: string;
  project: string;
  application: string;
}

type TGetItemsResult = { [key in navTitle]: string };

type TGetRoutes = (data: TGetItemsProps) => Partial<TGetItemsResult>;

const getRoutes =
  (id: navBarItemId): TGetRoutes =>
  () =>
    (
      ({
        [navBarItemId.TEAMS]: {
          [navTitle.TEAMS]: RouteNames.ORGANIZATION_TEAM_LIST,
        },
        [navBarItemId.SETTINGS]: {
          [navTitle.PUBLIC_INFORMATION]: RouteNames.ORGANIZATION_SETTINGS,
          [navTitle.SUBSCRIPTIONS]: RouteNames.ORGANIZATION_SUBSCRIPTION,
          [navTitle.DEFAULT_DEPLOYMENT_INTEGRATIONS]: RouteNames.ORGANIZATION_DEPLOYMENT_INTEGRATION_LIST,
        },
        [navBarItemId.PROJECT_ANALYTICS]: {
          [navTitle.DEPENDENCIES]: RouteNames.APPLICATION_DEPENDENCIES,
          [navTitle.NODE_GRAPH]: RouteNames.APPLICATION_NODE_GRAPH,
          [navTitle.DEPENDENCY_GRAPH]: RouteNames.APPLICATION_DEPENDENCY_GRAPH,
        },
        [navBarItemId.PROJECT_REMOTES]: {
          [navTitle.VERSIONS]: RouteNames.APPLICATION_REMOTES,
          [navTitle.MODULES]: RouteNames.APPLICATION_REMOTES,
          [navTitle.NPM_DEPENDENCIES]: RouteNames.APPLICATION_REMOTES,
        },
      }) as Record<string, Partial<TGetItemsResult>>
    )[id];

const {
  ANALYTICS,
  All,
  APPLICATIONS,
  COLLABORATIONS,
  CREATED_BY_ME,
  GENERAL,
  PUBLIC_INFORMATION,
  VERSIONS,
  DASHBOARD,
  DEPENDENCIES,
  DEPENDENCY_GRAPH,
  NPM_DEPENDENCIES,
  FAVORITE,
  MODULES,
  NODE_GRAPH,
  OVERVIEW,
  UML,
  PEOPLE,
  PROJECTS,
  REMOTES,
  REMOVED,
  SETTINGS,
  SUBSCRIPTIONS,
  TEAMS,
  TOKENS,
  TAGS,
} = navTitle;

interface TNavigationItem {
  id: navBarItemId;
  title: navTitle;
  items: navTitle[];
  getRoutes?: TGetRoutes;
}

const navigations: { [key in navBarItemId]: TNavigationItem } = {
  // organization lvl
  [navBarItemId.DASHBOARD]: {
    id: navBarItemId.DASHBOARD,
    title: DASHBOARD,
    items: [],
  },
  [navBarItemId.PROJECTS]: {
    id: navBarItemId.PROJECTS,
    title: PROJECTS,
    items: [All, CREATED_BY_ME, FAVORITE, REMOVED],
  },
  [navBarItemId.APPLICATIONS]: {
    id: navBarItemId.APPLICATIONS,
    title: APPLICATIONS,
    items: [],
  },
  [navBarItemId.TEAMS]: {
    id: navBarItemId.TEAMS,
    title: TEAMS,
    items: [],
  },
  [navBarItemId.PEOPLE]: {
    id: navBarItemId.PEOPLE,
    title: PEOPLE,
    items: [],
  },
  [navBarItemId.SETTINGS]: {
    id: navBarItemId.SETTINGS,
    title: SETTINGS,
    items: [PUBLIC_INFORMATION, SUBSCRIPTIONS],
    getRoutes: getRoutes(navBarItemId.SETTINGS),
  },
  // project lvl
  [navBarItemId.PROJECT_OVERVIEW]: {
    id: navBarItemId.PROJECT_OVERVIEW,
    title: OVERVIEW,
    items: [],
  },
  [navBarItemId.PROJECT_UML]: {
    id: navBarItemId.PROJECT_UML,
    title: UML,
    items: [],
  },
  [navBarItemId.PROJECT_VERSIONS]: {
    id: navBarItemId.PROJECT_VERSIONS,
    title: VERSIONS,
    items: [],
  },
  [navBarItemId.PROJECT_APPLICATION_LIST]: {
    id: navBarItemId.PROJECT_APPLICATION_LIST,
    title: APPLICATIONS,
    items: [],
    getRoutes: getRoutes(navBarItemId.PROJECT_APPLICATION_LIST),
  },
  [navBarItemId.PROJECT_REMOTES]: {
    id: navBarItemId.PROJECT_REMOTES,
    title: REMOTES,
    items: [MODULES, VERSIONS, NPM_DEPENDENCIES],
    getRoutes: getRoutes(navBarItemId.PROJECT_REMOTES),
  },
  [navBarItemId.PROJECT_ANALYTICS]: {
    id: navBarItemId.PROJECT_ANALYTICS,
    title: ANALYTICS,
    items: [DEPENDENCIES, NODE_GRAPH, DEPENDENCY_GRAPH],
    getRoutes: getRoutes(navBarItemId.PROJECT_ANALYTICS),
  },
  [navBarItemId.PROJECT_SETTINGS]: {
    id: navBarItemId.PROJECT_SETTINGS,
    title: SETTINGS,
    items: [TOKENS, GENERAL, COLLABORATIONS],
  },
  [navBarItemId.APPLICATION_SETTINGS]: {
    id: navBarItemId.APPLICATION_SETTINGS,
    title: SETTINGS,
    items: [TAGS],
  },
  [navBarItemId.PROFILE_OVERVIEW]: {
    id: navBarItemId.PROFILE_OVERVIEW,
    title: OVERVIEW,
    items: [OVERVIEW],
  },
  [navBarItemId.PROFILE_SETTINGS]: {
    id: navBarItemId.PROFILE_SETTINGS,
    title: SETTINGS,
    items: [SETTINGS],
  },
};

const pathsWithoutSideMenu = [
  RouteNames.ORGANIZATION,
  RouteNames.ORGANIZATION_MANAGED_APPLICATIONS,
  RouteNames.ORGANIZATION_PEOPLE,
  RouteNames.USER_PROFILE,
  RouteNames.APPLICATION_OVERVIEW,
  RouteNames.ORGANIZATION_TEAM_LIST,
  RouteNames.ORGANIZATION_TEAM,
  // error pages
  RouteNames.NOT_FOUND,
  RouteNames.NOT_AUTHORIZED,
  RouteNames.FORBIDDEN,
  RouteNames.INTERNAL_ERROR,
  // eslint-disable-next-line @typescript-eslint/no-deprecated
  RouteNames.NEW_SUBSCRIBER,
];

const pathsWithoutHeader = [
  // error pages
  RouteNames.NOT_FOUND,
  RouteNames.NOT_AUTHORIZED,
  RouteNames.FORBIDDEN,
  RouteNames.INTERNAL_ERROR,
  // eslint-disable-next-line @typescript-eslint/no-deprecated
  RouteNames.NEW_SUBSCRIBER,
];

export type { TNavigationItem }; // types
export { navigations, navTitle, navBarItemId, QUERY_MENU_ID, pathsWithoutSideMenu, pathsWithoutHeader };
