import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh)',
  },
  main: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  toolbar: {
    paddingRight: 24,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  content: {
    padding: `${theme.spacing(4).toString()} ${theme.spacing(2).toString()} !important`,
    background: theme.palette.bg.primary.default,
    height: '100%',
    overflowY: 'auto',
    width: '100%',
    '::-webkit-scrollbar': {
      width: '0.4em',
    },
    '::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,0.1)',
      border: '1px solid slategrey',
      borderRadius: '10px',
    },
  },
  container: {
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
    maxWidth: '1280px',
    margin: '0 auto',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  menuFlex: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  list: {
    flexGrow: 1,
    padding: '0',
    '.MuiListItemButton-root': {
      padding: '0',
      margin: '16px 0',
      transition: theme.transitions.create(['color'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      color: theme.palette.tx.primary,
      '&.active, &:hover': {
        backgroundColor: 'transparent',
        color: theme.palette.tx.secondary.default,
        svg: {
          path: {
            fill: theme.palette.tx.secondary.hover,
          },
        },
      },
      svg: {
        width: '24px',
        height: '24px',
        path: {
          fill: theme.palette.tx.primary,
          transition: theme.transitions.create(['fill', 'stroke'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        },
      },
    },
    '.iconContainer': {
      minWidth: '36px',
    },
  },
  collapse: {
    marginTop: '0 !important',
    borderBottom: theme.palette.border.primary,
    paddingBottom: '16px !important',
  },
  alert: {
    margin: `-${theme.spacing(4).toString()} -${theme.spacing(4).toString()} ${theme.spacing(4).toString()} -${theme
      .spacing(4)
      .toString()}`,
  },
}));
