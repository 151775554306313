import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    fontFamily: 'Inter',

    '& .error-message': {
      color: theme.palette.tx.error.primary,
      fontSize: '12px',
      margin: 0,
    },
    '& .value-error-message': {
      marginLeft: 120,
    },
  },

  searchSelect: {
    '&.MuiAutocomplete-root': {
      width: '100%',

      fieldset: {
        display: 'none',
      },

      '.MuiInputBase-root': {
        paddingTop: 0,
        paddingBottom: '2px',
        marginTop: 0,
      },
    },

    '.MuiSvgIcon-root': {
      fill: theme.palette.tx.primary,
    },

    '.MuiFormLabel-root': {
      top: '8px',
      transform: 'none',
      fontWeight: 400,
      fontSize: 16,
      left: '16px',

      '&.Mui-disabled': {
        color: theme.palette.tx.secondary.default,
      },
    },
  },

  selectContainer: {
    display: 'flex',
    border: theme.palette.border.secondary,
    backgroundColor: theme.palette.bg.primary.default,
    color: theme.palette.tx.primary,
    borderRadius: theme.borderRadius.md,
    width: '100%',
    lineHeight: '140%',
    height: 45,
    '&:hover': {
      borderColor: theme.palette.border.primary,
    },

    fieldset: {
      display: 'none',
    },
  },
  label: {
    fontSize: 14,
    fontWeight: 700,
    color: theme.palette.tx.secondary.default,
  },
  focused: {
    border: theme.palette.border.secondary,
    color: theme.palette.tx.secondary.alt,
    '&:hover': {
      border: theme.palette.border.primary,
    },
  },
}));
