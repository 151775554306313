import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { FormInput } from 'app-zephyr-forms';
import { confirmModalForm } from 'ze-api-contract/user-v2/profile-settings/update-profile';

export type DeleteTokenConfirmFormFields = { name: string; confirm: string } & Record<string, string>;

interface DeleteTokenConfirmFormProps {
  name: string;
  isFormValid: (value: boolean) => void;
}

const DeleteTokenConfirmForm = ({ name, isFormValid }: DeleteTokenConfirmFormProps) => {
  const {
    register,

    formState: { errors, isValid },
  } = useForm<DeleteTokenConfirmFormFields>({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: joiResolver(confirmModalForm(name)),
  });

  useEffect(() => {
    isFormValid(isValid);
  }, [isFormValid, isValid]);

  return (
    <FormInput id="confirm-name" name={'name'} register={register} errors={errors} label="To verify: type token name" />
  );
};

export { DeleteTokenConfirmForm };
