import { ReactNode } from 'react';
import { useStyles } from './styles';

export interface FormContainerProps {
  /**
   * form title
   */
  title: string;
  /**
   * form content
   */
  children: ReactNode;
  /**
   * optional root class name
   */
  className?: string;
  /**
   * form variant for title position
   */
  variant?: 'filled' | 'outlined';
}
/**
 * Form container with styles and title.
 */
const FormContainer = ({ children, title, className, variant = 'filled' }: FormContainerProps) => {
  const { classes, cx } = useStyles();

  return (
    <div className={cx(className)}>
      <div className={cx(classes.titleRoot, variant)}>
        <span className={classes.title}>{title}</span>
        <span className={cx(classes.stroke, variant)} />
      </div>
      <div className={cx(classes.body, variant)}>{children}</div>
    </div>
  );
};

export { FormContainer };
