import { FormControlLabel, Switch, FormControl } from '@mui/material';
import { Path, FieldValues, Control, Controller } from 'react-hook-form';

import { InputProps } from '../../_elements';
import { useStyles } from './styles';

export interface FormCheckboxProps<FormValuesProps extends FieldValues> extends Omit<InputProps, 'name' | 'onChange'> {
  name: Path<FormValuesProps>;
  control: Control<FormValuesProps>;
  isDisabled?: boolean;
  onChange?: (value: boolean) => void;
}

const FormSwitch = <FormValuesProps extends Record<string, unknown>>({
  name,
  className,
  label,
  control,
  isDisabled,
  onChange,
}: FormCheckboxProps<FormValuesProps>) => {
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.container, className)} aria-live="polite" data-testid="form-checkbox">
      <FormControlLabel
        label={label}
        control={
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <FormControl>
                <Switch
                  onChange={(e) => {
                    field.onChange(e.target.checked);
                    onChange?.(e.target.checked);
                  }}
                  checked={!!field.value}
                  disabled={isDisabled}
                />
              </FormControl>
            )}
          />
        }
      />
    </div>
  );
};

export { FormSwitch };
