import * as Joi from 'joi';

import { simplePath } from 'ze-api-contract/utils';
import {
  changeOrganizationName,
  orgSettingsDeleteForm,
  orgSettingsUpdateForm,
  updateOrgMembershipSettings,
  updateOrganizationPublicSettings,
} from './validations';
import { ORGANIZATION_DOMAIN_VALIDATOR } from '../../validation';

export const UPDATE_ORG_SETTINGS = 'v2/organization/:organization/profile-settings';

export function get_org_settings_path(params: { organization: string }): string {
  return simplePath(UPDATE_ORG_SETTINGS, params);
}

export const isPrivate = Joi.boolean().required();
export const formSchema = orgSettingsUpdateForm.append({
  isPrivate,
});
export const deleteOrgForm = orgSettingsDeleteForm;
export const renameOrgForm = changeOrganizationName;
export const schema = updateOrganizationPublicSettings;
export const membershipFormSchema = updateOrgMembershipSettings.append({
  domainInput: Joi.string().allow('').pattern(ORGANIZATION_DOMAIN_VALIDATOR.pattern.value).messages({
    'string.min': ORGANIZATION_DOMAIN_VALIDATOR.minLength.message,
    'string.max': ORGANIZATION_DOMAIN_VALIDATOR.maxLength.message,
    'string.pattern.base': ORGANIZATION_DOMAIN_VALIDATOR.pattern.message,
  }),
});
