import { FC, ReactNode } from 'react';
import { AnyRouter, Link as ReactRouterLink, LinkProps as ReactRouterLinkProps } from '@tanstack/react-router';
import { Link as MUILink, LinkProps as MuiLinkProps } from '@mui/material';
import { ParamsReducerFn, ToMaskOptions } from '@tanstack/react-router/dist/esm/link';

import { useStyles } from './styles';

type LinkProps = Omit<ReactRouterLinkProps, 'children'> &
  Pick<MuiLinkProps, 'sx'> & {
    children: ReactNode;
    className?: string;
    onClick?: () => void;
    color?: string;
    style?: React.CSSProperties;
    search?: ParamsReducerFn<AnyRouter, 'SEARCH', string, string>;
    params?: ParamsReducerFn<AnyRouter, 'PATH', string, string>;
    mask?: ToMaskOptions<AnyRouter, string, string>;
  };

const Link: FC<LinkProps> = ({ children, ...linkProps }) => {
  const { classes, cx } = useStyles();

  return (
    <MUILink {...linkProps} className={cx(classes.link, linkProps.className ?? '')} component={ReactRouterLink}>
      {children}
    </MUILink>
  );
};

export type { LinkProps }; // types
export { Link }; // component
