import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Stack } from '@mui/material';

import { DeployTarget } from 'ze-api-contract/application-v2/deploy-rollback/interfaces';

import { FormInput, FormAutocomplete } from '../../controls';
import { useStyles } from './styles';

const CONFIRM_WORD = 'Deploy';

export type VersionDeployFormFields = {
  target: DeployTarget | undefined;
  tagList: {
    label: string;
    value: string;
  } | null;
  envList: {
    label: string;
    value: string;
  } | null;
  confirmWord: string;
} & Record<string, string>;

interface VersionDeployFormProps {
  tagList: { label: string; value: string }[];
  envList: { label: string; value: string }[];
  onFormValidHandler: (value: VersionDeployFormFields | null) => void;
}

const VersionDeployForm = ({ tagList, envList, onFormValidHandler }: VersionDeployFormProps) => {
  const { classes } = useStyles();

  const {
    register,
    getValues,
    formState: { errors, isDirty },
    trigger,
    setValue,
    control,
    watch,
  } = useForm<VersionDeployFormFields>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      target: undefined,
      tagList: { label: '', value: '' },
      envList: { label: '', value: '' },
      confirmWord: '',
    } as VersionDeployFormFields,
  });

  const formValueTagListWatch = watch('tagList');
  const formValueEnvListWatch = watch('envList');
  const formValueVersionNameListWatch = watch('confirmWord');

  useMemo(() => {
    const data = getValues();
    if (!isDirty || data.confirmWord !== CONFIRM_WORD || (!data.tagList?.value && !data.envList?.value)) {
      onFormValidHandler(null);
      return;
    }

    onFormValidHandler(data);
  }, [formValueTagListWatch, formValueEnvListWatch, formValueVersionNameListWatch]);

  useMemo(() => {
    const data = getValues();
    if (data.tagList?.value) {
      setValue('target', DeployTarget.tag);
      setValue('envList', { label: '', value: '' });
    }
  }, [formValueTagListWatch]);

  useMemo(() => {
    const data = getValues();
    if (data.envList?.value) {
      setValue('target', DeployTarget.environment);
      setValue('tagList', { label: '', value: '' });
    }
  }, [formValueEnvListWatch]);

  return (
    <form>
      <Stack spacing={2}>
        <Stack className={classes.borderBox} spacing={1}>
          <FormAutocomplete
            id="tagList"
            trigger={trigger}
            setValue={setValue}
            control={control}
            name="tagList"
            options={tagList}
            errors={errors}
            label="Tags"
          />
          <div className={classes.separator}>
            <div className={classes.transparentTextBox}>OR</div>
          </div>
          <FormAutocomplete
            id="envList"
            trigger={trigger}
            setValue={setValue}
            control={control}
            name="envList"
            options={envList}
            errors={errors}
            label="Environments"
          />
        </Stack>
        <FormInput
          id="confirm-word"
          name={'confirmWord'}
          register={register}
          errors={errors}
          customLabel={
            <>
              To verify: type <span className={classes.confirmWord}>&nbsp;{CONFIRM_WORD}&nbsp;</span>below
            </>
          }
        />
      </Stack>
    </form>
  );
};

export { VersionDeployForm };
