import { simplePath } from 'ze-api-contract/utils';

import { baseSchema } from './validation';
import { ProjectSettingsBodyReq } from './interfaces';

export const UPDATE_PROJECT_SETTINGS_PATH = '/v2/project/:organization/:project/settings';

interface UpdateProjectSettingsParams {
  organization?: string;
  project?: string;
}

export function update_project_settings_path(params: UpdateProjectSettingsParams): string {
  return simplePath(UPDATE_PROJECT_SETTINGS_PATH, params);
}

export const schema = baseSchema.append<ProjectSettingsBodyReq>();

export const formSchema = baseSchema.append<ProjectSettingsBodyReq>();
