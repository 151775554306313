import { SVGProps } from 'react';

export type AzureIconProps = SVGProps<SVGSVGElement> & {
  isDisabled?: boolean;
};

export const DisabledAzureIcon = () => {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="azure-1-logo 1" clipPath="url(#clip0_4281_3551)">
        <path
          id="Vector"
          d="M17.9835 0L8.54998 8.18062L0.5 22.6298H7.75824L17.9835 0ZM19.2384 1.91402L15.2124 13.2616L22.9319 22.9604L7.95618 25.5335H32.5L19.2384 1.91402Z"
          fill="#424960"
        />
      </g>
      <defs>
        <clipPath id="clip0_4281_3551">
          <rect width="32" height="32" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const ActiveAzureIcon = () => {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="azure-1-logo 2" clipPath="url(#clip0_4281_1530)">
        <path
          id="Vector"
          d="M17.9835 0L8.54998 8.18062L0.5 22.6298H7.75824L17.9835 0ZM19.2384 1.91402L15.2124 13.2616L22.9319 22.9604L7.95618 25.5335H32.5L19.2384 1.91402Z"
          fill="#0072C6"
        />
      </g>
      <defs>
        <clipPath id="clip0_4281_1530">
          <rect width="32" height="32" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export function AzureIcon({ isDisabled, ...props }: AzureIconProps) {
  return <>{isDisabled ? <DisabledAzureIcon /> : <ActiveAzureIcon />}</>;
}
