import { simplePath } from '../utils';

export const GET_ORGANIZATION_LIST_PATH = '/v2/user/:userEmail/organization-list';

export interface GetOrganizationListParams {
  userEmail: string;
}

export function get_organization_list_path(params: GetOrganizationListParams): string {
  return simplePath(GET_ORGANIZATION_LIST_PATH, params);
}
