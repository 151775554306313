import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  form: {
    marginBottom: 24,
  },
  accentError: {
    color: theme.palette.tx.error.primary,
    fontStyle: 'italic',
  },
  errorMessage: {
    fontSize: 12,
    color: theme.palette.tx.error.primary,
    margin: 0,
  },
}));
