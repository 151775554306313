import { UserPinType } from 'ze-api-contract/enums';
import * as Joi from 'joi';

export const SAVE_USER_PINS_PATH = '/v2/user-pins';

export function save_user_pins_path() {
  return SAVE_USER_PINS_PATH;
}

export interface SaveUserPins {
  userPins: SaveUserPin[];
}

export interface SaveUserPin {
  organizationId?: string;
  projectId?: string;
  applicationId?: string;
  type: UserPinType;
}

export const schema = Joi.object({
  userPins: Joi.array().items(
    Joi.object<SaveUserPin>({
      type: Joi.string().valid(...Object.values(UserPinType)),
      organizationId: Joi.string().when('type', {
        is: UserPinType.Organization,
        then: Joi.required(),
        otherwise: Joi.forbidden(),
      }),
      projectId: Joi.string().when('type', {
        is: UserPinType.Project,
        then: Joi.required(),
        otherwise: Joi.forbidden(),
      }),
      applicationId: Joi.string().when('type', {
        is: UserPinType.Application,
        then: Joi.required(),
        otherwise: Joi.forbidden(),
      }),
    }),
  ),
}).required();
