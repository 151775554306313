import { useMutation, useQueryClient } from '@tanstack/react-query';

import { axios } from 'app-zephyr-axios';

import { teamQuery } from './team';
import { teamMemberQuery } from './get-team-member-list';

interface DeleteMemberParams {
  teamId: string;
  memberId: string;
  queryParams?: { name?: string };
}

export function useDeleteMember() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ teamId, memberId, queryParams }: DeleteMemberParams) =>
      axios.delete(`/v2/team/${teamId}/member/${memberId}`),
    onSuccess: async (res, { teamId, queryParams }) => {
      await queryClient.invalidateQueries(teamQuery.getById(teamId));
      await queryClient.invalidateQueries(teamMemberQuery.list(teamId, queryParams));
    },
  });
}
