import { ReactNode } from 'react';
import { Stack, Tooltip } from '@mui/material';

import { Button } from 'app-zephyr-components/Button';
import { CloudFlareIcon } from 'app-zephyr-icons/Cloudflare';
import { FastlyIcon } from 'app-zephyr-icons/Fastly';
import { AWSIcon } from 'app-zephyr-icons/AWS';
import { NetlifyIcon } from 'app-zephyr-icons/Netlify';
import { AzureIcon } from 'app-zephyr-icons/Azure';
import { GoogleCloudIcon } from 'app-zephyr-icons/GoogleCloud';
import {
  DeploymentIntegrationValue,
  DeploymentIntegrationPlatform,
} from 'ze-api-contract/deployment-integration/organization/interfaces';
import { availableIntegrationList } from 'app-zephyr-constants';
import { EditIcon } from 'app-zephyr-icons';
import { EnableDisableIntegrationForm } from 'app-zephyr-forms';
import { WarnIcon } from 'app-zephyr-icons/Warn';

import { ItemDetails } from '../item-details';
import { useStyles } from './styles';

type IconsComponents = {
  [key in DeploymentIntegrationPlatform]: {
    isDisabled: ReactNode | null;
    active: ReactNode | null;
  } | null;
};

const IconsComponents: IconsComponents = {
  cloudflare: {
    isDisabled: <CloudFlareIcon isDisabled={true} />,
    active: <CloudFlareIcon />,
  },
  aws: {
    isDisabled: <AWSIcon isDisabled={true} />,
    active: <AWSIcon />,
  },
  netlify: {
    isDisabled: <NetlifyIcon isDisabled={true} />,
    active: <NetlifyIcon />,
  },
  azure: {
    isDisabled: <AzureIcon isDisabled={true} />,
    active: <AzureIcon />,
  },
  gcp: {
    isDisabled: <GoogleCloudIcon isDisabled={true} />,
    active: <GoogleCloudIcon />,
  },
  fastly: {
    isDisabled: <FastlyIcon />,
    active: <FastlyIcon />,
  },
};

interface DeploymentIntegrationProps {
  integration: DeploymentIntegrationValue | Partial<DeploymentIntegrationValue>;
  isOrgLevel?: boolean;
  isLoading?: boolean;
  setDefaultHandler: () => void;
  integrationNavigateHandler: () => void;
  integrationEditAddNavigateHandler: () => void;
  enableDisableHandler?: () => void;
  workerVersion?: string;
  isLastWorkerVersion?: boolean;
}

export function DeploymentIntegrationItem({
  integration,
  isOrgLevel,
  isLoading,
  setDefaultHandler,
  integrationNavigateHandler,
  integrationEditAddNavigateHandler,
  enableDisableHandler,
  workerVersion,
  isLastWorkerVersion,
  ...props
}: DeploymentIntegrationProps) {
  const { classes, cx, theme } = useStyles();
  const isIntegrationAvailable = (): boolean => {
    if (!integration.platform) return false;

    return availableIntegrationList.includes(integration.platform);
  };

  const integrationNavigate = () => {
    if (!integration.name && !isIntegrationAvailable()) return;
    integrationNavigateHandler();
  };

  const setDefaultIntegration = (event: React.MouseEvent) => {
    event.stopPropagation();
    setDefaultHandler();
  };

  const integrationEditAddNavigate = (event: React.MouseEvent) => {
    event.stopPropagation();
    integrationEditAddNavigateHandler();
  };

  // TODO: should handle the case where there is not integration platform found in UI, need additional design
  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!integration.platform) return <></>;

  return (
    <Stack
      className={cx([
        classes.itemBox,
        !integration.name && !isIntegrationAvailable() ? 'disabled' : '',
        integration.isDefault ? 'active' : '',
      ])}
      direction={'column'}
      spacing={1}
      onClick={integrationNavigate}
    >
      <Stack direction={'row'} spacing={2}>
        <Stack
          justifyContent={'center'}
          alignItems={'center'}
          padding={'8px'}
          width={48}
          height={48}
          className={classes.imgBox}
        >
          {!integration.name && !isIntegrationAvailable()
            ? IconsComponents[integration.platform]?.isDisabled
            : IconsComponents[integration.platform]?.active}
        </Stack>
        <Stack
          alignItems={'flex-start'}
          justifyContent={'center'}
          spacing={0.5}
          width={'calc(100% - 96px)'}
          height={integration.name ? 'fit-content' : '100%'}
        >
          {integration.name && (
            <Stack alignSelf={'flex-end'}>
              {isOrgLevel && !!enableDisableHandler && (
                <Tooltip
                  title={
                    "You can enable/disable an integration. If all integrations are disabled, Zephyr's default built-in cloud will be used."
                  }
                  arrow
                  placement="top"
                >
                  <Stack
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <EnableDisableIntegrationForm
                      isEnabled={!!integration.isEnabled}
                      valueIsChanged={enableDisableHandler}
                      isDisabled={!!isLoading}
                    />
                  </Stack>
                </Tooltip>
              )}
            </Stack>
          )}
          <span
            className={cx([
              classes.itemTitle,
              (!integration.name && !isIntegrationAvailable()) || (integration.name && !integration.isDefault)
                ? 'disabled'
                : '',
            ])}
          >
            {integration.displayName}
          </span>
          {!integration.name && isIntegrationAvailable() && (
            <Button variant="text" className={classes.providerBtn} onClick={integrationEditAddNavigate}>
              Add Integration
            </Button>
          )}
          {!integration.name && !isIntegrationAvailable() && 'Coming Soon...'}
        </Stack>
      </Stack>
      {!!integration.name && (
        <Stack spacing={2} justifyContent={'space-between'} height={'100%'}>
          <Stack>
            {integration.integrationConfig && (
              <ItemDetails
                integrationConfig={integration.integrationConfig}
                deploymentConfig={integration.deploymentConfig}
                type={integration.platform}
              />
            )}
            <Stack direction={'row'} spacing={1} marginTop={2}>
              {!!workerVersion && <span>Worker version {workerVersion}</span>}
              {!workerVersion && <span>Worker version is N/A</span>}
              {!isLastWorkerVersion && (
                <Tooltip
                  title={'Worker version has been updated. Edit your integration to apply the new version.'}
                  arrow
                  placement="top"
                >
                  <Stack>
                    <WarnIcon width={'20px'} height={'20px'} />
                  </Stack>
                </Tooltip>
              )}
            </Stack>
          </Stack>

          <Stack direction={'row'} justifyContent={'space-between'}>
            {integration.name && !integration.isDefault && (
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Button
                  variant="text"
                  className={classes.providerBtn}
                  onClick={(event) => {
                    setDefaultIntegration(event);
                  }}
                >
                  Set as default
                </Button>
              </Stack>
            )}

            {integration.isDefault && (
              <span className={classes.defaultProviderSpan}>Default deployment integration</span>
            )}
            {integration.name && (
              <Stack onClick={integrationEditAddNavigate}>
                <EditIcon color={theme.palette.tx.secondary.alt} width={24} height={24} />
              </Stack>
            )}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}
