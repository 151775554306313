import { useForm } from 'react-hook-form';
import { FormInput } from 'app-zephyr-forms';
import { joiResolver } from '@hookform/resolvers/joi';
import { Stack } from '@mui/material';

import { deleteOrgForm } from 'ze-api-contract/organization-v2/settings/update-org-settings';

import { useStyles } from './styles';
import { useEffect } from 'react';

export type DeleteOrganizationConfirmFormFields = { name: string; confirm: string } & Record<string, string>;

interface DeleteOrganizationConfirmFormProps {
  name: string;
  setFormValid: (value: boolean) => void;
}

const DeleteOrganizationConfirmForm = ({ name, setFormValid }: DeleteOrganizationConfirmFormProps) => {
  const {
    register,

    formState: { errors, isValid },
  } = useForm<DeleteOrganizationConfirmFormFields>({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: joiResolver(deleteOrgForm(name)),
  });

  const { classes } = useStyles();

  useEffect(() => {
    setFormValid(isValid);
  }, [isValid, setFormValid]);

  return (
    <Stack spacing={2} marginBottom={24}>
      <FormInput
        id="confirm-name"
        name={'name'}
        register={register}
        errors={errors}
        label="To verify: type your organization name"
      />
      <FormInput
        id="confirm-word"
        name={'confirm'}
        register={register}
        errors={errors}
        customLabel={
          <>
            To verify: type <span className={classes.accentError}>&nbsp;delete&nbsp;</span>below
          </>
        }
      />
    </Stack>
  );
};

export { DeleteOrganizationConfirmForm };
