import { createQueryKeys } from '@lukemorales/query-key-factory';
import { axios } from 'app-zephyr-axios';
import { useQuery } from '@tanstack/react-query';

import {
  GET_ORG_TEAM_LIST_PATH,
  get_org_team_list_path,
  OrganizationTeamListItem,
} from 'ze-api-contract/organization-v2/get-organization-team-list';

export const orgTeamList = createQueryKeys('organization', {
  teamList: ({ organization, queryParams }: { organization?: string; queryParams?: { name?: string } }) => ({
    queryKey: [GET_ORG_TEAM_LIST_PATH, organization, { ...queryParams }],
    queryFn: async () => {
      if (!organization) return;
      return axios
        .get<{
          entities: OrganizationTeamListItem[];
        }>(get_org_team_list_path({ organization }), { params: queryParams })
        .then((res) => res.data.entities);
    },
  }),
});

/**
 * Get specific organization team list.
 **/
export function useOrganizationTeamList({
  organization,
  queryParams,
}: {
  organization: string | undefined;
  queryParams?: { name?: string };
}) {
  const { isLoading, data: teamList, error } = useQuery(orgTeamList.teamList({ organization, queryParams }));
  return { teamList, isLoading, error };
}
