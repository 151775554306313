import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createQueryKeys } from '@lukemorales/query-key-factory';

import { axios } from 'app-zephyr-axios';
import {
  CurrentSubscription,
  GET_ORGANIZATION_SUBSCRIPTION_PATH,
} from 'ze-api-contract/organization-v1/get-organization-subscription';
import {
  EnforceQuotaSettings,
  POST_ORG_SUBSCRIPTION_ENFORCE_QUOTA,
} from 'ze-api-contract/organization-v1/post-organization-subscription-enforce-quota';

// todo: upgrade to v2 API
/** @deprecated */
export const organizationQueries = createQueryKeys('organization', {
  currentOrgSubscription: {
    queryKey: [GET_ORGANIZATION_SUBSCRIPTION_PATH],
    queryFn: () => axios.get<CurrentSubscription>(GET_ORGANIZATION_SUBSCRIPTION_PATH).then((res) => res.data),
  },
});

/** @deprecated */
export function useCurrentOrgSubscription() {
  // eslint-disable-next-line @typescript-eslint/no-deprecated
  const { data: currentSubscription, error, isLoading } = useQuery(organizationQueries.currentOrgSubscription);

  return { currentSubscription, error, isLoading };
}

/** @deprecated */
export function useEnforceQuote() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: EnforceQuotaSettings) => axios.post(POST_ORG_SUBSCRIPTION_ENFORCE_QUOTA, dto),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['currentOrgSubscription'] });
    },
  });
}
