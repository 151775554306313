import Skeleton from '@mui/material/Skeleton';
import Divider from '@mui/material/Divider';
import { useStyles } from './styles';

export const PinOptionsFormSkeleton = () => {
  const { classes } = useStyles({ options: 4 });

  return (
    <>
      <div className={classes.input}>
        <Skeleton width={300} height={25} />
        <Skeleton width="100%" height={60} />
        <Skeleton width={150} height={20} />
      </div>
      <Divider />
      <div>
        <div className={classes.checkboxContainer}>
          <Skeleton width={250} height={35} />
          <Skeleton width={250} height={35} />
          <Skeleton width={250} height={35} />
          <Skeleton width={250} height={35} />
        </div>
        <div className={classes.buttonGroup}>
          <Skeleton width="100%" height={80} />
          <Skeleton width="100%" height={80} sx={{ marginLeft: 2 }} />
        </div>
      </div>
    </>
  );
};
