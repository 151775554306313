import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  item: {
    margin: 0,
    color: theme.palette.tx.secondary.default,
  },

  value: {
    color: theme.palette.tx.secondary.alt,
  },
}));
