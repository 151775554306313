import { SVGProps } from 'react';

export type EditIconProps = SVGProps<SVGSVGElement> & {
  color?: string;
};

export const EditIcon = ({ color = '#424960', ...props }: EditIconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M5 18.0029H9.24C9.37161 18.0036 9.50207 17.9784 9.62391 17.9286C9.74574 17.8789 9.85656 17.8055 9.95 17.7129L16.87 10.7829L19.71 8.00287C19.8037 7.90991 19.8781 7.7993 19.9289 7.67745C19.9797 7.55559 20.0058 7.42488 20.0058 7.29287C20.0058 7.16086 19.9797 7.03015 19.9289 6.90829C19.8781 6.78643 19.8037 6.67583 19.71 6.58287L15.47 2.29287C15.377 2.19914 15.2664 2.12475 15.1446 2.07398C15.0227 2.02321 14.892 1.99707 14.76 1.99707C14.628 1.99707 14.4973 2.02321 14.3754 2.07398C14.2536 2.12475 14.143 2.19914 14.05 2.29287L11.23 5.12287L4.29 12.0529C4.19732 12.1463 4.12399 12.2571 4.07423 12.379C4.02446 12.5008 3.99924 12.6313 4 12.7629V17.0029C4 17.2681 4.10536 17.5224 4.29289 17.71C4.48043 17.8975 4.73478 18.0029 5 18.0029ZM14.76 4.41287L17.59 7.24287L16.17 8.66287L13.34 5.83287L14.76 4.41287ZM6 13.1729L11.93 7.24287L14.76 10.0729L8.83 16.0029H6L6 13.1729ZM21 20.0029L3 20.0029C2.73478 20.0029 2.48043 20.1082 2.29289 20.2958C2.10536 20.4833 2 20.7377 2 21.0029C2 21.2681 2.10536 21.5224 2.29289 21.71C2.48043 21.8975 2.73478 22.0029 3 22.0029L21 22.0029C21.2652 22.0029 21.5196 21.8975 21.7071 21.71C21.8946 21.5224 22 21.2681 22 21.0029C22 20.7377 21.8946 20.4833 21.7071 20.2958C21.5196 20.1082 21.2652 20.0029 21 20.0029Z"
      fill={color}
    />
  </svg>
);
