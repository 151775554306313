import * as Joi from 'joi';

import {
  PROJECT_DISPLAY_NAME_VALIDATOR,
  PROJECT_NAME_JOI_VALIDATOR_MESSAGES,
  PROJECT_NAME_VALIDATOR,
  validationErrorMessagesDescriptionField,
} from '../../validation';

export const name = Joi.string()
  .pattern(PROJECT_NAME_VALIDATOR.pattern.value)
  .min(PROJECT_NAME_VALIDATOR.minLength.value)
  .max(PROJECT_NAME_VALIDATOR.maxLength.value)
  .required()
  .messages(PROJECT_NAME_JOI_VALIDATOR_MESSAGES);
export const displayName = Joi.string()
  .pattern(PROJECT_DISPLAY_NAME_VALIDATOR.pattern.value)
  .min(3)
  .max(50)
  .messages(PROJECT_NAME_JOI_VALIDATOR_MESSAGES);
export const description = Joi.string().empty('').max(200).messages(validationErrorMessagesDescriptionField);
export const isPrivate = Joi.boolean().required();

export const baseSchema = Joi.object({
  description,
  isPrivate,
  displayName,
});
