import { ReactElement } from 'react';
import { makeStyles } from 'tss-react/mui';

import { ErrorCard } from 'app-zephyr-components/ErrorCard';
import { SadFaceIcon } from 'app-zephyr-icons/SadFace';
import { LightHorizontalWithLogoImg } from 'app-zephyr-components/LightHorizontalWithLogoImg';
import { Link, useSearch } from '@tanstack/react-router';
import { RouteNames } from 'app-zephyr-routes';

const useStyles = makeStyles()((theme) => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
  },
  navbar: {
    padding: '32px',
    display: 'flex',
    alignItems: 'center',
  },
  line: {
    width: '100%',
    flex: '1',
    height: '1px',
    marginLeft: '32px',
    border: 'none',
    borderBottom: theme.palette.border.secondary,
  },
  container: {
    display: 'flex',
    width: '100%',
    height: '80vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    display: 'block',
    textAlign: 'center',
  },
  logoLink: {
    cursor: 'pointer',
  },
}));

export default function SidePanelLoginError() {
  // todo: valorkin
  const params = useSearch({ strict: false });
  const { classes } = useStyles();
  const reduceFn = (p: never) => p;

  return (
    <div className={classes.page}>
      <div className={classes.navbar}>
        <Link to={RouteNames.PERSONAL_DASHBOARD} search={reduceFn} params={reduceFn}>
          <LightHorizontalWithLogoImg class={classes.logoLink} />
        </Link>
        <hr className={classes.line} />
      </div>
      <div className={classes.container}>
        <ErrorCard
          icon={SadFaceIcon}
          title="Oooops!"
          subtitle={params.error ?? 'Something went Wrong'}
          description={
            params.errorMessage ?? 'Something unexpected happened while trying to login to the CLI, please try again.'
          }
          image={{
            url: '/svg/vectors/power-plugs-off.svg',
            alt: 'Disconnection Illustration',
          }}
        />
      </div>
    </div>
  );
}

SidePanelLoginError.getLayout = (page: ReactElement) => {
  return page;
};
