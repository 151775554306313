import { Link } from '@tanstack/react-router';
import { ErrorCard } from 'app-zephyr-components/ErrorCard';
import { SadFaceIcon } from 'app-zephyr-icons/SadFace';
import { LightHorizontalWithLogoImg } from 'app-zephyr-components/LightHorizontalWithLogoImg';

import { RouteNames } from 'app-zephyr-routes';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
  },
  navbar: {
    padding: '32px',
    display: 'flex',
    alignItems: 'center',
  },
  line: {
    width: '100%',
    flex: '1',
    height: '1px',
    marginLeft: '32px',
    border: 'none',
    borderBottom: theme.palette.border.secondary,
  },
  container: {
    display: 'flex',
    width: '100%',
    height: '80vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    display: 'block',
    textAlign: 'center',
  },
  logoLink: {
    cursor: 'pointer',
  },
}));

export default function NotAuthorizedError() {
  const { classes } = useStyles();

  return (
    <div className={classes.page}>
      <div className={classes.navbar}>
        <Link to={RouteNames.PERSONAL_DASHBOARD} search={(p: never) => p} params={(p: never) => p}>
          <LightHorizontalWithLogoImg class={classes.logoLink} />
        </Link>
        <hr className={classes.line} />
      </div>
      <div className={classes.container}>
        <ErrorCard
          icon={SadFaceIcon}
          title="Authorization Error"
          subtitle="Sorry, you don’t have an access to this page"
          description="Request access from the manager of your organization"
          image={{
            url: '/svg/vectors/not-authorized.svg',
            alt: 'Not Authorized Illustration',
          }}
        />
      </div>
    </div>
  );
}
