import { axios } from 'app-zephyr-axios';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useQuery } from '@tanstack/react-query';

import {
  get_org_people_list_path,
  GET_ORG_PEOPLE_LIST_PATH,
  GetOrgPeopleListResponse,
  GetPeopleListParams,
} from 'ze-api-contract/organization-v2/get-people-list';

export const orgPeopleList = createQueryKeys('organization', {
  peopleList: ({ organization, perPage, page, name }: GetPeopleListParams) => ({
    queryKey: [GET_ORG_PEOPLE_LIST_PATH, organization, page, perPage, name],
    queryFn: async () => {
      if (!organization) return;
      return axios
        .get<GetOrgPeopleListResponse>(get_org_people_list_path({ organization }), {
          params: {
            page,
            perPage: Number.isInteger(page) ? perPage : undefined,
            name,
          },
        })
        .then((res) => res.data);
    },
  }),
});

/**
 * Get specific organization people list with pagination.
 **/
export function useOrganizationPeopleList(params: GetPeopleListParams) {
  const { data, isLoading, error } = useQuery(orgPeopleList.peopleList(params));
  const peopleList = data?.entities ?? [];
  const peopleListCount = data?.pageMetadata?.lastPage ?? 0;
  const peopleLimitExceeded = data?.peopleLimitExceeded ?? false;

  return { peopleList, peopleListCount, isLoading, error, peopleLimitExceeded };
}
