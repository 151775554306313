import React, { useMemo } from 'react';
// @mui
import Container from '@mui/material/Container';
// elements
import { Header } from './_elements';
// constants
import { INavItem, NavigationItemsParams } from 'app-zephyr-constants';
import { useTypedParams } from 'app-zephyr-routes';
// styles
import { useStyles } from './styles';
import { User } from 'ze-api-contract/user-v2/get-current-user';

import { useOrganizationByName } from 'app-zephyr-domains/organization';
import {
  useUserCanManageOrg,
  useUserCanManageProj,
  useUserCanManageApp,
  useUserCanManagePeople,
} from 'app-zephyr-domains/user';

export interface LayoutProps {
  children: React.ReactNode;
  getNavigationItems?: (params: NavigationItemsParams) => INavItem[];
  currentUser?: User;
}

const Layout: React.FC<LayoutProps> = ({ children, currentUser, getNavigationItems = () => [] }) => {
  const { classes } = useStyles();
  const { organization, project, application, user } = useTypedParams();
  const username = currentUser?.username ?? user;

  const { organization: organizationValue } = useOrganizationByName(organization);
  const { can: canOrg } = useUserCanManageOrg({ organization: organizationValue });
  const { can: canProj } = useUserCanManageProj({ organization: organizationValue, project });
  const { can: canApp } = useUserCanManageApp({ organization: organizationValue, project, application });
  const { can: canPeople } = useUserCanManagePeople({ organization: organizationValue, peopleLimitExceeded: false });

  const navigationItems: INavItem[] = useMemo(
    () =>
      getNavigationItems({
        organization: { name: organization ?? '', settingsAvailable: canOrg?.updateOrganizationSettings },
        project: { name: project ?? '', settingsAvailable: canProj?.updateProjectSettings },
        application: { name: application ?? '', settingsAvailable: canApp?.updateApplicationSettings },
        user: { name: user ?? '' },
      }),
    [application, getNavigationItems, organization, project, user, canOrg, canProj, canApp],
  );

  return (
    <div className={classes.root}>
      <Header
        navigationItems={navigationItems}
        canCreateProject={canOrg?.createProject}
        canCreateTeam={canPeople?.managePeople}
        username={username}
        name={currentUser?.name}
      />
      <div className={classes.main}>
        <main className={classes.content}>
          <Container maxWidth={false} className={classes.container}>
            {children}
          </Container>
        </main>
      </div>
    </div>
  );
};

export { Layout };
