export function isEmailDomainValid(email: string): boolean {
  const pattern = /^@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

  return pattern.test(email);
}

export function isEmailValid(email: string): boolean {
  const pattern =
    /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

  const [user] = email.split('@');

  // due to https://auth0.com/docs/manage-users/user-accounts/create-users max size of user part is 64
  if (user.length > 64) {
    return false;
  }

  return pattern.test(email);
}
