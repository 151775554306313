import { axios } from 'app-zephyr-axios';
// hooks
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
// contracts
import { POST_USER_UPSERT_PATH, type PostUserUpsertBodyReq } from 'ze-api-contract/user-v2/upsert';
// utils
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const userUpsertQueryKey = createQueryKeys('user', {
  upsert: ({ user }: PostUserUpsertBodyReq) => ({
    queryKey: [POST_USER_UPSERT_PATH, user],
    queryFn: async () => {
      if (!user) return Promise.resolve(null);

      return axios.post(POST_USER_UPSERT_PATH, user);
    },
  }),
});

export function useUserUpsert() {
  const { user } = useAuth0();
  const result = useQuery(userUpsertQueryKey.upsert({ user }));

  return result;
}
