import { SVGProps } from 'react';

export type CreditCardIconProps = SVGProps<SVGSVGElement> & {
  color?: string;
};

export const CreditCardIcon = ({ color = '#fff', ...props }: CreditCardIconProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.75 14.625H9.9C10.1785 14.625 10.4455 14.5144 10.6425 14.3175C10.8394 14.1205 10.95 13.8535 10.95 13.575C10.95 13.2965 10.8394 13.0295 10.6425 12.8325C10.4455 12.6356 10.1785 12.525 9.9 12.525H6.75C6.47152 12.525 6.20445 12.6356 6.00754 12.8325C5.81062 13.0295 5.7 13.2965 5.7 13.575C5.7 13.8535 5.81062 14.1205 6.00754 14.3175C6.20445 14.5144 6.47152 14.625 6.75 14.625ZM19.35 4.125H4.65C3.81457 4.125 3.01335 4.45687 2.42261 5.04761C1.83187 5.63835 1.5 6.43957 1.5 7.275V16.725C1.5 17.5604 1.83187 18.3616 2.42261 18.9524C3.01335 19.5431 3.81457 19.875 4.65 19.875H19.35C20.1854 19.875 20.9866 19.5431 21.5774 18.9524C22.1681 18.3616 22.5 17.5604 22.5 16.725V7.275C22.5 6.43957 22.1681 5.63835 21.5774 5.04761C20.9866 4.45687 20.1854 4.125 19.35 4.125ZM20.4 16.725C20.4 17.0035 20.2894 17.2705 20.0925 17.4675C19.8955 17.6644 19.6285 17.775 19.35 17.775H4.65C4.37152 17.775 4.10445 17.6644 3.90754 17.4675C3.71062 17.2705 3.6 17.0035 3.6 16.725V10.425H20.4V16.725ZM20.4 8.325H3.6V7.275C3.6 6.99652 3.71062 6.72945 3.90754 6.53254C4.10445 6.33562 4.37152 6.225 4.65 6.225H19.35C19.6285 6.225 19.8955 6.33562 20.0925 6.53254C20.2894 6.72945 20.4 6.99652 20.4 7.275V8.325Z"
      fill={color}
    />
  </svg>
);
