export type DeployTargetParams = 'all' | DeployTarget;

export enum DeployTarget {
  environment = 'environment',
  tag = 'tag',
}

export interface DeployVersionBodyReq {
  id: string;
  deployTarget: DeployTarget;
  deployTargetId: string;
  snapshot_id: string;
}

export interface DeployedVersion {
  id: string;
  versionName: string;
  deployTarget: DeployTarget;
  deployTargetName: string;
  deployedAt: string;
  authorName: string;
  authorUserName: string;
  hostUrl: string;
  previousVersion?: {
    versionName: string;
    id: string;
    deployTarget: DeployTarget;
    deployTargetId: string;
    snapshot_id: string;
    // TODO: Remove this after implementing the transfer snapshot to another provider feature
    available: boolean;
  };
}

export interface RollbackVersionBodyReq {
  id: string;
}

export interface ApplicationVersionDeployTarget {
  id: string;
  name: string;
  deployTarget: DeployTarget;
}

export enum TypeLabels {
  all = 'All',
  target_tag = 'Target: Tags',
  target_env = 'Target: Environments',
}

export enum TypeLabelsValues {
  all = 'all',
  target_tag = 'tag',
  target_env = 'environment',
}

export interface GetOrgVersionsDeployedQueryParams {
  type: DeployTargetParams;
  name?: string;
}
