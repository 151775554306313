import { AxiosResponse } from 'axios';
import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { axios } from 'app-zephyr-axios';
// queries
import { Team } from 'ze-api-contract/team/interfaces';
import { OrganizationValue } from 'ze-api-contract/organization-v2/get-by-name';

import { orgTeamList } from '../organization';

type CreateTeamMutationProps = Pick<OrganizationValue, 'name'>;
export type CreateTeamMutation = UseMutationResult<
  AxiosResponse<{
    value: Team;
  }>,
  Error,
  CreateTeamMutationProps
>;

/**
 * POST create new team.
 **/
export function useCreateTeam(orgName: string): CreateTeamMutation {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ name }: CreateTeamMutationProps) => axios.post(`/v2/organization/${orgName}/team`, { name }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: orgTeamList.teamList({ organization: orgName }).queryKey });
    },
  });
}
