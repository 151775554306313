import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useQuery } from '@tanstack/react-query';

import { axios } from 'app-zephyr-axios';
import { User } from 'ze-api-contract/user-v2/get-current-user';
import { GET_USER_PATH, get_user_path } from 'ze-api-contract/user-v2/get-user';

const userQuery = createQueryKeys('user', {
  byUsername: (username?: string) => ({
    queryKey: [GET_USER_PATH, username],
    queryFn: async () => {
      if (!username) return Promise.resolve(undefined);

      return axios.get<{ value: User }>(get_user_path({ username })).then((res) => res.data.value);
    },
  }),
});

export function useUser(username?: string) {
  const { data: user, isLoading, error } = useQuery(userQuery.byUsername(username));

  return {
    user,
    isLoading,
    error,
  };
}
