import { simplePath } from 'ze-api-contract/utils';

export const GET_ORG_PROJECT_LIST_PATH: string = `/v2/organization/:organization/project-list`;

export function get_org_project_list_path(params: GetOrgProjectListParams): string {
  return simplePath(GET_ORG_PROJECT_LIST_PATH, params);
}

export interface GetOrgProjectListParams {
  organization: string;
}

export interface GetOrgProjectListQueryParams {
  type?: ProjectListQueryType;
  sort?: ProjectListQuerySort;
  name?: string;
  limit?: number;
  offset?: number;
  latestDays?: number;
}

export type ProjectListQueryType = 'all' | 'created_by_me' | 'public' | 'private' | 'removed';
export type ProjectListQuerySort = 'name_asc' | 'name_desc' | 'latest_updates';

type ProjectListInclude = 'application' | 'contributors';
export interface GetOrgProjectListQuery {
  include?: ProjectListInclude[];
}
export interface ProjectContributor {
  id: string;
  name: string;
  type: string;
  avatarColor: string;
  //todo: should be added type member | team to know which link should be generated on ui https://github.com/ZephyrCloudIO/zephyr-cloud-io/issues/557
}

export interface ProjectListItem {
  id: string;
  name: string;
  displayName: string;

  applications?: {
    id: string;
    name: string;
  }[];
  contributors?: ProjectContributor[];

  // todo: implement on backend
  visibility: 'public' | 'private';
  // todo: implement on backend
  deletedAt: string;
  updatedAt: string;
  authorId: string;
  author: {
    id: string;
    name: string;
  };
  // todo: implement on backend
  counters: {
    applications?: number;
    remotes?: number;
  };
  // todo: implement on backend
  tags: string[];
  description?: string;
  // todo: implement on backend
  hostPlatform: string;
  organizationId: string;
}
