import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  autocomplete: {
    '.MuiInputBase': {
      '&-root': {
        ...theme.typography.regular3,
        background: theme.palette.bg.primary.default,
        borderRadius: 8,
        gap: theme.spacing(1),
      },

      '&-root.MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
        padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
      },

      '&-root.MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input': {
        ...theme.typography.regular2,
        padding: 0,
      },
    },

    '.MuiOutlinedInput-notchedOutline': {
      border: theme.palette.border.secondary,
    },

    '.MuiChip': {
      '&-root': {
        ...theme.typography.regular3,
        background: theme.palette.bg.secondary.default,
        border: 'none',
        borderRadius: '5px',
        height: 'auto',
        gap: theme.spacing(0.5),
        lineHeight: '140%',
        margin: 0,
        padding: `${theme.spacing(0.25)} ${theme.spacing(1)}`,
      },

      '&-label': {
        padding: 0,
      },

      '&-root .MuiChip-deleteIcon': {
        width: '1rem',
        height: '1rem',
        color: theme.palette.tx.secondary.default,
        margin: 0,
      },
    },
  },
  errorMessage: {
    color: theme.palette.tx.error.primary,
    fontSize: '12px',
    margin: 0,
  },
}));
