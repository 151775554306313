import * as Joi from 'joi';
import { simplePath } from 'ze-api-contract/utils';
import { Condition } from './interfaces';
import {
  baseSchema,
  baseApplicationVersionId,
  formApplicationVersionId,
  baseCustomDNSNames,
  channelTypeSchema,
  conditionValueSchema,
  formSchemaCondition,
  formCustomDnsNames,
} from './validation';

interface UpdateApplicationEnvironmentParams {
  organization: string;
  project: string;
  application: string;
  environment: string;
}

export const UPDATE_APPLICATION_ENVIRONMENT =
  '/v2/application/:organization/:project/:application/environment/:environment';

export function update_application_environment_path(params: UpdateApplicationEnvironmentParams): string {
  return simplePath(UPDATE_APPLICATION_ENVIRONMENT, params);
}

export interface UpdateApplicationEnvironment {
  name?: string;
  condition?: Condition;
  applicationVersionId?: string;
  description?: string;
  customDnsNames?: string[];
}

export const schema = baseSchema.append<UpdateApplicationEnvironment>({
  applicationVersionId: baseApplicationVersionId,
  condition: Joi.object({
    channel: channelTypeSchema,
    value: conditionValueSchema,
  }),
  customDnsNames: baseCustomDNSNames,
});

export const formSchema = baseSchema.append<UpdateApplicationEnvironment>({
  applicationVersionId: formApplicationVersionId,
  condition: formSchemaCondition,
  customDnsNames: formCustomDnsNames,
});
