import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  input: {
    '&.error': {
      '&.MuiInputBase-root': {
        border: theme.palette.border.error.default,
        boxShadow: 'none !important',

        '&.Mui-focused': {
          border: theme.palette.border.error.solid,
          boxShadow: '0px 0px 0px 4px #F044383D, 0px 1px 2px 0px #1018280D !important',
        },
      },
    },
    '&.MuiInputBase-root': {
      background: theme.palette.bg.primary.default,
      border: theme.palette.border.secondary,
      borderRadius: 8,
      marginTop: 0,
      color: theme.palette.tx.primary,

      '&:before': {
        display: 'none',
      },
      '&:focused': {
        border: theme.palette.border.primary,
      },

      '&.disabled': {
        border: theme.palette.border.disabled.subtle,
        backgroundColor: theme.palette.bg.quarterary,
        cursor: 'not-allowed',
      },
    },

    '& .MuiInputBase-input:disabled': {
      opacity: ' .7',
      cursor: 'not-allowed',
    },

    '.MuiInputBase-root.Mui-focused': {
      border: theme.palette.border.primary,

      '& .MuiInputBase-input': {
        color: theme.palette.tx.primary,

        '&::placeholder': {
          color: theme.palette.tx.placeholder.default,
        },
      },
    },

    '& .MuiInputBase-input': {
      padding: '10px 16px',
      fontSize: theme.spacing(2),
      borderRadius: 8,
    },

    '& fieldset': {
      display: 'none !important',
    },
  },
}));
