import { HTMLAttributes } from 'react';
import Stack from '@mui/material/Stack';

import { useStyles } from './styles';

export function FormBox({ title, children }: HTMLAttributes<HTMLElement> & { title?: string }) {
  const { classes } = useStyles();

  return (
    <Stack className={classes.container} spacing={2}>
      {!!title && <h2>{title}</h2>}
      <Stack className={classes.box}>{children}</Stack>
    </Stack>
  );
}
