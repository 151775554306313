import { RouteNames } from './route-names';
import { simplePath } from 'ze-api-contract/utils';

const org_cache = new Map<string, string>();

interface OrganizationProps {
  organization?: { name: string | undefined };
  query?: string;
}

function cache_org(path: string, { organization, query }: OrganizationProps): string {
  const value = organization?.name;
  if (!value) return '/';
  const cache_key = `${path}:${value}:${query ?? ''}`;

  if (org_cache.has(cache_key)) {
    return org_cache.get(cache_key) ?? '/';
  }

  const url = simplePath(path, { organization: value }) + (query ?? '');
  org_cache.set(cache_key, url);

  return url;
}

export function organization_path(props: OrganizationProps): string {
  return cache_org(RouteNames.ORGANIZATION, props);
}

export function organization_projects_path(props: OrganizationProps): string {
  return cache_org(RouteNames.ORGANIZATION_PROJECTS, props);
}

export function organization_managed_applications_path(props: OrganizationProps): string {
  return cache_org(RouteNames.ORGANIZATION_MANAGED_APPLICATIONS, props);
}

export function organization_unmanaged_applications_path(props: OrganizationProps): string {
  return cache_org(RouteNames.ORGANIZATION_UNMANAGED_APPLICATIONS, props);
}

export function organization_unmanaged_application_new_path(props: OrganizationProps): string {
  return cache_org(RouteNames.ORGANIZATION_UNMANAGED_APPLICATION_NEW, props);
}

export function organization_unmanaged_application_edit_path({
  organization,
  application,
}: OrganizationProps & { application: { name: string } }): string {
  if (!organization?.name || !application.name) return '/';
  const url = simplePath(RouteNames.ORGANIZATION_UNMANAGED_APPLICATION_EDIT, {
    organization: organization.name,
    application: application.name,
  });
  return url;
}

export function organization_team_list_path(props: OrganizationProps): string {
  return cache_org(RouteNames.ORGANIZATION_TEAM_LIST, props);
}

export function organization_team_path({
  organization,
  team,
  query,
}: OrganizationProps & { team: { id: string | undefined } }): string {
  if (!organization?.name || !team.id) return '/';
  const url = simplePath(RouteNames.ORGANIZATION_TEAM, { organization: organization.name, team: team.id });
  return url + (query ?? '');
}

export function organization_people_path(props: OrganizationProps): string {
  return cache_org(RouteNames.ORGANIZATION_PEOPLE, props);
}

export function organization_settings_path(props: OrganizationProps): string {
  return cache_org(RouteNames.ORGANIZATION_SETTINGS, props);
}

export function organization_membership_path(props: OrganizationProps): string {
  return cache_org(RouteNames.ORGANIZATION_MEMBERSHIP, props);
}

export function organization_settings_payment_methods_path(props: OrganizationProps): string {
  return cache_org(RouteNames.ORGANIZATION_PAYMENT_METHODS, props);
}

export function organization_settings_billing_history_path(props: OrganizationProps): string {
  return cache_org(RouteNames.ORGANIZATION_BILLING_HISTORY, props);
}

export function organization_subscription_path(props: OrganizationProps): string {
  return cache_org(RouteNames.ORGANIZATION_SUBSCRIPTION, props);
}

export function organization_subscription_manage_path(props: OrganizationProps): string {
  return cache_org(RouteNames.ORGANIZATION_SUBSCRIPTION_MANAGE, props);
}

export function organization_deployment_integrations_list_path(props: OrganizationProps): string {
  return cache_org(RouteNames.ORGANIZATION_DEPLOYMENT_INTEGRATION_LIST, props);
}

export function organization_deployment_integrations_path({
  organization,
  type,
}: OrganizationProps & { type: { name: string | undefined } }): string {
  if (!organization?.name || !type.name) return '/';
  const url = simplePath(RouteNames.ORGANIZATION_DEPLOYMENT_INTEGRATION_OVERVIEW, {
    organization: organization.name,
    integrationType: type.name,
  });
  return url;
}

export function organization_deployment_integrations_new_path({
  organization,
  type,
}: OrganizationProps & { type: { name: string | undefined } }): string {
  if (!organization?.name || !type.name) return '/';
  const url = simplePath(RouteNames.ORGANIZATION_DEPLOYMENT_INTEGRATION_ADD, {
    organization: organization.name,
    integrationType: type.name,
  });
  return url;
}

export function organization_deployment_integrations_name_path({
  organization,
  type,
  name,
}: OrganizationProps & { type: { name: string | undefined }; name: { name: string } }): string {
  if (!organization?.name || !type.name || !name.name) return '/';
  const url = simplePath(RouteNames.ORGANIZATION_DEPLOYMENT_INTEGRATION, {
    organization: organization.name,
    integrationType: type.name,
    integrationName: name.name,
  });
  return url;
}

export function organization_deployment_integrations_edit_path({
  organization,
  type,
  name,
}: OrganizationProps & { type: { name: string | undefined }; name: { name: string } }): string {
  if (!organization?.name || !type.name || !name.name) return '/';
  const url = simplePath(RouteNames.ORGANIZATION_DEPLOYMENT_INTEGRATION_EDIT, {
    organization: organization.name,
    integrationType: type.name,
    integrationName: name.name,
  });
  return url;
}
