import { ReactNode, useRef } from 'react';
import { get } from 'lodash';
import { UseFormRegister, Path, FieldValues, FieldName, FieldErrors } from 'react-hook-form';
import { ErrorMessage, FieldValuesFromFieldErrors } from '@hookform/error-message';

import { Label } from '../../_elements/label';
import { Input, InputProps, ErrorMessage as FormErrorMessage } from '../../_elements';
import { useStyles } from './styles';
import React from 'react';

export interface FormInputProps<FormValuesProps extends FieldValues> extends Omit<InputProps, 'name'> {
  /**
   * input and error name
   */
  name: Path<FormValuesProps>;
  /**
   * react-hook-form registering control
   */
  register: UseFormRegister<FormValuesProps>;
  /**
   * errors object
   */
  errors?: FieldErrors<FormValuesProps>;
  hint?: string | ReactNode;
  startAdornment?: ReactNode;
}

const FormInput = <FormValuesProps extends Record<string, unknown>>({
  name,
  register,
  errors,
  className,
  hint,
  label,
  customLabel,

  ...props
}: FormInputProps<FormValuesProps>): React.JSX.Element => {
  const { classes, cx } = useStyles();

  // If the name is in a FieldArray, it will be 'fields.index.fieldName' and errors[name] won't return anything, so we are using lodash get
  const errorMessages = get(errors, name);

  const hasError = !!(errors && errorMessages);

  const { ref, ...rest } = register(name);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleLabelClick = () => {
    inputRef.current?.focus();
  };

  const setRefs = (element: HTMLInputElement | null) => {
    ref(element);
    inputRef.current = element;
  };

  return (
    <div className={cx([className, classes.container])} aria-live="polite" data-testid="form-input">
      {(!!customLabel || !!label) && (
        <Label onClick={handleLabelClick}>
          <p className={classes.label}> {customLabel ?? label}</p>
        </Label>
      )}
      <Input {...props} {...rest} hasError={hasError} placeholder={props.placeholder} ref={setRefs} />
      {hint && <div className={classes.hint}>{hint}</div>}
      <ErrorMessage
        errors={errors}
        name={name as unknown as FieldName<FieldValuesFromFieldErrors<FieldErrors<FormValuesProps>>>}
        render={({ message }) => <FormErrorMessage className={'error-message'}>{message}</FormErrorMessage>}
      />
    </div>
  );
};

export { FormInput };
