import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  button: {
    padding: `${theme.spacing(1).toString()} ${theme.spacing(2).toString()}`,
    borderRadius: theme.borderRadius['4xl'],
    minWidth: '8rem',
    height: '2.6rem',
    whiteSpace: 'nowrap',
    width: 'fit-content',

    '&.MuiButton-contained': {
      border: theme.palette.border.primary,
      background: theme.palette.tx.white,
      borderRadius: theme.borderRadius.full,
      color: theme.palette.bg.primary.default,
      transition: 'background-color 0.3s ease, transform 0.2s ease',
      boxShadow: theme.boxShadow.contained,
      svg: {
        fill: theme.palette.bg.primary.default,
      },
      '&:hover': {
        color: theme.palette.bg.primary.default,
        border: theme.palette.border.secondary,
        background: theme.palette.tx.secondary.default,
      },
      '&:focused': {
        color: theme.palette.tx.secondary.default,
        border: theme.palette.border.secondary,
        boxShadow: theme.boxShadow['contained-focused'],
      },
      '&.Mui-disabled': {
        border: theme.palette.border.none,
      },
    },

    '&.MuiButton-outlined': {
      border: theme.palette.border.secondary,
      color: theme.palette.tx.secondary.default,
      borderRadius: theme.borderRadius.full,
      padding: `${theme.spacing(1)} ${theme.spacing(3.2)}`,
      fontWeight: 600,
      fontSize: 14,

      '&:hover': {
        color: theme.palette.bg.primary.default,
        border: theme.palette.border.secondary,
        background: theme.palette.tx.primary,
      },
      '&:focused': {
        color: theme.palette.tx.secondary.default,
        border: theme.palette.border.secondary,
      },
      '&.Mui-disabled': {
        border: theme.palette.border.none,
      },
    },
    '&.MuiButton-containedWarning': {
      backgroundColor: theme.palette.brand.orange[700],
      color: theme.palette.tx.primary,
      borderColor: theme.palette.brand.orange[400],
      '&:hover': {
        background: theme.palette.brand.orange[600],
        color: theme.palette.tx.primary,
        borderColor: theme.palette.brand.orange[400],
      },
      '&:focused': {
        boxShadow: theme.boxShadow['contained-focused'],
        borderColor: theme.palette.brand.orange[400],
        color: theme.palette.tx.primary,
      },
    },

    '&.MuiButton-containedError': {
      backgroundColor: theme.palette.brand.pink[700],
      borderColor: theme.palette.brand.pink[400],
      color: theme.palette.tx.primary,
      '&:hover': {
        background: theme.palette.brand.pink[600],
        color: theme.palette.tx.primary,
        borderColor: theme.palette.brand.pink[400],
      },
      '&:focused': {
        boxShadow: theme.boxShadow['contained-focused'],
        borderColor: theme.palette.brand.pink[400],
        color: theme.palette.tx.primary,
      },
    },
  },
  disabled: {
    color: `${theme.palette.tx.disabled} !important`,
    backgroundColor: `${theme.palette.bg.disabled.default} !important`,
  },
}));
