import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  title: {
    fontSize: 17,
    fontWeight: 700,
    color: theme.palette.tx.secondary.default,
  },
  label: {
    fontSize: 14,
    fontWeight: 700,
    color: theme.palette.tx.secondary.default,
  },
  tag: {
    display: 'inline-block',
    fontFamily: 'Inter',
    backgroundColor: '#202A1D',
    color: '#54C752',
  },
}));
