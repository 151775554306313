import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  container: {
    '& label': {
      marginRight: 0,
      marginLeft: 0,
    },

    '& .MuiFormControlLabel-label': {
      marginLeft: theme.spacing(1),
    },

    '& .MuiSwitch-root': {
      width: '48px',
      height: '24px',
      padding: 0,

      '&:hover': {
        '& .MuiSwitch-track': {
          backgroundColor: '#646464',
        },
      },

      '& .MuiSwitch-track': {
        backgroundColor: theme.palette.bg.quarterary,
        borderRadius: '24px',
        border: '1px solid #D7D3D0',
        transition: 'backgroundColor ease 0.3s',
      },

      '& .MuiSwitch-thumb': {
        width: '16px',
        height: '16px',
        // todo: color should be changed or include into color schema
        backgroundColor: '#D7D3D0',
      },

      '& .MuiSwitch-switchBase': {
        top: '4px',
        left: '7px',
        padding: 0,

        '&.Mui-checked': {
          transform: 'translateX(18px)',

          '& .MuiSwitch-thumb': {
            backgroundColor: '#3EC594',
          },
        },

        '&.Mui-checked+.MuiSwitch-track': {
          border: '1px solid #3EC594',
          backgroundColor: theme.palette.bg.quarterary,
        },
      },
    },
  },
}));
