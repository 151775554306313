import { simplePath } from 'ze-api-contract/utils';

export const DELETE_APPLICATION_ENVIRONMENT =
  '/v2/application/:organization/:project/:application/environment/:environment';

interface DeleteApplicationEnvironmentParams {
  organization: string;
  project: string;
  application: string;
  environment: string;
}

export function delete_application_environment_path(params: DeleteApplicationEnvironmentParams): string {
  return simplePath(DELETE_APPLICATION_ENVIRONMENT, params);
}
