import React from 'react';
import { Helmet } from 'react-helmet-async';
// types
import { ISEO } from './interfaces';
/**
 * Common Helmet page with a basic seo meta tags.
 * @param title main page title.
 * @param description optional page description.
 * @param name optional creator name.
 * @param type optional page type.
 * @param children optional other tags.
 * @returns head content.
 */
const SEO: React.FC<ISEO> = ({ title, description = `${title} page`, name, type, children }) => (
  <>
    <Helmet>
      {/* Standard metadata tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      {/* End standard metadata tags */}
      {/* Facebook tags */}
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {/* End Facebook tags */}
      {/* Twitter tags */}
      <meta name="twitter:creator" content={name} />
      <meta name="twitter:card" content={type} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {/* End Twitter tags */}
    </Helmet>
    {children}
  </>
);

export { SEO };
