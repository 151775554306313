import { simplePath } from 'ze-api-contract/utils';
import { ApplicationTag } from './interfaces';

export const GET_APPLICATION_TAG_LIST = '/v2/application/:organization/:project/:application/tag-list';

interface GetApplicationTagListParams {
  organization: string;
  project: string;
  application: string;
}

export function get_application_tag_list_path(params: GetApplicationTagListParams): string {
  return simplePath(GET_APPLICATION_TAG_LIST, params);
}

export type ApplicationTagValue = ApplicationTag;
