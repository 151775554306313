import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useQuery } from '@tanstack/react-query';

import { axios } from 'app-zephyr-axios';
import { GET_TEAM_MEMBERS_PATH, get_team_member_list_path } from 'ze-api-contract/team/get-team-members';
import { TeamMember } from 'ze-api-contract/team/interfaces';

export const teamMemberQuery = createQueryKeys('teamMember', {
  list: (teamId?: string, queryParams?: { name?: string }) => ({
    queryKey: [GET_TEAM_MEMBERS_PATH, teamId ?? '', { ...queryParams }],
    queryFn: async () => {
      if (!teamId) return Promise.resolve(undefined);

      return axios
        .get<{
          value: TeamMember[];
        }>(get_team_member_list_path({ teamId }), { params: queryParams })
        .then((res) => res.data.value);
    },
  }),
});

export function useTeamMemberList({ teamId, queryParams }: { teamId?: string; queryParams?: { name?: string } }) {
  const { isLoading, data: memberList, error } = useQuery(teamMemberQuery.list(teamId, queryParams));

  return { memberList, isLoading, error };
}
