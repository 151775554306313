import { ConfirmModal } from 'app-zephyr-components/confirm-modal';

export const DeleteIntegrationConfirmModal = ({
  integrationName,
  loading,
  open,
  onDeleteIntegration,
  onClose,
}: {
  integrationName: string | undefined;
  loading: boolean;
  open: boolean;
  onDeleteIntegration: () => Promise<void>;
  onClose: () => void;
}) => {
  return (
    <ConfirmModal
      title={`You about to Delete ${integrationName ?? ''} Integration`}
      description="Once you delete integration, there is no going back. Are you sure you want to do it?"
      type="error"
      open={open && !!integrationName}
      cancelBtnText="No, go back"
      confirmBtnText="Yes, Delete integration"
      onClose={() => {
        if (loading) return;
        onClose();
      }}
      disabled={loading}
      onConfirm={onDeleteIntegration}
    />
  );
};
