import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  alertContent: {
    color: theme.palette.tx.tertiary.default,
    textAlign: 'center',
    padding: `${theme.spacing(15).toString()} ${theme.spacing(2).toString()} ${theme.spacing(15).toString()} ${theme
      .spacing(2)
      .toString()}`,
    fontSize: 14,
    borderRadius: theme.borderRadius.md,
    border: theme.palette.border.secondary,
    backgroundColor: theme.palette.bg.primary.alt,
    svg: {
      color: theme.palette.brand.turquoise[500],
      width: 35,
      height: 35,
    },
    p: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '120%',
      margin: 0,
    },
  },
}));
