export enum RouteNames {
  INIT = '_init',
  // Personal dashboard for a quick navigation and overview
  PERSONAL_DASHBOARD = '/',

  /** Organization level */

  // Dashboard/onboarding for an organization
  ORGANIZATION = '/org/$organization',

  ORGANIZATION_PROJECTS = '/org/$organization/projects',

  ORGANIZATION_MANAGED_APPLICATIONS = '/org/$organization/managed-applications',
  ORGANIZATION_UNMANAGED_APPLICATIONS = '/org/$organization/unmanaged-applications',
  ORGANIZATION_UNMANAGED_APPLICATION_NEW = '/org/$organization/unmanaged-applications/new',
  // todo: discuss route, is it ok, or it should be with project and it will be displayed in future as managed application
  ORGANIZATION_UNMANAGED_APPLICATION_EDIT = '/org/$organization/unmanaged-applications/$application',

  ORGANIZATION_TEAM_LIST = '/org/$organization/teams',
  ORGANIZATION_TEAM = '/org/$organization/teams/$team',

  ORGANIZATION_PEOPLE = '/org/$organization/people',

  /** Organization settings */

  ORGANIZATION_SETTINGS = '/org/$organization/settings',

  ORGANIZATION_MEMBERSHIP = '/org/$organization/settings/membership',

  ORGANIZATION_SUBSCRIPTION = '/org/$organization/subscription',
  ORGANIZATION_SUBSCRIPTION_MANAGE = '/org/$organization/subscription/manage',

  ORGANIZATION_PAYMENT_METHODS = '/org/$organization/settings/payment-methods',

  ORGANIZATION_BILLING_HISTORY = '/org/$organization/settings/billing-history',
  ORGANIZATION_DEPLOYMENT_INTEGRATION_LIST = '/org/$organization/settings/deployment-integrations',
  ORGANIZATION_DEPLOYMENT_INTEGRATION_OVERVIEW = '/org/$organization/settings/deployment-integrations/$integrationType',
  ORGANIZATION_DEPLOYMENT_INTEGRATION = '/org/$organization/settings/deployment-integrations/$integrationType/$integrationName',
  ORGANIZATION_DEPLOYMENT_INTEGRATION_ADD = '/org/$organization/settings/deployment-integrations/$integrationType/add',
  ORGANIZATION_DEPLOYMENT_INTEGRATION_EDIT = '/org/$organization/settings/deployment-integrations/$integrationType/$integrationName/edit',

  /** Project level */

  PROJECT_OVERVIEW = '/org/$organization/$project',
  PROJECT_APPLICATION_LIST = '/org/$organization/$project/applications',
  // todo: change this when we have a project level
  PROJECT_SETTINGS_GENERAL = '/org/$organization/$project/settings/general',
  PROJECT_SETTINGS_DEPLOYMENT_INTEGRATION_LIST = '/org/$organization/$project/settings/deployment-integrations',
  PROJECT_SETTINGS_COLLABORATORS_AND_TEAM = '/org/$organization/$project/settings/collaborations-teams',

  /** Application level */
  APPLICATION_OVERVIEW = '/org/$organization/$project/$application/overview',
  APPLICATION_VERSIONS = '/org/$organization/$project/$application/versions',
  APPLICATION_UML = '/org/$organization/$project/$application/uml',
  APPLICATION_REMOTES = '/org/$organization/$project/$application/remotes',
  APPLICATION_MODULE = '/org/$organization/$project/$application/module/$module',
  APPLICATION_DEPENDENCIES = '/org/$organization/$project/$application/analytics/dependencies',
  APPLICATION_NODE_GRAPH = '/org/$organization/$project/$application/analytics/node-graph',
  APPLICATION_NODE_DEPENDENCIES = '/org/$organization/$project/$application/analytics/node/$applicationId/dependencies',
  APPLICATION_DEPENDENCY_GRAPH = '/org/$organization/$project/$application/analytics/dependency-graph',
  APPLICATION_SETTINGS_GENERAL = '/org/$organization/$project/$application/settings/general',
  APPLICATION_SETTINGS_TAGS = '/org/$organization/$project/$application/settings/tags',
  APPLICATION_SETTINGS_TAGS_ADD = '/org/$organization/$project/$application/settings/tags/add',
  APPLICATION_SETTINGS_DEPLOYMENT_INTEGRATION_LIST = '/org/$organization/$project/$application/settings/deployment-integrations',

  // todo: should be discussed will be there tag id or tag name
  APPLICATION_SETTINGS_TAGS_EDIT = '/org/$organization/$project/$application/settings/tags/$tag',

  APPLICATION_SETTINGS_ENVIRONMENT = '/org/$organization/$project/$application/settings/environment',
  APPLICATION_SETTINGS_ENVIRONMENT_ADD = '/org/$organization/$project/$application/settings/environment/add',
  // todo: should be discussed will be there env id or env name
  APPLICATION_SETTINGS_ENVIRONMENT_EDIT = '/org/$organization/$project/$application/settings/environment/$environment',
  APPLICATION_SETTINGS_DEPLOYMENT_AND_ROLLBACK = '/org/$organization/$project/$application/settings/deployment-rollback',

  /** User level */
  USER_PROFILE = '/profile/$username',
  USER_PROFILE_SETTINGS = '/profile/settings',
  USER_PROFILE_SETTINGS_EMAILS = '/profile/settings/emails',
  USER_PROFILE_SETTINGS_USER_TOKENS = '/profile/settings/user-tokens',
  USER_PROFILE_SETTINGS_EDIT_USER_TOKEN = '/profile/settings/user-tokens/$userToken',
  USER_PROFILE_SETTINGS_GENERATE_USER_TOKENS = '/profile/settings/user-tokens/generate',
  AUTHENTICATED_USER_PROFILE = '/my-profile',

  /** code status level */
  NOT_FOUND = '/404',
  NOT_AUTHORIZED = '/401',
  FORBIDDEN = '/403',
  INTERNAL_ERROR = '/500',

  /** @deprecated */
  NEW_SUBSCRIBER = '/new-subscriber',
  /** @deprecated */
  NOTIFICATIONS = '/notifications',

  /** oauth */
  // /oauth/...
  CLI_LOGIN_SUCCESS = '/notifications/cli-login-success',
  CLI_LOGIN_FAILURE = '/notifications/cli-login-failure',
  SIDE_PANEL_LOGIN_SUCCESS = '/notifications/side-panel-login-success',
  SIDE_PANEL_LOGIN_FAILURE = '/notifications/side-panel-login-failure',
}
