import * as Joi from 'joi';
import {
  APPLICATION_CONDITION_FIELD_JOI_VALIDATOR_MESSAGES,
  APPLICATION_TAG_NAME_JOI_VALIDATOR_MESSAGES,
  APPLICATION_TAG_NAME_VALIDATOR,
  APPLICATION_CONDITION_TEXT_VALUE,
} from '../../validation';
import { channelList } from './interfaces';

export const baseSchema = Joi.object({
  name: Joi.string()
    .pattern(APPLICATION_TAG_NAME_VALIDATOR.pattern.value)
    .min(APPLICATION_TAG_NAME_VALIDATOR.minLength.value)
    .max(APPLICATION_TAG_NAME_VALIDATOR.maxLength.value)
    .required()
    .messages(APPLICATION_TAG_NAME_JOI_VALIDATOR_MESSAGES),
});

export const conditionValueSchema = Joi.object({
  label: Joi.string().required().allow('').messages(APPLICATION_CONDITION_FIELD_JOI_VALIDATOR_MESSAGES),
  value: Joi.string()
    .required()
    .pattern(APPLICATION_CONDITION_TEXT_VALUE.pattern.value)
    .min(APPLICATION_CONDITION_TEXT_VALUE.minLength.value)
    .max(APPLICATION_CONDITION_TEXT_VALUE.maxLength.value)
    .allow('')
    .messages(APPLICATION_CONDITION_FIELD_JOI_VALIDATOR_MESSAGES),
});

export const conditionTypeValueSchema = Joi.string()
  .valid(...channelList)
  .required()
  .messages({ enum: `Condition Type must be one of ${channelList.join(', ')}` });

export const formConditions = Joi.array()
  .items(
    Joi.object({
      type: Joi.object({
        value: conditionTypeValueSchema,
        label: Joi.string().required().messages(APPLICATION_CONDITION_FIELD_JOI_VALIDATOR_MESSAGES),
      }),
      value: conditionValueSchema,
    }),
  )
  .unique((a, b) => {
    if (a.type.value === b.type.value) {
      return a.value.value === b.value.value;
    }

    return false;
  });

export const applicationVersionIdSchema = Joi.string()
  .guid({ version: 'uuidv4' })
  .messages({ guid: 'Application version id must be a valid UUID v4' });
