export interface Environment {
  readonly env: 'local' | 'development' | 'staging' | 'production';
  readonly production: boolean;
  readonly coreApiBaseUrl: string;
  readonly auth0: {
    domain: string;
    clientId: string;
    scope?: string;
  };
  readonly stripePkKey: string;
  readonly stripePrices: {
    personal: string;
    standard: string;
    users: string;
  };
  readonly GTM_ID: string;
  readonly cerbosHttpUrl: string;
  readonly useSentry: boolean;
  readonly sentryDsn: string;
  readonly sentryRelease: string;
}

let _env: Environment;

const envValue = {
  get value(): Environment {
    return _env;
  },

  set value(value: Environment) {
    _env = value;
  },
};

export { envValue };
