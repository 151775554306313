import { UserPinOption } from 'ze-api-contract/user-v2/user-pins/get-user-pin-options';

export const PinOptionLabel = ({ type, application, organization, project }: UserPinOption) => {
  if (type === 'organization') return organization?.displayName;
  if (type === 'project')
    return (
      <>
        <span className="labelPrefix">{organization?.displayName} / </span>
        {project?.displayName}
      </>
    );
  return (
    <>
      <span className="labelPrefix">
        {organization?.displayName} / {project?.displayName} /&nbsp;
      </span>
      {application?.displayName}
    </>
  );
};
