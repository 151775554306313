import { navBarItemId, navigations, navTitle } from './navigation';
import {
  application_dependencies_path,
  application_overview_path,
  application_remotes_path,
  application_settings_general_path,
  application_uml_path,
  application_versions_path,
  organization_managed_applications_path,
  organization_path,
  organization_people_path,
  organization_projects_path,
  organization_settings_path,
  organization_team_list_path,
  project_application_list_path,
  project_overview_path,
  project_settings_general_path,
  RouteNames,
  user_path,
  user_settings_path,
  authenticated_user_profile_path,
} from 'app-zephyr-routes';

export interface INavItem {
  title: navTitle;
  // route patter for matching
  path: string;
  // fully formed url
  link?: string;
  id: navBarItemId;
  availablePaths?: string[];
}

export interface NavigationItemsParams {
  organization: { name: string; settingsAvailable?: boolean };
  project: { name: string; settingsAvailable?: boolean };
  application: { name?: string; settingsAvailable?: boolean };
  user: { name: string };
}

export function navigationItemsOrgLevel({ organization }: NavigationItemsParams): INavItem[] {
  const items = [
    {
      title: navigations[navBarItemId.DASHBOARD].title,
      id: navBarItemId.DASHBOARD,
      path: RouteNames.ORGANIZATION,
      link: organization_path({ organization }),
    },
    {
      title: navigations[navBarItemId.PROJECTS].title,
      id: navBarItemId.PROJECTS,
      path: RouteNames.ORGANIZATION_PROJECTS,
      link: organization_projects_path({ organization }),
    },
    {
      title: navigations[navBarItemId.APPLICATIONS].title,
      id: navBarItemId.APPLICATIONS,
      path: RouteNames.ORGANIZATION_MANAGED_APPLICATIONS,
      link: organization_managed_applications_path({ organization }),
      availablePaths: [
        RouteNames.ORGANIZATION_MANAGED_APPLICATIONS,
        RouteNames.ORGANIZATION_UNMANAGED_APPLICATIONS,
        RouteNames.ORGANIZATION_UNMANAGED_APPLICATION_NEW,
        RouteNames.ORGANIZATION_UNMANAGED_APPLICATION_EDIT,
      ],
    },
    {
      title: navigations[navBarItemId.TEAMS].title,
      id: navBarItemId.TEAMS,
      path: RouteNames.ORGANIZATION_TEAM_LIST,
      link: organization_team_list_path({ organization }),
      availablePaths: [RouteNames.ORGANIZATION_TEAM_LIST, RouteNames.ORGANIZATION_TEAM],
    },
    {
      title: navigations[navBarItemId.PEOPLE].title,
      id: navBarItemId.PEOPLE,
      path: RouteNames.ORGANIZATION_PEOPLE,
      link: organization_people_path({ organization }),
    },
  ];
  if (organization.settingsAvailable) {
    items.push({
      title: navigations[navBarItemId.SETTINGS].title,
      id: navBarItemId.SETTINGS,
      path: RouteNames.ORGANIZATION_SETTINGS,
      link: organization_settings_path({ organization }),
      availablePaths: [
        RouteNames.ORGANIZATION_SETTINGS,
        RouteNames.ORGANIZATION_MEMBERSHIP,
        RouteNames.ORGANIZATION_SUBSCRIPTION,
        RouteNames.ORGANIZATION_SUBSCRIPTION_MANAGE,
        RouteNames.ORGANIZATION_BILLING_HISTORY,
        RouteNames.ORGANIZATION_PAYMENT_METHODS,
        RouteNames.ORGANIZATION_DEPLOYMENT_INTEGRATION_LIST,
        RouteNames.ORGANIZATION_DEPLOYMENT_INTEGRATION_OVERVIEW,
        RouteNames.ORGANIZATION_DEPLOYMENT_INTEGRATION,
        RouteNames.ORGANIZATION_DEPLOYMENT_INTEGRATION_ADD,
        RouteNames.ORGANIZATION_DEPLOYMENT_INTEGRATION_EDIT,
      ],
    });
  }
  return items;
}

export function navigationItemsProjectLevel({ organization, project }: NavigationItemsParams): INavItem[] {
  const items: INavItem[] = [
    {
      title: navigations[navBarItemId.PROJECT_OVERVIEW].title,
      id: navBarItemId.PROJECT_OVERVIEW,
      path: RouteNames.PROJECT_OVERVIEW,
      link: project_overview_path({ organization, project }),
    },
    {
      title: navigations[navBarItemId.PROJECT_APPLICATION_LIST].title,
      id: navBarItemId.PROJECT_APPLICATION_LIST,
      path: RouteNames.PROJECT_APPLICATION_LIST,
      link: project_application_list_path({ organization, project }),
    },
  ];
  if (project.settingsAvailable) {
    items.push({
      title: navigations[navBarItemId.PROJECT_SETTINGS].title,
      id: navBarItemId.PROJECT_SETTINGS,
      path: RouteNames.PROJECT_SETTINGS_GENERAL,
      link: project_settings_general_path({ organization, project }),
      availablePaths: [
        RouteNames.PROJECT_SETTINGS_GENERAL,
        RouteNames.PROJECT_SETTINGS_COLLABORATORS_AND_TEAM,
        RouteNames.PROJECT_SETTINGS_DEPLOYMENT_INTEGRATION_LIST,
      ],
    });
  }
  return items;
}

export function navigationItemsAppLevel({ organization, project, application }: NavigationItemsParams): INavItem[] {
  const items: INavItem[] = [
    {
      title: navigations[navBarItemId.PROJECT_OVERVIEW].title,
      id: navBarItemId.PROJECT_OVERVIEW,
      path: RouteNames.APPLICATION_OVERVIEW,
      link: application_overview_path({ organization, project, application }),
    },
    {
      title: navigations[navBarItemId.PROJECT_VERSIONS].title,
      id: navBarItemId.PROJECT_VERSIONS,
      path: RouteNames.APPLICATION_VERSIONS,
      link: application_versions_path({ organization, project, application }),
    },
    {
      title: navigations[navBarItemId.PROJECT_UML].title,
      id: navBarItemId.PROJECT_UML,
      path: RouteNames.APPLICATION_UML,
      link: application_uml_path({ organization, project, application }),
    },
    {
      title: navigations[navBarItemId.PROJECT_REMOTES].title,
      id: navBarItemId.PROJECT_REMOTES,
      path: RouteNames.APPLICATION_REMOTES,
      link: application_remotes_path({ organization, project, application }),
      availablePaths: [RouteNames.APPLICATION_REMOTES, RouteNames.APPLICATION_MODULE],
    },
    {
      title: navigations[navBarItemId.PROJECT_ANALYTICS].title,
      id: navBarItemId.PROJECT_ANALYTICS,
      path: RouteNames.APPLICATION_DEPENDENCIES,
      link: application_dependencies_path({ organization, project, application }),
      availablePaths: [
        RouteNames.APPLICATION_DEPENDENCIES,
        RouteNames.APPLICATION_DEPENDENCY_GRAPH,
        RouteNames.APPLICATION_NODE_GRAPH,
        RouteNames.APPLICATION_NODE_DEPENDENCIES,
      ],
    },
  ];
  if (application.settingsAvailable) {
    items.push({
      title: navigations[navBarItemId.APPLICATION_SETTINGS].title,
      id: navBarItemId.APPLICATION_SETTINGS,
      path: RouteNames.APPLICATION_SETTINGS_GENERAL,
      link: application_settings_general_path({ organization, project, application }),
      availablePaths: [
        RouteNames.APPLICATION_SETTINGS_GENERAL,
        RouteNames.APPLICATION_SETTINGS_TAGS,
        RouteNames.APPLICATION_SETTINGS_TAGS_ADD,
        RouteNames.APPLICATION_SETTINGS_TAGS_EDIT,
        RouteNames.APPLICATION_SETTINGS_ENVIRONMENT,
        RouteNames.APPLICATION_SETTINGS_ENVIRONMENT_ADD,
        RouteNames.APPLICATION_SETTINGS_ENVIRONMENT_EDIT,
        RouteNames.APPLICATION_SETTINGS_DEPLOYMENT_INTEGRATION_LIST,
        RouteNames.APPLICATION_SETTINGS_DEPLOYMENT_AND_ROLLBACK,
      ],
    });
  }
  return items;
}

export interface NavigationItemsProfileLevelParams {
  user: { name: string };
}

export function navigationItemsProfileLevel({ user }: NavigationItemsParams): INavItem[] {
  return [
    {
      title: navigations[navBarItemId.PROFILE_OVERVIEW].title,
      id: navBarItemId.PROJECT_OVERVIEW,
      path: RouteNames.USER_PROFILE,
      link: user_path({ username: user.name }),
    },
  ];
}

export function navigationItemsAuthenticatedUserProfileLevel(): INavItem[] {
  return [
    {
      title: navigations[navBarItemId.PROFILE_OVERVIEW].title,
      id: navBarItemId.PROJECT_OVERVIEW,
      path: RouteNames.USER_PROFILE,
      link: authenticated_user_profile_path(),
    },
    {
      title: navigations[navBarItemId.PROFILE_SETTINGS].title,
      id: navBarItemId.PROFILE_SETTINGS,
      path: RouteNames.USER_PROFILE_SETTINGS,
      link: user_settings_path(),
      availablePaths: [
        RouteNames.USER_PROFILE_SETTINGS,
        RouteNames.USER_PROFILE_SETTINGS_EMAILS,
        RouteNames.USER_PROFILE_SETTINGS_USER_TOKENS,
        RouteNames.USER_PROFILE_SETTINGS_EDIT_USER_TOKEN,
        RouteNames.USER_PROFILE_SETTINGS_GENERATE_USER_TOKENS,
      ],
    },
  ];
}
