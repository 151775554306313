import { useState, useEffect } from 'react';
import debounce from 'lodash.debounce';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { FormInput } from 'app-zephyr-forms';
import { renameOrgForm } from 'ze-api-contract/organization-v2/settings/update-org-settings';

import { useStyles } from './styles';
import { CanManageOrgConfig } from 'app-zephyr-guards/org';

export type RenameOrganizationConfirmFormFields = { name: string } & Record<string, string>;

interface RenameOrganizationConfirmFormProps {
  can: CanManageOrgConfig;
  name: string;
  isFormValid: (value: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  nameIsChanged: (value: string) => Promise<void | boolean>;
  onFormSubmit: () => void;
}

const RenameOrganizationConfirmForm = ({
  can,
  nameIsChanged,
  name,
  isFormValid,
  onFormSubmit,
}: RenameOrganizationConfirmFormProps) => {
  const [isUnique, setIsUnique] = useState(false);
  const { classes } = useStyles();

  const {
    getValues,
    register,
    formState: { errors, isValid, isDirty },
    watch,
  } = useForm<RenameOrganizationConfirmFormFields>({
    defaultValues: { name },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: joiResolver(renameOrgForm),
  });

  const watchAllFields = watch();

  const getIsUniqueName = debounce(async () => {
    if (!isValid) return;
    if (!isDirty) {
      setIsUnique(true);
      return;
    }
    const name = getValues().name;
    const res = await nameIsChanged(name);
    setIsUnique(!!res);
  }, 300);

  useEffect(() => {
    void getIsUniqueName();
    return () => {
      getIsUniqueName.cancel();
    };
  }, [getIsUniqueName, watchAllFields]);

  useEffect(() => {
    isFormValid(isValid && isDirty && isUnique);
  }, [isDirty, isFormValid, isUnique, isValid]);

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        isValid && isDirty && isUnique && onFormSubmit();
      }}
    >
      <FormInput
        id="confirm-name"
        className={classes.form}
        name={'name'}
        register={register}
        errors={errors}
        label="Rename your organization"
        disabled={!can.updateOrganizationName}
        placeholder="Fill organization name"
      />
      {isDirty && isValid && !isUnique && <p className={classes.errorMessage}>Name should be unique</p>}
    </form>
  );
};

export { RenameOrganizationConfirmForm };
