import { useQuery } from '@tanstack/react-query';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { axios } from 'app-zephyr-axios';

import {
  get_project_deployment_integration_list_path,
  PROJECT_DEPLOYMENT_INTEGRATION,
} from 'ze-api-contract/deployment-integration/project/path';
import { DeploymentIntegrationValue } from 'ze-api-contract/deployment-integration/organization/interfaces';

export const projectDeploymentIntegrationQuery = createQueryKeys('cloudProvider', {
  list: (organization?: string, project?: string) => ({
    queryKey: [PROJECT_DEPLOYMENT_INTEGRATION, organization, project],
    queryFn: async () => {
      if (!organization || !project) return Promise.resolve(undefined);

      return axios
        .get<{
          entities: DeploymentIntegrationValue[];
        }>(get_project_deployment_integration_list_path({ organization, project }))
        .then((res) => res.data.entities);
    },
  }),
});

export function useProjectDeploymentIntegrationList(organization: string | undefined, project: string | undefined) {
  const {
    data: deploymentIntegrationList,
    isLoading,
    error,
  } = useQuery(projectDeploymentIntegrationQuery.list(organization, project));

  return { deploymentIntegrationList, isLoading, error };
}
