import { Link, useMatchRoute } from '@tanstack/react-router';
// @mui
import { Tab } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
// hooks, context, constants
import { INavItem } from 'app-zephyr-constants';
// styles
import { useStyles } from './styles';

export interface TopNavigationProps {
  /**
   * top header navigation items
   */
  navigationItems: INavItem[];
}

/**
 * Header navigation bar.
 */
export function TopNavigation({ navigationItems }: Readonly<TopNavigationProps>) {
  const { classes } = useStyles();
  const matchRoute = useMatchRoute();

  const active = navigationItems.find(({ link, availablePaths }) => {
    if (!availablePaths) return matchRoute({ to: link });

    return availablePaths.some((path) => matchRoute({ to: path }));
  });

  if (!navigationItems.length) return null;

  return (
    <TabContext value={active?.id ?? navigationItems[0].id}>
      <TabList className={classes.navbarTabs} data-e2e="LAYOUT_NAVIGATION_MENU" role="navigation" variant="scrollable">
        {navigationItems.map((item) => (
          <Tab
            key={item.id}
            className={classes.tab}
            value={item.id}
            label={item.title}
            component={Link}
            to={item.link}
            data-e2e={`LAYOUT_NAVIGATION_MENU__${item.title.toUpperCase()}__BUTTON`}
          />
        ))}
      </TabList>
    </TabContext>
  );
}
