const isValidDate = (date: Date): boolean => date instanceof Date && !isNaN(date.getTime());

const initialDateOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
  hour: 'numeric',
  minute: 'numeric',
  hour12: false,
};
/**
 * Format Date data to a custom string.
 * @param date date object or date string data.
 * @param options date options object.
 * @returns {string} string.
 * @example
 * 'Last edited: Feb 04, 2024, 18:29'
 */
const formatDate = (date?: Date | string, options: Intl.DateTimeFormatOptions = {}): string => {
  if (!date) return '---';

  const DateTimeFormat = new Intl.DateTimeFormat('en-US', Object.assign(initialDateOptions, options));
  return DateTimeFormat.format(new Date(date));
};

export { isValidDate, formatDate };
