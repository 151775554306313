import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  container: {
    h2: {
      margin: 0,
      fontSize: 16,
      fontWeight: 600,
      color: theme.palette.tx.primary,
    },
  },

  box: {
    padding: 16,
    backgroundColor: theme.palette.brand.gray['900-20%'],
    borderRadius: theme.borderRadius.lg,
    border: theme.palette.border.secondary,
  },
}));
