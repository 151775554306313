import { useState, useEffect } from 'react';
import debounce from 'lodash.debounce';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { FormInput } from 'app-zephyr-forms';
import { changeUserName } from 'ze-api-contract/user-v2/profile-settings/update-profile';

import { useStyles } from './styles';

export type RenameProfileConfirmFormFields = { name: string } & Record<string, string>;

interface RenameProfileConfirmFormProps {
  name: string;
  isFormValid: (value: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  checkNameIsUnique: (value: string) => Promise<void | boolean>;
  nameIsChanged: (value: string) => void;
  onFormSubmit: () => void;
}

const RenameProfileConfirmForm = ({
  nameIsChanged,
  name,
  isFormValid,
  checkNameIsUnique,
  onFormSubmit,
}: RenameProfileConfirmFormProps) => {
  const [isUnique, setIsUnique] = useState(false);

  const { classes } = useStyles();

  const {
    getValues,
    register,
    formState: { errors, isValid, isDirty },
    watch,
  } = useForm<RenameProfileConfirmFormFields>({
    defaultValues: { name },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: joiResolver(changeUserName),
  });

  const watchAllFields = watch();

  const getIsUniqueName = debounce(async () => {
    if (!isValid) return;
    if (!isDirty) {
      setIsUnique(true);
      return;
    }
    const name = getValues().name;
    const res = await checkNameIsUnique(name);
    setIsUnique(!!res);
  }, 300);

  useEffect(() => {
    nameIsChanged(getValues().name);
    void getIsUniqueName();
    return () => {
      getIsUniqueName.cancel();
    };
  }, [getIsUniqueName, getValues, nameIsChanged, watchAllFields]);

  useEffect(() => {
    isFormValid(isValid && isDirty && isUnique);
  }, [isDirty, isFormValid, isUnique, isValid]);

  return (
    <form onSubmit={onFormSubmit}>
      <FormInput id="user-name" name={'name'} register={register} errors={errors} label="Change your username" />
      {isDirty && isValid && !isUnique && <p className={classes.errorMessage}>Username should be unique</p>}
    </form>
  );
};

export { RenameProfileConfirmForm };
