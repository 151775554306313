import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

import { FormContainer } from 'app-zephyr-forms';

export function DeploymentIntegrationFormSkeleton({ title }: { title: string }) {
  return (
    <Stack spacing={2}>
      <FormContainer title={title}>
        <Stack spacing={1}>
          <Skeleton width={70} height={15} />
          <Skeleton width={'100%'} height={40} />
        </Stack>
        <Stack spacing={1}>
          <Skeleton width={70} height={15} />
          <Skeleton width={'100%'} height={40} />
        </Stack>
        <Stack spacing={1}>
          <Skeleton width={70} height={15} />
          <Skeleton width={'100%'} height={40} />
        </Stack>
        <Stack spacing={1}>
          <Skeleton width={70} height={15} />
          <Skeleton width={'100%'} height={40} />
        </Stack>
      </FormContainer>
      <Stack direction={'row'} spacing={2} justifyContent={'flex-end'}>
        <Skeleton width={90} height={45} />
        <Skeleton width={90} height={45} />
      </Stack>
    </Stack>
  );
}
