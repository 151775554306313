import { Link, Outlet } from '@tanstack/react-router';
import { QueryClient } from '@tanstack/react-query';

import { Loading } from 'app-zephyr-components/AlertContent';
import { DisplayError } from 'app-zephyr-components/AlertContent/Error';
import { Layout } from 'app-zephyr-components/Layout';
import { useCurrentUser, currentUser } from 'app-zephyr-domains/user';
import { RouteNames } from 'app-zephyr-routes';

export function queryLoader(queryClient: QueryClient) {
  return () => {
    void queryClient.prefetchQuery(currentUser.current);
    return null;
  };
}

export function AuthenticatedUserDashboardLayout() {
  const { user, error, isLoading } = useCurrentUser();

  if (error) return <DisplayError error={error} />;
  if (isLoading) return <Loading isLoading={isLoading} />;

  if (!user) {
    return <Link to={RouteNames.NOT_AUTHORIZED} search={(p: never) => p} params={(p: never) => p} />;
  }

  return (
    <Layout currentUser={user}>
      <Outlet />
    </Layout>
  );
}
