import { simplePath } from 'ze-api-contract/utils';
import { changeOrganizationName } from './validations';

export const UPDATE_ORG_NAME = 'v2/organization/:organization/name';

export function get_org_name_path(params: { organization: string }): string {
  return simplePath(UPDATE_ORG_NAME, params);
}

export const schema = changeOrganizationName;
