import { RouteNames } from './route-names';
import { simplePath } from 'ze-api-contract/utils';

const app_cache = new Map<string, string>();

interface ApplicationProps {
  organization?: { name?: string };
  project?: { name?: string };
  application?: { name?: string };
  tag?: { name?: string };
  environment?: { name?: string };
  query?: string;
}

function cache_me(
  path: string,
  { organization, project, application, tag, environment, query }: ApplicationProps,
): string {
  if (!organization?.name || !project?.name || !application?.name) return '/';
  const cache_key = `${path}:${organization.name}:${project.name}:${application.name}:${tag?.name ?? ''}:${
    environment?.name ?? ''
  }:${query ?? ''}`;

  if (app_cache.has(cache_key)) {
    return app_cache.get(cache_key) ?? '/';
  }

  const params = {
    organization: organization.name,
    project: project.name,
    application: application.name,
    tag: tag?.name,
    environment: environment?.name,
  };
  new URLSearchParams(query);
  const url = simplePath(path, params) + (query ?? '');

  app_cache.set(cache_key, url);

  return url;
}

export function application_overview_path(props: ApplicationProps): string {
  return cache_me(RouteNames.APPLICATION_OVERVIEW, props);
}

export function application_versions_path(props: ApplicationProps): string {
  return cache_me(RouteNames.APPLICATION_VERSIONS, props);
}

export function application_uml_path(props: ApplicationProps): string {
  return cache_me(RouteNames.APPLICATION_UML, props);
}

export function application_remotes_path(props: ApplicationProps): string {
  return cache_me(RouteNames.APPLICATION_REMOTES, props);
}

export function application_module_path(props: ApplicationProps & { module: string }): string {
  if (!props.organization?.name || !props.project?.name || !props.application?.name || !props.module) return '/';
  return (
    simplePath(RouteNames.APPLICATION_MODULE, {
      organization: props.organization.name,
      project: props.project.name,
      application: props.application.name,
      module: props.module,
    }) + (props.query ?? '')
  );
}

export function application_dependencies_path(props: ApplicationProps): string {
  return cache_me(RouteNames.APPLICATION_DEPENDENCIES, props);
}

export function application_node_graph_path(props: ApplicationProps): string {
  return cache_me(RouteNames.APPLICATION_NODE_GRAPH, props);
}

export function application_dependency_graph_path(props: ApplicationProps): string {
  return cache_me(RouteNames.APPLICATION_DEPENDENCY_GRAPH, props);
}

export function application_node_dependencies_path(props: ApplicationProps & { applicationId: string }): string {
  const { organization, project, application, applicationId, query } = props;
  if (!organization?.name || !project?.name || !application?.name || !applicationId) return '/';
  const params = {
    organization: organization.name,
    project: project.name,
    application: application.name,
    applicationId,
  };
  return simplePath(RouteNames.APPLICATION_NODE_DEPENDENCIES, params) + (query ?? '');
}

export function application_settings_general_path(props: ApplicationProps): string {
  return cache_me(RouteNames.APPLICATION_SETTINGS_GENERAL, props);
}

export function application_settings_tags_path(props: ApplicationProps): string {
  return cache_me(RouteNames.APPLICATION_SETTINGS_TAGS, props);
}

export function application_settings_tags_add_path(props: ApplicationProps): string {
  return cache_me(RouteNames.APPLICATION_SETTINGS_TAGS_ADD, props);
}

export function application_settings_tags_edit_path(props: ApplicationProps): string {
  return cache_me(RouteNames.APPLICATION_SETTINGS_TAGS_EDIT, props);
}

export function application_settings_environment_path(props: ApplicationProps): string {
  return cache_me(RouteNames.APPLICATION_SETTINGS_ENVIRONMENT, props);
}

export function application_settings_environment_add_path(props: ApplicationProps): string {
  return cache_me(RouteNames.APPLICATION_SETTINGS_ENVIRONMENT_ADD, props);
}

export function application_settings_environment_edit_path(props: ApplicationProps): string {
  return cache_me(RouteNames.APPLICATION_SETTINGS_ENVIRONMENT_EDIT, props);
}

export function application_settings_deployment_integrations_list_path(props: ApplicationProps): string {
  return cache_me(RouteNames.APPLICATION_SETTINGS_DEPLOYMENT_INTEGRATION_LIST, props);
}

export function application_settings_deployment_and_rollback_path(props: ApplicationProps): string {
  return cache_me(RouteNames.APPLICATION_SETTINGS_DEPLOYMENT_AND_ROLLBACK, props);
}
