const UPDATE_NAME_DELAY = 500;

function isEqualByFields(currentData: Record<string, unknown>, data: Record<string, unknown>): boolean {
  let isEqual = true;
  for (const key in data) {
    const dataField = data[key];
    const currentField = currentData[key];
    if (Array.isArray(dataField) && Array.isArray(currentField)) {
      isEqual = dataField.join() !== currentField.join();
    } else {
      isEqual = dataField === currentField;
    }

    if (!isEqual) {
      break;
    }
  }

  return isEqual;
}

export { UPDATE_NAME_DELAY, isEqualByFields };
