import { Button as ButtonMUI, ButtonProps } from '@mui/material';
import { useStyles } from './styles';

/**
 * Component is customized for color: 'error' | 'warning' | 'primary' | 'secondary' other colors are default from mui
 **/
function Button({ color, variant, children, className, disabled, ...props }: ButtonProps) {
  const { classes, cx } = useStyles();

  return (
    <ButtonMUI
      className={cx(classes.button, className, { [classes.disabled]: disabled })}
      variant={variant ?? 'contained'}
      color={color ?? 'primary'}
      disabled={disabled}
      {...props}
    >
      {children}
    </ButtonMUI>
  );
}

export { Button };
