import { lazy, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from '@tanstack/react-router';
import * as Sentry from '@sentry/react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import clsx from 'clsx';
import { LazyLoadedComponent } from 'app-zephyr-components/LazyLoadedComponent';

import { useCreateTeam } from 'app-zephyr-domains/team';
import { useCreateOrganization } from 'app-zephyr-domains/organization/create-organization';
import { useCurrentUser } from 'app-zephyr-domains/user';

import { ProtectedComponent } from 'app-zephyr-guards';
import { AddIcon, PeopleIcon } from 'app-zephyr-icons';
import { organization_path, organization_team_list_path, useTypedParams } from 'app-zephyr-routes';

import { useStyles } from './styles.module';

const CreateTeamModal = lazy(() => import('../team/modals/CreateTeam'));
const AddOrganizationModal = lazy(() => import('app-zephyr-components/AddOrganizationModal'));
const AlphaAcceptModal = lazy(() => import('app-zephyr-components/AlphaAcceptModal'));

interface NavbarActionMenuProps {
  canCreateProject?: boolean;
  canCreateTeam?: boolean;
}

//todo: button should be seperated into component, cause there will be the same one for notifications
export function NavbarActionMenu({ canCreateProject, canCreateTeam }: Readonly<NavbarActionMenuProps>) {
  // global state
  const { classes } = useStyles();
  const { organization } = useTypedParams();
  const { logout } = useAuth0();
  const navigate = useNavigate();
  const { user } = useCurrentUser();

  // mutations
  const { mutateAsync: createOrganization } = useCreateOrganization();
  const { mutateAsync: createTeam } = useCreateTeam(organization ?? '');

  // local state
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [openNewTeamModal, setOpenNewTeamModal] = useState(false);
  const [openNewOrgModal, setOpenNewOrgModal] = useState(false);
  const [openAcceptModal, setOpenAcceptModal] = useState(false);

  // create new organization modal
  const alphaAcceptModal = () => {
    setAnchorEl(null);
    setOpenAcceptModal(true);
  };

  const onAlphaAcceptModalConfirm = () => {
    setOpenAcceptModal(false);
    setTimeout(() => {
      setOpenNewOrgModal(true);
    }, 200);
  };

  const onCreateOrgSave = async (organizationDisplayName: string, organizationName: string) => {
    if (!user) return;

    if (!organizationName || !organizationDisplayName) {
      Sentry.captureMessage(
        `Organization name or display name is empty: ${organizationName} ${organizationDisplayName}`,
        {
          level: 'warning',
          user,
          tags: { onCreateOrgSave: 'emptyName' },
        },
      );
      return;
    }

    const _newOrg = {
      name: organizationName.trim(),
      displayName: organizationDisplayName.trim(),
      acceptedBy: user.id,
    };
    const res = await createOrganization(_newOrg);

    if (res.data) {
      closeCreateOrgMenu();
      void navigate({
        to: organization_path({ organization: _newOrg }),
        search: (p: never) => p,
        params: (p: never) => p,
      });
    }
  };

  const closeCreateOrgMenu = () => {
    setAnchorEl(null);
    setOpenNewOrgModal(false);
  };

  const createNewTeam = () => {
    setOpenNewTeamModal(true);
  };

  const onClose = () => {
    setOpenNewTeamModal(false);
    setOpenNewOrgModal(false);
    setOpenAcceptModal(false);
  };

  const onConfirm = async (name: string) => {
    await createTeam({ name });
    onClose();
    void navigate({
      to: organization_team_list_path({ organization: { name: organization } }),
      search: (p: never) => p,
      params: (p: never) => p,
    });
  };

  const dashboardNavigate = () => {
    onClose();
    void navigate({ to: '/', search: (p: never) => p, params: (p: never) => p });
  };

  return (
    <>
      <Button
        startIcon={<AddIcon />}
        className={clsx(classes.actionBtn, `group`)}
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
        data-e2e="LAYOUT_HEADER__ADD_MORE__BUTTON"
      />

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        className={classes.menuContainer}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <ProtectedComponent can={!!canCreateTeam}>
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
            }}
          >
            {!!organization && (
              <ListItemButton
                disableRipple
                style={{ width: '100%' }}
                onClick={createNewTeam}
                data-e2e="LAYOUT_HEADER__NEW_TEAM__BUTTON"
              >
                <ListItemIcon className="iconContainer">{<PeopleIcon />}</ListItemIcon>
                <ListItemText>New Team</ListItemText>
              </ListItemButton>
            )}
          </MenuItem>
        </ProtectedComponent>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
          }}
        >
          <ListItemButton
            disableRipple
            style={{ width: '100%' }}
            onClick={alphaAcceptModal}
            data-e2e="LAYOUT_HEADER__NEW_TEAM__BUTTON"
          >
            <ListItemIcon className="iconContainer">{<PeopleIcon />}</ListItemIcon>
            <ListItemText>New Organization</ListItemText>
          </ListItemButton>
        </MenuItem>
      </Menu>

      <LazyLoadedComponent>
        {openNewTeamModal && <CreateTeamModal onClose={onClose} onConfirm={onConfirm} open={openNewTeamModal} />}
      </LazyLoadedComponent>

      <LazyLoadedComponent>
        {openNewOrgModal && <AddOrganizationModal open={openNewOrgModal} onClose={onClose} onSave={onCreateOrgSave} />}
      </LazyLoadedComponent>

      <LazyLoadedComponent>
        {openAcceptModal && (
          <AlphaAcceptModal
            open={openAcceptModal}
            onLogOut={() => logout()}
            onChangeOrg={dashboardNavigate}
            onConfirm={onAlphaAcceptModalConfirm}
          />
        )}
      </LazyLoadedComponent>
    </>
  );
}
