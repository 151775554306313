import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  versionStr: {
    fontSize: theme.spacing(2),
    color: theme.palette.tx.brand.primary,
    fontWeight: 600,
    paddingLeft: theme.spacing(0.5),
  },
  emptyVersion: {
    fontSize: theme.spacing(2),
    color: theme.palette.tx.warning.primary,
    fontWeight: 700,
    paddingLeft: theme.spacing(0.5),
  },
  displayFlex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'fit-content',
  },
  externalLink: {
    marginLeft: 10,
  },
}));
