import { SVGProps } from 'react';

export type ApplicationIconProps = SVGProps<SVGSVGElement> & {
  color?: string;
};

export const ProjectIcon = ({ color = '#7B84A3', ...props }: ApplicationIconProps) => (
  <svg
    width={props.width ?? 16}
    height={props.width ?? 17}
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.4539 5.93081C17.4539 5.93081 17.4539 5.93081 17.4539 5.86012L17.4009 5.72756C17.3824 5.69944 17.3617 5.67284 17.3391 5.64803C17.3154 5.61062 17.2888 5.57516 17.2595 5.54199L17.18 5.48013L17.0386 5.40943L10.4109 1.31792C10.2704 1.23014 10.1082 1.18359 9.94249 1.18359C9.7769 1.18359 9.61457 1.23014 9.47415 1.31792L2.89945 5.40943L2.81992 5.48013L2.74039 5.54199C2.71109 5.57516 2.6845 5.61062 2.66085 5.64803C2.63813 5.67284 2.61745 5.69944 2.599 5.72756L2.54597 5.86012C2.54597 5.86012 2.54597 5.86012 2.54597 5.93081C2.53729 6.00715 2.53729 6.08423 2.54597 6.16057V13.8841C2.54567 14.0342 2.58365 14.182 2.65632 14.3135C2.72899 14.4449 2.83396 14.5557 2.96131 14.6352L9.58899 18.7267C9.62982 18.7519 9.67465 18.7698 9.72157 18.7797C9.72157 18.7797 9.76574 18.7797 9.79224 18.7797C9.94182 18.8272 10.1023 18.8272 10.2518 18.7797C10.2518 18.7797 10.296 18.7797 10.3225 18.7797C10.3694 18.7698 10.4142 18.7519 10.4551 18.7267L17.0386 14.6352C17.1659 14.5557 17.2709 14.4449 17.3436 14.3135C17.4162 14.182 17.4542 14.0342 17.4539 13.8841V6.16057C17.4626 6.08423 17.4626 6.00715 17.4539 5.93081ZM9.08532 16.3937L4.225 13.3892V7.75123L9.08532 10.747V16.3937ZM9.96899 9.21816L5.02032 6.16057L9.96899 3.11182L14.9177 6.16057L9.96899 9.21816ZM15.7131 13.3892L10.8527 16.3937V10.747L15.7131 7.75123V13.3892Z"
      fill={color}
    />
  </svg>
);

export const ProjectLargeIcon = ({ color = '#424960', ...props }: ApplicationIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ?? 40}
    height={props.width ?? 40}
    viewBox={`0 0 ${props.width?.toString() ?? (40).toString()} ${props.width?.toString() ?? (40).toString()}`}
    fill="none"
  >
    <path
      d="M34.9071 11.8628C34.9071 11.8628 34.9071 11.8628 34.9071 11.7215L34.801 11.4563C34.7641 11.4001 34.7227 11.3469 34.6773 11.2973C34.63 11.2225 34.5768 11.1515 34.5182 11.0852L34.3592 10.9615L34.0764 10.8201L20.8209 2.63706C20.54 2.4615 20.2155 2.36841 19.8842 2.36841C19.553 2.36841 19.2284 2.4615 18.9475 2.63706L5.79811 10.8201L5.63905 10.9615L5.47998 11.0852C5.42139 11.1515 5.3682 11.2225 5.32091 11.2973C5.27548 11.3469 5.23411 11.4001 5.1972 11.4563L5.09115 11.7215C5.09115 11.7215 5.09115 11.7215 5.09115 11.8628C5.07378 12.0155 5.07378 12.1697 5.09115 12.3224V27.7694C5.09055 28.0697 5.16651 28.3653 5.31185 28.6281C5.45719 28.891 5.66712 29.1125 5.92183 29.2717L19.1773 37.4547C19.2589 37.5051 19.3485 37.5409 19.4424 37.5607C19.4424 37.5607 19.5307 37.5607 19.5838 37.5607C19.8828 37.6556 20.2038 37.6556 20.5028 37.5607C20.5028 37.5607 20.5912 37.5607 20.6442 37.5607C20.738 37.5409 20.8277 37.5051 20.9093 37.4547L34.0764 29.2717C34.3311 29.1125 34.541 28.891 34.6864 28.6281C34.8317 28.3653 34.9077 28.0697 34.9071 27.7694V12.3224C34.9244 12.1697 34.9244 12.0155 34.9071 11.8628ZM18.1699 32.7888L8.4492 26.7796V15.5037L18.1699 21.4951V32.7888ZM19.9372 18.4375L10.0399 12.3224L19.9372 6.22486L29.8346 12.3224L19.9372 18.4375ZM31.4253 26.7796L21.7046 32.7888V21.4951L31.4253 15.5037V26.7796Z"
      fill={color}
    />
  </svg>
);

export const ApplicationIcon = ({ color = '#7B84A3', ...props }: ApplicationIconProps) => (
  <svg width="24" height="24" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.9631 5.24504C13.9631 5.24504 13.9631 5.24504 13.9631 5.18848L13.9206 5.08244C13.9059 5.05994 13.8893 5.03866 13.8712 5.01881C13.8522 4.98889 13.831 4.96052 13.8075 4.93398L13.7439 4.88449L13.6308 4.82793L8.32862 1.55472C8.21626 1.4845 8.08643 1.44727 7.95393 1.44727C7.82144 1.44727 7.6916 1.4845 7.57925 1.55472L2.31949 4.82793L2.25586 4.88449L2.19224 4.93398C2.1688 4.96052 2.14752 4.98889 2.12861 5.01881C2.11044 5.03866 2.09389 5.05994 2.07912 5.08244L2.03671 5.18848C2.03671 5.18848 2.03671 5.18848 2.03671 5.24504C2.02976 5.30611 2.02976 5.36778 2.03671 5.42885V11.6077C2.03647 11.7278 2.06685 11.846 2.12498 11.9512C2.18312 12.0563 2.26709 12.1449 2.36898 12.2086L7.67115 15.4818C7.70378 15.5019 7.73966 15.5163 7.77719 15.5242C7.77719 15.5242 7.81254 15.5242 7.83375 15.5242C7.95335 15.5621 8.08176 15.5621 8.20137 15.5242C8.20137 15.5242 8.23672 15.5242 8.25792 15.5242C8.29546 15.5163 8.33133 15.5019 8.36397 15.4818L13.6308 12.2086C13.7327 12.1449 13.8167 12.0563 13.8748 11.9512C13.9329 11.846 13.9633 11.7278 13.9631 11.6077V5.42885C13.97 5.36778 13.97 5.30611 13.9631 5.24504ZM7.26819 13.6154L3.37992 11.2118V6.70137L7.26819 9.09795V13.6154ZM7.97514 7.87492L4.01618 5.42885L7.97514 2.98985L11.9341 5.42885L7.97514 7.87492ZM12.5704 11.2118L8.6821 13.6154V9.09795L12.5704 6.70137V11.2118Z"
      fill={color}
    />
  </svg>
);
