export enum TypeLabels {
  all = 'All',
  created_by_me = 'Created by me',
  public = 'Public',
  private = 'Private',
  removed = 'Removed',
}

export enum SortLabels {
  asc = 'asc',
  desc = 'desc',
  latest_updates = 'by Latest updates',
}

export enum TypeLabelsValues {
  all = 'all',
  created_by_me = 'created_by_me',
  public = 'public',
  private = 'private',
  removed = 'removed',
}

export enum SortLabelsValues {
  asc = 'name_asc',
  desc = 'name_desc',
  latest_updates = 'latest_updates',
}
