import { useEffect, useState } from 'react';
import { joiResolver } from '@hookform/resolvers/joi';
import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form';

import { Button } from 'app-zephyr-components/Button';
import { FormBox } from 'app-zephyr-components/FormBox';
import { membershipFormSchema } from 'ze-api-contract/organization-v2/settings/update-org-settings';

import { useStyles } from './styles';
import { TextFieldChips } from '../../controls/form-chips-input';
import { FormSwitch } from '../../controls/form-switch';

type MembershipFormFields = {
  openMembership: boolean;
  domains: string[];
} & Record<string, boolean | string | string[]>;

interface MembershipForm extends MembershipFormFields {
  domainInput: string;
}

interface MembershipFormProps {
  membership: MembershipFormFields;
  canSubmit?: boolean;
  onSubmit: (value: MembershipFormFields) => void | Promise<void>;
}

export const MembershipForm = ({ membership, canSubmit, onSubmit }: MembershipFormProps) => {
  const { classes } = useStyles();
  const [isReset, setIsReset] = useState(false);
  const {
    register,
    control,
    getValues,
    reset,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isDirty, isValid },
    trigger,
  } = useForm<MembershipForm>({
    defaultValues: { ...membership, domainInput: '' },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: joiResolver(membershipFormSchema),
  });

  const { openMembership, domains } = watch();

  useEffect(() => {
    setIsReset(false);
  }, [domains]);

  const submit = handleSubmit(({ domainInput, ...values }: MembershipForm) => onSubmit(values));
  const resetValues = () => {
    reset(membership);
    setIsReset(true);
  };

  return (
    <form onSubmit={(e) => void submit(e)}>
      <FormBox title="Membership">
        <Stack gap={2}>
          <p className={classes.description}>
            {' '}
            If a team member signs up with an email from a whitelisted domain, they will be automatically added to this
            organization.
          </p>

          <FormSwitch id={'openMembership-control'} control={control} name="openMembership" label="Open membership" />

          <TextFieldChips
            control={control}
            disabled={!openMembership}
            errors={errors}
            id="domains"
            name="domains"
            getValues={getValues}
            setValue={setValue}
            trigger={trigger}
            register={register}
            inputName="domainInput"
            defaultValue={membership.domains}
            isReset={isReset}
            hint={'To add domains you need to fill out domain and press Enter'}
            label="Whitelisted domains"
          />
        </Stack>
      </FormBox>

      <Stack display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} marginTop={2} gap={2}>
        <Button variant="outlined" onClick={resetValues} type="button">
          Reset
        </Button>
        <Button disabled={canSubmit === false || !isDirty || !isValid} type="submit">
          Save changes
        </Button>
      </Stack>
    </form>
  );
};
