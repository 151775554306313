/** simplified version of generatePath
 * credits to https://github.com/remix-run/react-router/blob/703892c32b40acf9d437191b676c7bd48cdf26c4/packages/router/utils.ts#L789
 * */

export function simplePath(path: string, _params: unknown): string {
  if (!_params) return path;

  const params = _params as Record<string, string>;

  // ensure `/` is added at the beginning if the path is absolute
  const prefix = path.startsWith('/') ? '/' : '';

  const segments = path
    .split(/\/+/)
    .map((segment) => {
      const { 1: key } = /^[:$](\w+)(\??)$/.exec(segment) ?? [];

      if (key) {
        return params[key];
      }

      // Remove any optional markers from optional static segments
      return segment.replace(/\?$/g, '');
    })
    // Remove empty segments
    .filter((segment) => !!segment);

  return prefix + segments.join('/');
}
