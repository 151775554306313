import { simplePath } from 'ze-api-contract/utils';

import { name, baseSchema } from './validation';
import { ApplicationSettingsBodyReq } from './interfaces';

export const UPDATE_APPLICATION_SETTINGS_PATH = '/v2/application/:organization/:project/:application/settings';

export interface UpdateApplicationSettingsParams {
  /**
   * Organization name.
   */
  organization: string | undefined;
  /**
   * Project name.
   */
  project: string | undefined;
  /**
   * Application name.
   */
  application: string | undefined;
}

export function update_application_settings_path(params: UpdateApplicationSettingsParams): string {
  return simplePath(UPDATE_APPLICATION_SETTINGS_PATH, params);
}

export const schema = baseSchema.append<ApplicationSettingsBodyReq>();

export const formSchema = baseSchema.append<ApplicationSettingsBodyReq & { name: string }>({
  name,
});
