import { Stack } from '@mui/material';

import { OpenExternal } from 'app-zephyr-icons/OpenExternal';

import { useStyles } from './styles';

interface ApplicationAddTagProps {
  version?: string;
  title?: string;
  link?: string;
}

const VersionItem = ({ version, title, link }: ApplicationAddTagProps) => {
  const { classes, cx } = useStyles();

  return (
    <Stack direction={'row'}>
      {title}
      <span className={cx([classes.displayFlex, version ? classes.versionStr : classes.emptyVersion])}>
        {version ?? 'not found'}
        {!!link && (
          <a className={cx([classes.displayFlex, classes.externalLink])} href={link} target="_blank" rel="noreferrer">
            <OpenExternal width={20} height={20} />
          </a>
        )}
      </span>
    </Stack>
  );
};

export { VersionItem };
