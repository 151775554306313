import { CerbosOrgActions, CerbosOrgKind } from 'ze-api-contract/organization-v2/cerbos-actions';
import { OrganizationValue } from 'ze-api-contract/organization-v2/get-by-name';
import { User } from 'ze-api-contract/user-v2/get-current-user';
import { Role } from 'ze-api-contract/enums/role';
import { AttributeValue, getDecision } from '../decision';

const getOrgRole = (role: Role | undefined): string => `organization_${role ?? 'viewer'}`;

const checkAction = async (organization: OrganizationValue, user: User, action: CerbosOrgActions): Promise<boolean> => {
  const decision = await getDecision({
    principal: {
      id: user.id,
      roles: [getOrgRole(organization.role)],
    },
    resource: {
      kind: CerbosOrgKind,
      id: organization.id,
      attributes: {
        oldData: {},
        newData: {},
        organization: organization as unknown as AttributeValue,
      },
    },
    actions: [action],
  });

  return decision.isAllowed(action) ?? false;
};

const checkActions = async (
  organization: OrganizationValue,
  user: User,
  actions: CerbosOrgActions[],
): Promise<boolean[]> => {
  const decision = await getDecision({
    principal: {
      id: user.id,
      roles: [getOrgRole(organization.role)],
    },
    resource: {
      kind: CerbosOrgKind,
      id: organization.id,
      attributes: {
        oldData: {},
        newData: {},
        organization: organization as unknown as AttributeValue,
      },
    },
    actions,
  });

  return actions.map((action) => decision.isAllowed(action) ?? false);
};

export const canMangeConfigKey = [
  'canManageOrg',
  CerbosOrgActions.UPDATE_ORGANIZATION,
  CerbosOrgActions.UPDATE_ORGANIZATION_NAME,
  CerbosOrgActions.UPDATE_ORGANIZATION_SETTINGS,
  CerbosOrgActions.DELETE_ORGANIZATION,
  CerbosOrgActions.CREATE_PROJECT,
].join('/');

export interface CanManageOrgConfig {
  updateOrganization: boolean;
  updateOrganizationName: boolean;
  updateOrganizationSettings: boolean;
  deleteOrganization: boolean;
  createProject: boolean;
}

export const getCanManageOrgConfig = async (
  organization: OrganizationValue,
  user: User,
): Promise<CanManageOrgConfig> => {
  const [updateOrganization, updateOrganizationName, updateOrganizationSettings, deleteOrganization, createProject] =
    await checkActions(organization, user, [
      CerbosOrgActions.UPDATE_ORGANIZATION,
      CerbosOrgActions.UPDATE_ORGANIZATION_NAME,
      CerbosOrgActions.UPDATE_ORGANIZATION_SETTINGS,
      CerbosOrgActions.DELETE_ORGANIZATION,
      CerbosOrgActions.CREATE_PROJECT,
    ]);
  return {
    updateOrganization,
    updateOrganizationName,
    updateOrganizationSettings,
    deleteOrganization,
    createProject,
  };
};

export const getCanManageIntegrations = async (organization: OrganizationValue, user: User): Promise<boolean> => {
  return checkAction(organization, user, CerbosOrgActions.UPDATE_ORG_DEPLOYMENT_INTEGRATIONS);
};

export const canMangeIntegrationsKey = ['canManage', CerbosOrgActions.UPDATE_ORG_DEPLOYMENT_INTEGRATIONS].join('/');
