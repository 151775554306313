import { simplePath } from 'ze-api-contract/utils';

import { ApplicationListItem, PageMetadata } from './interfaces';

export const GET_ORG_APPLICATION_LIST_PATH: string = '/v2/organization/:organization/application-list';

export function get_org_application_list_path(params: { organization: string }): string {
  return simplePath(GET_ORG_APPLICATION_LIST_PATH, params);
}

export interface ApplicationListResponse {
  entities: ApplicationListItem[];
  pageMetadata?: PageMetadata;
}
