import { useForm } from 'react-hook-form';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

import { FormInput } from 'app-zephyr-forms';

import { useStyles } from './styles';

export type TopFilterFormFields = { name: string; confirm: string } & Record<string, string>;

interface TopFilterFormProps {
  name: string;
  onChange: (value: string) => void;
  placeholder?: string;
}

const TopFilterForm = ({ name, onChange, placeholder }: TopFilterFormProps) => {
  const { classes } = useStyles();
  const {
    register,
    getValues,
    formState: { errors },
  } = useForm<TopFilterFormFields>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: { name: name },
  });

  const onChangeHandler = () => {
    const value = getValues().name;
    onChange(value);
  };

  return (
    <form onChange={onChangeHandler}>
      <FormInput
        id="search-input"
        name={'name'}
        errors={errors}
        register={register}
        placeholder={name ? '' : (placeholder ?? 'Find project')}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon className={classes.svg} />
          </InputAdornment>
        }
      />
    </form>
  );
};

export { TopFilterForm };
