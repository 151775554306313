export enum CerbosOrgActions {
  CREATE_ORGANIZATION = 'create-organization',
  GET_ORG_APPLICATION_LIST = 'get-organization-application-list',
  GET_ORG_PEOPLE_LIST = 'get-organization-people-list',
  GET_ORG_PROJECT_LIST = 'get-organization-project-list',
  GET_ORG_TEAM_LIST = 'get-organization-team-list',
  GET_ORGANIZATION = 'get-organization',
  UPDATE_ORGANIZATION = 'update-organization',
  CAN_MANAGE_PEOPLE = 'can-manage-people',
  CAN_MANAGE_OWNER = 'can-manage-owner',
  CAN_MANAGE_ALL = 'can-manage-all',
  INVITE_PEOPLE = 'invite-people',
  CAN_INVITE_PEOPLE = 'can-invite-people',
  DELETE_PEOPLE = 'delete-people',
  UPDATE_PEOPLE = 'update-people',
  CAN_UPDATE_PEOPLE = 'can-update-people',
  GET_PROJECT = 'get-project',
  GET_APPLICATION = 'get-application',
  GET_TEAM = 'get-team',
  GET_ORGANIZATION_COUNTERS = 'get-organization-counters',
  UPDATE_ORGANIZATION_NAME = 'update-organization-name',
  UPDATE_ORGANIZATION_SETTINGS = 'update-organization-settings',
  DELETE_ORGANIZATION = 'delete-organization',
  CREATE_PROJECT = 'create-project',
  GET_ORG_DEPLOYMENT_INTEGRATIONS = 'get-organization-deployment-integrations',
  UPDATE_ORG_DEPLOYMENT_INTEGRATIONS = 'update-organization-deployment-integrations',
}

export enum CerbosProjectActions {
  CREATE_PROJECT = 'create-project',
  UPDATE_PROJECT = 'update-project',
  UPDATE_PROJECT_NAME = 'update-project-name',
  UPDATE_PROJECT_SETTINGS = 'update-project-settings',
  DELETE_PROJECT = 'delete-project',
  RESTORE_PROJECT = 'restore-project',
  GET_PROJECT_SETTINGS = 'get-project-settings',

  GET_CONTRIBUTOR_LIST = 'get-contributor-list',
  MANAGE_CONTRIBUTOR = 'manage-contributor',
  MANAGE_ALL_CONTRIBUTOR = 'manage-all-contributor',
  UPDATE_CONTRIBUTOR_ROLE = 'update-contributor-role',
  REMOVE_CONTRIBUTOR = 'remove-contributor',
}

export enum CerbosApplicationActions {
  GET_APPLICATION_SETTINGS = 'get-application-settings',
  CREATE_APPLICATION = 'create-application',
  UPDATE_APPLICATION = 'update-application',
  UPDATE_APPLICATION_NAME = 'update-application-name',
  UPDATE_APPLICATION_SETTINGS = 'update-application-settings',
  DELETE_APPLICATION = 'delete-application',

  GET_UNMANAGED_APPLICATION = 'get-unmanaged-application',
  GET_UNMANAGED_APPLICATION_LIST = 'get-unmanaged-application-list',
  CREATE_UNMANAGED_APPLICATION = 'create-unmanaged-application',
  UPDATE_UNMANAGED_APPLICATION = 'update-unmanaged-application',
  DELETE_UNMANAGED_APPLICATION = 'delete-unmanaged-application',
}

export enum CerbosSubscriptionActions {
  CAN_MANAGE_SUBSCRIPTION = 'manage-subscription',
}

export enum CerbosTeamActions {
  CREATE_TEAM = 'can-update-team',
  DELETE_TEAM = 'delete-team',
}

export const CerbosOrgKind = 'organization';
export const CerbosPeopleKind = 'people';
export const CerbosProjectKind = 'project';
export const CerbosApplicationKind = 'application';
export const CerbosSubscriptionKind = 'subscription';
export const CerbosTeamKind = 'team';
