import { useMutation, useQueryClient } from '@tanstack/react-query';

import { axios } from 'app-zephyr-axios';
import { TeamRole } from 'ze-api-contract/team/interfaces';
import { teamQuery } from 'app-zephyr-domains/team';
interface UpdateMemberTeamRole {
  role: TeamRole;
}

interface UpdateMemberTeamRoleParams {
  teamId: string;
  memberId: string;
  data: UpdateMemberTeamRole;
}

export function useUpdateMemberTeamRole() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ teamId, memberId, data }: UpdateMemberTeamRoleParams) =>
      axios.post(`/v2/team/${teamId}/member/${memberId}`, data),
    onSuccess: async (res, { teamId }) => {
      await queryClient.invalidateQueries(teamQuery.getById(teamId));
    },
  });
}
