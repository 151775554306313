import { TextField } from '@mui/material';
import { APPLICATION_NAME_VALIDATOR } from 'ze-api-contract/validation';

export interface ApplicationNameConfirmFormProps {
  applicationName: string;
  nameError: string;
  className?: string;
  handleNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
}

function ApplicationNameConfirmForm(props: ApplicationNameConfirmFormProps) {
  const { applicationName, className, nameError, handleNameChange, onSubmit } = props;

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit();
      }}
      noValidate
    >
      <TextField
        name="applicationName"
        className={className}
        value={applicationName}
        error={!!nameError}
        helperText={nameError}
        inputProps={{ ...APPLICATION_NAME_VALIDATOR }}
        onChange={handleNameChange}
      />
    </form>
  );
}

export { ApplicationNameConfirmForm };
