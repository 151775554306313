import { ReactElement, ComponentType, HTMLAttributes, SVGProps } from 'react';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { Button } from 'app-zephyr-components/Button';
import Typography from '@mui/material/Typography';

import { GoBackIcon } from 'app-zephyr-icons/GoBack';
import { SmileGoodIcon } from 'app-zephyr-icons/SmileGood';
import { LightHorizontalWithLogoImg } from 'app-zephyr-components/LightHorizontalWithLogoImg';
import { Link } from '@tanstack/react-router';
import { RouteNames } from 'app-zephyr-routes';

const MessageCardStyles = makeStyles()((theme) => ({
  component: {
    width: '100%',
    maxWidth: '778px',
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 40px',
    background: theme.palette.bg.primary.default,
    borderRadius: '16px',
  },
  top: {
    display: 'flex',
    alignItems: 'center',
  },
  logoLink: {
    cursor: 'pointer',
  },
  line: {
    width: '100%',
    flex: '1',
    height: '1px',
    marginLeft: '16px',
    border: 'none',
    borderBottom: theme.palette.border.secondary,
  },
  content: {
    marginTop: '40px',
  },
  iconContainer: {
    width: '32px',
    height: '32px',
  },
  titleArea: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    margin: 0,
    marginLeft: '8px',
    color: theme.palette.tx.primary,
    fontSize: '32px',
    fontWeight: 600,
    lineHeight: '38px',
  },
  subtitle: {
    marginTop: '16px',
    color: theme.palette.tx.tertiary.default,
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '25px',
  },
  description: {
    marginTop: '4px',
    color: theme.palette.tx.tertiary.default,
  },
}));

export type MessageCardProps = HTMLAttributes<HTMLElement> & {
  icon: ComponentType<SVGProps<SVGSVGElement>>;
  title: string;
  subtitle: string;
  description: string;
  image?: {
    url: string;
    alt: string;
    width?: number;
    height?: number;
  };
};

export function MessageCard({
  icon,
  title,
  subtitle,
  description,
  image,
  className,
  children,
  ...rest
}: MessageCardProps) {
  const Icon = icon;
  const { classes, theme } = MessageCardStyles();

  return (
    <div className={clsx(classes.component, className)} {...rest}>
      <div className={classes.top}>
        <Link to={RouteNames.PERSONAL_DASHBOARD} search={(p: never) => p} params={(p: never) => p}>
          <LightHorizontalWithLogoImg class={classes.logoLink} />
        </Link>
        <hr className={classes.line} />
      </div>

      <Grid className={classes.content} container justifyContent="space-between" alignItems="center">
        <Grid item xs={image?.url ? 6 : undefined}>
          <div className={classes.titleArea}>
            <Icon className={classes.iconContainer} color={theme.palette.tx.success.primary} />
            <Typography variant="h1" className={classes.title}>
              {title}
            </Typography>
          </div>
          <Typography variant="h2" className={classes.subtitle}>
            {subtitle}
          </Typography>
          <Typography variant="body1" className={classes.description}>
            {description}
          </Typography>

          <Stack marginTop={3}>
            <Link to={RouteNames.PERSONAL_DASHBOARD} search={(p: never) => p} params={(p: never) => p}>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<GoBackIcon color={theme.palette.brand.turquoise[500]} />}
              >
                Back to Home Page
              </Button>
            </Link>
          </Stack>
        </Grid>
        {image?.url && (
          <Grid item xs={6}>
            <img
              src={image.url}
              alt={image.alt}
              // these are in pixels
              width={image.width ?? 340}
              height={image.height ?? 240}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
}

const useStyles = makeStyles()((theme) => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
  },
  navbar: {
    padding: '32px',
    display: 'flex',
    alignItems: 'center',
  },
  line: {
    width: '100%',
    flex: '1',
    height: '1px',
    marginLeft: '32px',
    border: 'none',
    borderBottom: theme.palette.border.secondary,
  },
  container: {
    display: 'flex',
    width: '100%',
    height: '80vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    display: 'block',
    textAlign: 'center',
  },
  logoLink: {
    cursor: 'pointer',
  },
}));

export default function CLILoginSuccess() {
  const { classes } = useStyles();

  return (
    <div className={classes.page}>
      <div className={classes.navbar}>
        <Link to={RouteNames.PERSONAL_DASHBOARD} search={(p: never) => p} params={(p: never) => p}>
          <LightHorizontalWithLogoImg class={classes.logoLink} />
        </Link>
        <hr className={classes.line} />
      </div>
      <div className={classes.container}>
        <MessageCard
          icon={SmileGoodIcon}
          title="Zephyr CLI Login Success!"
          subtitle="Zephyr CLI was successfully authenticated"
          description="Now you can close this tab and return to the CLI."
        />
      </div>
    </div>
  );
}

CLILoginSuccess.getLayout = (page: ReactElement) => {
  return page;
};
