import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
// mui
import { Stack } from '@mui/material';
import Alert from '@mui/material/Alert';
// components
import { Button } from 'app-zephyr-components/Button';
// _elements
import { FormContainer } from '../../_elements';
// utils
import { FormInput, FormTextArea, FormCheckbox } from 'app-zephyr-forms';
import { joiResolver } from '@hookform/resolvers/joi';
// contracts
import { ProjectSettings, ProjectSettingsBodyReq } from 'ze-api-contract/project-v2/settings/interfaces';
import { formSchema } from 'ze-api-contract/project-v2/settings/update-project-settings';

export type ProjectSettingsFormFields = ProjectSettingsBodyReq;

interface ProjectSettingsFormProps {
  isSaveProjectSettingsPending: boolean;
  updateProjectSettingsError: Error | null;
  onSubmit: (value: ProjectSettingsBodyReq) => void;
  projectSettings?: ProjectSettings;
}

/**
 * Project settings form.
 * @returns {React.FC} react-hook-form form implementation.
 */
const ProjectSettingsForm = ({
  updateProjectSettingsError,
  isSaveProjectSettingsPending,
  onSubmit,
  projectSettings,
}: ProjectSettingsFormProps) => {
  const defaultValues = useMemo(
    () => ({
      displayName: projectSettings?.displayName ?? '',
      description: projectSettings?.description ?? '',
      isPrivate: !!projectSettings?.isPrivate,
    }),
    [projectSettings],
  );

  const {
    register,
    handleSubmit,
    control,
    trigger,
    reset,
    formState: { isValid, errors, isDirty },
  } = useForm<ProjectSettingsFormFields>({
    defaultValues,
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: joiResolver(formSchema),
  });

  const handleOnSubmit = handleSubmit((data) => {
    onSubmit(data);
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <form onSubmit={(data) => void handleOnSubmit(data)} onChange={() => void trigger()}>
      <Stack marginBottom={2}>
        <FormContainer title={'Public information'} variant="outlined">
          <Stack spacing={3}>
            <FormInput<ProjectSettingsFormFields>
              id="displayName"
              name="displayName"
              label="Project name"
              register={register}
              errors={errors}
            />
            <FormTextArea
              id="description"
              name="description"
              label="Project description"
              register={register}
              errors={errors}
              placeholder="Fill out a short description for this project."
            />
          </Stack>
        </FormContainer>
      </Stack>
      <FormContainer title={'Privacy options'} variant="outlined">
        <Stack>
          <FormCheckbox<ProjectSettingsFormFields>
            id="isPrivate"
            name="isPrivate"
            label="Make project private"
            control={control}
            errors={errors}
          />
        </Stack>
      </FormContainer>
      {!!updateProjectSettingsError && <Alert severity="error">Something went wrong, please try again</Alert>}
      <Stack display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} marginTop={2} gap={2}>
        <Button
          variant="outlined"
          onClick={() => {
            reset(defaultValues);
          }}
        >
          Reset
        </Button>
        <Button disabled={isSaveProjectSettingsPending || !isValid || !isDirty} type="submit">
          Save changes
        </Button>
      </Stack>
    </form>
  );
};

export { ProjectSettingsForm };
