import { simplePath } from 'ze-api-contract/utils';

export const GET_ORGANIZATION_UNIQUE_NAME_PATH = '/v2/organization/unique-name/:displayName';

interface GetOrganizationUniqueNameParams {
  displayName: string;
}
export function get_organization_uniquer_name_path(params: GetOrganizationUniqueNameParams): string {
  return simplePath(GET_ORGANIZATION_UNIQUE_NAME_PATH, params);
}
