export interface UrlLink {
  link: string;
  label: string;
}
export interface PrivacyOptions {
  isPrivate: boolean;
}
export interface LocationOptions {
  location: string;
  locationVisible: boolean;
}

export interface OwnedOrganization {
  id: string;
  name: string;
  displayName: string;
}

export interface ProfileSettings {
  // primary fields
  id: string;
  name: string;
  username: string;
  email: string;
  // auth0
  emailIsVerified: boolean;
  // primary fields
  avatarColor: string;
  userPortrait?: string;
  description?: string;
  // profileSettings fields
  socialAccounts?: UrlLink[];
  privacyOptions?: PrivacyOptions;
  locationOptions?: LocationOptions;
  // relationships
  ownedOrganizations: OwnedOrganization[];
}

type ProfileSettingsBody = Omit<
  ProfileSettings,
  'id' | 'name' | 'username' | 'email' | 'emailIsVerified' | 'avatarColor' | 'ownedOrganizations'
>;
export interface ProfileSettingsBodyReq extends ProfileSettingsBody {
  name?: string;
  username?: string;
}

export interface Error {
  message: string;
  response: {
    data: {
      errorMessage: string;
    };
  };
}

export interface ChangePasswordBodyReq {
  oldPassword: string;
  newPassword: string;
}

export enum NotificationUpdate {
  daily = 'Daily Updates',
  weekly = 'Weekly Updates',
  monthly = 'Monthly Updates',
  quartely = 'Quarterly Updates',
  annually = 'Anual Updates',
}

export const notificationUpdateList = Object.keys(NotificationUpdate);

export interface NotificationsActivity {
  value: keyof typeof NotificationUpdate;
  label: string;
  receiveNotifications: boolean;
}

export interface ProfileSettingsEmailOptions {
  defaultEmail: string;
  emailIsVerified: boolean;
  privacyOptions?: PrivacyOptions;
  notificationsActivity?: NotificationsActivity;
  additionalEmails?: string[];
}

export type ProfileSettingsEmailOptionsBodyReq = Omit<ProfileSettingsEmailOptions, 'defaultEmail' | 'emailIsVerified'>;

export interface SetDefaultEmailBodyReq {
  email: string;
}
