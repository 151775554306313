import { CerbosOrgActions, CerbosPeopleKind, CerbosOrgKind } from 'ze-api-contract/organization-v2/cerbos-actions';
import { OrganizationValue } from 'ze-api-contract/organization-v2/get-by-name';
import { User } from 'ze-api-contract/user-v2/get-current-user';
import { Role } from 'ze-api-contract/enums/role';
import { AttributeValue, getDecision } from '../decision';
import { Member, NewMember } from 'ze-api-contract/member-v2/member';

const getOrgRole = (role: Role | undefined): string => `organization_${role ?? 'viewer'}`;

const checkAction = async (organization: OrganizationValue, user: User, action: CerbosOrgActions): Promise<boolean> => {
  const decision = await getDecision({
    principal: {
      id: user.id,
      roles: [getOrgRole(organization.role)],
    },
    resource: {
      kind: CerbosPeopleKind,
      id: organization.id,
      attributes: {
        oldData: {},
        newData: {},
        organization: organization as unknown as AttributeValue,
      },
    },
    actions: [action],
  });

  return decision.isAllowed(action) ?? false;
};

const checkActions = async (
  organization: OrganizationValue,
  user: User,
  peopleLimitExceeded: boolean,
  actions: CerbosOrgActions[],
): Promise<boolean[]> => {
  const decision = await getDecision({
    principal: {
      id: user.id,
      roles: [getOrgRole(organization.role)],
    },
    resource: {
      kind: CerbosPeopleKind,
      id: organization.id,
      attributes: {
        oldData: { peopleLimitExceeded },
        newData: { peopleLimitExceeded },
        organization: organization as unknown as AttributeValue,
      },
    },
    actions,
  });

  return actions.map((action) => decision.isAllowed(action) ?? false);
};

export const getCanManageAll = async (organization: OrganizationValue, user: User): Promise<boolean> => {
  return checkAction(organization, user, CerbosOrgActions.CAN_MANAGE_ALL);
};

export const getCanManageMembers = async (organization: OrganizationValue, user: User): Promise<boolean> => {
  return checkAction(organization, user, CerbosOrgActions.CAN_MANAGE_PEOPLE);
};

export const getCanManageOwner = async (organization: OrganizationValue, user: User): Promise<boolean> => {
  return checkAction(organization, user, CerbosOrgActions.CAN_MANAGE_OWNER);
};

export const canMangeConfigKey = [
  'canManage',
  CerbosOrgActions.CAN_MANAGE_ALL,
  CerbosOrgActions.CAN_MANAGE_PEOPLE,
  CerbosOrgActions.CAN_MANAGE_OWNER,
].join('/');

export interface CanManageConfig {
  manageAll: boolean;
  managePeople: boolean;
  manageOwner: boolean;
}

export const getCanManageConfig = async (
  organization: OrganizationValue,
  user: User,
  peopleLimitExceeded: boolean,
): Promise<CanManageConfig> => {
  const [manageAll, managePeople, manageOwner] = await checkActions(organization, user, peopleLimitExceeded, [
    CerbosOrgActions.CAN_MANAGE_ALL,
    CerbosOrgActions.CAN_MANAGE_PEOPLE,
    CerbosOrgActions.CAN_MANAGE_OWNER,
  ]);
  return {
    manageAll,
    managePeople,
    manageOwner,
  };
};

export const getAllowedActions = async (
  organization: OrganizationValue,
  user: User,
  member: NewMember,
  peopleLimitExceeded: boolean,
): Promise<boolean> => {
  const action = CerbosOrgActions.INVITE_PEOPLE;
  const decision = await getDecision({
    principal: {
      id: user.id,
      roles: [getOrgRole(organization.role)],
    },
    resource: {
      kind: CerbosOrgKind,
      id: organization.id,
      attributes: {
        oldData: { peopleLimitExceeded },
        newData: { ...member, peopleLimitExceeded } as unknown as AttributeValue,
        organization: organization as unknown as AttributeValue,
      },
    },
    actions: [action],
  });

  return decision.isAllowed(action) ?? false;
};

export const getCanInviteMember = async (
  organization: OrganizationValue,
  user: User,
  member: NewMember,
): Promise<boolean> => {
  const action = CerbosOrgActions.CAN_INVITE_PEOPLE;
  const decision = await getDecision({
    principal: {
      id: user.id,
      roles: [getOrgRole(organization.role)],
    },
    resource: {
      kind: CerbosOrgKind,
      id: organization.id,
      attributes: {
        oldData: {},
        newData: member as unknown as AttributeValue,
        organization: organization as unknown as AttributeValue,
      },
    },
    actions: [action],
  });

  return decision.isAllowed(action) ?? false;
};

export const getCanUpdateMember = async (
  organization: OrganizationValue,
  user: User,
  member: NewMember,
  oldMember: Member,
): Promise<boolean> => {
  const action = CerbosOrgActions.CAN_UPDATE_PEOPLE;
  const decision = await getDecision({
    principal: {
      id: user.id,
      roles: [getOrgRole(organization.role)],
    },
    resource: {
      kind: CerbosPeopleKind,
      id: organization.id,
      attributes: {
        oldData: oldMember as unknown as AttributeValue,
        newData: member as unknown as AttributeValue,
        organization: organization as unknown as AttributeValue,
      },
    },
    actions: [action],
  });

  return decision.isAllowed(action) ?? false;
};

export const getCanDeleteMember = async (
  organization: OrganizationValue,
  user: User,
  member: Member,
): Promise<boolean> => {
  const action = CerbosOrgActions.DELETE_PEOPLE;
  const decision = await getDecision({
    principal: {
      id: user.id,
      roles: [getOrgRole(organization.role)],
    },
    resource: {
      kind: CerbosPeopleKind,
      id: organization.id,
      attributes: {
        oldData: member as unknown as AttributeValue,
        newData: {},
        organization: organization as unknown as AttributeValue,
      },
    },
    actions: [action],
  });

  return decision.isAllowed(action) ?? false;
};
