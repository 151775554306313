import { provider, ProviderComposer } from 'app-zephyr-components/ProviderCompose';
import { Auth0ProviderWithHistory } from './components/auth0-provicer-with-history';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider } from '@tanstack/react-router';
import { router } from './router';
import { AxiosInterceptor } from 'app-zephyr-axios';
import { HelmetProvider } from 'react-helmet-async';
import { SEO } from 'app-zephyr-components/SEO';
import CssBaseline from '@mui/material/CssBaseline';
import { GoogleScripts } from 'app-zephyr-components/Google-scripts';
import { envValue } from 'app-zephyr-environment';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import StylesProvider from '@mui/styles/StylesProvider';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { fireCustomEvent, GTM_ID } from 'app-zephyr-components/GoogleTagManager';
import { createTheme } from '@mui/material';
import { darkThemeOptions } from 'app-zephyr-styles/themes';

const environment = envValue.value;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 10,
    },
  },
});

export function App() {
  // TODO: refactor this to use correct theme type
  const theme = createTheme(darkThemeOptions as unknown);

  return (
    <ProviderComposer
      providers={[
        provider(Auth0ProviderWithHistory),
        provider(AxiosInterceptor),
        provider(QueryClientProvider, { client: queryClient }),
        provider(HelmetProvider),
        provider(StylesProvider, { injectFirst: true }),
        provider(ThemeProvider, { theme }),
      ]}
    >
      <SEO title="Zephyr">
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
        <link rel="icon" href={'/favicon.ico'} />
        <link rel="document" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
        <link
          href="https://fonts.googleapis.com/css2?family=Lato:wght@400;500;700&family=Open+Sans:wght@400;500;700&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap"
          rel="stylesheet"
        />
      </SEO>
      <CssBaseline />
      <RouterProvider router={router(queryClient)} />
      {environment.production && <GoogleScripts id={GTM_ID} fireCustomEvent={fireCustomEvent} />}
      {!environment.production && <ReactQueryDevtools initialIsOpen={false} />}
    </ProviderComposer>
  );
}
