import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useQuery } from '@tanstack/react-query';
import { User } from 'ze-api-contract/user-v2/get-current-user';
import { OrganizationValue } from 'ze-api-contract/organization-v2/get-by-name';
import { getCanManageAppConfig, canManageConfigKey, CanManageAppConfig } from 'app-zephyr-guards/application';
import { useCurrentUser } from './current-user';

export interface CanManageAppParams {
  organization: OrganizationValue | undefined;
  project: string | undefined;
  application: string | undefined;
  user: User | undefined;
}

export const queryKeys = createQueryKeys('user-can-manage-org', {
  can: ({ organization, project, application, user }: CanManageAppParams) => ({
    queryKey: [canManageConfigKey, organization, project, application, user],
    queryFn: () => {
      if (!organization || !project || !application || !user)
        return Promise.resolve<CanManageAppConfig>({
          updateApplication: false,
          updateApplicationName: false,
          updateApplicationSettings: false,
          deleteApplication: false,
        });
      return getCanManageAppConfig(organization, user);
    },
  }),
});

export function useUserCanManageApp(params: Omit<CanManageAppParams, 'user'>) {
  const { user } = useCurrentUser();
  const { isLoading, data: can, error } = useQuery(queryKeys.can({ ...params, user }));

  return {
    can,
    isLoading,
    error,
  };
}
