import { makeStyles } from 'tss-react/mui';
import { CSSObject } from '@emotion/react';

const itemsCenter = {
  display: 'flex',
  alignItems: 'center',
} satisfies CSSObject;

export const useStyles = makeStyles()((theme) => ({
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    width: '100vw',
    background: theme.palette.bg.secondary.default,
    position: 'relative',
    zIndex: 1,
    borderBottom: theme.palette.border.secondary,
  },
  header: {
    ...itemsCenter,
    alignContent: 'center',
    justifyItems: 'center',
  },

  toolbar: {
    ...itemsCenter,
    justifyContent: 'space-between',
    maxWidth: '1280px',
    width: '80vw',
    margin: '0 auto',
    paddingBlock: 0,
    padding: `${theme.spacing(2).toString()} ${theme.spacing(1).toString()}`,
    gap: theme.spacing(1),
    maxHeight: 54,
    overflow: 'hidden',

    '&.navigation': {
      flexWrap: 'nowrap',
      paddingBlock: 0,
    },

    '&.hidden': {
      height: 0,
    },
  },
  logoContainer: {
    ...itemsCenter,
    gap: theme.spacing(1),
    justifyContent: 'center',
    justifyItems: 'center',
    flexBasis: 'auto',
    minWidth: 0,
    maxWidth: '100%',
  },
  actions: {
    flexShrink: 0,
    marginLeft: 'auto',
    paddingBlock: theme.spacing(1),
  },
  menuContainer: {
    ...itemsCenter,
    gap: 6,
  },
  documentationLink: {
    marginRight: theme.spacing(1),
  },
  documentationButton: {
    fontWeight: 600,
    fontSize: 14,
  },
}));
