import * as Joi from 'joi';
import { simplePath } from 'ze-api-contract/utils';

import { ProfileSettings } from './interfaces';
import { baseSchema, emailValidation, socialAccountsFormValidation, nameValidation } from './validation';

export const POST_PROFILE_SETTINGS = 'v2/user-profile-settings';

export function post_profile_settings_path(): string {
  return simplePath(POST_PROFILE_SETTINGS, {});
}

export const schema = baseSchema.required();

export const formSchema = baseSchema
  .append<ProfileSettings>({
    email: emailValidation,
    avatarColor: Joi.string().optional().allow(null, ''),
    userPortrait: Joi.string().optional().allow(null, ''),
    socialAccounts: socialAccountsFormValidation,
  })
  .required();

export const confirmModalForm = (tokenName: string) =>
  Joi.object({
    name: Joi.string().required().valid(tokenName).messages({
      'any.only': `You must input the required field correctly to finish this action.`,
    }),
  });

export const changeUserName = Joi.object({
  name: nameValidation,
});

export const accountDeleteModalForm = (userName: string, userEmail: string) =>
  Joi.object({
    name: Joi.alternatives().try(
      // not using email as validation here because it will fail on rule set
      Joi.string()
        .required()
        .valid(userEmail)
        .messages({ 'any.only': `You must input the correct email ${userEmail} to finish this action.` }),
      Joi.string()
        .required()
        .valid(userName)
        .messages({ 'any.only': `You must input the correct user name ${userName} to finish this action.` }),
    ),
    confirm: Joi.string()
      .valid('delete-account')
      .messages({ 'any.only': 'You must type in "delete-account" to complete this action.' }),
  });
