import { useMutation, useQueryClient } from '@tanstack/react-query';
import { axios } from 'app-zephyr-axios';
// hooks
import { organizationDeploymentIntegrationQuery } from './get-organization-deployment-integrations';
import { set_default_org_integrations_path } from 'ze-api-contract/deployment-integration/organization/path';

export function useSetDefaultOrganizationCloudflareIntegration(organization: string) {
  const queryClient = useQueryClient();
  const { queryKey } = organizationDeploymentIntegrationQuery.list(organization);

  return useMutation({
    mutationFn: (integration: string) => axios.post(set_default_org_integrations_path({ organization, integration })),
    onSuccess: async (res, integration) => {
      await queryClient.invalidateQueries({ queryKey });
      await queryClient.invalidateQueries({
        queryKey: organizationDeploymentIntegrationQuery.integration(organization, integration).queryKey,
      });
    },
  });
}
