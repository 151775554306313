import { useQuery } from '@tanstack/react-query';
import { createQueryKeys } from '@lukemorales/query-key-factory';

import { axios } from 'app-zephyr-axios';
import { AvailableOrganization } from 'ze-api-contract/user-v2/get-current-user-organizations-list';
import { GET_ORGANIZATION_LIST_PATH, get_organization_list_path } from 'ze-api-contract/user-v2/get-organization-list';

export const orgList = createQueryKeys('organization', {
  organizationList: (userEmail?: string) => ({
    queryKey: [GET_ORGANIZATION_LIST_PATH, userEmail],
    queryFn: async () => {
      if (!userEmail) return Promise.resolve();

      return axios
        .get<{ entities: AvailableOrganization[] }>(get_organization_list_path({ userEmail }))
        .then((res) => res.data.entities);
    },
  }),
});

export function useOrganizationList(userEmail?: string) {
  const { data, error, isLoading } = useQuery(orgList.organizationList(userEmail));

  return {
    organizationList: data,
    isLoading,
    error,
  };
}
