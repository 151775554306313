import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  box: {
    padding: '20px 36px',
    borderRadius: 16,
    backgroundColor: theme.palette.bg.secondary.default,

    '& img': {
      maxWidth: 160,
    },
  },

  title: {
    textAlign: 'center',
    fontWeight: 700,
    fontSize: 14,
  },
}));
