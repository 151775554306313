import { Alert, Snackbar, AlertColor } from '@mui/material';

export function SnackBarAlertComponent({
  open,
  onClose,
  message,
  alertColor,
}: {
  open: boolean;
  onClose: () => void;
  message: string;
  alertColor?: AlertColor;
}) {
  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={onClose}>
      <div>
        <AlertComponent message={message} alertColor={alertColor ?? 'error'} />
      </div>
    </Snackbar>
  );
}

function AlertComponent({ message, alertColor }: { message: string; alertColor?: AlertColor }) {
  return (
    <Alert elevation={6} variant="filled" severity={alertColor} key={alertColor}>
      {message}
    </Alert>
  );
}
