import { axios } from 'app-zephyr-axios';
import { useQuery } from '@tanstack/react-query';
import { createQueryKeys } from '@lukemorales/query-key-factory';

import {
  get_org_project_list_path,
  GET_ORG_PROJECT_LIST_PATH,
  GetOrgProjectListQueryParams,
  ProjectListItem,
} from 'ze-api-contract/organization-v2/get-project-list';

interface ProjectListProps {
  organization?: string;
  queryParams?: GetOrgProjectListQueryParams;
}

export const orgProjectList = createQueryKeys('organization', {
  projectList: ({ organization, queryParams }: ProjectListProps) => ({
    queryKey: [GET_ORG_PROJECT_LIST_PATH, organization, queryParams],
    queryFn: async (): Promise<ProjectListItem[] | undefined> => {
      if (!organization) return [];

      const params = queryParams;

      return axios
        .get<{ entities: ProjectListItem[] }>(get_org_project_list_path({ organization }), { params })
        .then((res) => res.data.entities);
    },
  }),
});

/**
 * Get specific organization project list.
 **/
export function useProjectList({ organization, queryParams }: ProjectListProps) {
  const { isLoading, data: projectList, error } = useQuery(orgProjectList.projectList({ organization, queryParams }));

  return { projectList, isLoading, error };
}
