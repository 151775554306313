import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useQuery } from '@tanstack/react-query';
import { User } from 'ze-api-contract/user-v2/get-current-user';
import { OrganizationValue } from 'ze-api-contract/organization-v2/get-by-name';
import { getCanManageConfig, canMangeConfigKey } from 'app-zephyr-guards/org-people';
import { useCurrentUser } from './current-user';

export interface CanManagePeopleParams {
  organization: OrganizationValue | undefined;
  user: User | undefined;
  peopleLimitExceeded: boolean;
}

export const queryKeys = createQueryKeys('user-can-manage', {
  can: ({ organization, user, peopleLimitExceeded }: CanManagePeopleParams) => ({
    queryKey: [canMangeConfigKey, organization, user, peopleLimitExceeded],
    queryFn: async () => {
      if (!organization || !user)
        return {
          manageAll: false,
          manageOwner: false,
          managePeople: false,
        };
      return getCanManageConfig(organization, user, peopleLimitExceeded);
    },
  }),
});

export function useUserCanManagePeople(params: Omit<CanManagePeopleParams, 'user'>) {
  const { user } = useCurrentUser();
  const { isLoading, data: can, error } = useQuery(queryKeys.can({ ...params, user }));

  return {
    can,
    isLoading,
    error,
  };
}
