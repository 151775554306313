import { simplePath } from 'ze-api-contract/utils';
import * as Joi from 'joi';
import { SetDefaultEmailBodyReq } from './interfaces';

export const POST_EMAIL_DEFAULT = 'v2/user-profile-settings/set-default-email';

export function post_default_email_path(): string {
  return simplePath(POST_EMAIL_DEFAULT, {});
}

export const schema = Joi.object<SetDefaultEmailBodyReq>({
  email: Joi.string().email({ tlds: false }).required(),
}).required();
