import * as Joi from 'joi';

import { TEAM_NAME_VALIDATOR, PROJECT_NAME_JOI_VALIDATOR_MESSAGES } from '../validation';

export const createTeamValidation = Joi.object({
  name: Joi.string()
    .pattern(TEAM_NAME_VALIDATOR.pattern.value)
    .min(TEAM_NAME_VALIDATOR.minLength.value)
    .max(TEAM_NAME_VALIDATOR.maxLength.value)
    .required()
    .messages(PROJECT_NAME_JOI_VALIDATOR_MESSAGES),
});
