import * as Joi from 'joi';
import {
  DESCRIPTION_JOI_VALIDATOR_MESSAGES,
  DESCRIPTION_VALIDATOR,
  EMAIL_JOI_VALIDATOR_MESSAGES,
  LINKS_JOI_VALIDATOR_MESSAGES,
  ORGANIZATION_DISPLAY_NAME_JOI_VALIDATOR_MESSAGES,
  ORGANIZATION_DISPLAY_NAME_VALIDATOR,
  ORGANIZATION_DOMAIN_VALIDATOR,
  ORGANIZATION_NAME_JOI_VALIDATOR_MESSAGES,
  ORGANIZATION_NAME_VALIDATOR,
} from '../../validation';
import { ChangeOrganizationName, OrganizationPublicSettings } from './interfaces';

const displayNameValidation = Joi.string()
  .max(ORGANIZATION_DISPLAY_NAME_VALIDATOR.maxLength.value)
  .min(ORGANIZATION_DISPLAY_NAME_VALIDATOR.minLength.value)
  .pattern(ORGANIZATION_DISPLAY_NAME_VALIDATOR.pattern.value)
  .required()
  .messages(ORGANIZATION_DISPLAY_NAME_JOI_VALIDATOR_MESSAGES);

const nameValidation = Joi.string()
  .max(ORGANIZATION_NAME_VALIDATOR.maxLength.value)
  .min(ORGANIZATION_NAME_VALIDATOR.minLength.value)
  .pattern(ORGANIZATION_NAME_VALIDATOR.pattern.value)
  .required()
  .messages(ORGANIZATION_NAME_JOI_VALIDATOR_MESSAGES);

const emailValidation = Joi.string().email({ tlds: false }).messages(EMAIL_JOI_VALIDATOR_MESSAGES).required();

export const inviteMemberBaseSchema = Joi.object({
  name: displayNameValidation,
  email: emailValidation,
});

const optionalEmailValidation = Joi.string()
  .email({ tlds: false })
  .optional()
  .messages(EMAIL_JOI_VALIDATOR_MESSAGES)
  .allow('');

const emptyStringValidation = Joi.string().optional().allow('');

const descriptionValidation = Joi.string()
  .optional()
  .max(DESCRIPTION_VALIDATOR.maxLength.value)
  .pattern(DESCRIPTION_VALIDATOR.pattern.value)
  .messages(DESCRIPTION_JOI_VALIDATOR_MESSAGES)
  .allow(null, '');

const socialAccountsValidation = Joi.array()
  .items(
    Joi.object({
      label: emptyStringValidation,
      link: emptyStringValidation,
    }),
  )
  .max(5)
  .required()
  .default([]);

export const socialAccountsFormValidation = Joi.array()
  .items(
    Joi.object({
      link: Joi.string().uri().messages(LINKS_JOI_VALIDATOR_MESSAGES).optional().allow(null, ''),
      label: Joi.string().uri().messages(LINKS_JOI_VALIDATOR_MESSAGES).optional().allow(null, ''),
    }),
  )
  .max(5)
  .required()
  .default([]);

const privacyOptionsValidation = Joi.object({
  isPrivate: Joi.boolean().required(),
}).required();

const locationValidation = Joi.string().required().max(100).allow('').default('');

const locationOptionsValidation = Joi.object({
  location: locationValidation,
  locationVisible: Joi.boolean().required().default(true),
}).required();

const CONFIRM_WORD = 'delete';

export const orgSettingsDeleteForm = (orgName: string) =>
  Joi.object({
    name: Joi.string().required().valid(orgName).messages({
      'any.only': `You must input the full organization name`,
    }),
    confirm: Joi.string()
      .required()
      .valid(CONFIRM_WORD)
      .messages({
        'any.only': `you must type in '${CONFIRM_WORD}' to take complete this action`,
      }),
  });

export const updateOrganizationPublicSettings = Joi.object<OrganizationPublicSettings>({
  displayName: displayNameValidation,
  email: optionalEmailValidation,
  description: descriptionValidation,
  privacyOptions: privacyOptionsValidation,
  locationOptions: locationOptionsValidation,
  socialAccounts: socialAccountsValidation,
}).required();

export const changeOrganizationName = Joi.object<ChangeOrganizationName>({
  name: nameValidation,
}).required();

export const orgSettingsUpdateForm = updateOrganizationPublicSettings.append({
  socialAccounts: socialAccountsFormValidation,
  avatarColor: emptyStringValidation,
  logoUrl: emptyStringValidation,
  name: nameValidation,
});

export const updateOrgMembershipSettings = Joi.object({
  openMembership: Joi.boolean().required(),
  domains: Joi.array().items(Joi.string().pattern(ORGANIZATION_DOMAIN_VALIDATOR.pattern.value)).messages({
    'string.min': ORGANIZATION_DOMAIN_VALIDATOR.minLength.message,
    'string.max': ORGANIZATION_DOMAIN_VALIDATOR.maxLength.message,
    'string.pattern.base': ORGANIZATION_DOMAIN_VALIDATOR.pattern.message,
  }),
});
