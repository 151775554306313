import React from 'react';
// @mui
import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';
// types
import { IEmpty } from './interfaces';
// styles
import { useStyles } from './styles';

/**
 * Empty display content.
 * @param isRender render flag.
 * @param title page title.
 * @param icon optional custom alert icon.
 * @param className optional root className.
 */
const Empty: React.FC<IEmpty> = ({ isRender, title, icon, className }) => {
  const { classes, cx } = useStyles();

  if (!isRender) return null;

  return (
    <div className={cx([classes.alertContent, className])}>
      {icon ?? <SearchIcon />}
      <Typography variant="body1">{title}</Typography>
    </div>
  );
};

export { Empty };
