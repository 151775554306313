import { DetailedHTMLProps, InputHTMLAttributes, forwardRef } from 'react';
import { OutlinedInput } from '@mui/material';
import classNames from 'classnames';
import { useStyles } from './styles';

export interface TextFieldProps
  extends Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'size'> {
  /**
   * input id
   */
  id: string;
  /**
   * input name
   */
  name: string;
  /**
   * input label
   */
  label?: string;
  /**
   * custom class
   */
  className?: string;
  /**
   * amount of rows
   */
  rows?: number;
}

const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  ({ id, name, label, className = '', rows, ...props }, ref) => {
    const { classes, cx } = useStyles();

    return (
      <OutlinedInput
        inputProps={{
          id,
          'aria-label': label,
          itemType: 'text',
          ...props,
        }}
        placeholder={props.placeholder}
        inputRef={ref}
        name={name}
        fullWidth
        label={label ?? ''}
        rows={rows ?? 4}
        multiline
        className={cx(classes.textField, classNames(className))}
      />
    );
  },
);

export { TextField };
