import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useQuery } from '@tanstack/react-query';

import { axios } from 'app-zephyr-axios';
import {
  AvailableOrganization,
  GET_CURRENT_USER_ORGANIZATION_LIST_PATH,
} from 'ze-api-contract/user-v2/get-current-user-organizations-list';

export const currentUserOrganizationList = createQueryKeys('user', {
  available: {
    queryKey: [GET_CURRENT_USER_ORGANIZATION_LIST_PATH],
    queryFn: () =>
      axios
        .get<{ entities: AvailableOrganization[] }>(GET_CURRENT_USER_ORGANIZATION_LIST_PATH)
        .then((res) => res.data.entities),
  },
});

export function useCurrentUserOrganizationList() {
  const { data, error, isLoading } = useQuery(currentUserOrganizationList.available);

  return {
    organizationList: data,
    isLoading,
    error,
  };
}
