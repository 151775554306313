import { useMutation, useQueryClient } from '@tanstack/react-query';
import { axios } from 'app-zephyr-axios';
// hooks
import { applicationDeploymentIntegrationQuery } from './get-application-deployment-integration-list';
import { post_set_default_application_deployment_integrations_path } from 'ze-api-contract/deployment-integration/application/path';

export function useSetDefaultApplicationCloudflareIntegration(
  organization: string,
  project: string,
  application: string,
) {
  const queryClient = useQueryClient();
  const { queryKey } = applicationDeploymentIntegrationQuery.list(organization, project, application);

  return useMutation({
    mutationFn: (integration: string) =>
      axios.post(
        post_set_default_application_deployment_integrations_path({ organization, project, application, integration }),
      ),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey });
    },
  });
}
