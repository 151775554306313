import styled from '@mui/material/styles/styled';
import AvatarComponent, { AvatarProps } from '@mui/material/Avatar';

const StyledAvatar = styled(AvatarComponent)(({ theme }) => ({
  '&': {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '150%',
    color: theme.palette.tx.primary,
  },
}));

type CustomeAvatarProps = {
  width: number | string;
  height: number | string;
  backgroundColor: string;
} & AvatarProps;

export const Avatar = ({ width, height, backgroundColor, children, ...props }: CustomeAvatarProps) => {
  return (
    <StyledAvatar sx={{ width, height, backgroundColor }} {...props}>
      {children}
    </StyledAvatar>
  );
};
