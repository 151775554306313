import { simplePath } from 'ze-api-contract/utils';
import { Team } from 'ze-api-contract/team/interfaces';

export const GET_ORG_TEAM_LIST_PATH = `/v2/organization/:organization/team-list`;

interface GetOrganizationTeamListParams {
  organization: string;
}

export function get_org_team_list_path(params: GetOrganizationTeamListParams): string {
  return simplePath(GET_ORG_TEAM_LIST_PATH, params);
}

export interface OrganizationTeamListItem extends Team {
  memberCount: number;
}

export interface GetTeamListQueryParams {
  name?: string;
}
