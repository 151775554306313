export const WEEK = 7;
export const MONTH = 30;
export const QUARTER = 90;
export const PERMANENT = 999 * 365;

export enum EXPIRE_TIME_LABELS {
  WEEK = '7 days',
  MONTH = '30 days',
  QUARTER = '90 days',
  PERMANENT = 'Permanent',
}
