import { simplePath } from '../utils';

export const GET_USER_PATH = '/v2/user/:username';

export interface GetUserParams {
  username: string;
}

export function get_user_path(params: GetUserParams) {
  return simplePath(GET_USER_PATH, params);
}
