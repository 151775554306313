import { useQuery } from '@tanstack/react-query';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { axios } from 'app-zephyr-axios';

import {
  get_org_integration_list_path,
  ORG_DEPLOYMENT_INTEGRATION_LIST,
  get_org_integration_path,
  ORG_DEPLOYMENT_INTEGRATION,
  ENABLED_DEPLOYMENT_INTEGRATION_LIST,
  get_enabled_deployment_integration_list_path,
} from 'ze-api-contract/deployment-integration/organization/path';
import { DeploymentIntegrationValue } from 'ze-api-contract/deployment-integration/organization/interfaces';

export const organizationDeploymentIntegrationQuery = createQueryKeys('deploymentIntegration', {
  list: (organization?: string) => ({
    queryKey: [ORG_DEPLOYMENT_INTEGRATION_LIST, organization],
    queryFn: async () => {
      if (!organization) return Promise.resolve(undefined);

      return axios
        .get<{ entities: DeploymentIntegrationValue[] }>(get_org_integration_list_path({ organization }))
        .then((res) => res.data.entities);
    },
  }),
  integration: (organization?: string, name?: string) => ({
    queryKey: [ORG_DEPLOYMENT_INTEGRATION, organization, name],
    queryFn: async () => {
      if (!organization || !name) return Promise.resolve(undefined);
      return axios
        .get<{ value: DeploymentIntegrationValue }>(get_org_integration_path({ organization, integration: name }))
        .then((res) => res.data.value);
    },
  }),
  enabledList: (organization?: string) => ({
    queryKey: [ENABLED_DEPLOYMENT_INTEGRATION_LIST, organization],
    queryFn: async () => {
      if (!organization) return Promise.resolve(undefined);
      return axios
        .get<{ entities: DeploymentIntegrationValue[] }>(get_enabled_deployment_integration_list_path({ organization }))
        .then((res) => res.data.entities);
    },
  }),
});

export function useOrganizationDeploymentIntegrationList(orgName: string | undefined) {
  const {
    data: deploymentIntegrationList,
    isLoading,
    error,
  } = useQuery(organizationDeploymentIntegrationQuery.list(orgName));

  return { deploymentIntegrationList, isLoading, error };
}

export function useOrganizationDeploymentIntegration(orgName: string | undefined, name: string | undefined) {
  const {
    data: deploymentIntegration,
    isLoading,
    error,
  } = useQuery(organizationDeploymentIntegrationQuery.integration(orgName, name));

  return { deploymentIntegration, isLoading, error };
}

export function useOrganizationEnabledDeploymentIntegrationList(orgName: string | undefined) {
  const {
    data: enabledDeploymentIntegrationList,
    isLoading,
    error,
  } = useQuery(organizationDeploymentIntegrationQuery.enabledList(orgName));

  return { enabledDeploymentIntegrationList, isLoading, error };
}
