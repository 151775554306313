import { useMutation, useQueryClient } from '@tanstack/react-query';
import { axios } from 'app-zephyr-axios';

import { organizationDeploymentIntegrationQuery } from './get-organization-deployment-integrations';
import { enable_disable_org_integrations_path } from 'ze-api-contract/deployment-integration/organization/path';

export function useEnableDisableOrganizationIntegration(organization: string) {
  const queryClient = useQueryClient();
  const { queryKey } = organizationDeploymentIntegrationQuery.list(organization);

  return useMutation({
    mutationFn: (integration: string) =>
      axios.post(enable_disable_org_integrations_path({ organization, integration })),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey });
    },
  });
}
