import Button from '@mui/material/Button';

import { useStyles } from './styles';

interface PillBtnProps {
  label: string;
  isActive: boolean;
  onClick: () => void;
}

export const PillBtn = ({ label, isActive, onClick }: PillBtnProps) => {
  const { classes, cx } = useStyles();

  return (
    <Button onClick={onClick} className={cx([classes.button, { active: isActive }])}>
      {label}
    </Button>
  );
};
