import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  confirmBtn: {
    marginLeft: theme.spacing(2),
  },

  confirmWord: {
    color: theme.palette.brand.purple[300],
    fontWeight: 600,
  },

  accentError: {},

  separator: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',

    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      width: '100%',
      height: '1px',
      backgroundColor: theme.palette.bg.tertiary,
      top: '50%',
      zIndex: '-1',
    },
  },

  transparentTextBox: {
    backgroundColor: theme.palette.bg.secondary.default,
    width: 'fit-content',
    padding: '0 3px',
  },

  borderBox: {
    padding: 16,
    border: theme.palette.border.secondary,
    borderRadius: 8,
  },
}));
