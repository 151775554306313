import { useMutation, useQueryClient } from '@tanstack/react-query';

import { axios } from 'app-zephyr-axios';

import { teamQuery } from './team';
import { orgTeamList } from '../organization';

interface UpdateTeamData {
  name: string;
}

interface UpdateTeamParams {
  organizationName: string;
  teamId: string;
  data: UpdateTeamData;
}

export function useUpdateTeam() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ teamId, data }: UpdateTeamParams) => axios.post(`/v2/team/${teamId}`, data),
    onSuccess: async (res, { teamId, organizationName }) => {
      await queryClient.invalidateQueries(orgTeamList.teamList({ organization: organizationName }));
      await queryClient.invalidateQueries(teamQuery.getById(teamId));
    },
  });
}
