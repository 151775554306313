import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  checkBox: {
    display: 'flex',
    height: 18,

    '.MuiCheckbox-root': {
      padding: 0,
      width: 18,
      height: 18,
    },
  },

  checked: {
    width: 18,
    height: 18,
  },
}));
