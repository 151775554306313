import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  root: {
    ...theme.typography.regular2,
    background: theme.palette.bg.primary.default,
    borderRadius: 8,

    '.MuiSelect': {
      '&-select.MuiSelect-outlined.MuiInputBase-input': {
        lineHeight: '1.5rem',
        padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
      },
    },

    '.MuiOutlinedInput-notchedOutline': {
      border: theme.palette.border.secondary,
    },
  },
}));
