import React from 'react';
// @mui
import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';
// types
import { ILoading } from './interfaces';
// styles
import { useStyles } from './styles';

/**
 * Loading display content.
 * @param isLoading error instance, uses as a render flag.
 * @param title optional page title.
 * @param icon optional custom alert icon.
 * @param className optional root className.
 */
const Loading: React.FC<ILoading> = ({ isLoading, title = 'Loading...', icon, className }) => {
  const { classes, cx } = useStyles();

  if (!isLoading) return null;

  return (
    <div className={cx([classes.alertContent, className])}>
      {icon ?? <SearchIcon />}
      <Typography variant="body1">{title}</Typography>
    </div>
  );
};

export { Loading };
