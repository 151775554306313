import { Checkbox } from '@mui/material';
import { Path, Control, Controller } from 'react-hook-form';
import { CheckboxCheckedIcon, CheckboxOutlinedIcon } from 'app-zephyr-icons';

import { useStyles } from './styles';

export const FormCheckboxElement = <FormValuesProps extends Record<string, unknown>>({
  name,
  control,
}: {
  name: Path<FormValuesProps>;
  control: Control<FormValuesProps>;
}) => {
  const { classes, theme } = useStyles();

  return (
    <div className={classes.checkBox}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Checkbox
            {...field}
            checked={!!field.value}
            disableRipple={true}
            icon={<CheckboxOutlinedIcon />}
            checkedIcon={
              <div className={classes.checked}>
                <CheckboxCheckedIcon color={theme.palette.brand.turquoise[300]} />{' '}
              </div>
            }
            onChange={(e) => {
              field.onChange(e.target.checked);
            }}
          />
        )}
      />
    </div>
  );
};
