import { TextField } from '@mui/material';
import { PROJECT_NAME_VALIDATOR } from 'ze-api-contract/validation';

export interface ProjectNameConfirmFormProps {
  newProjectName: string;
  nameError: string;
  className?: string;
  handleNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
}

function ProjectNameConfirmForm(props: ProjectNameConfirmFormProps) {
  const { newProjectName, className, nameError, handleNameChange, onSubmit } = props;

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit();
      }}
      noValidate
    >
      <TextField
        className={className}
        value={newProjectName}
        error={!!nameError}
        helperText={nameError}
        inputProps={{ ...PROJECT_NAME_VALIDATOR }}
        onChange={handleNameChange}
      />
    </form>
  );
}

export { ProjectNameConfirmForm };
