import * as Joi from 'joi';
import { CreateUserTokenReq, UpdateUserTokenNameReq, UpdateUserTokenReq } from './user-auth-token.interface';
import { USER_TOKEN_NAME_VALIDATOR, USER_TOKEN_NAME_JOI_VALIDATOR_MESSAGES } from 'ze-api-contract/validation';

export const userTokenNameValidator = Joi.string()
  .pattern(USER_TOKEN_NAME_VALIDATOR.pattern.value)
  .min(USER_TOKEN_NAME_VALIDATOR.minLength.value)
  .max(USER_TOKEN_NAME_VALIDATOR.maxLength.value)
  .messages(USER_TOKEN_NAME_JOI_VALIDATOR_MESSAGES)
  .required();

export const createTokenSchema = Joi.object<CreateUserTokenReq>({
  description: Joi.string().optional().allow(''),
  name: userTokenNameValidator,
  expiryDays: Joi.number().min(1).required(),
});

export const createTokenFormSchemaUI = Joi.object<CreateUserTokenReq>({
  name: userTokenNameValidator,
  description: Joi.string().allow('').optional().max(400),
  expiryDays: Joi.object({
    label: Joi.string(),
    value: Joi.number().integer().min(1).required(),
  }).required(),
});

export const updateTokenSchema = Joi.object<UpdateUserTokenReq>({
  description: Joi.string().allow('').optional(),
});

export const updateTokenFormSchema = Joi.object<UpdateUserTokenReq>({
  description: Joi.string().optional().allow(''),
});

export const updateTokenNameSchema = Joi.object<UpdateUserTokenNameReq>({
  name: userTokenNameValidator,
});
