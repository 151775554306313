import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

import { useStyles } from '../deployment-integration-item/styles';

export function DeploymentIntegrationItemSkeleton() {
  const { classes } = useStyles();

  return (
    <Stack className={classes.itemBox} direction={'column'} spacing={1}>
      <Stack
        justifyContent={'center'}
        alignItems={'center'}
        padding={'8px'}
        width={56}
        height={56}
        className={classes.imgBox}
      >
        <Skeleton variant="circular" />
      </Stack>
      <Stack>
        <span className={classes.itemTitle}>
          <Skeleton width={200} height={20} />
          <Skeleton width={150} height={20} />
        </span>
      </Stack>
    </Stack>
  );
}
