import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

import { axios } from 'app-zephyr-axios';
import {
  GET_USER_PIN_OPTIONS_PATH,
  UserPinOption,
  get_user_pin_options_path,
} from 'ze-api-contract/user-v2/user-pins/get-user-pin-options';
import {
  GET_USER_PINS_PATH,
  GetUserPinsParams,
  UserPin,
  get_user_pins_path,
} from 'ze-api-contract/user-v2/user-pins/get-user-pins';
import { get_my_pin_list_path } from 'ze-api-contract/user-v2/user-pins/get-my-pin-list';
import { SaveUserPins, save_user_pins_path } from 'ze-api-contract/user-v2/user-pins/save-user-pins';

export const userPinsQuery = createQueryKeys('user-pins', {
  userPinList: (params: GetUserPinsParams) => ({
    queryKey: [GET_USER_PINS_PATH, params],
    queryFn: async () => {
      if (!params.username) return Promise.resolve(null);
      return axios.get<{ entities: UserPin[] }>(get_user_pins_path(params)).then((res) => res.data.entities);
    },
  }),
  myPinList: {
    queryKey: [GET_USER_PINS_PATH],
    queryFn: async () => {
      return axios.get<{ entities: UserPin[] }>(get_my_pin_list_path()).then((res) => res.data.entities);
    },
  },
  userPinOptions: () => ({
    queryKey: [GET_USER_PIN_OPTIONS_PATH],
    queryFn: async () => {
      return axios.get<{ entities: UserPinOption[] }>(get_user_pin_options_path()).then((res) => res.data.entities);
    },
  }),
});

export function useMyPinList() {
  const { data: pins, isLoading, error } = useQuery(userPinsQuery.myPinList);

  return { pins, isLoading, error };
}

export function useUserPinList(params: GetUserPinsParams) {
  const { data: pins, isLoading, error } = useQuery(userPinsQuery.userPinList(params));

  return { pins, isLoading, error };
}

export function useUserPinOptions() {
  const { data: pinOptions, isLoading, error } = useQuery(userPinsQuery.userPinOptions());

  return { pinOptions, isLoading, error };
}

export function useSaveUserPins() {
  const queryClient = useQueryClient();
  const { queryKey } = userPinsQuery.myPinList;

  return useMutation({
    mutationFn: (data: SaveUserPins) => axios.post(save_user_pins_path(), data),
    onSuccess: async () => queryClient.invalidateQueries({ queryKey }),
  });
}
