import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  container: {
    width: '100%',
    fontFamily: 'Inter',

    '& .MuiFormControlLabel-root': {
      width: '100%',
      margin: 0,
      gap: 10,

      '& .MuiButtonBase-root': {
        paddingLeft: 0,
      },

      '& .MuiFormControlLabel-label': {
        color: 'white',
        fontSize: 16,
        lineHeight: 0,
      },
    },

    '& .error-message': {
      color: theme.palette.tx.error.primary,
      fontSize: '12px',
      margin: 0,
    },
  },
}));
