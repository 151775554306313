import { useParams } from '@tanstack/react-router';
/**
 * Custom route params type.
 */
interface CustomParams {
  organization?: string;
  project?: string;
  application?: string;
  applicationId?: string;
  module?: string;
  team?: string;
  user?: string;
  tag?: string;
  integrationType?: string;
  integrationName?: string;
  userToken?: string;
}
/**
 * useTypedParams Params type.
 */
type TypedParams<T extends string = string> = {
  [key in T]: string;
} & CustomParams;

/**
 * Typed the useParams with a custom types.
 */
const useTypedParams = <T extends string = string>() =>
  useParams<never, never, false, TypedParams<T>>({ strict: false });

export { CustomParams, TypedParams }; // types
export { useTypedParams };
