import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from '@tanstack/react-router';
// mui
import { Stack } from '@mui/material';
import Alert from '@mui/material/Alert';
// components
import { Button } from 'app-zephyr-components/Button';
import { Link } from 'app-zephyr-components/Link';
// _elements
import { FormContainer } from '../../_elements';
// utils
import { FormInput, FormMultiselect, FormTextArea } from 'app-zephyr-forms';
import { joiResolver } from '@hookform/resolvers/joi';
import { useTypedParams, application_settings_environment_path } from 'app-zephyr-routes';
// contracts
import {
  CreateApplicationEnvironment,
  CreateApplicationEnvironmentForm,
  formSchema,
} from 'ze-api-contract/application-v2/application-environment/create';
import { EnvironmentConditionOptions } from 'ze-api-contract/application-v2/application-environment/get-condition-list';
// styles
import { useStyles } from './styles';
import {
  ConditionValue,
  Channel,
  channelList,
} from 'ze-api-contract/application-v2/application-environment/interfaces';
import { TYPE_LABELS } from 'app-zephyr-domains/application/application-environment';
import { OpenExternal } from 'app-zephyr-icons/OpenExternal';
import { TooltipWrapper } from 'app-zephyr-components/Tooltip-wrapper';

type FormType = 'EDIT' | 'CREATE';

export type ApplicationEnvironmentFormFields = CreateApplicationEnvironmentForm & Record<string, string>;

interface ApplicationEnvironmentFormProps {
  isCreateEnvironmentPending: boolean;
  createEnvironmentError: Error | null;
  onConditionChange?: (value: ApplicationEnvironmentFormFields) => void;
  onSubmit: (value: CreateApplicationEnvironment) => Promise<void>;
  conditionList: EnvironmentConditionOptions;
  environment?: CreateApplicationEnvironmentForm;
  formValues: ApplicationEnvironmentFormFields | null;
  versionValue?: {
    version: string;
    remote_host: string;
  };
  type: FormType;
  isDnsNameEnabled: boolean;
}

function isEditFormType(type: FormType): boolean {
  return type === 'EDIT';
}

/**
 * Edit environment form.
 * @returns {React.FC} react-hook-form form implementation.
 */
const ApplicationEnvironmentForm = ({
  createEnvironmentError,
  isCreateEnvironmentPending,
  onSubmit,
  onConditionChange,
  conditionList,
  environment,
  formValues,
  type,
  versionValue,
  isDnsNameEnabled,
}: ApplicationEnvironmentFormProps) => {
  const { organization, project, application } = useTypedParams();
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    trigger,
    reset,
    watch,
    formState: { isValid, errors, isDirty },
  } = useForm<ApplicationEnvironmentFormFields>({
    defaultValues: environment as ApplicationEnvironmentFormFields,
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: joiResolver(formSchema),
  });

  const { classes } = useStyles();
  const navigate = useNavigate();

  const handleOnSubmit = handleSubmit(() => {
    const data = getValues();
    const result = {
      ...data,
      condition: {
        channel: data.condition.type.value as Channel,
        value: { label: data.condition.value.label, value: data.condition.value.value },
      },
      customDnsNames:
        data.customDnsNames
          ?.replace(/\s/g, '')
          .split(',')
          .filter((x) => x.length) ?? [],
    };

    void onSubmit(result);
  });

  const onCancel = () => {
    const projParams = {
      organization: { name: organization },
      project: { name: project },
      application: { name: application },
    };

    void navigate({
      to: application_settings_environment_path(projParams),
      search: (p: never) => p,
      params: (p: never) => p,
    });
  };

  const getTypeChannel = (): ConditionValue[] => {
    return channelList.map((channel) => {
      return { value: channel, label: TYPE_LABELS[channel] };
    });
  };

  const getValueChannel = () => {
    const values = getValues();
    const typeItem = values.condition.type.value;
    return conditionList[typeItem as keyof typeof conditionList];
  };

  useEffect(() => {
    reset(environment as ApplicationEnvironmentFormFields);
  }, [environment, reset, setValue]);

  useEffect(() => {
    if (!formValues) return;
    Object.keys(formValues).forEach((key) => {
      setValue(key, formValues[key], { shouldDirty: true, shouldValidate: true });
    });
  }, [formValues, reset, setValue]);

  return (
    <form onSubmit={(data) => void handleOnSubmit(data)} onChange={() => void trigger()}>
      <FormContainer title={isEditFormType(type) ? 'Edit environment' : 'Create environment'}>
        <Stack spacing={3}>
          <FormInput<ApplicationEnvironmentFormFields>
            id="name"
            name="name"
            label="Environment name"
            register={register}
            disabled={type === 'EDIT'}
            errors={errors}
            placeholder="Fill environment name"
          />

          <Stack>
            <span className={classes.title}>Environment conditions</span>
          </Stack>
          <Stack direction={'row'} spacing={3}>
            <FormMultiselect
              id="condition"
              name="condition"
              label="Channel"
              placeholder="Select channel condition"
              control={control}
              getValues={getValues}
              setValue={setValue}
              trigger={trigger}
              watch={watch}
              onFirstSelectChange={() => {
                setValue('condition.value', { label: '', value: '' } as never);
              }}
              onChange={() => {
                onConditionChange?.(getValues());
              }}
              options={{
                type: getTypeChannel(),
                value: getValueChannel(),
              }}
              errors={errors}
            />
            <FormInput
              id="applicationVersionId"
              name="applicationVersionId"
              label="Version"
              register={register}
              disabled={true}
              errors={errors}
              placeholder="Compatibility version"
            />
            {(!!versionValue?.remote_host || !!formValues?.applicationVersionId) && (
              <Stack alignSelf={'center'}>
                <a
                  href={versionValue?.remote_host ?? formValues?.applicationVersionId}
                  target="_blank"
                  style={{ display: 'block', height: 20 }}
                  rel="noreferrer"
                >
                  <OpenExternal width={20} height={20} />
                </a>
              </Stack>
            )}
          </Stack>
          <Stack spacing={1}>
            <FormInput
              id="customDnsNames"
              name="customDnsNames"
              label="Custom DNS name"
              register={register}
              hint={
                isDnsNameEnabled &&
                'Your custom DNS name should be separated by comma without prefix, for example: domain.com, domain_2.com'
              }
              errors={errors}
              placeholder="Fill custom DNS"
              disabled={!isDnsNameEnabled}
            />
            {!isDnsNameEnabled && (
              <p>
                This field is only available for BYOC (custom integration). Learn more on{' '}
                <Link to="https://docs.zephyr-cloud.io/how-to/custom-domain" target="_blank">
                  this page
                </Link>
              </p>
            )}
          </Stack>
          <FormTextArea
            id="description"
            name="description"
            label="Description"
            register={register}
            errors={errors}
            placeholder="Fill out a short description for this environment."
          />
        </Stack>
      </FormContainer>
      {!!createEnvironmentError && <Alert severity="error">Something went wrong, please try again</Alert>}
      <Stack display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} marginTop={2}>
        <TooltipWrapper name={'Navigate back to existing application environment list.'}>
          <Button variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
        </TooltipWrapper>
        <Button disabled={isCreateEnvironmentPending || !isValid || !isDirty} type="submit">
          {isEditFormType(type) ? 'Publish changes' : 'Create environment'}
        </Button>
      </Stack>
    </form>
  );
};

export { ApplicationEnvironmentForm };
export { ApplicationEnvironmentFormSkeleton } from './skeleton';
