import * as Joi from 'joi';

import {
  URL_STRING_JOI_VALIDATION_MESSAGES,
  APPLICATION_NAME_VALIDATOR,
  APPLICATION_ENVIRONMENT_NAME_VALIDATOR,
  URL_STRING_VALIDATOR,
  APPLICATION_ENVIRONMENT_NAME_JOI_VALIDATOR_MESSAGES,
  APPLICATION_NAME_JOI_VALIDATOR_MESSAGES,
  APPLICATION_DISPLAY_NAME_VALIDATOR,
  APPLICATION_DISPLAY_NAME_JOI_VALIDATOR_MESSAGES,
  requiredFieldJoiErrorMessage,
} from '../../validation';

// todo: should improved with messages and name validation

const environments = Joi.array()
  .items(
    Joi.object({
      name: Joi.string()
        .required()
        .pattern(APPLICATION_ENVIRONMENT_NAME_VALIDATOR.pattern.value)
        .min(APPLICATION_ENVIRONMENT_NAME_VALIDATOR.minLength.value)
        .max(APPLICATION_ENVIRONMENT_NAME_VALIDATOR.maxLength.value)
        .messages(APPLICATION_ENVIRONMENT_NAME_JOI_VALIDATOR_MESSAGES),
      remote_host: Joi.string()
        .required()
        .uri()
        .pattern(URL_STRING_VALIDATOR.pattern)
        .messages(URL_STRING_JOI_VALIDATION_MESSAGES),
      remote_entry_url: Joi.string().required().uri().messages(URL_STRING_JOI_VALIDATION_MESSAGES),
    }),
  )
  .min(1)
  .required()
  .unique((a, b) => a.name === b.name)
  //TODO: add validation messages for unique on UI
  .messages({
    'array.unique': 'Each environment name must be unique',
  });

export const createUnmanagedApplication = Joi.object({
  name: Joi.string()
    .required()
    .max(APPLICATION_NAME_VALIDATOR.maxLength.value)
    .min(APPLICATION_NAME_VALIDATOR.minLength.value)
    .pattern(APPLICATION_NAME_VALIDATOR.pattern.value)
    .messages(APPLICATION_NAME_JOI_VALIDATOR_MESSAGES),
  displayName: Joi.string()
    .required()
    .max(APPLICATION_DISPLAY_NAME_VALIDATOR.maxLength.value)
    .min(APPLICATION_DISPLAY_NAME_VALIDATOR.minLength.value)
    .pattern(APPLICATION_DISPLAY_NAME_VALIDATOR.pattern.value)
    .messages(APPLICATION_DISPLAY_NAME_JOI_VALIDATOR_MESSAGES),
  projectId: Joi.string().required().uuid({ version: 'uuidv4' }),
  environments,
})
  .required()
  .messages(requiredFieldJoiErrorMessage);

export const createUnmanagedApplicationFormSchema = createUnmanagedApplication.append({
  projectId: Joi.object({
    label: Joi.string().required(),
    value: Joi.string().required().uuid({ version: 'uuidv4' }),
  }).required(),
});
