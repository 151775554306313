import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  title: {
    fontSize: 14,
    fontWeight: 700,
    color: theme.palette.tx.secondary.default,
    lineHeight: 1.4,
  },
}));
