import { Stack, Breadcrumbs } from '@mui/material';

import { Button } from 'app-zephyr-components/Button';
import { NetlifyIcon } from 'app-zephyr-icons/Netlify';
import { getBreadCrumbList } from 'app-zephyr-deployment-integrations';
import {
  DeploymentIntegrationValue,
  DeploymentIntegrationPlatform,
  NetlifyDeploymentConfig,
} from 'ze-api-contract/deployment-integration/organization/interfaces';
import { OpenExternal } from 'app-zephyr-icons/OpenExternal';

import { useProviderPageStyles as useStyles } from '../../styles';
import { ItemDetails } from '../item-details';

interface NetlifyContentProps {
  breadCrumbList: {
    title: string;
    href?: string;
  }[];
  addIntegrationHandler: () => void;
  editIntegrationHandler: () => void;
  removeIntegrationHandler: () => void;
  setAsDefault?: () => void;
  integration: DeploymentIntegrationValue | undefined;
}

export const NetlifyContent = ({
  breadCrumbList,
  addIntegrationHandler,
  editIntegrationHandler,
  removeIntegrationHandler,
  integration,
  setAsDefault,
}: NetlifyContentProps) => {
  const { classes } = useStyles();

  const deploymentConfig = integration?.deploymentConfig as NetlifyDeploymentConfig | undefined;
  const hasCustomDomain = deploymentConfig?.domain && !deploymentConfig.domain.endsWith('.netlify.app');
  const hasSiteName = !!deploymentConfig?.siteName;
  const accountSlug = deploymentConfig?.accountSlug ?? '{YOUR-ACCOUNT-SLUG}';
  const customDomain = hasCustomDomain ? deploymentConfig.domain : '{YOUR-DOMAIN}';
  const siteName = deploymentConfig?.siteName ?? '{YOUR-SITE-NAME}';

  const netlifyDnsHttpsDocLink = `https://app.netlify.com/sites/${siteName}/domain-management#https`;
  const netlifyNameServersLink = `https://app.netlify.com/teams/${accountSlug}/dns/${customDomain}#name-servers`;

  return (
    <Stack spacing={2} className={classes.pageContent}>
      <Breadcrumbs separator="&#10094;" aria-label="breadcrumb" className={classes.breadCrumbBox}>
        {getBreadCrumbList(breadCrumbList)}
      </Breadcrumbs>

      <Stack className={classes.introBox} direction={'row'} spacing={2}>
        <Stack
          justifyContent={'center'}
          alignItems={'center'}
          padding={'8px'}
          width={48}
          height={48}
          className={classes.imgBox}
        >
          <NetlifyIcon />
        </Stack>
        <Stack direction={'column'} spacing={1}>
          <span className={classes.title_32}>{integration?.displayName ?? DeploymentIntegrationPlatform.NETLIFY}</span>
          <span className={classes.description_14}>
            Netlify is the essential platform for the delivery of exceptional and dynamic web experiences, without
            limitations.
          </span>
        </Stack>
        <Stack alignItems={'flex-end'} flexGrow={1} justifyContent={'center'}>
          {!integration?.name && (
            <Button className={classes.providerBtn} onClick={addIntegrationHandler}>
              Add Integration
            </Button>
          )}
          {!!integration?.name && (
            <Stack direction={'row'} spacing={1}>
              <Button onClick={editIntegrationHandler} sx={{ width: 100 }}>
                Edit
              </Button>
              <Button color="error" onClick={removeIntegrationHandler} sx={{ width: 100 }}>
                Remove
              </Button>
            </Stack>
          )}
        </Stack>
      </Stack>
      {!!integration && (
        <Stack className={classes.introBox} spacing={2}>
          <ItemDetails
            integrationConfig={integration.integrationConfig}
            deploymentConfig={deploymentConfig}
            type={DeploymentIntegrationPlatform.NETLIFY}
          />
          {!integration.isDefault && (
            <Button onClick={setAsDefault} variant="outlined" sx={{ width: 'fit-content' }}>
              Set as default
            </Button>
          )}
        </Stack>
      )}

      <Stack spacing={3}>
        <Stack spacing={2}>
          <span className={classes.title_24}>About</span>
          <p className={classes.description_16}>
            Netlify Composable Web Platform Streamlined orchestration, simplified and unified workflows, and real-time
            updates across infrastructure, workflows, websites and teams — all supported by Enterprise-grade security,
            services, and a world-class partner ecosystem.
          </p>
        </Stack>
        <Stack spacing={1}>
          <p className={classes.title_24}> For integration You will need to provide next information</p>
          <ul>
            <li>API Token</li>
            <li>Site ID</li>
          </ul>
          <p className={classes.description_14}>
            {' '}
            API token can be created here -{' '}
            <a
              className={classes.link}
              href="https://app.netlify.com/user/applications#oauth"
              target="_blank"
              rel="noreferrer"
            >
              {' '}
              netlify.com <OpenExternal width={16} height={16} />
            </a>
            . More information here -{' '}
            <a
              className={classes.link}
              href="https://docs.netlify.com/api/get-started/#authentication"
              target="_blank"
              rel="noreferrer"
            >
              {' '}
              netlify.authentication.com <OpenExternal width={16} height={16} />
            </a>
          </p>
        </Stack>

        <Stack spacing={1}>
          <p className={classes.title_24}>Netlify wildcard support for subdomains</p>

          <p className={classes.description_16}>
            Zephyr uses wildcard subdomain so You need provide your oun Domain to Netlify and setup it like described
            below
          </p>

          <p>For setup wildcard support for subdomains several conditions must be met:</p>
          <ul>
            <li>Have a Pro plan or more.</li>
            <li>DNS is managed by Netlify (add name servers to your domain settings)</li>
            <li>The site doesn’t have any domain aliases nor branch subdomains.</li>
          </ul>

          <p className={classes.title_16}>Point your domain’s name servers to Netlify</p>
          <p className={classes.description_14}>
            <span>follow this instruction</span> -{' '}
            {hasSiteName ? (
              <a className={classes.link} href={netlifyNameServersLink} target="_blank" rel="noreferrer">
                {netlifyNameServersLink} <OpenExternal width={16} height={16} />
              </a>
            ) : (
              <code>{netlifyNameServersLink}</code>
            )}
          </p>
          <p>
            To use Netlify DNS, go to your domain registrar and change your domain’s name servers to the following
            custom hostnames assigned to your DNS zone.
          </p>
          <ul>
            {/* eslint-disable-next-line @cspell/spellchecker */}
            <li>dns1.p{'{dd}'}.nsone.net</li>
            {/* eslint-disable-next-line @cspell/spellchecker */}
            <li>dns2.p{'{dd}'}.nsone.net</li>
            {/* eslint-disable-next-line @cspell/spellchecker */}
            <li>dns3.p{'{dd}'}.nsone.net</li>
            {/* eslint-disable-next-line @cspell/spellchecker */}
            <li>dns4.p{'{dd}'}.nsone.net</li>
          </ul>
          <a
            className={classes.link}
            href={'https://docs.netlify.com/domains-https/netlify-dns/delegate-to-netlify'}
            target="_blank"
            rel="noreferrer"
          >
            Learn more about directing DNS traffic to Netlify
            <OpenExternal width={16} height={16} />
          </a>
          <p>
            Visit{' '}
            {hasCustomDomain ? (
              <a className={classes.link} href={netlifyDnsHttpsDocLink} target="_blank" rel="noreferrer">
                {netlifyDnsHttpsDocLink} <OpenExternal width={16} height={16} />
              </a>
            ) : (
              <code>{netlifyDnsHttpsDocLink}</code>
            )}
          </p>
          <p>Check SSL/TLS certificate.</p>
          <p>
            Sure that section <code>Domains:</code> has next pattern <code>{`*.${customDomain}, ${customDomain}`}</code>
          </p>
          <p>
            Once the conditions are met, you need to <b>netlify contact support</b> and ask them to enable them wildcard
            for your site.
          </p>
          <p>After that, go to the DNS settings and add the following entry:</p>
          <p>
            <code>CNAME with name ‘*’ and value {`${siteName}.netlify.app’`} </code>
          </p>
          <p>
            Made <code>{`www.${customDomain}`}</code> primary
          </p>
        </Stack>

        <Stack spacing={1}>
          <p className={classes.title_24}>What will be created?</p>
          <p className={classes.description_16}>
            Environment variable <code>JWT_SECRET</code>
          </p>
          <p className={classes.description_16}>
            Function <code>ze_worker_for_upload</code>
          </p>
          <p className={classes.description_16}>
            Edge Function <code>ze_worker_for_serve</code>
          </p>
          <p className={classes.title_24}>How to use integration?</p>
          <p className={classes.description_14}>
            Create new Netlify deployment integration and try build new application with Zephyr. During deployment
            system take information about site using provided parameters. Using <code>Zephyr Plugin</code> build assets
            will be uploaded to Netlify Blobs (<code>ze-snapshots</code>, <code>ze-envs</code>, <code>ze-files</code>).
            Note tah site has to have custom domain with `*-ze.` subdomain.
          </p>
          <p className={classes.description_14}>
            During deployment will be created new Application Tags in Zephyr You can check it on application settings
            page. And for each tags will be created separate ze-env.
          </p>
        </Stack>
      </Stack>
    </Stack>
  );
};
