import { useMemo } from 'react';

import { useForm, useFieldArray } from 'react-hook-form';
import { Avatar, Stack, IconButton } from '@mui/material';
import { joiResolver } from '@hookform/resolvers/joi';

import { Button } from 'app-zephyr-components/Button';
import { FormCheckbox, FormInput, FormTextArea } from 'app-zephyr-forms';
import { FormBox } from 'app-zephyr-components/FormBox';
import { getAbbreviation } from 'app-zephyr-utils';
import { LinkIcon } from 'app-zephyr-icons/Link';
import { TrashIcon } from 'app-zephyr-icons/Trash';
import {
  OrganizationPublicSettings,
  OrganizationSettings,
  ChangeOrganizationName,
} from 'ze-api-contract/organization-v2/settings/interfaces';
import { formSchema } from 'ze-api-contract/organization-v2/settings/update-org-settings';
import { CanManageOrgConfig } from 'app-zephyr-guards/org';

import { useStyles } from './styles';

const MAX_SOCIAL_ACC_AMOUNT = 5;

type Avatar = Pick<OrganizationSettings, 'logoUrl' | 'avatarColor'>;
export type OrgSettingsForm = OrganizationPublicSettings &
  Partial<ChangeOrganizationName> &
  Partial<Avatar> & { isPrivate?: boolean };
export type PublicOrgSettingsFormFields = OrgSettingsForm & Record<string, string>;

export interface PublicOrgSettingsFormProps {
  onSubmit: (value: OrganizationPublicSettings) => void;
  settings: OrgSettingsForm;
  can: CanManageOrgConfig;
}

// todo: exclude social accounts into separate form and use it in profile settings
// todo: Sveta resolve trouble with dirty state after submit

const PublicOrgSettingsForm = ({ can, settings, onSubmit }: PublicOrgSettingsFormProps) => {
  const {
    register,
    reset,
    control,
    getValues,
    watch,
    formState: { isValid, errors, isDirty },
  } = useForm<PublicOrgSettingsFormFields>({
    defaultValues: {
      ...settings,
      isPrivate: settings.privacyOptions.isPrivate,
    } as PublicOrgSettingsFormFields,
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: joiResolver(formSchema),
  });

  const { fields, append, remove } = useFieldArray<PublicOrgSettingsFormFields>({
    control,
    name: 'socialAccounts' as never,
  });

  const socialAccountsWatch = watch();

  const { classes, theme, cx } = useStyles();

  const onSubmitHandler = () => {
    const data = getValues();

    const socialAccounts = data.socialAccounts.map((item) => {
      return {
        link: item.label,
        label: item.label,
      };
    });

    const socialAccountsRes = socialAccounts.filter((item) => !!item.link);
    const privacyOptions = { isPrivate: Boolean(data.isPrivate) };

    delete data.avatarColor;
    delete data.logoUrl;
    delete data.name;
    delete data.isPrivate;

    const res = {
      ...data,
      socialAccounts: socialAccountsRes,
      privacyOptions,
    } as OrganizationPublicSettings;

    onSubmit(res);
  };

  const onReset = () => {
    reset(settings as PublicOrgSettingsFormFields);
  };

  const onRemove = (index: number) => {
    if (fields.length < 2) return;
    remove(index);
  };

  const freeSocialAccAmount = (): boolean => {
    return !!(MAX_SOCIAL_ACC_AMOUNT - fields.length);
  };

  const isSocialItemDisabled = (index: number): boolean => {
    return !!errors.socialAccounts?.[index] || !getValues().socialAccounts[index].label;
  };

  const socialAccRedirect = (index: number) => {
    const url = getValues().socialAccounts[index].label;
    if (!url) return;
    window.open(url, '_blank');
  };

  const addSocialAccountsButtonDisabled = useMemo(() => {
    const socialAccounts = socialAccountsWatch.socialAccounts;

    return (
      !can.updateOrganizationSettings ||
      fields.length > MAX_SOCIAL_ACC_AMOUNT - 1 ||
      socialAccounts.some((acc) => !acc.label)
    );
  }, [can.updateOrganizationSettings, fields.length, socialAccountsWatch]);

  return (
    <form
      className={classes.form}
      onSubmit={(event) => {
        event.preventDefault();
        onSubmitHandler();
      }}
    >
      <Stack spacing={2}>
        <FormBox title="Public Information">
          <Stack spacing={2}>
            <Stack direction={'row'} spacing={3}>
              <Avatar
                alt={'avatar'}
                src={settings.logoUrl ?? ''}
                sx={{
                  width: '136px',
                  height: '136px',
                  backgroundColor: settings.avatarColor ?? theme.palette.tx.secondary.alt,
                }}
                className={classes.avatar}
              >
                {getAbbreviation(settings.displayName)}
              </Avatar>
              <Stack spacing={1} width={'100%'}>
                <FormInput
                  id="name"
                  name="name"
                  label="Organization name"
                  register={register}
                  disabled={true}
                  hint="This is your unique organization identifier that will be used by the system. It cannot be modified on Dashboard."
                  errors={errors}
                  placeholder="Fill organization name"
                />
                <FormInput
                  id="displayName"
                  name="displayName"
                  label="Display name"
                  register={register}
                  hint="This name is how your organization will be displayed on Zephyr."
                  errors={errors}
                  disabled={!can.updateOrganizationSettings}
                  placeholder="Fill organization display name"
                />
                <FormInput
                  className={classes.hintLink}
                  id="email"
                  name="email"
                  label="Public email"
                  register={register}
                  errors={errors}
                  disabled={!can.updateOrganizationSettings}
                  placeholder="Fill organization email"
                  hint="This email will be the contact email for any issues using Zephyr, typically provided by the person who set up Zephyr in your organization. It will be shown to everyone in your organization."
                />
              </Stack>
            </Stack>
            <FormTextArea
              id="description"
              name="description"
              label="Description"
              register={register}
              errors={errors}
              placeholder="Fill out a short description for this organization."
            />
            <Stack spacing={2}>
              <div>
                <h3 className={classes.title}>Social accounts</h3>
                <span className={cx(classes.hint, !freeSocialAccAmount() && 'warning')}>
                  {freeSocialAccAmount()
                    ? `${(MAX_SOCIAL_ACC_AMOUNT - fields.length).toString()} remaining`
                    : 'You have reached the maximum amount of accounts'}
                </span>
              </div>

              <Stack spacing={1}>
                {fields.map((field, index) => (
                  <div key={field.id + index.toString()}>
                    <Stack direction={'row'} maxWidth={360} spacing={1} alignItems={'center'}>
                      <IconButton
                        disableRipple
                        disabled={isSocialItemDisabled(index)}
                        onClick={() => {
                          socialAccRedirect(index);
                        }}
                      >
                        <LinkIcon
                          color={
                            isSocialItemDisabled(index)
                              ? theme.palette.tx.secondary.alt
                              : theme.palette.brand.turquoise[500]
                          }
                        />
                      </IconButton>
                      <FormInput
                        key={field.id}
                        id={`socialAccounts.${index.toString()}`}
                        name={`socialAccounts.${index.toString()}.label`}
                        register={register}
                        errors={errors}
                        placeholder="Fill social account link"
                      />
                      {fields.length > 1 && (
                        <IconButton
                          disableRipple
                          onClick={() => {
                            onRemove(index);
                          }}
                        >
                          <TrashIcon color={theme.palette.tx.error.primary} width={24} height={24} />
                        </IconButton>
                      )}
                    </Stack>
                  </div>
                ))}

                <Button
                  className={classes.textBtn}
                  variant="text"
                  disabled={addSocialAccountsButtonDisabled}
                  disableRipple
                  onClick={() => {
                    append({
                      label: '',
                      link: '',
                    });
                  }}
                >
                  Add additional link
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </FormBox>
      </Stack>
      <Stack spacing={2}>
        <FormBox title="Privacy options">
          <Stack spacing={2}>
            <FormCheckbox<PublicOrgSettingsFormFields>
              id="isPrivate"
              name="isPrivate"
              label="Make organization private"
              control={control}
              errors={errors}
            />
          </Stack>
        </FormBox>
      </Stack>
      <Stack display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} marginTop={2} gap={2}>
        <Button variant="outlined" onClick={onReset}>
          Reset
        </Button>
        <Button disabled={!isDirty || !isValid || !can.updateOrganizationSettings} type="submit">
          Save Changes
        </Button>
      </Stack>
    </form>
  );
};

export { PublicOrgSettingsForm };
