import { useForm, useFieldArray } from 'react-hook-form';
import { useNavigate } from '@tanstack/react-router';
import { Avatar, Stack, IconButton } from '@mui/material';
import { joiResolver } from '@hookform/resolvers/joi';
import { Button } from 'app-zephyr-components/Button';
import { FormInput, FormTextArea } from 'app-zephyr-forms';
import { formSchema } from 'ze-api-contract/user-v2/profile-settings/update-profile';
import { FormBox } from 'app-zephyr-components/FormBox';
import { getAbbreviation } from 'app-zephyr-utils';
import { Link } from 'app-zephyr-components/Link';
import { LinkIcon } from 'app-zephyr-icons/Link';
import { TrashIcon } from 'app-zephyr-icons/Trash';
import { UrlLink, ProfileSettingsBodyReq } from 'ze-api-contract/user-v2/profile-settings/interfaces';
import { authenticated_user_profile_path, user_settings_emails_path } from 'app-zephyr-routes';

import { useStyles } from './styles';

const MAX_SOCIAL_ACC_AMOUNT = 5;

export interface ProfileSettingsForm {
  name: string;
  email: string;
  username?: string;
  description?: string;
  socialAccounts?: UrlLink[];
  avatarColor?: string;
  userPortrait?: string;
}

export type PublicProfileSettingsFormFields = ProfileSettingsForm & Record<string, string>;

interface PublicProfileSettingsFormProps {
  onChange?: (value: PublicProfileSettingsFormFields) => void;
  onSubmit: (value: ProfileSettingsBodyReq) => void;
  settings: ProfileSettingsForm;
}

const PublicProfileSettingsForm = ({ settings, onSubmit }: PublicProfileSettingsFormProps) => {
  const {
    register,
    control,
    getValues,
    formState: { isValid, errors, isDirty },
  } = useForm<PublicProfileSettingsFormFields>({
    defaultValues: settings as PublicProfileSettingsFormFields,
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: joiResolver(formSchema),
  });

  const { fields, append, remove } = useFieldArray<PublicProfileSettingsFormFields>({
    control,
    name: 'socialAccounts' as never,
  });

  const { classes, theme, cx } = useStyles();
  const navigate = useNavigate();

  const onSubmitHandler = () => {
    const data = getValues();
    const socialAccounts =
      data.socialAccounts?.map((item) => {
        return {
          link: item.label,
          label: item.label,
        };
      }) ?? [];

    const socialAccountsRes = socialAccounts.filter((item) => !!item.link);

    const res = {
      name: data.name,
      description: data.description,
      socialAccounts: socialAccountsRes,
    };

    onSubmit(res);
  };

  const onCancel = () => {
    void navigate({ to: authenticated_user_profile_path(), search: (p: never) => p, params: (p: never) => p });
  };

  const onRemove = (index: number) => {
    if (fields.length < 2) return;
    remove(index);
  };

  const freeSocialAccAmount = (): boolean => {
    return !!(MAX_SOCIAL_ACC_AMOUNT - fields.length);
  };

  const socialAccRedirect = (index: number) => {
    const url = getValues().socialAccounts?.[index].label;
    if (!url) return;
    window.open(url, '_blank');
  };

  const isSocialItemDisabled = (index: number): boolean => {
    return !!errors.socialAccounts?.[index] || !getValues().socialAccounts?.[index].label;
  };

  return (
    <form onSubmit={onSubmitHandler} className={classes.form}>
      <Stack spacing={2}>
        <FormBox title="Public Profile">
          <Stack spacing={2}>
            <Stack direction={'row'} spacing={3} alignItems={'flex-start'}>
              <Avatar
                alt={'avatar'}
                src={settings.userPortrait ?? ''}
                sx={{
                  width: '136px',
                  height: '136px',
                  backgroundColor: settings.avatarColor ?? theme.palette.brand.purple,
                }}
                className={classes.avatar}
              >
                {getAbbreviation(settings.name)}
              </Avatar>
              <Stack spacing={1} width={'100%'}>
                <FormInput
                  id="name"
                  name="name"
                  label="Profile name"
                  register={register}
                  hint="It will appear around Zephyr-Cloud where you contribute or are mentioned"
                  errors={errors}
                />
                <FormInput
                  id="username"
                  name="username"
                  disabled={true}
                  label="Username"
                  register={register}
                  hint="This is your unique identifier that will be used by the system"
                  errors={errors}
                />
                <FormInput
                  className={classes.hintLink}
                  id="email"
                  name="email"
                  label="Public email"
                  register={register}
                  disabled={true}
                  hint={
                    <>
                      You can manage verified email addresses in your{' '}
                      <Link className={classes.link} to={user_settings_emails_path()}>
                        <span>email settings</span>
                      </Link>
                    </>
                  }
                  errors={errors}
                />
              </Stack>
            </Stack>
            <FormTextArea id="description" name="description" label="Description" register={register} errors={errors} />
            <Stack spacing={2}>
              <div>
                <h3 className={classes.title}>Social accounts</h3>
                <span className={cx(classes.hint, !freeSocialAccAmount() && 'warning')}>
                  {freeSocialAccAmount()
                    ? `${(MAX_SOCIAL_ACC_AMOUNT - fields.length).toString()} remaining`
                    : 'You have reached the maximum amount of accounts'}
                </span>
              </div>

              <Stack spacing={1}>
                {fields.map((field, index) => (
                  <div key={field.id + index.toString()}>
                    <Stack direction={'row'} maxWidth={360} spacing={1} alignItems={'center'}>
                      <IconButton
                        disableRipple
                        disabled={isSocialItemDisabled(index)}
                        onClick={() => {
                          socialAccRedirect(index);
                        }}
                      >
                        <LinkIcon
                          color={
                            isSocialItemDisabled(index) ? theme.palette.tx.disabled : theme.palette.brand.turquoise[500]
                          }
                        />
                      </IconButton>
                      <FormInput
                        id="link"
                        name={`socialAccounts.${index.toString()}.label`}
                        register={register}
                        placeholder="https://x.com/ZephyrCloudIO"
                        errors={errors}
                      />
                      {fields.length > 1 && (
                        <IconButton
                          disableRipple
                          onClick={() => {
                            onRemove(index);
                          }}
                        >
                          <TrashIcon
                            color={theme.palette.tx.error.primary || ''}
                            width={24}
                            height={24}
                            strokeWidth={1}
                          />
                        </IconButton>
                      )}
                    </Stack>
                  </div>
                ))}

                <Button
                  className={classes.textBtn}
                  variant="text"
                  disabled={
                    fields.length > MAX_SOCIAL_ACC_AMOUNT - 1 || getValues().socialAccounts?.some((acc) => !acc.label)
                  }
                  disableRipple
                  onClick={() => {
                    append({
                      label: '',
                      link: '',
                    });
                  }}
                >
                  Add additional link
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </FormBox>
      </Stack>
      <Stack display={'flex'} gap={2} flexDirection={'row'} justifyContent={'flex-end'} marginTop={2}>
        <Button variant="outlined" onClick={onCancel} type="button">
          Cancel
        </Button>
        <Button disabled={!isDirty || !isValid} type="submit">
          Save changes
        </Button>
      </Stack>
    </form>
  );
};

export { PublicProfileSettingsForm };
