import { FC, ComponentType, useEffect } from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import * as Sentry from '@sentry/browser';
// components
import { LazyLoadedComponent } from 'app-zephyr-components/LazyLoadedComponent';
import { Loading } from 'app-zephyr-components/Loading';
// domains
import { useCurrentUser } from 'app-zephyr-domains/user';

function withApiAuthenticationRequired<P>(Component: ComponentType): FC<P> {
  return (props: P): JSX.Element => {
    const { user, isLoading: isUserApiLoading } = useCurrentUser();
    const { logout, isLoading: isAuth0Loading } = useAuth0();

    useEffect(() => {
      if (!isAuth0Loading && !isUserApiLoading && !user) {
        logout().catch(() => void 0);
      }
    }, [user, logout, isAuth0Loading, isUserApiLoading]);

    if (!user) return <Loading />;

    Sentry.setUser(user);
    return <LazyLoadedComponent component={<Component {...props} />} />;
  };
}

interface RouteProps {
  component: ComponentType;
  [x: string]: unknown;
}

const ProtectedRoute = ({ component, ...args }: RouteProps) => {
  const ProtectedRouteAuth0 = withAuthenticationRequired(component, args);
  const ProtectedRouteInt = withApiAuthenticationRequired(ProtectedRouteAuth0);

  return <ProtectedRouteInt />;
};

export { withApiAuthenticationRequired, ProtectedRoute }; // routes
