import { HTTP as Cerbos } from '@cerbos/http';
import { envValue } from 'app-zephyr-environment';
/**
 * HTTP client to the cerbos server.
 */
const cerbos = new Cerbos(envValue.value.cerbosHttpUrl);
/**
 * Value is the type of values that can be used as attributes.
 */
export type AttributeValue = string | number | boolean | null | { [key: string]: AttributeValue } | AttributeValue[];
/**
 * Principal is the entity that is trying to access a resource.
 */
export interface Principal {
  id: string;
  roles: string[];
  attributes?: Record<string, AttributeValue>;
}
/**
 * Resource is the entity that is being accessed.
 */
export interface Resource {
  id: string;
  kind: string;
  attributes?: Record<string, AttributeValue>;
}
/**
 * DecisionProps is the input to the getDecision function.
 */
export interface DecisionProps {
  principal: Principal;
  resource: Resource;
  actions: string[];
}
/**
 * getDecision returns the cerbos decision for the given principal, resource and actions.
 * @example
    const decision = await getDecision({
    principal: {
      id: "harry",
      roles: [
        "organization_owner",
      ],
    },
    resource: {
      kind: "organization",
      id: "organization1",
    },
    actions: [
      "create-organization",
      "get_org_people_list",
    ],
  });

  if (decision.isAllowed("create-organization")) {
    // perform the action...
  }

  if (decision.isAllowed("get_org_people_list")) {
    // perform the action...
  }
 */
export const getDecision = ({ principal, resource, actions }: DecisionProps) =>
  cerbos.checkResource({
    principal,
    resource,
    actions,
  });
