import { useNavigate } from '@tanstack/react-router';

import {
  organization_deployment_integrations_new_path,
  organization_deployment_integrations_edit_path,
} from 'app-zephyr-routes';
import { DeploymentIntegrationValue } from 'ze-api-contract/deployment-integration/organization/interfaces';

import { CloudflareContent } from '../../components/cloudflare-content';
import { getBreadCrumbList } from '../../utils';

export const CloudflarePage = ({
  integration,
  organization,
  platform,
  onDelete,
  onSetDefaultIntegration,
}: {
  integration: DeploymentIntegrationValue | undefined;
  organization: string;
  platform: string;
  onDelete: () => void;
  onSetDefaultIntegration: () => void;
}) => {
  const navigate = useNavigate();

  return (
    <CloudflareContent
      breadCrumbList={getBreadCrumbList({ organization, platform, name: integration?.name })}
      addIntegrationHandler={() => {
        void navigate({
          to: organization_deployment_integrations_new_path({
            organization: { name: organization },
            type: { name: platform },
          }),
        });
      }}
      editIntegrationHandler={() => {
        void navigate({
          to: organization_deployment_integrations_edit_path({
            organization: { name: organization },
            type: { name: platform },
            name: { name: integration?.name ?? '' },
          }),
        });
      }}
      removeIntegrationHandler={() => {
        onDelete();
      }}
      setAsDefault={onSetDefaultIntegration}
      integration={integration}
    />
  );
};

export default CloudflarePage;
