import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useQuery } from '@tanstack/react-query';

import { axios } from 'app-zephyr-axios';
import { Team } from 'ze-api-contract/team/interfaces';

export const teamQuery = createQueryKeys('team', {
  getById: (teamId?: string) => ({
    queryKey: ['team', teamId],
    queryFn: () => {
      if (!teamId) return Promise.resolve(undefined);

      return axios.get<{ value: Team }>(`/v2/team/${teamId}`).then((res) => res.data.value);
    },
  }),
});

export function useTeam(teamId?: string) {
  const { isLoading, data: team, error } = useQuery(teamQuery.getById(teamId));

  return { team, isLoading, error };
}
