import { simplePath } from 'ze-api-contract/utils';
import { RouteNames } from './route-names';

const user_cache = new Map<string, string>();

interface UserProps {
  username: string | undefined;
}

function cache_user(path: string, { username }: UserProps): string {
  if (!username) {
    return '/';
  }

  const cache_key = `${path}:${username}`;
  const cached = user_cache.get(cache_key);

  if (cached) {
    return cached;
  }

  const url = simplePath(path, { username });
  user_cache.set(cache_key, url);

  return url;
}

export function user_path(props: UserProps): string {
  return cache_user(RouteNames.USER_PROFILE, props);
}

export function authenticated_user_profile_path(): string {
  return RouteNames.AUTHENTICATED_USER_PROFILE;
}

export function user_settings_path(): string {
  return RouteNames.USER_PROFILE_SETTINGS;
}

export function user_settings_emails_path(): string {
  return RouteNames.USER_PROFILE_SETTINGS_EMAILS;
}

export function user_settings_user_tokens_path(): string {
  return RouteNames.USER_PROFILE_SETTINGS_USER_TOKENS;
}

export function user_settings_user_token_generate_path(): string {
  return RouteNames.USER_PROFILE_SETTINGS_GENERATE_USER_TOKENS;
}

export function user_settings_user_token_edit_path(userToken: string): string {
  if (!userToken) return '';
  return simplePath(RouteNames.USER_PROFILE_SETTINGS_EDIT_USER_TOKEN, { userToken });
}
