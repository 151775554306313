/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { ComponentProps, ComponentType, PropsWithChildren } from 'react';

export const provider = <C extends React.JSXElementConstructor<any>>(
  provider: C,
  props: Partial<ComponentProps<C>> = {},
): [C, Partial<ComponentProps<C>>] => [provider, props];

interface ProviderComposerProps {
  providers: [React.JSXElementConstructor<PropsWithChildren> | ComponentType, Record<string, any>][];
  children: React.JSX.Element | React.JSX.Element[];
}

export const ProviderComposer = ({ providers, children }: ProviderComposerProps) => {
  for (let i = providers.length - 1; i >= 0; --i) {
    const [Provider, props] = providers[i];
    children = <Provider {...props}>{children}</Provider>;
  }
  return children;
};
