import { makeStyles } from 'tss-react/mui';
export const useStyles = makeStyles()((theme) => ({
  actionBtn: {
    padding: '8px',
    width: 'auto',
    minWidth: 0,
    border: 'none',
    borderRadius: '4px',
    backgroundColor: theme.palette.tx.white,
    transition: 'background-color 0.3s ease, transform 0.2s ease', // Transition for background color and transform (for click effect)
    svg: {
      fill: theme.palette.bg.primary.default,
    },
    '&:hover': {
      border: 'none',
      backgroundColor: theme.palette.tx.primary,
      svg: {
        fill: theme.palette.tx.primary,
      },
    },
    // TODO: this is not applied
    '&:active': {
      backgroundColor: theme.palette.bg.primary.solid, // Background color when clicked
      transform: 'scale(0.98)', // Slightly scale down for a click effect
    },

    '& .MuiButton-startIcon': {
      margin: 0,
      transition: 'fill 0.3s ease', // Transition for icon color change
    },

    '&:active .MuiButton-startIcon svg': {
      fill: '#ffffff', // Icon color when clicked
    },
  },

  menuContainer: {
    '.MuiPaper-root': {
      background: theme.palette.bg.primary.default,
      borderRadius: '8px',
      border: theme.palette.border.secondary,
      textAlign: 'right',
    },
    '.MuiMenuItem-root': {
      padding: 0,
      color: theme.palette.tx.secondary.default,
      alignItems: 'center',
      justifyContent: 'space-between',
      '&:hover': {
        color: theme.palette.tx.secondary.hover,
        background: theme.palette.bg.primary.hover,
      },
      'a, span': {
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '150%',
        color: theme.palette.tx.secondary.default,
        textDecoration: 'none',
      },
      svg: {
        fill: theme.palette.tx.tertiary.default,
      },
      '.Mui-focusVisible, &:hover': {
        background: theme.palette.bg.secondary.hover,
      },
    },
  },
  flexMenu: {
    display: 'flex',
  },
}));
