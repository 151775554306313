import { Link } from '@tanstack/react-router';
import { makeStyles } from 'tss-react/mui';
import { LightHorizontalWithLogoImg } from 'app-zephyr-components/LightHorizontalWithLogoImg';
import { RouteNames } from 'app-zephyr-routes';
import { envValue } from 'app-zephyr-environment';

const useStyles = makeStyles()((theme) => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    maxHeight: 'calc(100vh - 70px)',
    overflow: 'scroll',

    '& button': {
      display: 'block',
      width: '245px',
      borderRadius: '8px',
      padding: '8px 16px',
      fontSize: '16px',
      fontWeight: 600,
      background: 'transparent',
      border: theme.palette.border.secondary,
      color: theme.palette.tx.primary,
      cursor: 'pointer',
    },

    '& button.filledBtn': {
      background: theme.palette.bg.quarterary,
      border: 'none',
      marginBottom: '16px',
    },

    '& img': {
      marginLeft: '10px',
    },

    '& h2': {
      fontSize: '32px',
      fontWeight: 700,
      lineHeight: '120%',
      color: theme.palette.tx.success.primary,
      margin: 0,
    },

    '& p': {
      marginTop: '16px',
      fontSize: '18px',
      fontWeight: 500,
      lineHeight: '120%',
      color: theme.palette.tx.primary,
    },

    '& p.grey': {
      color: theme.palette.tx.secondary.hover,
    },
  },

  navbar: {
    padding: '32px',
    display: 'flex',
    alignItems: 'center',
  },

  line: {
    width: '100%',
    flex: '1',
    height: '1px',
    marginLeft: '32px',
    border: 'none',
    borderBottom: theme.palette.border.secondary,
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '780px',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    background: theme.palette.bg.tertiary,
    borderRadius: '16px',
    padding: '24px 40px',
  },

  paper: {
    display: 'block',
    textAlign: 'center',
  },

  logoLink: {
    cursor: 'pointer',
  },

  box: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },

  top: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },

  content: {
    marginTop: '40px',
  },

  innerBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '32px',
  },

  btnBox: {
    marginTop: '24px',
  },
}));

export default function ForbiddenErrorNotValidEmail() {
  const { classes } = useStyles();
  const auth0Domain = envValue.value.auth0.domain;
  const clientId = envValue.value.auth0.clientId;
  const baseUrl = window.location.origin;
  const logOutPath = `${auth0Domain}/v2/logout?returnTo=${baseUrl}&client_id=${clientId}`;
  function logOutRedirect() {
    window.location.href = logOutPath;
  }

  function subscribeRedirect() {
    window.location.href = 'https://zephyr-cloud.typeform.com/to/YGPRccXJ?typeform-source=t.co';
  }

  return (
    <div className={classes.page}>
      <div className={classes.navbar}>
        <Link to={RouteNames.PERSONAL_DASHBOARD} search={(p: never) => p} params={(p: never) => p}>
          <LightHorizontalWithLogoImg class={classes.logoLink} />
        </Link>
        <hr className={classes.line} />
      </div>
      <div className={classes.box}>
        <div className={classes.container}>
          <div className={classes.top}>
            <Link to={RouteNames.PERSONAL_DASHBOARD} search={(p: never) => p} params={(p: never) => p}>
              <LightHorizontalWithLogoImg class={classes.logoLink} />
            </Link>
            <hr className={classes.line} />
          </div>
          <div className={classes.innerBox}>
            <div>
              <h2>Join Our Closed Alpha</h2>
              <p>We appreciate your interest!</p>
              <p className={'grey'}>
                Zephyr Cloud is currently only available for closed alpha users. Please fill out this form to sign up
                for closed alpha and we will contact you as soon as we are able to let you in.
              </p>
              <div className={classes.btnBox}>
                <button className={'filledBtn'} onClick={subscribeRedirect}>
                  Get invited to Closed Alpha
                </button>
                <button onClick={logOutRedirect}>Try with another email</button>
              </div>
            </div>
            <img src={'/svg/vectors/authorized.svg'} alt={'Access Denied Illustration'} width={340} height={240} />
          </div>
        </div>
      </div>
    </div>
  );
}
