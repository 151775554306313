import { axios } from 'app-zephyr-axios';
import { QueryClient } from '@tanstack/react-query';
import { createQueryKeys } from '@lukemorales/query-key-factory';

import {
  GET_ORGANIZATION_UNIQUE_NAME_PATH,
  get_organization_uniquer_name_path,
} from 'ze-api-contract/organization-v2/get-organization-unique-name';
import {
  GET_IS_UNIQUE_ORG_NAME_PATH,
  get_organization_by_name_path,
} from 'ze-api-contract/organization-v2/get-is-unique-org-name';

export const organizationUniqueNameQuery = createQueryKeys('organization', {
  getUniqueName: (displayName?: string) => ({
    queryKey: [GET_ORGANIZATION_UNIQUE_NAME_PATH, displayName],
    queryFn: async () => {
      if (!displayName) return;

      return axios
        .get<{ value: string }>(get_organization_uniquer_name_path({ displayName }))
        .then((res) => res.data.value);
    },
  }),
  getIsUniqueOrgName: (name?: string) => ({
    queryKey: [GET_IS_UNIQUE_ORG_NAME_PATH, name],
    queryFn: async () => {
      if (!name) return false;

      return axios.get<{ value: boolean }>(get_organization_by_name_path({ name })).then((res) => res.data.value);
    },
  }),
});

export async function getUniqueOrganizationName(queryClient: QueryClient, displayName: string): Promise<string> {
  return queryClient.fetchQuery(organizationUniqueNameQuery.getUniqueName(displayName));
}

export async function useGetIsUniqueOrganizationName(queryClient: QueryClient, name?: string): Promise<boolean> {
  return queryClient.fetchQuery(organizationUniqueNameQuery.getIsUniqueOrgName(name));
}
